import React, { useCallback, ChangeEvent, useImperativeHandle } from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import './TextInput.css'

export interface TextInputRef {
  focus: () => void
}

export interface TextInputProps {
  type?: 'text' | 'textarea' | 'password' | 'price'
  value: string | undefined
  label?: string | null
  placeholder: string
  isLocked?: boolean
  className?: string
  width?: string
  onChange: (value: string) => void
  onClick?: () => void
  autoComplete?: string | undefined
  isMandatory?: boolean
  isWrong?: boolean
  borderOnFocus?: boolean
  withRoundCorners?: boolean
  size: 'small' | 'medium' | 'large'
  textAreaRowsCount?: number
  minPrice?: number
  maxPrice?: number
  priceDecimal?: boolean
}

export const TextInput = React.forwardRef<TextInputRef, TextInputProps>(
  (
    {
      type = 'text',
      value,
      placeholder,
      label = null,
      isLocked = false,
      className,
      width = '100%',
      onChange,
      onClick,
      autoComplete = undefined,
      isMandatory = false,
      isWrong = false,
      borderOnFocus = true,
      withRoundCorners = false,
      size = 'medium',
      textAreaRowsCount,
      minPrice = 0,
      maxPrice = Number.MAX_VALUE,
      priceDecimal = true,
    },
    ref
  ) => {
    const handleChange = useCallback(
      (newValue: string) => {
        if (value !== newValue) {
          onChange(newValue)
        }
      },
      [onChange, value]
    )

    const handleInputChange = useCallback(
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        handleChange(e.target.value)
      },
      [handleChange]
    )

    const handleNumberChange = useCallback(
      (values: NumberFormatValues) => {
        handleChange(values.value)
      },
      [handleChange]
    )

    const textInput = React.useRef<HTMLInputElement>(null)
    const textArea = React.useRef<HTMLTextAreaElement>(null)

    useImperativeHandle(ref, () => ({
      focus: () => {
        type === 'textarea'
          ? textArea.current && textArea.current.focus()
          : textInput.current && textInput.current.focus()
      },
    }))

    if (isMandatory) placeholder += '*'

    return (
      <div
        className={
          'text-input-wrapper' +
          (className ? ' ' + className : '') +
          (isWrong ? ' error' : '') +
          ' ' +
          size
        }
        onClick={onClick}
      >
        {label && (
          <div className={'label'}>
            <span>
              {label}
              {isMandatory ? ' *' : ''}
            </span>
          </div>
        )}
        {type === 'textarea' ? (
          <textarea
            ref={textArea}
            className={
              'text-area' +
              (borderOnFocus ? '' : ' no-focus') +
              (withRoundCorners ? ' round-corners' : '')
            }
            value={value}
            placeholder={placeholder}
            onChange={handleInputChange}
            autoComplete={autoComplete}
            rows={textAreaRowsCount}
            style={{ width: width }}
            disabled={isLocked}
          />
        ) : type === 'price' ? (
          <NumberFormat
            className={
              'text-input' +
              (borderOnFocus ? '' : ' no-focus') +
              (withRoundCorners ? ' round-corners' : '')
            }
            placeholder={placeholder}
            thousandsGroupStyle="thousand"
            value={value}
            onValueChange={handleNumberChange}
            decimalSeparator="."
            displayType="input"
            type="text"
            thousandSeparator={' '}
            allowNegative={false}
            decimalScale={priceDecimal ? 2 : 0}
            fixedDecimalScale={true}
            isAllowed={({ floatValue }) => {
              if (floatValue === undefined) return false
              return floatValue <= maxPrice ? true : floatValue >= minPrice ? true : false
            }}
            disabled={isLocked}
          />
        ) : (
          <input
            ref={textInput}
            className={
              'text-input' +
              (borderOnFocus ? '' : ' no-focus') +
              (withRoundCorners ? ' round-corners' : '')
            }
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={handleInputChange}
            autoComplete={autoComplete}
            style={{ width: width }}
            disabled={isLocked}
          />
        )}
      </div>
    )
  }
)
