import {
  ComputerVisionFileDTO,
  ObjectDetectionCreationDTO,
  ObjectDetectionDTO,
  VisualSearchCreationDTO,
  VisualSearchDTO,
} from '@fynde/dtos'
import axiosApi from '../utils/axios'
import { dataURLToFile } from '../utils/tool'

// GET
export const getObjectDetection = async (
  objectDetectionId: string
): Promise<ObjectDetectionDTO | null> => {
  try {
    const resp = await axiosApi.get<ObjectDetectionDTO>(
      `/computer-vision/object-detection/${objectDetectionId}`
    )
    return resp.data
  } catch (err) {
    console.error('An error occured while getting an object-detection:', err)
  }
  return null
}

export const getVisualSearch = async (visualSearchId: string): Promise<VisualSearchDTO | null> => {
  try {
    const resp = await axiosApi.get<VisualSearchDTO>(
      `/computer-vision/visual-search/${visualSearchId}`
    )
    return resp.data
  } catch (err) {
    console.error('An error occured while getting a visual-search:', err)
  }
  return null
}

// POST
export const postCVFile = async (
  base64File: string,
  onUploadProgress: (progressEvent: any) => void
): Promise<ComputerVisionFileDTO | null> => {
  console.debug('Post a CV file')

  const file = dataURLToFile(base64File, `request-${Date.now()}.jpeg`)
  if (!file) {
    console.error('Could not convert file from base64')
    return null
  }

  const formData = new FormData()
  formData.append('file', file)

  try {
    const resp = await axiosApi.post<ComputerVisionFileDTO>(`/cv-file/`, formData, {
      onUploadProgress: (progressEvent) => onUploadProgress(progressEvent),
      timeout: 120000,
    })
    return resp.data
  } catch (err) {
    console.error('An error occured while posting a cv-file:', err)
  }
  return null
}

export const postObjectDetection = async (
  dto: ObjectDetectionCreationDTO
): Promise<ObjectDetectionDTO | null> => {
  try {
    const resp = await axiosApi.post<ObjectDetectionDTO>('/computer-vision/object-detection', dto)
    return resp.data
  } catch (err) {
    console.error('An error occured while posting an object-detection:', err)
  }
  return null
}

export const postVisualSearch = async (
  dto: VisualSearchCreationDTO
): Promise<VisualSearchDTO | null> => {
  try {
    const resp = await axiosApi.post<VisualSearchDTO>('/computer-vision/visual-search', dto)
    return resp.data
  } catch (err) {
    console.error('An error occured while posting an object-detection:', err)
  }
  return null
}
