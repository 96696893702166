import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput } from '../../atoms/TextInput/TextInput'
import './ProfessionalModal.css'
import {
  ProfessionalDTO,
  UserProjectProfessionalCreationDTO,
  UserProjectProfessionalUpdateDTO,
} from '@fynde/dtos'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { Modal } from '../../organisms/Modal/Modal'
import { Button } from '../../atoms/Button/Button'

export interface ProfessionalModalProps {
  isOpen: boolean
  onSubmit?: () => void
  onCancel: () => void
  userProjectId: string
  professional?: ProfessionalDTO
}

export const ProfessionalModal: React.FC<ProfessionalModalProps> = ({
  isOpen,
  onSubmit,
  onCancel,
  userProjectId,
  professional,
}) => {
  const { t } = useTranslation()

  // data
  const [firstName, setFirstName] = useState<string>('')
  const hasFirstNameChanged = useMemo(() => {
    return !!professional && firstName !== (professional.firstName || '')
  }, [professional, firstName])

  const [lastName, setLastName] = useState<string>('')
  const hasLastNameChanged = useMemo(() => {
    return !!professional && lastName !== (professional.lastName || '')
  }, [professional, lastName])

  const [job, setJob] = useState<string>('')
  const hasJobChanged = useMemo(() => {
    return !!professional && job !== (professional.job || '')
  }, [professional, job])

  const [companyName, setCompanyName] = useState<string>('')
  const hasCompanyNameChanged = useMemo(() => {
    return !!professional && companyName !== (professional.companyName || '')
  }, [professional, companyName])

  const [phone, setPhone] = useState<string>('')
  const hasPhoneChanged = useMemo(() => {
    return !!professional && phone !== (professional.phone || '')
  }, [professional, phone])

  const [email, setEmail] = useState<string>('')
  const hasEmailChanged = useMemo(() => {
    return !!professional && email !== (professional.email || '')
  }, [professional, email])

  const [website, setWebsite] = useState<string>('')
  const hasWebsiteChanged = useMemo(() => {
    return !!professional && website !== (professional.website || '')
  }, [professional, website])

  const [addressLine1, setAddressLine1] = useState<string>('')
  const [addressLine2, setAddressLine2] = useState<string>('')
  const [addressZipcode, setAddressZipcode] = useState<string>('')
  const [addressCity, setAddressCity] = useState<string>('')
  const [addressRegion, setAddressRegion] = useState<string>('')
  const [addressCountry, setAddressCountry] = useState<string>('')
  const hasCompanyAddressChanged = useMemo(() => {
    return (
      !!professional &&
      (addressLine1 !== (professional.companyAddress?.line1 || '') ||
        addressLine2 !== (professional.companyAddress?.line2 || '') ||
        addressZipcode !== (professional.companyAddress?.zipcode || '') ||
        addressCity !== (professional.companyAddress?.city || '') ||
        addressRegion !== (professional.companyAddress?.region || '') ||
        addressCountry !== (professional.companyAddress?.country || ''))
    )
  }, [
    professional,
    addressLine1,
    addressLine2,
    addressZipcode,
    addressCity,
    addressRegion,
    addressCountry,
  ])

  const clearForm = useCallback(() => {
    setFirstName(professional?.firstName || '')
    setLastName(professional?.lastName || '')
    setJob(professional?.job || '')
    setCompanyName(professional?.companyName || '')
    setPhone(professional?.phone || '')
    setEmail(professional?.email || '')
    setWebsite(professional?.website || '')
    setAddressLine1(professional?.companyAddress?.line1 || '')
    setAddressLine2(professional?.companyAddress?.line2 || '')
    setAddressZipcode(professional?.companyAddress?.zipcode || '')
    setAddressCity(professional?.companyAddress?.city || '')
    setAddressRegion(professional?.companyAddress?.region || '')
    setAddressCountry(professional?.companyAddress?.country || '')
  }, [professional])

  useEffect(() => {
    clearForm()
  }, [professional])

  // validation
  const hasDataChanged = useMemo(() => {
    if (!professional) return false
    return (
      hasFirstNameChanged ||
      hasLastNameChanged ||
      hasJobChanged ||
      hasCompanyNameChanged ||
      hasPhoneChanged ||
      hasEmailChanged ||
      hasWebsiteChanged ||
      hasCompanyAddressChanged
    )
  }, [
    professional,
    hasFirstNameChanged,
    hasLastNameChanged,
    hasJobChanged,
    hasCompanyNameChanged,
    hasPhoneChanged,
    hasEmailChanged,
    hasWebsiteChanged,
    hasCompanyAddressChanged,
  ])

  const isCompanyNameValid = useMemo(() => {
    return companyName.search(/\w/) > -1
  }, [companyName])

  const isJobValid = useMemo(() => {
    return job.search(/\w/) > -1
  }, [job])

  const isFormValid = useMemo(() => {
    let result = isCompanyNameValid && isJobValid
    if (professional) result = result && hasDataChanged
    return result
  }, [professional, isCompanyNameValid, isJobValid, hasDataChanged])

  // cancel
  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  // submit
  const userId = useStoreState((store) => store.user.userId())
  const postProfessional = useStoreActions(
    (actions) => actions.projectProfessionals.postProjectProfessional
  )
  const patchProfessional = useStoreActions(
    (actions) => actions.projectProfessionals.patchUserProjectProfessional
  )

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const getPostDTO = () => {
    if (!userId) {
      console.error(
        '[ProfessionalModal] cannot post a new user-project-professional: userId is missing'
      )
      return null
    }
    let dto: UserProjectProfessionalCreationDTO = {
      userProjectId: userProjectId,
      professional: {
        companyName,
        job,
        firstName,
        lastName,
        email,
        phone,
        website,
        companyAddress: {
          line1: addressLine1,
          line2: addressLine2,
          zipcode: addressZipcode,
          city: addressCity,
          region: addressRegion,
          country: addressCountry,
        },
        createdBy: userId,
      },
    }
    // remove undefined and empty string values
    dto = JSON.parse(
      JSON.stringify(dto, (key, value) => {
        if (value === undefined) return undefined
        if (typeof value === 'string' && value.search(/\w/) === -1) return undefined
        return value
      })
    )
    return dto
  }

  const getPatchDTO = () => {
    if (!professional) {
      console.error(
        '[ProfessionalModal] cannot patch a user-project-professional: entity is missing'
      )
      return null
    }
    if (!hasDataChanged) {
      console.error(
        '[ProfessionalModal] cannot patch a user-project-professional: data has not changed'
      )
      return null
    }

    let dto: UserProjectProfessionalUpdateDTO = {
      userProjectId: userProjectId,
      professionalId: professional.id,
      professional: {
        companyName,
        job,
        firstName,
        lastName,
        email,
        phone,
        website,
        companyAddress: {
          line1: addressLine1,
          line2: addressLine2,
          zipcode: addressZipcode,
          city: addressCity,
          region: addressRegion,
          country: addressCountry,
        },
      },
    }
    // remove undefined and empty string values
    dto = JSON.parse(
      JSON.stringify(dto, (key, value) => {
        if (value === undefined) return undefined
        if (typeof value === 'string' && value.search(/\w/) === -1) return null
        return value
      })
    )
    return dto
  }

  const handleSubmit = async () => {
    console.debug('[ProfessionalModal] handleSubmit')
    if (!isFormValid) return

    let success = false

    // post
    if (!professional) {
      const dto = getPostDTO()
      console.log('[ProfessionalModal] post user-project-professional:', dto)
      if (!dto) return

      setIsSubmitting(true)
      success = await postProfessional(dto)
    }
    //patch
    else {
      const dto = getPatchDTO()
      console.log('[ProfessionalModal] patch user-project-professional:', dto)
      if (!dto) return

      setIsSubmitting(true)
      success = await patchProfessional(dto)
    }

    setIsSubmitting(false)
    if (success) {
      clearForm()
      handleCancel()
      if (onSubmit) onSubmit()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      header={
        professional ? t('professionalModal.update.header') : t('professionalModal.add.header')
      }
      withCross={true}
      withButtons={false}
      onCancel={handleCancel}
      className={'professional-modal'}
    >
      <>
        <div className={'form'}>
          {/* Company Name */}
          <div className={'row mandatory' + (isCompanyNameValid ? ' good' : '')}>
            <div className={'field'}>{t('professionalModal.companyName.field')}</div>
            <div className={'input'}>
              <TextInput
                type={'text'}
                placeholder={t('professionalModal.companyName.placeholder')}
                size={'medium'}
                value={companyName}
                onChange={setCompanyName}
                isLocked={isSubmitting}
              />
            </div>
          </div>
          {/* Job */}
          <div className={'row mandatory' + (isJobValid ? ' good' : '')}>
            <div className={'field'}>{t('professionalModal.job.field')}</div>
            <div className={'input'}>
              <TextInput
                type={'text'}
                placeholder={t('professionalModal.job.placeholder')}
                size={'medium'}
                value={job}
                onChange={setJob}
                isLocked={isSubmitting}
              />
            </div>
          </div>
          {/* First Name */}
          <div className={'row'}>
            <div className={'field'}>{t('professionalModal.firstName.field')}</div>
            <div className={'input'}>
              <TextInput
                type={'text'}
                placeholder={t('professionalModal.firstName.placeholder')}
                size={'medium'}
                value={firstName}
                onChange={setFirstName}
                isLocked={isSubmitting}
              />
            </div>
          </div>
          {/* Last Name */}
          <div className={'row'}>
            <div className={'field'}>{t('professionalModal.lastName.field')}</div>
            <div className={'input'}>
              <TextInput
                type={'text'}
                placeholder={t('professionalModal.lastName.placeholder')}
                size={'medium'}
                value={lastName}
                onChange={setLastName}
                isLocked={isSubmitting}
              />
            </div>
          </div>
          {/* Phone */}
          <div className={'row'}>
            <div className={'field'}>{t('professionalModal.phone.field')}</div>
            <div className={'input'}>
              <TextInput
                type={'text'}
                placeholder={t('professionalModal.phone.placeholder')}
                size={'medium'}
                value={phone}
                onChange={setPhone}
                isLocked={isSubmitting}
              />
            </div>
          </div>
          {/* Email */}
          <div className={'row'}>
            <div className={'field'}>{t('professionalModal.email.field')}</div>
            <div className={'input'}>
              <TextInput
                type={'text'}
                placeholder={t('professionalModal.email.placeholder')}
                size={'medium'}
                value={email}
                onChange={setEmail}
                isLocked={isSubmitting}
              />
            </div>
          </div>
          {/* Website */}
          <div className={'row'}>
            <div className={'field'}>{t('professionalModal.website.field')}</div>
            <div className={'input'}>
              <TextInput
                type={'text'}
                placeholder={t('professionalModal.website.placeholder')}
                size={'medium'}
                value={website}
                onChange={setWebsite}
                isLocked={isSubmitting}
              />
            </div>
          </div>

          {/* Address */}
          <div className={'row'}>
            <span className={'field'}>{t('project.settings.projectAddress.label')}</span>
            <div className={'rows'}>
              <div className={'row'}>
                <span className={'field'}>{t('address.line1.label')}</span>
                <div className={'input'}>
                  <TextInput
                    type={'text'}
                    value={addressLine1}
                    onChange={setAddressLine1}
                    placeholder={t('address.line1.placeholder')}
                    size={'medium'}
                    isLocked={isSubmitting}
                  />
                </div>
              </div>
              <div className={'row'}>
                <span className={'label'}>{t('address.line2.label')}</span>
                <div className={'input'}>
                  <TextInput
                    type={'text'}
                    value={addressLine2}
                    onChange={setAddressLine2}
                    placeholder={t('address.line2.placeholder')}
                    size={'medium'}
                    isLocked={isSubmitting}
                  />
                </div>
              </div>
              <div className={'row'}>
                <span className={'label'}>{t('address.zipcode.label')}</span>
                <div className={'input'}>
                  <TextInput
                    type={'text'}
                    value={addressZipcode}
                    onChange={setAddressZipcode}
                    placeholder={t('address.zipcode.placeholder')}
                    size={'medium'}
                    isLocked={isSubmitting}
                  />
                </div>
              </div>
              <div className={'row'}>
                <span className={'label'}>{t('address.city.label')}</span>
                <div className={'input'}>
                  <TextInput
                    type={'text'}
                    value={addressCity}
                    onChange={setAddressCity}
                    placeholder={t('address.city.placeholder')}
                    size={'medium'}
                    isLocked={isSubmitting}
                  />
                </div>
              </div>
              <div className={'row'}>
                <span className={'label'}>{t('address.region.label')}</span>
                <div className={'input'}>
                  <TextInput
                    type={'text'}
                    value={addressRegion}
                    onChange={setAddressRegion}
                    placeholder={t('address.region.placeholder')}
                    size={'medium'}
                    isLocked={isSubmitting}
                  />
                </div>
              </div>
              <div className={'row'}>
                <span className={'label'}>{t('address.country.label')}</span>
                <div className={'input'}>
                  <TextInput
                    type={'text'}
                    value={addressCountry}
                    onChange={setAddressCountry}
                    placeholder={t('address.country.placeholder')}
                    size={'medium'}
                    isLocked={isSubmitting}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={'submit-button-wrapper'}>
            <Button
              onClick={handleSubmit}
              isLocked={!isFormValid || isSubmitting}
              filled={true}
              fontSize={'medium'}
            >
              {professional
                ? t('professionalModal.update.submitButton')
                : t('professionalModal.add.submitButton')}
            </Button>
          </div>
        </div>
      </>
    </Modal>
  )
}
