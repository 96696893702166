import React from 'react'
import { IonBackdrop } from '@ionic/react'
import { CenteredLoading } from '../../molecules/CenteredLoading/CenteredLoading'
import './ListItem.css'

export type ListItemProps = {
  isContentLoading?: boolean
  onClick?: () => void
  allowHover: boolean
  className?: string
}

export const ListItem: React.FC<ListItemProps> = ({
  children,
  onClick,
  allowHover,
  isContentLoading = false,
  className,
}) => {

  return (
    <div
      className={'li-item'
        + (className ? ' ' + className : '')
        + (allowHover ? ' withHover' : '')
      }
    >
      {onClick && (
        <IonBackdrop
          visible={true}
          tappable={true}
          onIonBackdropTap={onClick}
        />
      )}

      {isContentLoading ? (
        <CenteredLoading className={"li-item-content"} isLight={true}/>
      ) : (
        <div className={"li-item-content"}>
          {children}
        </div>
      )}      
    </div>
  )
}