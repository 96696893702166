import React, { ChangeEvent, useCallback, useMemo } from 'react'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-icon.svg'
import './DateInput.css'

export interface DateInputRef {
  focus: () => void
}

export interface DateInputProps {
  value: Date | undefined
  placeholder: string
  className?: string
  onChange: (value: Date | undefined) => void
  size: 'small' | 'medium' | 'large'
  noCross: boolean
  isLocked?: boolean
}

export const DateInput: React.FC<DateInputProps> = ({
  value,
  placeholder,
  className,
  onChange,
  size,
  noCross,
  isLocked = false,
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newwValue = !!event.target.value ? new Date(event.target.value) : undefined
      onChange(newwValue)
    },
    [onChange]
  )

  const inputValue = useMemo(() => {
    if (!value) return ''
    const year = value.getFullYear()
    const month = value.getMonth() + 1
    const day = value.getDate()
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
  }, [value])

  return (
    <div className={`date-input-wrapper ${size} ${className || ''}`}>
      <input
        className={'date-input'}
        type={'date'}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
        required={noCross}
        disabled={isLocked}
      />
      <CalendarIcon />
    </div>
  )
}
