import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../Modal/Modal'
import './UserProjectProductModal.css'
import { ProjectProductVariationCardWrapper } from '../ProjectProductVariationCard/ProjectProductVariationCardWrapper'
import { ChatWrapper } from '../../molecules/Chat/ChatWrapper'
import { Socket } from 'socket.io-client'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { TabHeader } from '../../atoms/TabHeader/TabHeader'
import { Ordering, UserOpinion } from '@fynde/dtos'
import styled from 'styled-components'
import { ReactComponent as GoodIcon } from '../../../assets/icons/thumbs-up-icon.svg'
import { ReactComponent as NeutralIcon } from '../../../assets/icons/balance-icon.svg'
import { ReactComponent as BadIcon } from '../../../assets/icons/thumbs-down-icon.svg'

const GlobalBarSegment = styled.div<{ percentage: number }>`
  width: ${(props) => props.percentage + '%'};
`

export interface UserProjectProductChatModalProps {
  isOpen: boolean
  onCancel: () => void
  userProjectProductId: string
  socket: Socket | undefined
}

export const UserProjectProductChatModal: React.FC<UserProjectProductChatModalProps> = ({
  isOpen,
  onCancel,
  userProjectProductId,
  socket,
}) => {
  const { t } = useTranslation()

  // data
  const userId = useStoreState((store) => store.user.userId())
  const product = useStoreState((store) =>
    store.projectProducts.projectProduct(userProjectProductId)
  )
  const project = useStoreState((store) =>
    !product ? null : store.projects.project(product.projectId)
  )
  const members = useStoreState((store) =>
    !project ? [] : store.projectMembers.orderedProjectMembers(project.id, Ordering.NameAsc)
  )
  const opinions = useStoreState((store) =>
    store.userProjectProductOpinions.userProjectProductOpinions(userProjectProductId)
  )

  const otherMembers = useMemo(() => {
    return members.filter((m) => m.user.id !== userId)
  }, [userId, members])

  const projectProductCardProps = useMemo(() => {
    if (
      !project ||
      !product ||
      (product.productVariationId && !product.productVariation) ||
      (product.userProductId && !product.userProduct)
    ) {
      return undefined
    }
    return {
      userProjectProductId,
      isDefaultProject: project.isDefault,
      productVariationCardProps: {
        productVariationId: product.productVariation?.id,
        userProductId: product.userProduct?.id,
        brandId: product.productVariation?.product.brand?.id,
        productId: product.productVariation?.product.id,
        eshopProductVariationId: product.eshopProductVariation?.id,
        displayName: (product.productVariation?.displayName || product.userProduct?.name)!,
        brandName: product.productVariation?.product.brand?.name || product.userProduct?.brand,
        buyLink: product.eshopProductVariation?.url || product.userProduct?.url,
        price: product.eshopProductVariation?.price || product.userProduct?.price,
        thumbnail:
          product.productVariation?.thumbnail?.media.publicUrl ||
          product.userProduct?.thumbnail?.publicUrl,
        thumbnailBgColor: product.productVariation?.thumbnail?.hexaColor || undefined,
      },
    }
  }, [userProjectProductId, project, product])

  const getMemberOpinion = useCallback(
    (userId: string) => {
      const result = opinions.filter((o) => o.userId === userId)
      return result.length === 0 ? undefined : result[0].opinion
    },
    [opinions]
  )
  const userOpinion = useMemo(() => {
    if (userId === null) return null
    return getMemberOpinion(userId)
  }, [userId, getMemberOpinion])

  const globalOpinion = useMemo(() => {
    if (opinions.length === 0) return [0, 100, 0]
    let positiveOpinions = 0
    let negativeOpinions = 0
    for (const opinion of opinions) {
      if (opinion.opinion === UserOpinion.Good) positiveOpinions += 1
      else if (opinion.opinion === UserOpinion.Bad) negativeOpinions += 1
    }
    const positivePercentage = (positiveOpinions / members.length) * 100
    const negativePercentage = (negativeOpinions / members.length) * 100
    const neutralPercentage = 100 - (positivePercentage + negativePercentage)
    return [positivePercentage, neutralPercentage, negativePercentage]
  }, [opinions, members])

  // tabs
  const [selectedTab, setSelectedTab] = useState<number>(0)

  // opinions
  const [isPostingOpinion, setIsPostingOpinion] = useState<boolean>(false)
  const postOpinion = useStoreActions(
    (store) => store.userProjectProductOpinions.postUserProjectProductOpinion
  )
  const patchOpinion = useStoreActions(
    (store) => store.userProjectProductOpinions.patchUserProjectProductOpinion
  )

  const handleReview = useCallback(
    async (opinion: UserOpinion) => {
      if (userId === null) {
        console.error(
          '[UserProjectProductModal] cannot post/patch opinion because userId is missing'
        )
        return
      }
      setIsPostingOpinion(true)
      if (!userOpinion) {
        await postOpinion({
          userId: userId,
          userProjectProductId,
          opinion: opinion,
        })
      } else {
        await patchOpinion({
          userId: userId,
          userProjectProductId,
          opinion: opinion,
        })
      }
      setIsPostingOpinion(false)
    },
    [setIsPostingOpinion, postOpinion, patchOpinion, userId, userProjectProductId, userOpinion]
  )

  // cancel modal
  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  return (
    <Modal
      isOpen={isOpen}
      onCancel={handleCancel}
      withButtons={false}
      withCross={true}
      className={'user-project-product-chat-modal'}
      maxWidth={'calc(1200px)'}
    >
      <div>
        <div className={'left-part'}>
          <div>
            {projectProductCardProps ? (
              <ProjectProductVariationCardWrapper {...projectProductCardProps} />
            ) : (
              <div className={'product-placeholder'} />
            )}
          </div>
        </div>

        <div className={'right-part'}>
          <TabHeader
            tabTexts={['Discussion', 'Avis']}
            selectedTab={selectedTab}
            onChange={setSelectedTab}
          />

          {/* Chat */}
          {selectedTab === 0 && project && projectProductCardProps && (
            <ChatWrapper
              projectId={project.id}
              userProjectProductId={projectProductCardProps.userProjectProductId}
              socket={socket}
              scrollOnEditorSizeChange={false}
            />
          )}

          {/* Opinions */}
          {selectedTab === 1 && projectProductCardProps && (
            <>
              <h4>{t('project.productModal.globalOpinion.header')}</h4>
              {opinions.length < members.length && (
                <p>{t('project.productModal.globalOpinion.missingOpinions')}</p>
              )}

              {/* Global opinion */}
              <div className={'global-bar'}>
                {globalOpinion[0] > 0 && (
                  <GlobalBarSegment
                    className={'global-bar-segment good'}
                    percentage={globalOpinion[0]}
                  >
                    <GoodIcon />
                    <span>{globalOpinion[0].toFixed(0) + ' %'}</span>
                  </GlobalBarSegment>
                )}
                {globalOpinion[1] > 0 && (
                  <GlobalBarSegment
                    className={'global-bar-segment neutral'}
                    percentage={globalOpinion[1]}
                  />
                )}
                {globalOpinion[2] > 0 && (
                  <GlobalBarSegment
                    className={'global-bar-segment bad'}
                    percentage={globalOpinion[2]}
                  >
                    <span>{globalOpinion[2].toFixed(0) + ' %'}</span>
                    <BadIcon />
                  </GlobalBarSegment>
                )}
              </div>

              {/* User opinion */}
              <h4>{t('project.productModal.userOpinion.header')}</h4>
              <div className={'user-opinion-ctas'}>
                <button
                  className={
                    'cta good' +
                    (isPostingOpinion || (userOpinion && userOpinion !== UserOpinion.Good)
                      ? ' not-selected'
                      : '')
                  }
                  disabled={isPostingOpinion || (!!userOpinion && userOpinion === UserOpinion.Good)}
                  onClick={() => handleReview(UserOpinion.Good)}
                >
                  <GoodIcon />
                  <div className={'bg'} />
                </button>
                <button
                  className={
                    'cta neutral' +
                    (isPostingOpinion || (userOpinion && userOpinion !== UserOpinion.Neutral)
                      ? ' not-selected'
                      : '')
                  }
                  onClick={() => handleReview(UserOpinion.Neutral)}
                  disabled={
                    isPostingOpinion || (!!userOpinion && userOpinion === UserOpinion.Neutral)
                  }
                >
                  <NeutralIcon />
                  <div className={'bg'} />
                </button>
                <button
                  className={
                    'cta bad' +
                    (isPostingOpinion || (userOpinion && userOpinion !== UserOpinion.Bad)
                      ? ' not-selected'
                      : '')
                  }
                  onClick={() => handleReview(UserOpinion.Bad)}
                  disabled={isPostingOpinion || (!!userOpinion && userOpinion === UserOpinion.Bad)}
                >
                  <BadIcon />
                  <div className={'bg'} />
                </button>
              </div>

              {/* Members opinion */}
              {otherMembers.length > 0 && (
                <>
                  <h4>{t('project.productModal.membersOpinions.header')}</h4>
                  {otherMembers.map((m) => {
                    const userOpinion = getMemberOpinion(m.user.id)
                    return (
                      <div className={'member-li'} key={m.user.id}>
                        <span>
                          {m.user.firstname} {m.user.lastname}
                        </span>
                        {userOpinion === undefined ? (
                          <span className={'member-opinion light-txt'}>
                            {t('project.productModal.membersOpinions.missingOpinion')}
                          </span>
                        ) : (
                          <div className={'member-opinion opinion-bullet ' + userOpinion}></div>
                        )}
                      </div>
                    )
                  })}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}
