import React, { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { useStoreState, useStoreActions } from '../../../store/hooks'
import { GridCard } from '../GridCard/GridCard'
import { Loading } from '../../atoms/Loading/Loading'
import { RequestStatus } from '../../../utils/reqStatus'
import { ContainerCardProductVariation } from '../ContainerCardProductVariation/ContainerCardProductVariation'
import Mixpanel, { MixpanelEvents } from '../../../utils/mixpanel'
import { ITEM_PER_PAGE } from '../../../store/similar-products'

const InitialLoader = styled(Loading)<{ visible: boolean }>`
  display: block;
  margin-left: auto;
  margin-right: auto;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  margin-top: 1.5rem;
`

const StyledGridCard = styled(GridCard)`
  overflow: hidden;
`
export interface SimilarProductsProps {
  visualSearchId: string
  objectTypes: string[] | null
  hitBottom: boolean
  productVariationId: string
}

export const SimilarProducts: React.FC<SimilarProductsProps> = ({
  hitBottom,
  visualSearchId,
  objectTypes,
  productVariationId,
}) => {
  const currentVsId = useStoreState((state) => state.similarProducts.reqVisualSearchId)
  const currentObjectTypes = useStoreState((state) => state.similarProducts.reqObjectTypes)
  const currentSkip = useStoreState((state) => (state.similarProducts.results?.length || 0) + state.similarProducts.wrongResultsCount)

  useStoreActions((actions) => {
    if (currentVsId !== visualSearchId) {
      actions.similarProducts.setReqVisualSearchId(visualSearchId)
    }
    if (currentObjectTypes !== objectTypes) {
      actions.similarProducts.setReqObjectTypes(objectTypes)
    }
  })

  const moreSimilarProductsReqStatus = useStoreState((state) => state.similarProducts.reqStatusMore)
  const similarProductsReqStatus = useStoreState((state) => state.similarProducts.reqStatus)

  const [isFetchingMore, setIsFetchingMore] = useState(false)
  const { fetch, fetchMore } = useStoreActions((actions) => actions.similarProducts)

  const { results, hasMore } = useStoreState((state) => state.similarProducts)

  const loading = useMemo(() => {
    return similarProductsReqStatus !== RequestStatus.Completed
  }, [similarProductsReqStatus])

  // Initial fetch
  useEffect(() => {
    if (results === null) {
      console.debug('[similarProducts] Fetching similar products')
      fetch()
    }
  })

  // Fetch more
  useEffect(() => {
    if (hitBottom && moreSimilarProductsReqStatus !== RequestStatus.Loading && hasMore) {
      setIsFetchingMore(true)
      fetchMore(() => setIsFetchingMore(false))

      Mixpanel.track(MixpanelEvents.LoadSimilarProductPagination, {
        visualSearchId: currentVsId,
        objectTypes: objectTypes,
        skip: currentSkip,
        take: ITEM_PER_PAGE,
        productVariationId: productVariationId,
      })
    }
  }, [
    fetchMore,
    moreSimilarProductsReqStatus,
    hasMore,
    setIsFetchingMore,
    hitBottom,
    currentVsId,
    currentSkip,
    productVariationId,
    objectTypes,
  ])

  if (loading) {
    return <InitialLoader visible={true} isLight={true}/>
  }

  return (
    <>
      <StyledGridCard locked={false} >
        {results && results.map((i) => <ContainerCardProductVariation key={i.id} data={i} />)}
      </StyledGridCard>

      <InitialLoader visible={isFetchingMore} isLight={true}/>
    </>
  )
}
