import React from 'react'
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-down-icon.svg'
import './BackButton.css'

export interface BackButtonProps {
  onClick: () => void
  className?: string
}

export const BackButton: React.FC<BackButtonProps> = ({ onClick, className }) => (
  <>
    <button onClick={onClick} className={'back-button ' + (className ? className : '')}>
      <ArrowIcon className={'light'}/>
    </button>
  </>
)
