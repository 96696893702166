import React, { useCallback } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { CardProductVariationProps } from '../CardProductVariation/CardProductVariation'
import { Ordering } from '@fynde/dtos'
import { ProjectProperty } from '../../../store/projects'
import Mixpanel, { MixpanelEvents } from '../../../utils/mixpanel'
import { AddToProjectModal } from './AddToProjectModal'

export interface AddToProjectModalWrapperProps {
  isOpen: boolean
  onCancel: () => void
  productVariationProps: CardProductVariationProps
}

export const AddToProjectModalWrapper: React.FC<AddToProjectModalWrapperProps> = ({
  isOpen,
  onCancel,
  productVariationProps,
}) => {
  // user data
  const projects = useStoreState((store) => store.projects.orderedProjects(Ordering.NameAsc))
  const products = useStoreState((store) => store.projectProducts.projectProducts)

  const isProjectLike = useCallback(
    (projectId: string, productVarationId?: string, userProductId?: string) => {
      let result = false
      for (let project of projects) {
        if (project.id === projectId) {
          for (let product of products(projectId)) {
            if (
              (productVarationId && product.productVariationId === productVarationId) ||
              (userProductId && product.userProductId === userProductId)
            ) {
              result = true
              break
            }
          }
        }
      }
      return result
    },
    [projects, products]
  )

  // project creation
  const userId = useStoreState((store) => store.user.userId())
  const addProject = useStoreActions((store) => store.projects.createProject)
  const isNewProjectNameValid = useStoreActions((store) => store.projects.validateNewProjectName)

  const checkNewProjectName = useCallback(
    (newProjectName) => {
      return newProjectName.length > 0 && isNewProjectNameValid({ newName: newProjectName })
    },
    [isNewProjectNameValid]
  )

  const handleNewProjectValidation = useCallback(
    async (newProjectName: string): Promise<boolean> => {
      console.debug('[ProjectCreationButton] handle validation...')
      if (!userId) return false
      return addProject({
        userId,
        name: newProjectName,
      })
    },
    [addProject, userId]
  )

  // validate
  const addProjectProduct = useStoreActions((store) => store.projectProducts.createProduct)

  const handleSubmit = useCallback(
    async (selectedProjectsIds: string[]) => {
      console.debug('[AddToProjectModalWrapper] handleSubmit')
      console.debug('[AddToProjectModalWrapper] selected projects:', selectedProjectsIds)

      let successfullyAddedTo: string[] = []
      for (let projectId of selectedProjectsIds) {
        console.debug('analyse project:', projectId)

        if (
          isProjectLike(
            projectId,
            productVariationProps.productVariationId,
            productVariationProps.userProductId
          )
        )
          continue

        console.debug('[AddToProjectModalWrapper] add new like to project...')
        await addProjectProduct({
          projectId: projectId,
          productVariationId: productVariationProps.productVariationId,
          userProductId: productVariationProps.userProductId,
        }).then((success) => {
          console.debug('[AddToProjectModalWrapper] new user-project-like added:', success)
          if (success) successfullyAddedTo.push(projectId)
        })
      }

      Mixpanel.track(MixpanelEvents.ProductAddedToProjects, {
        userId: userId,
        userProductId: productVariationProps.userProductId,
        productVariationId: productVariationProps.productVariationId,
        projectIds: successfullyAddedTo,
        projectsCount: successfullyAddedTo.length,
      })
    },
    [addProjectProduct, isProjectLike, productVariationProps, userId]
  )

  return (
    <AddToProjectModal
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={(projectsIds) => handleSubmit(projectsIds)}
      productVariationProps={productVariationProps}
      ownProjects={projects
        .filter((project) => project.property === ProjectProperty.Own)
        .map((project) => {
          return {
            id: project.id,
            name: project.name,
            isDefault: project.isDefault,
          }
        })}
      sharedProjects={projects
        .filter((project) => project.property === ProjectProperty.Shared)
        .map((project) => {
          return {
            id: project.id,
            name: project.name,
            isDefault: project.isDefault,
          }
        })}
      projectLikeChecker={isProjectLike}
      newProjectNameChecker={checkNewProjectName}
      onNewProject={handleNewProjectValidation}
    />
  )
}
