import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross-icon.svg'
import { ReactComponent as FiltersIcon } from '../../../assets/icons/filters-icon.svg'
import { useTranslation } from 'react-i18next'
import { TextInput } from '../../atoms/TextInput/TextInput'
import { Button } from '../../atoms/Button/Button'
import { RangeSlider } from '../../atoms/RangeSlider/RangeSlider'
import styled from 'styled-components'
import { useWindowSize } from '../../../utils/useWindowSize'
import './ProductsSearchBar.css'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { CVController } from '../../atoms/CVController/CVController'
import { readAsDataURL } from '../../../utils/tool'
import { calculateBoxOverlap, Crop } from '../../../utils/crop'
import config from '../../../config'
import { budgetSteps } from '../../../store/filters'
import { RequestStatus } from '../../../utils/reqStatus'
import { Loading } from '../../atoms/Loading/Loading'

const ColorBullet = styled.div<{ hexaColor: string }>`
  background-color: ${(props) => props.hexaColor};
  border-color: ${(props) =>
    props.hexaColor.toLowerCase() === '#ffffff' ? 'black' : 'transparent'};
`

export interface ProductsSearchBarProps {}

export const ProductsSearchBar: React.FC<ProductsSearchBarProps> = () => {
  const { t } = useTranslation()

  const windowSize = useWindowSize()

  const [isOpen, setIsOpen] = useState<boolean>(true)

  const keywords = useStoreState((store) => store.filters.keywords)
  const setKeywords = useStoreActions((actions) => actions.filters.setKeywords)

  // brands
  const [brandInput, setBrandInput] = useState('')

  const brands = useStoreState((store) => store.brands.brands)
  const brandsStatus = useStoreState((store) => store.brands.reqStatus)
  const selectedBrandIds = useStoreState((store) => store.filters.selectedBrandIds)
  const setSelectedBrandIds = useStoreActions((actions) => actions.filters.setSelectedBrandIds)

  const handleBrandClick = useCallback(
    (currentBrandId: string) => {
      setSelectedBrandIds(
        selectedBrandIds.some((brandId) => brandId === currentBrandId)
          ? selectedBrandIds.filter((brandId) => brandId !== currentBrandId)
          : [...selectedBrandIds, currentBrandId]
      )
    },
    [selectedBrandIds, setSelectedBrandIds]
  )

  const maxMatchedBrands = 8
  const matchedBrands = useMemo(() => {
    if (brandInput === '') return []
    const results = []
    for (const brand of brands) {
      if (selectedBrandIds.includes(brand.id)) continue
      if (brand.name.search(new RegExp(brandInput, 'i')) > -1) {
        results.push(brand)
      }
    }
    return results
  }, [brands, brandInput, selectedBrandIds])

  // budget
  const selectedBudgetRange = useStoreState((store) => store.filters.selectedPriceRange)
  const setSelectedBudgetRange = useStoreActions((actions) => actions.filters.setSelectedPriceRange)

  // clamped slider's values
  const getBudgetSliderClosestStep = useCallback((value: number) => {
    let closestValue = budgetSteps[0]
    let closestDelta = Number.MAX_VALUE
    for (const step of budgetSteps) {
      const delta = Math.abs(value - step)
      if (closestDelta > delta) {
        closestValue = step
        closestDelta = delta
      }
    }
    return closestValue
  }, [])

  const [budgetRange, setBudgetRange] = useState<number[]>([
    getBudgetSliderClosestStep(selectedBudgetRange.min),
    getBudgetSliderClosestStep(selectedBudgetRange.max),
  ])

  // slider
  const handleBudgetRangeChange = useCallback(
    (min: number, max: number) => {
      // update min
      if (min !== budgetRange[0]) {
        setBudgetRange((prevValue) => [min, prevValue[1]])
        setSelectedBudgetRange({ min, max: selectedBudgetRange.max })
      }
      // or max
      else {
        setBudgetRange((prevValue) => [prevValue[0], max])
        setSelectedBudgetRange({ min: selectedBudgetRange.min, max })
      }
    },
    [budgetRange, selectedBudgetRange, setSelectedBudgetRange]
  )

  // input min
  const handleBudgetMinInputChange = (min: string) => {
    const floatMin = Number(min)
    setSelectedBudgetRange({ min: floatMin, max: selectedBudgetRange.max })
    let closestMin = getBudgetSliderClosestStep(floatMin)
    setBudgetRange((prevValue) => [closestMin, prevValue[1]])
  }

  // input max
  const handleBudgetMaxInputChange = (max: string) => {
    const floatMax = Number(max)
    setSelectedBudgetRange({ min: selectedBudgetRange.min, max: floatMax })
    let closestMax = getBudgetSliderClosestStep(floatMax)
    setBudgetRange((prevValue) => [prevValue[0], closestMax])
  }

  // colors
  const colorTags = useStoreState((store) => store.colorTags.localizedTags)
  const selectedColorTagIds = useStoreState((store) => store.filters.selectedColorTagIds)
  const setSelectedColorTagIds = useStoreActions(
    (actions) => actions.filters.setSelectedColorTagIds
  )

  const handleColorClick = useCallback(
    (colorId: string) => {
      setSelectedColorTagIds(
        selectedColorTagIds.includes(colorId)
          ? selectedColorTagIds.filter((id) => id !== colorId)
          : [...selectedColorTagIds, colorId]
      )
    },
    [selectedColorTagIds, setSelectedColorTagIds]
  )

  // materials
  const materialTags = useStoreState((store) => store.materialTags.localizedTags)
  const selectedMaterialTagIds = useStoreState((store) => store.filters.selectedMaterialTagIds)
  const setSelectedMaterialTagIds = useStoreActions(
    (actions) => actions.filters.setSelectedMaterialTagIds
  )

  const handleMaterialClick = useCallback(
    (materialId: string) => {
      setSelectedMaterialTagIds(
        selectedMaterialTagIds.includes(materialId)
          ? selectedMaterialTagIds.filter((id) => id !== materialId)
          : [...selectedMaterialTagIds, materialId]
      )
    },
    [selectedMaterialTagIds, setSelectedMaterialTagIds]
  )

  // image
  const postCVFile = useStoreActions((actions) => actions.computerVision.postCVFile)
  const clearCVFile = useStoreActions((actions) => actions.computerVision.clearCVFile)
  const clearVSRegion = useStoreActions((actions) => actions.computerVision.clearRegion)
  const imageData = useStoreState((store) => store.computerVision.reqFile)

  const hiddenImageInput = useRef<HTMLInputElement>(null)
  const handleImportImageClick = () => {
    if (hiddenImageInput.current) hiddenImageInput.current.click()
  }

  const handleImageFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const file = event.target.files[0]
    const imageData = await readAsDataURL(file)
    clearVSRegion()
    setCropsCount(0)
    postCVFile(imageData)
  }

  const handleImageDeletion = useCallback(() => {
    clearCVFile()
  }, [clearCVFile])

  // image hover
  const [imageOverCounter, setImageOverCounter] = useState<number>(0)
  const [imageOver, setImageOver] = useState<boolean>(false)

  useEffect(() => {
    if (imageOverCounter > 0 && !imageOver) setImageOver(true)
    else if (imageOverCounter === 0 && imageOver) setImageOver(false)
  }, [imageOverCounter])

  // image crop
  const selectedRegion = useStoreState((store) => store.computerVision.region)
  const setRegion = useStoreActions((actions) => actions.computerVision.setRegion)
  const objectDetections = useStoreState((store) => store.computerVision.objectDetectionObjects)
  const setSelectedObjectDetectionTypes = useStoreActions(
    (actions) => actions.filters.setSelectedObjectDetectionTypes
  )

  // const [crop, setCrop] = useState<Crop | undefined>(undefined)
  const [cropsCount, setCropsCount] = useState<number>(0)

  const objectDetectionCrops = useMemo(() => {
    if (objectDetections === undefined || objectDetections.length === 0) return undefined
    console.log(`🚓 ObjectDetection => ${objectDetections.map((o) => o.class).join(', ')} `)
    return objectDetections.map<Crop>((o) => ({
      height: o.region.height,
      width: o.region.width,
      x: o.region.x,
      y: o.region.y,
      objectTypes: o.class,
    }))
  }, [objectDetections])

  const handleCropChange = useCallback(
    (crop: Crop, suggestedCropIndex: number | null) => {
      setCropsCount((prevValue) => prevValue + 1)
      // setCropFromOD(suggestedCropIndex !== null)
      setRegion({ ...crop })
    },
    [setRegion]
  )

  // filters count
  const filterCount = useMemo(() => {
    return (
      selectedBrandIds.length +
      (selectedBudgetRange.min === budgetSteps[0] ? 0 : 1) +
      (selectedBudgetRange.max === budgetSteps[budgetSteps.length - 1] ? 0 : 1) +
      selectedColorTagIds.length +
      selectedMaterialTagIds.length +
      (!!imageData ? 1 : 0)
    )
  }, [
    selectedBrandIds,
    selectedBudgetRange.min,
    selectedBudgetRange.max,
    selectedColorTagIds,
    selectedMaterialTagIds,
    imageData,
  ])

  // submit
  const searchStatus = useStoreState((store) => store.search.reqStatus)
  const postVisualSearch = useStoreActions((actions) => actions.computerVision.postVisualSearch)
  const fetchResults = useStoreActions((actions) => actions.search.fetch)

  const isSearchReady = useMemo(() => {
    return keywords.search(/\w/) > -1 || filterCount > 0
  }, [keywords, filterCount])

  const isSearching = useMemo(() => {
    return searchStatus === RequestStatus.Loading
  }, [searchStatus])

  const handleSubmit = async () => {
    console.log('Search for products...')

    if (!!imageData) {
      // calculate region-related object-detection types
      // this cannot be done right after a crop because OD may still be processing at this moment
      // - if default crop, apply all detected objectTypes
      // - else, only apply the ones that are related to the crop
      const isDefaultCrop = cropsCount === 1
      const allODTypes = []
      if (!!objectDetectionCrops) {
        for (const crop of objectDetectionCrops) {
          if (crop.objectTypes) allODTypes.push(...crop.objectTypes)
        }
      }
      const odTypes = isDefaultCrop ? allODTypes : []

      // We calculate overlap between the crop and the object-detection's crops
      // if one is too similar, we apply its object-detection's classes
      const overlapTypes = []
      if (!!objectDetectionCrops && !!selectedRegion) {
        for (const suggestedCrop of objectDetectionCrops) {
          if (!suggestedCrop.objectTypes || suggestedCrop.objectTypes.length === 0) continue
          const overlap = calculateBoxOverlap(selectedRegion, suggestedCrop)
          console.debug('overlap:', overlap, 'crop:', suggestedCrop)
          if (overlap >= config.cropOverlapThreshold)
            overlapTypes.push(...suggestedCrop.objectTypes)
        }
      }
      odTypes.push(...overlapTypes)
      setSelectedObjectDetectionTypes(odTypes)

      await postVisualSearch()
    }

    fetchResults()
  }

  return (
    <div className={`products-search-bar ${isOpen ? '' : 'closed'}`}>
      <div className={'top-part'}>
        {/* Search bar */}
        <TextInput
          value={keywords}
          onChange={setKeywords}
          placeholder={t('productSearchBar.search.placeholder')}
          size={'medium'}
          isLocked={isSearching}
        />
        {/* Filters button */}
        <div className={'advance-button'}>
          {filterCount > 0 && <span>+{filterCount}</span>}
          <button onClick={() => setIsOpen((prevValue) => !prevValue)}>
            <span>{t('productSearchBar.filtersButton')}</span>
            <FiltersIcon />
          </button>
        </div>

        {/* Submit */}
        <Button
          onClick={handleSubmit}
          filled={true}
          fontSize={'medium'}
          isLocked={!isSearchReady || isSearching}
        >
          {t('productSearchBar.submitButton')}
        </Button>
      </div>

      <div className={`bottom-part`}>
        {/* Brands */}
        <div className={'search-filter-card brands'}>
          <h5>{t('productSearchBar.brands.header')}</h5>

          <div className={'content'}>
            <TextInput
              value={brandInput}
              onChange={setBrandInput}
              placeholder={t('productSearchBar.brands.search.placeholder')}
              size={'medium'}
              isLocked={isSearching}
            />

            {brandsStatus === RequestStatus.Loading && brandInput !== '' && (
              <div className={'brand-loader'}>
                <Loading isLight />
              </div>
            )}

            {selectedBrandIds.length > 0 && (
              <div>
                <h6>{t('productSearchBar.brands.selection')}</h6>
                {brands
                  .filter((brand) => selectedBrandIds.includes(brand.id))
                  .map((brand) => (
                    <div
                      className={`filter-button selected ${isSearching ? 'locked' : ''}`}
                      key={brand.id}
                    >
                      <span>{brand.name}</span>
                      <CrossIcon onClick={() => handleBrandClick(brand.id)} />
                    </div>
                  ))}
              </div>
            )}

            {matchedBrands.length > 0 && (
              <div>
                <h6>{t('productSearchBar.brands.results')}</h6>
                <ol>
                  {matchedBrands.slice(0, maxMatchedBrands).map((brand) => (
                    <li
                      key={brand.id}
                      className={`filter-button ${isSearching ? 'locked' : ''}`}
                      onClick={() => handleBrandClick(brand.id)}
                    >
                      {brand.name}
                    </li>
                  ))}
                  {matchedBrands.length > maxMatchedBrands && (
                    <li className={'filter-button locked'}>...</li>
                  )}
                </ol>
              </div>
            )}
          </div>
        </div>

        {/* Budget */}
        <div className={`search-filter-card budget ${windowSize.width > 1400 ? '' : 'narrow'}`}>
          <h5>{t('productSearchBar.budget.header')}</h5>
          <div>
            <RangeSlider
              steps={budgetSteps}
              selectedMin={budgetRange[0]}
              selectedMax={budgetRange[1]}
              onChange={handleBudgetRangeChange}
              isLocked={isSearching}
            />
          </div>
          <div className={'budget-inputs'}>
            <div className={'budget-input'}>
              <span className={'label'}>{t('productSearchBar.budget.min')}</span>
              <TextInput
                type={'price'}
                value={selectedBudgetRange.min.toString()}
                onChange={handleBudgetMinInputChange}
                placeholder={''}
                priceDecimal={false}
                maxPrice={budgetRange[1] - 1}
                size={'small'}
                isLocked={isSearching}
              />
              <span>€</span>
            </div>
            <div className={'budget-input'}>
              <span className={'label'}>{t('productSearchBar.budget.max')}</span>
              <TextInput
                type={'price'}
                value={selectedBudgetRange.max.toString()}
                onChange={handleBudgetMaxInputChange}
                placeholder={''}
                priceDecimal={false}
                minPrice={budgetRange[0] + 1}
                maxPrice={budgetSteps[budgetSteps.length - 1]}
                size={'small'}
                isLocked={isSearching}
              />
              <span>€</span>
            </div>
          </div>
        </div>

        {/* Colors */}
        <div className={`search-filter-card colors ${windowSize.width > 1400 ? '' : 'narrow'}`}>
          <h5>{t('productSearchBar.colors.header')}</h5>
          <div>
            <ol>
              {colorTags.map((color) => (
                <li
                  key={color.id}
                  className={`filter-button ${
                    selectedColorTagIds.includes(color.id) ? 'selected' : ''
                  } ${isSearching ? 'locked' : ''}`}
                  onClick={() => handleColorClick(color.id)}
                >
                  <ColorBullet className={'bullet'} hexaColor={color.hexaColor} />
                  <span>{color.name}</span>
                </li>
              ))}
            </ol>
          </div>
        </div>

        {/* Materials */}
        <div className={`search-filter-card materials ${windowSize.width > 1400 ? '' : 'narrow'}`}>
          <h5>{t('productSearchBar.materials.header')}</h5>
          <div>
            <ol>
              {materialTags.map((tag) => (
                <li
                  className={`filter-button ${
                    selectedMaterialTagIds.includes(tag.id) ? 'selected' : ''
                  } ${isSearching ? 'locked' : ''}`}
                  onClick={() => handleMaterialClick(tag.id)}
                  key={tag.id}
                >
                  <span>{tag.name}</span>
                </li>
              ))}
            </ol>
          </div>
        </div>

        {/* Image */}
        <div
          className={`search-filter-card image ${windowSize.width > 1400 ? '' : 'narrow'} ${
            !isSearching && imageOverCounter > 0 ? 'zoom' : 'idle'
          }`}
        >
          <h5>{t('productSearchBar.image.header')}</h5>
          <div className={'content'}>
            {!imageData ? (
              <>
                <div className={'image-wrapper'}>
                  <div className="placeholder">{t('productSearchBar.image.noImage')}</div>
                </div>
                <div className={'buttons-wrapper'}>
                  <Button
                    onClick={handleImportImageClick}
                    fontSize={'small'}
                    filled={true}
                    isLocked={isSearching}
                  >
                    {t('productSearchBar.image.importButton')}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div
                  className={'image-wrapper'}
                  onMouseEnter={() => setImageOverCounter((prevValue) => prevValue + 1)}
                  onMouseLeave={() =>
                    setTimeout(() => setImageOverCounter((prevValue) => prevValue - 1), 200)
                  }
                >
                  <CVController
                    imageSrc={imageData}
                    withEmptySpace={false}
                    isLocked={isSearching}
                    onCrop={handleCropChange}
                    suggestedCrops={objectDetectionCrops}
                    initialCrop={selectedRegion}
                    availableWidth={!isSearching && imageOverCounter > 0 ? 400 : 120}
                    availableHeight={!isSearching && imageOverCounter > 0 ? 400 : 120}
                  />
                </div>
                <p className={'idle-message'}>{t('productSearchBar.image.idleMessage')}</p>
                <p className={'hover-message'}>{t('productSearchBar.image.hoverMessage')}</p>
                <div className={'buttons-wrapper'}>
                  <Button
                    onClick={handleImageDeletion}
                    fontSize={'small'}
                    filled={false}
                    isLocked={isSearching}
                  >
                    {t('productSearchBar.image.deleteButton')}
                  </Button>
                  <Button
                    onClick={handleImportImageClick}
                    fontSize={'small'}
                    filled={true}
                    isLocked={isSearching}
                  >
                    {t('productSearchBar.image.updateButton')}
                  </Button>
                </div>
              </>
            )}
            <input
              type="file"
              ref={hiddenImageInput}
              onChange={handleImageFileChange}
              style={{ display: 'none' }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
