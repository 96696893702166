import React, { useState, useEffect } from 'react'
import HomeApp from './HomeApp'
import HomeDesktop from './HomeDesktop'
import { isMobile } from '../utils/platform'
import { Plugins } from '@capacitor/core'
import { useIonViewDidEnter } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'
import { useStoreActions } from '../store/hooks'
import { WorldArea } from '@fynde/dtos'
import config from '../config';


const { Device, Geolocation } = Plugins

const Home: React.FC = () => {
  const { t } = useTranslation()

  // title
  useIonViewDidEnter(() => {
    document.title = config.pageTitlePrefix + t('pageTitle.Home');
  }, [t])

  // platform
  const isDesktopPlatform = !isMobile()

  // Mixpanel
  useEffect(() => {
    async function getLaunchData() {
      const deviceInfo = await Device.getInfo();

      // Following the logic from https://help.mixpanel.com/hc/en-us/articles/360001355506-Tracking-First-Time-Users
      const mixpanelEventData = {
        'First Time': 'TRUE',
        'appId': deviceInfo.appId,
        'appName': deviceInfo.appName,
        'appBuild': deviceInfo.appBuild,
        'appVersion': deviceInfo.appVersion,
      }
      Mixpanel.track(MixpanelEvents.AppLaunched, mixpanelEventData)
      Mixpanel.register({ 'First Time': 'FALSE' })
    }
    getLaunchData();
  }, [])

  // World area
  const setWorldArea = useStoreActions((actions) => actions.user.setWorldArea)
  useEffect(() => {
    async function getLocationData() {
      // Prompt Geolocation permission
      let currentPosition = null;
      try {
        currentPosition = await Geolocation.getCurrentPosition();
        if (currentPosition.coords.longitude > -30 && currentPosition.coords.longitude < 170) {
          setWorldArea(WorldArea.Europe)
        } else {
          setWorldArea(WorldArea.America)
        }
      } catch (error) {
        console.error(error);
      }

      const mixpanelEventData = {
        'latitude': currentPosition?.coords.latitude,
        'longitude': currentPosition?.coords.longitude,
      }
      Mixpanel.track(MixpanelEvents.LocationUpdated, mixpanelEventData)
    }
    getLocationData();
  }, [setWorldArea])

  // window size
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWindowWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <>
        {(isDesktopPlatform && windowWidth > 768) ? (
          <HomeDesktop/>
        ) : (
          <HomeApp />
        )}
    </>
  )
}

export default Home
