import axiosApi from '../utils/axios'
import {
  UserMessageLightDTO,
  UserProjectMessageCreationDTO,
  UserProjectMessageLightDTO,
  UserProjectMessagesLightDTO,
  UserProjectProductMessageCreationDTO,
  UserProjectProductMessageLightDTO,
  UserProjectProductMessagesLightDTO,
} from '@fynde/dtos'

// messages loading
export const fetchUserProjectMessages = async (
  userToken: string,
  projectId: string
): Promise<UserProjectMessagesLightDTO | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.get<UserProjectMessagesLightDTO>(
      `/user-project-message/${projectId}`,
      config
    )
    return resp.data
  } catch (err) {
    console.error('An error occured while fetching user-project-messages:', err)
  }
  return null
}

export const fetchUserProjectProductMessages = async (
  userToken: string,
  projectId: string
): Promise<UserProjectProductMessagesLightDTO[] | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    params: {
      projectId: projectId,
    },
  }
  try {
    console.log(config)
    const resp = await axiosApi.get<UserProjectProductMessagesLightDTO[]>(
      `/user-project-product-message`,
      config
    )
    return resp.data
  } catch (err) {
    console.error('An error occured while fetching user-project-product-messages:', err)
  }
  return null
}

// message creation
export const postUserProjectMessage = async (
  userId: string,
  userToken: string,
  projectId: string,
  message: string,
  userFileIds: string[]
): Promise<UserProjectMessageLightDTO | null> => {
  console.debug(
    `[user-message] post user project message: ${message.length} char(s), ${userFileIds.length} file(s)`
  )
  // post message
  const payload: UserProjectMessageCreationDTO = {
    projectId: projectId,
    message: {
      userId: userId!,
      text: message,
      userFileIds: userFileIds,
    },
  }
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.post<UserProjectMessageLightDTO>(
      `/user-project-message/`,
      payload,
      config
    )
    console.debug('[user-message] posted user-project-message:', resp.data)
    return resp.data
  } catch (err) {
    console.error('An error occured while posting a new user-project-message:', err)
  }
  return null
}

export const postUserProjectProductMessage = async (
  userId: string,
  userToken: string,
  userProjectProductId: string,
  message: string,
  userFileIds: string[]
): Promise<UserProjectProductMessageLightDTO | null> => {
  const payload: UserProjectProductMessageCreationDTO = {
    userProjectProductId,
    message: {
      userId,
      text: message,
      userFileIds,
    },
  }
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.post<UserProjectProductMessageLightDTO>(
      `/user-project-product-message/`,
      payload,
      config
    )
    return resp.data
  } catch (err) {
    console.error('An error occured while posting a new user-project-product-message:', err)
  }
  return null
}

// message update
export const patchUserMessage = async (
  userToken: string,
  messageId: string,
  messageText: string
): Promise<UserMessageLightDTO | null> => {
  const payload = {
    text: messageText,
  }
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.patch<UserMessageLightDTO>(
      `/user-message/${messageId}`,
      payload,
      config
    )
    return resp.data
  } catch {
    console.error('An error occured while patching a user-project-message')
    return null
  }
}

// message deletion
export const deleteUserMessage = async (userToken: string, messageId: string): Promise<boolean> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  let success = true
  try {
    await axiosApi.delete(`/user-message/${messageId}`, config)
  } catch (err) {
    console.error('An error occured while removing a user-project-message:', err)
    success = false
  }
  return success
}
//
