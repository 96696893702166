import {
  ProductVariationDTO,
  ProductVariationFiltersDTO,
  ProductVariationPaginatedDTO,
  WorldArea,
} from '@fynde/dtos'
import axiosApi from '../utils/axios'

// GET
export const getProductVariation = async (
  productVariationId: string,
  worldArea: string
): Promise<ProductVariationDTO | null> => {
  const config = {
    params: {
      worldArea: worldArea,
    },
  }
  try {
    const resp = await axiosApi.get<ProductVariationDTO>(
      `product-variations/${productVariationId}`,
      config
    )
    return resp.data
  } catch (err) {
    console.error('An error occured while getting a product-variation:', err)
  }
  return null
}

export const getProductVariations = async (
  filters: ProductVariationFiltersDTO,
  take: number,
  skip: number
): Promise<ProductVariationPaginatedDTO | null> => {
  const config = {
    params: {
      ...filters,
      take,
      skip,
    },
  }
  try {
    const resp = await axiosApi.get<ProductVariationPaginatedDTO>(`product-variations/`, config)
    return resp.data
  } catch (err) {
    console.error('An error occured while getting product-variations:', err)
  }
  return null
}

export const getProductVariationsByIds = async (
  userToken: string,
  productVariationIds: string[],
  worldArea: string
): Promise<ProductVariationDTO[] | null> => {
  if (productVariationIds.length === 0) return []

  const dto: ProductVariationFiltersDTO = {
    ids: productVariationIds,
    worldArea: worldArea as WorldArea,
  }
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    params: dto,
  }
  try {
    const resp = await axiosApi.get<ProductVariationDTO[]>(`product-variations/`, config)
    return resp.data
  } catch (err) {
    console.error('An error occured while getting product-variations:', err)
  }
  return null
}
