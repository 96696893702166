import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { Card } from '../../atoms/Card/Card'
import { Button } from '../../atoms/Button/Button'
import { IconButton } from '../../atoms/IconButton/IconButton'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg'
import './VisualSearchCard.css'
import { IonBackdrop } from '@ionic/react'

export interface HistoryCardProps {
  createdAt: Date
  imageUrl: string
  onOpen: () => void
  onRemove: () => void
}

export const VisualSearchCard: React.FC<HistoryCardProps> = ({
  createdAt,
  imageUrl,
  onRemove,
  onOpen,
}) => {
  const { t } = useTranslation()

  const formatedDay = useMemo(() => {
    try {
      return format(createdAt, 'dd/MM/yyyy')
    } catch (err) {
      return ''
    }
  }, [createdAt])
  const formatedHour = useMemo(() => {
    try {
      return format(createdAt, 'HH:mm')
    } catch (err) {
      return ''
    }
  }, [createdAt])

  // hover
  const [mouseOverCounter, setMouseOverCounter] = useState<number>(0)
  const [mouseOver, setMouseOver] = useState<boolean>(false)

  useEffect(() => {
    if (mouseOverCounter > 0 && !mouseOver) setMouseOver(true)
    else if (mouseOverCounter === 0 && mouseOver) setMouseOver(false)
  }, [mouseOverCounter])

  return (
    <Card className={'visual-search-card'}>
      <IonBackdrop
        visible={false}
        tappable={true}
        onIonBackdropTap={onOpen}
        onMouseEnter={() => setMouseOverCounter((prevValue) => prevValue + 1)}
        onMouseLeave={() => setMouseOverCounter((prevValue) => prevValue - 1)}
      />

      <section>
        <img src={imageUrl} alt="search" />
      </section>

      <section>
        <div className={'content condensed-txt'}>
          <span className={'bold'}>{formatedDay}</span>
          <span>{formatedHour}</span>

          <div
            className={'buttons-wrapper'}
            onMouseEnter={() => setMouseOverCounter((prevValue) => prevValue + 1)}
            onMouseLeave={() => setMouseOverCounter((prevValue) => prevValue - 1)}
          >
            <Button onClick={onOpen} filled={mouseOver}>
              {t('visualSearchCard.open')}
            </Button>
            <IconButton icon={<TrashIcon />} size={'small'} onClick={onRemove} />
          </div>
        </div>
      </section>
    </Card>
  )
}
