import { Thunk, thunk, action, Action, Computed, computed } from 'easy-peasy'
import { RequestStatus } from '../utils/reqStatus'
import { Ordering, UserProjectCreationDTO, UserProjectDTO, UserProjectUpdateDTO } from '@fynde/dtos'
import axiosApi from '../utils/axios'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'
import { isApp } from '../utils/platform'
import { StoreModel } from './store'

export enum ProjectProperty {
  Own = 0,
  Shared = 1,
}

export interface Project extends UserProjectDTO {
  property: ProjectProperty
}

export interface ProjectsModel {
  // store
  projects: Project[]
  projectsLoadingStatus: RequestStatus

  // computed
  orderedProjects: Computed<
    ProjectsModel,
    (order: Ordering, property?: ProjectProperty) => Project[]
  >
  project: Computed<ProjectsModel, (projectId: string) => Project | null, StoreModel>
  projectBudget: Computed<ProjectsModel, (projectId: string) => number | undefined, StoreModel>
  isProjectFullyLoaded: Computed<ProjectsModel, (projectId: string) => boolean, StoreModel>
  isTheProjectOwner: Computed<
    ProjectsModel,
    (projectId: string, email: string) => boolean,
    StoreModel
  >

  // actions
  _setProjects: Action<ProjectsModel, Project[]>
  _addProject: Action<ProjectsModel, Project>
  _deleteProject: Action<ProjectsModel, string>
  _setProjectsLoadingStatus: Action<ProjectsModel, RequestStatus>
  _updateProject: Action<ProjectsModel, UserProjectDTO>

  // thunks
  createProject: Thunk<ProjectsModel, UserProjectCreationDTO, any, StoreModel, Promise<boolean>>
  fetchProjects: Thunk<ProjectsModel, void, any, StoreModel>
  completeProject: Thunk<
    ProjectsModel,
    { projectDTO: UserProjectDTO; property: ProjectProperty },
    any,
    StoreModel,
    Project
  >
  deleteProject: Thunk<ProjectsModel, string, any, StoreModel, Promise<boolean>>
  patchProject: Thunk<
    ProjectsModel,
    { projectId: string; dto: UserProjectUpdateDTO },
    any,
    StoreModel,
    Promise<boolean>
  >
  validateNewProjectName: Thunk<
    ProjectsModel,
    { newName: string; exception?: string | null },
    any,
    StoreModel,
    boolean
  >
}

export const projects: ProjectsModel = {
  projectsLoadingStatus: RequestStatus.Idle,
  projects: [],

  _setProjectsLoadingStatus: action((state, loadingStatus) => {
    state.projectsLoadingStatus = loadingStatus
  }),

  project: computed((state) => {
    return (projectId) => {
      for (let project of state.projects) {
        if (project.id === projectId) return project
      }
      return null
    }
  }),

  projectBudget: computed(
    [(state) => state, (state, storeState) => storeState],
    (state, storeState) => {
      return (projectId) => {
        for (let project of state.projects) {
          // found
          if (project.id === projectId) {
            const productsLoadingStatus =
              storeState.projectProducts.projectProductsLoadingStatus(projectId)
            if (productsLoadingStatus !== RequestStatus.Completed) return undefined

            let budget = 0
            const products = storeState.projectProducts.projectProducts(projectId)
            for (let product of products) {
              if (product.validated !== true) continue
              if (product.eshopProductVariation) {
                budget += product.quantity * product.eshopProductVariation.price
              } else if (product.userProduct) {
                budget += product.quantity * product.userProduct.price
              }
            }
            return budget
          }
        }
        // not found
        return undefined
      }
    }
  ),

  isProjectFullyLoaded: computed(
    [(state) => state, (state, storeState) => storeState],
    (state, storeState) => {
      return (projectId) => {
        for (let project of state.projects) {
          if (project.id === projectId)
            return (
              storeState.projectProducts.projectProductsLoadingStatus(projectId) ===
                RequestStatus.Completed &&
              storeState.projectMembers.projectLoadingStatus(projectId) === RequestStatus.Completed
            )
        }
        return false
      }
    }
  ),

  isTheProjectOwner: computed((state) => {
    return (projectId, userEmail) => {
      const project = state.project(projectId)
      if (!project) return false
      return project.ownerEmail === userEmail
    }
  }),

  orderedProjects: computed((state) => {
    return (order, property) => {
      let results: Project[] =
        property === undefined
          ? state.projects
          : state.projects.filter((project) => project.property === property)
      if (results.length === 0) return []

      switch (order) {
        case Ordering.CreationDateAsc:
          results.sort((first, second) => (first.createdAt < second.createdAt ? -1 : 1))
          break
        case Ordering.CreationDateDesc:
          results.sort((first, second) => (first.createdAt > second.createdAt ? -1 : 1))
          break
        case Ordering.NameAsc:
          results.sort((first, second) =>
            first.name.toLowerCase() < second.name.toLowerCase() ? -1 : 1
          )
          break
        case Ordering.NameDesc:
          results.sort((first, second) =>
            first.name.toLowerCase() > second.name.toLowerCase() ? -1 : 1
          )
          break
      }
      // default project is always first (own projects only)
      let defaultProjectIndex = -1
      for (let i = 0; i < results.length; i++) {
        if (results[i].isDefault) {
          defaultProjectIndex = i
          break
        }
      }
      if (defaultProjectIndex >= 0) {
        const defaultProject = results.splice(defaultProjectIndex, 1)[0]
        results.unshift(defaultProject)
      }
      return results
    }
  }),

  _setProjects: action((state, projects) => {
    for (let project of projects) {
      project.createdAt = new Date(project.createdAt)
      project.updatedAt = new Date(project.updatedAt)
      if (!!project.startDate) project.startDate = new Date(project.startDate)
      if (!!project.endDate) project.endDate = new Date(project.endDate)
    }
    state.projects = projects
  }),

  _addProject: action((state, newProject) => {
    if (!state.projects.map((project) => project.id).includes(newProject.id)) {
      newProject.createdAt = new Date(newProject.createdAt)
      newProject.updatedAt = new Date(newProject.updatedAt)
      if (!!newProject.startDate) newProject.startDate = new Date(newProject.startDate)
      if (!!newProject.endDate) newProject.endDate = new Date(newProject.endDate)
      state.projects = [newProject, ...state.projects]
    }
  }),

  _updateProject: action((state, projectDTO) => {
    for (let i = 0; i < state.projects.length; i++) {
      if (state.projects[i].id === projectDTO.id) {
        projectDTO.createdAt = new Date(projectDTO.createdAt)
        projectDTO.updatedAt = new Date(projectDTO.updatedAt)
        if (!!projectDTO.startDate) projectDTO.startDate = new Date(projectDTO.startDate)
        if (!!projectDTO.endDate) projectDTO.endDate = new Date(projectDTO.endDate)
        const updatedProject: Project = {
          ...projectDTO,
          property: state.projects[i].property,
        }
        state.projects[i] = updatedProject
        return
      }
    }
  }),

  _deleteProject: action((state, projectId) => {
    state.projects = state.projects.filter((project) => project.id !== projectId)
  }),

  createProject: thunk(async (actions, dto, { getStoreState }) => {
    const isNameValid = actions.validateNewProjectName({ newName: dto.name })
    if (!isNameValid) {
      console.error(
        '[store.projects] project creation impossible: a project already exists with this name'
      )
      return false
    }

    let response = null
    try {
      const store = getStoreState()
      if (!store.user.token) {
        console.error("[store.projects] user-project creation impossible: user's token is missing")
        return false
      }

      const config = {
        headers: {
          Authorization: `Bearer ${store.user.token}`,
        },
      }
      response = await axiosApi.post<UserProjectDTO>(`/user-project`, dto, config)
    } catch (err) {
      console.error('[store.projects] error when posting a new user-project:', err)
      return false
    }

    const project = actions.completeProject({
      projectDTO: response.data,
      property: ProjectProperty.Own,
    })

    Mixpanel.track(MixpanelEvents.ProjectCreated, {
      projectId: project.id,
      projectName: project.name,
      withDescription: project.description && project.description.length > 0,
    })

    return true
  }),

  deleteProject: thunk(async (actions, projectId, { getState, getStoreState }) => {
    const store = getStoreState()
    try {
      if (!store.user.token) {
        console.error("[store.projects] project deletion impossible: user's token is missing")
        return false
      }

      const config = {
        headers: {
          Authorization: `Bearer ${store.user.token}`,
        },
      }
      await axiosApi.delete<UserProjectDTO>(`/user-project/${projectId}`, config)
    } catch (err) {
      console.error('[store.projects] error when deleting user-project:', err)
      return false
    }

    const project = getState().project(projectId)
    const products = store.projectProducts.projectProducts(projectId)
    const membersCount = store.projectMembers.projectMembersCount(projectId)
    if (project) {
      Mixpanel.track(MixpanelEvents.ProjectDeleted, {
        projectId: project.id,
        projectName: project.name,
        productsCount: products.length,
        membersCount,
        createdAt: project.createdAt,
      })
    }

    actions._deleteProject(projectId)

    return true
  }),

  completeProject: thunk((actions, { projectDTO, property }, { getStoreActions }) => {
    const { createdAt, ...projectData } = projectDTO
    const result: Project = {
      ...projectData,
      createdAt: projectDTO.createdAt,
      property: property,
    }
    actions._addProject(result)
    getStoreActions().projectProducts.fetchProjectProducts(projectDTO.id)
    getStoreActions().projectMembers.fetchProjectMembers(projectDTO.id)
    console.debug('[store.projects] completed project:', result)
    return result
  }),

  // for the sake of clarity, we want to avoid projects with the same name for the same user
  validateNewProjectName: thunk((actions, { newName, exception = null }, { getState }) => {
    const state = getState()
    const formattedNewName = newName.toLowerCase()
    const formattedException = exception ? exception.toLowerCase() : null
    for (let project of state.projects) {
      if (formattedException && project.name.toLowerCase() === formattedException) continue
      if (project.name.toLowerCase() === formattedNewName) return false
    }
    return true
  }),

  fetchProjects: thunk(async (actions, payload, { getStoreState }) => {
    // only web version
    if (isApp()) return

    const storeState = getStoreState()

    console.debug('[store.projects] fetch projects from API')
    const userId = storeState.user.userId()
    if (!userId) {
      console.error('[store.projects] user id not found')
      actions._setProjectsLoadingStatus(RequestStatus.Failed)
      return
    }

    const headers = {
      Authorization: `Bearer ${storeState.user.token}`,
    }

    // own projects
    try {
      console.debug('[store.projects] trying to fetch own projects')
      actions._setProjectsLoadingStatus(RequestStatus.Loading)
      const config = {
        headers: headers,
        params: {
          userId: userId,
        },
      }
      const resp = await axiosApi.get<UserProjectDTO[]>(`/user-project/`, config)
      console.debug('[store.projects] response:', resp.data)
      for (const project of resp.data) {
        if (project.ownerEmail === storeState.user.user.email) {
          actions.completeProject({
            projectDTO: project,
            property: ProjectProperty.Own,
          })
        } else {
          actions.completeProject({
            projectDTO: project,
            property: ProjectProperty.Shared,
          })
        }
      }

      actions._setProjectsLoadingStatus(RequestStatus.Completed)
    } catch (err) {
      console.error('[store.projects] error when fetching projects from API: ' + err)
      actions._setProjectsLoadingStatus(RequestStatus.Failed)
    }
  }),

  patchProject: thunk(async (actions, { projectId, dto }, { getStoreState }) => {
    console.debug(`[store.projects] update project infos...`)
    const storeState = getStoreState()

    let updatedProject = undefined
    // update online DB
    const config = {
      headers: {
        Authorization: `Bearer ${storeState.user.token}`,
      },
    }
    try {
      const resp = await axiosApi.patch<UserProjectDTO>(`/user-project/${projectId}`, dto, config)
      updatedProject = resp.data
    } catch (err) {
      return false
    }
    if (!updatedProject) return false

    // update offline state
    actions._updateProject(updatedProject)

    Mixpanel.track(MixpanelEvents.ProjectUpdated, {
      projectId,
      dto,
    })

    console.debug(`[store.projects] project infos updated`)
    return true
  }),
}
