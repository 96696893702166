import { Action, Thunk, action, thunk, Computed, computed } from 'easy-peasy'
import { Plugins } from '@capacitor/core'
import { LoginDTO, AuthDTO, UserDTO, UserUpdateDTO, LanguageCode, WorldArea } from '@fynde/dtos'
import axiosApi from '../utils/axios'
import { RequestStatus } from '../utils/reqStatus'
import i18n from '../utils/i18n'
import { StoreModel } from './store'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'

const TUTORIAL_WAS_SHOW_STORAGE_KEY = 'user_tutorialWasShown'
const USER_ID_STORAGE_KEY = 'user_id'
const TOKEN_STORAGE_KEY = 'user_token'
const LANGUAGE_STORAGE_KEY = 'user_language'
const WOLRD_AREA_STORAGE_KEY = 'user_world_area'

const { Storage, Device } = Plugins

export interface UserModel {
  // store
  tutorialWasShown: undefined | boolean
  appVersion: string | null
  user: UserDTO
  token: string | null
  language: string | null
  worldArea: string | null
  localDataStatus: RequestStatus
  onlineDataStatus: RequestStatus

  // computed
  userId: Computed<UserModel, () => string | null, StoreModel>

  // actions
  _setTutorialViewState: Action<UserModel, undefined | boolean>
  _setAppVersion: Action<UserModel, string | null>
  _setUserId: Action<UserModel, string | null>
  _setToken: Action<UserModel, string | null>
  _setLanguage: Action<UserModel, string | null>
  _updateUser: Action<UserModel, UserDTO>
  _setWorldArea: Action<UserModel, string | null>
  _setLocalDataStatus: Action<UserModel, RequestStatus>
  _setOnlineDataStatus: Action<UserModel, RequestStatus>

  // thunks
  setTutorialViewState: Thunk<UserModel, undefined | boolean>
  patchUser: Thunk<UserModel, UserUpdateDTO, any, StoreModel, Promise<boolean>>
  setUserId: Thunk<UserModel, string | null>
  setToken: Thunk<UserModel, string | null>
  setLanguage: Thunk<UserModel, string | null>
  saveLanguageInDB: Thunk<UserModel, string>
  setWorldArea: Thunk<UserModel, string | null>
  fetchLocalUserData: Thunk<UserModel>
  fetchOnlineUserData: Thunk<UserModel>
  logIn: Thunk<UserModel, LoginDTO, any, StoreModel, Promise<string | null>>
  logOut: Thunk<UserModel>
  unregister: Thunk<UserModel>
}

export const user: UserModel = {
  localDataStatus: RequestStatus.Idle,
  onlineDataStatus: RequestStatus.Idle,
  tutorialWasShown: undefined,
  appVersion: null,
  user: {
    id: '',
    email: '',
    firstname: '',
    lastname: '',
    professional: null,
    allowsNewsletters: false,
    language: LanguageCode.English,
    country: null,
    uiFirstColor: null,
    uiSecondColor: null,
  },
  token: null,
  language: null,
  worldArea: null,

  // computed
  userId: computed((state) => {
    return () => {
      return state.user.id === '' ? null : state.user.id
    }
  }),

  setUserId: thunk(async (actions, value) => {
    if (!!value) {
      await Storage.set({ key: USER_ID_STORAGE_KEY, value: value })
    } else {
      await Storage.remove({ key: USER_ID_STORAGE_KEY })
    }
    actions._setUserId(value)
  }),

  _setLocalDataStatus: action((state, localDataStatus) => {
    state.localDataStatus = localDataStatus
  }),

  _setOnlineDataStatus: action((state, onlineDataStatus) => {
    state.onlineDataStatus = onlineDataStatus
  }),

  // tutorial view state
  _setTutorialViewState: action((state, value) => {
    state.tutorialWasShown = value
  }),
  setTutorialViewState: thunk(async (actions, value) => {
    if (value !== undefined) {
      await Storage.set({ key: TUTORIAL_WAS_SHOW_STORAGE_KEY, value: JSON.stringify(value) })
    } else {
      await Storage.remove({ key: TUTORIAL_WAS_SHOW_STORAGE_KEY })
    }
    actions._setTutorialViewState(value)
  }),

  // last app version opened
  _setAppVersion: action((state, value) => {
    state.appVersion = value
  }),

  // user id
  _setUserId: action((state, value) => {
    state.user.id = value || ''
  }),

  _updateUser: action((state, patchedUser) => {
    state.user = patchedUser
  }),

  // token
  _setToken: action((state, value) => {
    state.token = value
  }),
  setToken: thunk(async (actions, value) => {
    if (value != null) {
      await Storage.set({ key: TOKEN_STORAGE_KEY, value: value })
    } else {
      await Storage.remove({ key: TOKEN_STORAGE_KEY })
    }
    actions._setToken(value)
  }),

  // language
  _setLanguage: action((state, value) => {
    state.language = value
  }),

  setLanguage: thunk(async (actions, value) => {
    let newValue =
      value !== null
        ? value
        : // default value
        navigator.language.search(new RegExp('^fr')) !== -1
        ? LanguageCode.French
        : LanguageCode.English

    await Storage.set({ key: LANGUAGE_STORAGE_KEY, value: newValue })
    i18n.changeLanguage(newValue)
    actions._setLanguage(newValue)
  }),

  saveLanguageInDB: thunk(async (actions, value) => {
    const payload: UserUpdateDTO = {
      language: value,
    }
    return await actions.patchUser(payload)
  }),

  // world area
  _setWorldArea: action((state, value) => {
    state.worldArea = value
  }),

  setWorldArea: thunk(async (actions, value) => {
    if (value === null) {
      await Storage.set({ key: WOLRD_AREA_STORAGE_KEY, value: WorldArea.Europe })
    } else {
      await Storage.set({ key: WOLRD_AREA_STORAGE_KEY, value: value })
      actions._setWorldArea(value)
    }
  }),

  // patch
  patchUser: thunk(async (actions, dto, { getState }) => {
    const state = getState()
    const userId = state.userId()
    if (!userId) {
      console.error('[store.user] cannot patch user because userId is missing')
      return false
    }

    // validate data
    if (
      (dto.firstname && dto.firstname.search(/\w/) === -1) ||
      (dto.lastname && dto.lastname.search(/\w/) === -1)
    ) {
      console.error('[store.user] firstname and lastname cannot be empty')
      return false
    }
    console.debug('[store.user] patch user:', dto)

    let patchedUser: UserDTO | null = null
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }
      const resp = await axiosApi.patch<UserDTO>(`/user/${userId}`, dto, config)
      patchedUser = resp.data
    } catch (err) {
      console.error('[store.user] an error occured while patching user:', err)
      return false
    }

    if (patchedUser) {
      actions._updateUser(patchedUser)

      Mixpanel.track(MixpanelEvents.UserUpdated, {
        userId: userId,
        emailUpdated: !!dto.email,
        passwordUpdated: !!dto.password,
        nameUpdated: !!dto.firstname || !!dto.lastname,
        languageUpdated: !!dto.language,
        professionalUpdated: !!dto.professional,
        countryUpdated: !!dto.country,
        allowNewslettersUpdated: !!dto.allowsNewsletters,
      })
    }
    return true
  }),

  fetchLocalUserData: thunk(async (actions, _) => {
    console.debug('[store.user] fetch local user data')
    actions._setLocalDataStatus(RequestStatus.Loading)

    //  login
    try {
      const { value } = await Storage.get({
        key: USER_ID_STORAGE_KEY,
      })
      if (value != null) actions._setUserId(value)
    } catch (err) {
      console.error('[store.user] error when getting local user id', err)
    }

    try {
      const { value } = await Storage.get({
        key: TOKEN_STORAGE_KEY,
      })
      if (value != null) actions._setToken(value)
    } catch (err) {
      console.error('[store.user] error when getting local token', err)
    }

    //  language
    try {
      const { value } = await Storage.get({
        key: LANGUAGE_STORAGE_KEY,
      })
      if (value != null) {
        actions.setLanguage(value)
        console.debug('[store.user] user saved language:', value)
      } else {
        actions.setLanguage(null)
        console.debug('[store.user] user saved language: default')
      }
    } catch (err) {
      console.error('[store.user] error when getting local saved language', err)
    }

    //  world area
    try {
      const { value } = await Storage.get({
        key: WOLRD_AREA_STORAGE_KEY,
      })
      if (value != null) {
        actions.setWorldArea(value)
        console.debug('[store.user] user saved world area:', value)
      } else {
        actions.setWorldArea(null)
        console.debug('[store.user] user saved world area: default')
      }
    } catch (err) {
      console.error('[store.user] error when getting local saved world area', err)
    }

    //  tutorial
    try {
      const { value } = await Storage.get({
        key: TUTORIAL_WAS_SHOW_STORAGE_KEY,
      })
      let tutorialWasShown: boolean
      if (value == null) {
        tutorialWasShown = false
      } else {
        tutorialWasShown = JSON.parse(value)
      }
      actions._setTutorialViewState(tutorialWasShown)
      console.debug('[store.user] tutorial was seen: ' + tutorialWasShown)
    } catch (err) {
      console.error('[store.user] error when getting tutorial seen state', err)
    }

    // app version
    const deviceInfo = await Device.getInfo()
    actions._setAppVersion(deviceInfo.appVersion)
    console.debug('[store.user] current app version: ' + deviceInfo.appVersion)

    actions._setLocalDataStatus(RequestStatus.Completed)
  }),

  fetchOnlineUserData: thunk(async (actions, payload, { getState }) => {
    console.debug('[store.user] fetch online user data')
    actions._setOnlineDataStatus(RequestStatus.Loading)
    const state = getState()
    const userId = state.userId()
    if (!userId) {
      console.error('[store.user] user id not found')
      actions._setOnlineDataStatus(RequestStatus.Failed)
      return
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }
      const resp = await axiosApi.get<UserDTO>(`/user/${userId}`, config)
      await actions._updateUser(resp.data)
    } catch (err) {
      console.error("[store.user] error when getting user's account data:", err)
    }

    actions._setOnlineDataStatus(RequestStatus.Completed)
  }),

  logIn: thunk(async (actions, payload: LoginDTO) => {
    console.debug('[store.user] log in')
    const result = await axiosApi
      .post<AuthDTO>(`/auth/login`, payload)
      .then(async (resp) => {
        actions.setLanguage(resp.data.language)
        await actions.setUserId(resp.data.userId)
        await actions.setToken(resp.data.token) // must be the last action because of observers

        Mixpanel.track(MixpanelEvents.UserLogIn, {
          userId: resp.data.userId,
        })
        return null
      })
      .catch((err) => {
        if (err.response) {
          console.debug('[store.user] error response:', err.response.data)
          return err.response.data.message
        } else {
          console.debug('[store.user] error:', err)
          return err
        }
      })
    return result
  }),

  logOut: thunk(async (actions, payload, { getState }) => {
    console.debug('[store.user] log out')

    Mixpanel.track(MixpanelEvents.UserLogOut, {
      userId: getState().userId,
    })

    actions._setLocalDataStatus(RequestStatus.Idle)
    actions._setOnlineDataStatus(RequestStatus.Idle)
    actions.setToken(null)
    actions.setUserId(null)
    actions.setLanguage(null)
    actions.setTutorialViewState(undefined)
    actions._setAppVersion(null)
  }),

  unregister: thunk(async (actions, _, { getState }) => {
    const state = getState()
    const userId = state.userId()
    if (!userId) return false

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }
      await axiosApi.delete<AuthDTO>(`/user/${userId}`, config)
      console.debug('[store.user] user deleted')

      Mixpanel.track(MixpanelEvents.UserSignOut, {
        userId: getState().userId,
      })

      actions.logOut()
      return true
    } catch (err) {
      console.error('[store.user] error when deleting user:', err)
      return false
    }
  }),
}
