import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { CenteredLoading } from '../../molecules/CenteredLoading/CenteredLoading'
import { CardFilter } from '../../molecules/FilterCard/FilterCard'
import './ColorFilterCard.css'

const ColorBullet = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  ${(props) =>
    ['#fff', '#ffffff'].includes(props.color.toLocaleLowerCase())
      ? css`
          border-color: ${(props) => props.theme.colors.grey300} !important;
        `
      : undefined
  }
`

export interface ColorTagData {
  id: string
  name: string
  hexaColor: string
}

export interface ColorFilterCardProps {
  colors: ColorTagData[]
  selectedColorIds: string[]
  onClear?: () => void
  onSelectionChange?: (selection: ColorTagData[]) => void
}

export const ColorFilterCard: React.FC<ColorFilterCardProps> = ({
  colors,
  selectedColorIds,
  onClear,
  onSelectionChange,
}) => {
  const { t } = useTranslation()

  const [selectedColorTags, setSelectedColorTags] = useState<ColorTagData[]>(
    () => colors.filter((color) => selectedColorIds.includes(color.id))
  )

  const onClearClicked = useCallback(() => {
    setSelectedColorTags([])
    if (onClear)
      onClear()
  }, [onClear])

  const onTagClick = (clickedId: string) => {
    console.log('[ColorFilterTag] onTagClick')
    const isAlreadySelected = selectedColorTags.some(
      (selectedMat) => selectedMat.id === clickedId
    )

    const newSelection:ColorTagData[] = isAlreadySelected
      ? selectedColorTags.filter((selectedMat) => selectedMat.id !== clickedId)
      : [...selectedColorTags, colors.filter((color) => color.id === clickedId)[0]]

    setSelectedColorTags(newSelection)
    
    if (onSelectionChange)
      onSelectionChange(newSelection);
  }

  return (
    <CardFilter 
      title={t('filters.color.title')}
      onClearCliked={onClearClicked} 
      disabled={selectedColorTags.length === 0}
      className={'color-filter-card'}
    >
      {colors.length === 0 ? (
        <CenteredLoading isLight={true}/>
      ) : (
        <div className={'colors'}>
          {colors.map((color) => (
            <div
              key={color.id}
              className={
                'item'
                + (selectedColorTags.some((selectedTag) => selectedTag.id === color.id) ? ' selected' : '')
              }
              onClick={() => onTagClick(color.id)}
            >
              <ColorBullet className={'bullet'} color={color.hexaColor} />
              <span className={'label'}>
                {color.name.toLocaleLowerCase()}
              </span>
            </div>
          ))}
        </div>
      )}
    </CardFilter>
  )
}
