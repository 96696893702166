import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getExtensionFromMimetype,
  getFormattedDate,
  isTheFileAnImage,
  splitFileExtension,
  toReadableFileSize,
} from '../../../utils/tool'
import { ListItem } from '../../atoms/ListItem/ListItem'
import { IconButton } from '../IconButton/IconButton'
import { ReactComponent as FileIcon } from '../../../assets/icons/file-icon.svg'
import { ReactComponent as CancelIcon } from '../../../assets/icons/cross-icon.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil-icon.svg'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-icon.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg'
import './FileListItem.css'
import { TextInput, TextInputRef } from '../TextInput/TextInput'
import { Button } from '../Button/Button'
import { LoadingBar } from '../LoadingBar/LoadingBar'

export interface FileListItemData {
  uploadProgress: number
  fileId: string
  imgData: string | null
  name: string
  mimetype: string
  size: number
  creationDate: Date
  owner: string
}

export interface FileListItemProps extends FileListItemData {
  isLoading: boolean
  onNameEdit: (fileId: string, newName: string) => void
  onUploadCancel: (fileId: string) => void
  onDownload: (fileId: string) => void
  onDelete: (fileId: string) => void
}

export const FileListItem: React.FC<FileListItemProps> = ({
  isLoading,
  uploadProgress,
  fileId,
  imgData,
  name,
  mimetype,
  size,
  creationDate,
  owner,
  onNameEdit,
  onUploadCancel,
  onDownload,
  onDelete,
}) => {
  const { t } = useTranslation()

  const formattedDate = useMemo(() => {
    return getFormattedDate(creationDate, 'DMY')
  }, [creationDate])

  // image
  const isImage = useMemo(() => {
    return isTheFileAnImage(mimetype)
  }, [mimetype])

  const format = useMemo(() => {
    return getExtensionFromMimetype(mimetype) || ''
  }, [mimetype])

  const filename = useMemo(() => {
    return splitFileExtension(name)[0]
  }, [name])

  const extension = useMemo(() => {
    return splitFileExtension(name)[1]
  }, [name])

  // name edit
  const nameInputRef = useRef<TextInputRef>(null)
  const [isEditingName, setIsEditingName] = useState<boolean>(false)
  const [newName, setNewName] = useState<string>(name)

  const handleNameEdit = useCallback(() => {
    setNewName(filename)
    setIsEditingName(true)
  }, [filename, setNewName, setIsEditingName])

  const handleNameEditSubmit = useCallback(() => {
    setIsEditingName(false)
    onNameEdit(fileId, newName + extension)
  }, [fileId, newName, extension, setIsEditingName, onNameEdit])

  useEffect(() => {
    if (isEditingName && nameInputRef.current) nameInputRef.current.focus()
  }, [isEditingName])

  // deletion
  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false)

  const handleDeletion = useCallback(() => {
    setShowDeleteWarning(false)
    onDelete(fileId)
  }, [fileId, setShowDeleteWarning, onDelete])

  return (
    <ListItem allowHover={false} isContentLoading={isLoading} className={'file-li-item'}>
      <div className={'preview'}>
        {isImage && imgData ? <img alt={''} src={imgData} /> : <FileIcon />}
      </div>

      {showDeleteWarning ? (
        <>
          <span>{t('chatMessageFileDeletionModal.shortMessage')}</span>
          <Button onClick={() => setShowDeleteWarning(false)} filled={false} fontSize={'small'}>
            {t('chatMessageFileDeletionModal.cancel')}
          </Button>
          <Button onClick={handleDeletion} filled={true} fontSize={'small'}>
            {t('chatMessageFileDeletionModal.submit')}
          </Button>
        </>
      ) : isEditingName ? (
        <>
          <TextInput
            value={newName}
            onChange={setNewName}
            placeholder={t('chatMessageFileRenamingModal.namePlaceholder')}
            size={'small'}
            ref={nameInputRef}
          />
          <Button onClick={() => setIsEditingName(false)} filled={false} fontSize={'small'}>
            {t('chatMessageFileRenamingModal.cancel')}
          </Button>
          <Button
            onClick={handleNameEditSubmit}
            filled={true}
            fontSize={'small'}
            isLocked={filename === newName || newName === ''}
          >
            {t('chatMessageFileRenamingModal.submit')}
          </Button>
        </>
      ) : (
        <>
          <div className={'name'}>
            <span className={'ellipsis-txt'}>{filename}</span>
          </div>

          {uploadProgress < 100 ? (
            <>
              <LoadingBar progress={uploadProgress} withText={true} />
              <IconButton
                icon={<CancelIcon />}
                onClick={() => onUploadCancel(fileId)}
                bg={'alpha'}
                size={'small'}
              />
            </>
          ) : (
            <>
              <div className={'format'}>
                <span>{format.toUpperCase()}</span>
              </div>

              <div className={'size'}>
                <span>{toReadableFileSize(size)}</span>
              </div>

              <div className={'date'}>{formattedDate}</div>

              <div className={'owner'}>
                <span className={'ellipsis-txt'}>{owner}</span>
              </div>

              <div className={'cta'}>
                <IconButton
                  icon={<EditIcon />}
                  onClick={handleNameEdit}
                  bg={'alpha'}
                  size={'small'}
                />
                <IconButton
                  icon={<DownloadIcon />}
                  onClick={() => onDownload(fileId)}
                  bg={'alpha'}
                  size={'small'}
                />
                <IconButton
                  icon={<TrashIcon />}
                  onClick={() => setShowDeleteWarning(true)}
                  bg={'alpha'}
                  size={'small'}
                />
              </div>
            </>
          )}
        </>
      )}
    </ListItem>
  )
}
