import React, { useState } from 'react'
import { ReactComponent as OpenedEye } from '../../../assets/icons/opened-eye-icon.svg'
import { ReactComponent as ClosedEye } from '../../../assets/icons/closed-eye-icon.svg'
import { TextInput } from '../TextInput/TextInput'
import './PasswordInput.css'

export interface PasswordInputProps {
  value?: string
  placeholder: string
  className?: string
  onChange: (value: string) => void
  withEye: boolean
  isMandatory?: boolean
  isWrong?: boolean
  autoComplete?: string | undefined
  initialDisplayMode?: 'visible' | 'hidden'
}

export const PasswordInput: React.FC<PasswordInputProps> = ({ 
  placeholder,
  value,
  className,
  onChange,
  withEye,
  isMandatory = false,
  isWrong = false,
  autoComplete = undefined,
  initialDisplayMode = 'hidden',
}) => {
  const [inputType, setInputType] = useState<'text' | 'password'>(
    initialDisplayMode === 'visible' ? 'text' : 'password'
  );
  const [isEyeClicked, setEyeClickedState] = useState(initialDisplayMode === 'visible');

  const handleEyeClick = () => {
    if (isEyeClicked) {
      setEyeClickedState(false);
      setInputType('password');
    } else {
      setEyeClickedState(true);
      setInputType('text');
    }
  }

  if (isMandatory)
      placeholder += '*'

  return (
    <div
      className={'password-input'
        + (className ? ' ' + className : '')
        + (isWrong ? ' error' : '')
      }
    >
      <TextInput
        type={inputType}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        autoComplete={autoComplete}
        size={'medium'}
      />
      {withEye && 
        <div className={'icon-wrapper'} onClick={handleEyeClick}>
          {isEyeClicked
            ? <OpenedEye />
            : <ClosedEye />
          }
        </div>
      }
    </div>
  )
}
