import React from 'react'
import { CenteredLoading } from '../components/molecules/CenteredLoading/CenteredLoading'
import { IonPage } from '@ionic/react'

const LoadingPage: React.FC = () => {
  return (
    <IonPage>
      <CenteredLoading/>
    </IonPage>
  )
}

export default LoadingPage
