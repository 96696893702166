import React, { useEffect } from 'react'
import { useStoreActions } from '../store/hooks'
import './i18n'

export const AppInit: React.FC = ({ children }) => {
  const initializeApp = useStoreActions((actions) => actions.initializeApp)

  useEffect(() => {
    initializeApp()
  }, [initializeApp])

  return <>{children}</>
}
