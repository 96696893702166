import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { Nav } from '../../atoms/Nav/Nav'
import { useHistory } from 'react-router'

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.greyBg};
`

const Content = styled.div<{ haveFooter?: boolean; noPaddingTop?: boolean; noPaddingSide?: boolean; noPaddingBottom?: boolean }>`
  margin-top: 4.5rem;
  box-sizing: border-box;
  overflow-y: auto;
  height: calc(100vh - 4rem);
  padding-top: ${(props) => (props.noPaddingTop != null && props.noPaddingTop ? 0 : '1rem')};
  padding-left: ${(props) => (props.noPaddingSide != null && props.noPaddingSide ? 0 : '1rem')};
  padding-right: ${(props) => (props.noPaddingSide != null && props.noPaddingSide ? 0 : '1rem')};
  padding-bottom: ${(props) => (props.noPaddingBottom != null && props.noPaddingBottom ? 0 : '1rem')};

  ${(props) =>
    props.haveFooter
      ? css`
          padding-bottom: calc(1.5rem + 2.5rem);
        `
      : undefined}
`

const Header = styled.div`
  position: absolute;
  background-color: transparent;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1rem 1rem;
  box-sizing: border-box;
  display: flex;
  z-index: 2;
  height: 4.5rem;
`

const Button = styled.button`
  border: none;
  background-color: transparent;
  outline: 0px;
  padding: 0px;
  margin: 0px;
`

const Footer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 1rem 1rem;
  background-color: ${(props) => props.theme.colors.greyBg};
`
export interface BaseAppLayoutProps {
  label: string
  className?: string
  footer?: React.ReactNode
  onBackClicked?: () => void
  noPaddingTop?: boolean
  noPaddingSide?: boolean
  noPaddingBottom?: boolean
}

export const BaseAppLayout: React.FC<BaseAppLayoutProps> = ({
  label,
  onBackClicked,
  children,
  className,
  footer,
  noPaddingTop,
  noPaddingSide = false,
  noPaddingBottom = false,
}) => {
  const history = useHistory()

  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <Container className={className}>
      <Header>
        <Button onClick={onBackClicked || goBack}>
          <Nav>{label}</Nav>
        </Button>
      </Header>
      <Content noPaddingTop={noPaddingTop} noPaddingSide={noPaddingSide} noPaddingBottom={noPaddingBottom} haveFooter={!!footer}>
        {children}
      </Content>
      {footer && <Footer>{footer}</Footer>}
    </Container>
  )
}
