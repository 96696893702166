import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { BaseAppLayout } from '../BaseAppLayout/BaseAppLayout'
import { useTranslation } from 'react-i18next'
import { CameraSource } from '@capacitor/core'
import { BackButtonEvent } from '@ionic/core';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
`

const Choice = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.black};
  margin-bottom: 1rem;
  
  :last-child {
    margin-bottom: none;
  }
`

const Label = styled.p`
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 13pt;
  max-width: 15em;
  line-height: 1.5em;
  color: ${(props) => props.theme.colors.white};
`


export interface ImageSourceChooserProps {
    cameraLabel: string
    galleryLabel: string
    callback: (source: CameraSource) => Promise<void>
    onBackClicked: () => void
  }
export const ImageSourceChooser: React.FC<ImageSourceChooserProps> = ({
    cameraLabel,
    galleryLabel,
    callback,
    onBackClicked,
  }) => {
    const { t } = useTranslation()

    // Android back button
    const handleBackButton = useCallback(
      (ev: BackButtonEvent) => {
        ev.preventDefault()
        ev.detail.register(10, onBackClicked);
      }, [onBackClicked]
    )

    useEffect(() => {
      document.addEventListener('ionBackButton', handleBackButton as EventListener);
      return () => {
        document.removeEventListener('ionBackButton', handleBackButton as EventListener);
      }
    }, [handleBackButton])

    return(
      <BaseAppLayout 
        noPaddingTop={true}
        noPaddingSide={false}
        noPaddingBottom={false}
        onBackClicked={onBackClicked}
        label={t('homeLayout.sourceHeader')}
      >
        <Container>
          <Choice onClick={() => callback(CameraSource.Camera)}>
              <Label>{cameraLabel}</Label>
          </Choice>
          <Choice onClick={() => callback(CameraSource.Photos)}>
              <Label>{galleryLabel}</Label>
          </Choice>
      </Container>
    </BaseAppLayout>
    );
}
