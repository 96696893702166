import { Thunk, thunk, action, Action, Computed, computed } from 'easy-peasy'
import { Plugins } from '@capacitor/core'
import { 
  UserSearchDTO,
  UserSearchCreationDTO,
  UserSearchesPaginatedDTO,
  UserSearchesLightDTO,
  ObjectDetectionLightDTO,
  VisualSearchLightDTO
} from '@fynde/dtos'
import { RequestStatus } from '../utils/reqStatus'
import axiosApi from '../utils/axios'
import { isApp } from '../utils/platform'
import { StoreModel } from './store'

const HISTORY_STORAGE_KEY = 'history'

const { Storage } = Plugins

const getStorageValue = async (): Promise<HistoryStorageItem[]> => {
  const { value } = await Storage.get({ key: HISTORY_STORAGE_KEY })
  return (value && JSON.parse(value)) || []
}

export interface HistoryStorageItem {
  cvFileId: string
  visualSearchId: string
  objectDetectionId: string
}

export interface HistoryItem {
  cvFileId: string
  thumbnailUrl: string
  visualSearch: VisualSearchLightDTO
  objectDetection: ObjectDetectionLightDTO
}

export const HistoryItemToStorageItem = (item: HistoryItem): HistoryStorageItem => {
  return {
    cvFileId: item.cvFileId,
    objectDetectionId: item.objectDetection.id,
    visualSearchId: item.visualSearch.id,
  }
}

export interface HistoryModel {
  addItem: Thunk<HistoryModel, HistoryItem, any, StoreModel>
  removeItem: Thunk<HistoryModel, string, any, StoreModel>

  items: HistoryItem[]
  itemIds: Computed<HistoryModel, string[]>

  _addItem: Action<HistoryModel, HistoryItem>
  _removeItem: Action<HistoryModel, string>
  _setItems: Action<HistoryModel, HistoryItem[]>

  fetchItems: Thunk<HistoryModel, void, any, StoreModel>
  reqStatus: RequestStatus

  setReqStatus: Action<HistoryModel, RequestStatus>
}

export const history: HistoryModel = {
  reqStatus: RequestStatus.Idle,
  items: [],

  setReqStatus: action((state, reqStatus) => {
    state.reqStatus = reqStatus
  }),

  itemIds: computed([(state) => state.items], (items) => {
    return items.map((i) => i.cvFileId)
  }),

  _addItem: action((state, item) => {
    const exist = state.items.some((i) => i.cvFileId === item.cvFileId)

    if (!exist) {
      state.items = [item, ...state.items]
    }
  }),

  _removeItem: action((state, itemId) => {
    state.items = state.items.filter((i) => i.cvFileId !== itemId)
  }),

  _setItems: action((state, items) => {
    state.items = items
  }),

  addItem: thunk(async (actions, item, { getState, getStoreState }) => {
    const ids = getState().itemIds
    if (ids.includes(item.cvFileId)) {
      return
    }
    
    // app
    if (isApp()) {
      await Storage.set({
        key: HISTORY_STORAGE_KEY,
        value: JSON.stringify([
          ...new Set([
            HistoryItemToStorageItem(item),
            ...getState().items.map(HistoryItemToStorageItem),
          ]),
        ]),
      })
    }
    // user account
    else {
      try {
        const store = getStoreState();
        const userId = store.user.userId()
        if (!userId || store.user.token === null) return;

        const config = {
          headers: {
            Authorization: `Bearer ${store.user.token}`
          }
        }
        const payload:UserSearchCreationDTO = {
          userId: userId,
          computerVisionFileId: item.cvFileId
        }
        console.debug("[store.history] addItem payload:", JSON.stringify(payload));
        await axiosApi.post<UserSearchDTO>(`/user-search`, payload, config);
      } catch (err) {
        console.error("[store.history] error when posting user-search:", err);
      }
    }

    actions._addItem(item)
  }),

  removeItem: thunk(async (actions, cvFileId, { getState, getStoreState }) => {
    // app
    if (isApp()) {
      await Storage.set({
        key: HISTORY_STORAGE_KEY,
        value: JSON.stringify(getState().items.filter((item) => item.cvFileId !== cvFileId).map(HistoryItemToStorageItem)),
      })
    }
    // user account
    else {
      try {
        const store = getStoreState();
        if (store.user.userId() == null || store.user.token == null) return;

        const config = {
          headers: {
            Authorization: `Bearer ${store.user.token}`
          },
          data: {
            userId: store.user.userId(),
            computerVisionFileId: cvFileId
          }
        }
        console.debug("[store.history] removeItem payload:", JSON.stringify(config.data));
        await axiosApi.delete<UserSearchDTO>(`/user-search`, config);
      } catch (err) {
        console.error("[store.history] error when deleting user-search:", err);
      }
    }

    actions._removeItem(cvFileId)
  }),

  fetchItems: thunk(async (actions,  payload, { getStoreState }) => {
    let storageItems: HistoryStorageItem[] = []
    actions.setReqStatus(RequestStatus.Loading)

    // App
    if (isApp()) {
      console.log("[store.history] fetch user's requests from local storage");
      try {
        storageItems = [...new Set(await getStorageValue())]
      } catch (err) {
        console.error("[store.history] error when fetching user's requests list:", err)
        actions.setReqStatus(RequestStatus.Failed)
        return
      }
    }

    // Browser
    else {
      actions._setItems([])
      actions.setReqStatus(RequestStatus.Completed)
      return
      // const storeState = getStoreState()
      // const userId = storeState.user.userId();
      // if (!userId) {
      //   console.error("[store.history] user id not found");
      //   actions.setReqStatus(RequestStatus.Failed)
      //   return
      // }
      // console.log("[store.history] fetch user's history from API, userId:", userId);

      // try {
      //   const resp = await axiosApi.get<UserSearchesPaginatedDTO>(`/user-search/${userId}`)
      //   storageItems = resp.data.data.map((userRequest: UserSearchDTO) => ({
      //     cvFileId: userRequest.cvFileId,
      //     visualSearchId: userRequest.visualSearchRequestId,
      //     objectDetectionId: userRequest.objectDetectionRequestId,
      //   }));
      //   //console.log("[store.history] user's requests from API: ", JSON.stringify(storageItems));
      //   console.log("[store.history] user's requests from API: ", storageItems.length);
      // } catch (err) {
      //   console.error("[store.history] error when fetching user's requests list from API: " + err);
      //   actions.setReqStatus(RequestStatus.Failed)
      //   return
      // }
    }

    // check if CV requests still exists in DB and get their basic informations (date, thumbnail, results count)
    try {
      const searches = await axiosApi.get<UserSearchesLightDTO>(
        `/user-search/`,
        {
          params: {
            "vsIds": storageItems.map((item) => item.visualSearchId),
          }
        }
      )
      //console.debug("[store.history] user searches list:", searches)

      const availableItems: HistoryItem[] = [];
      for (let el of searches.data.data) {
        el.visualSearch.createdAt = new Date(el.visualSearch.createdAt)
        availableItems.push({
          cvFileId: el.computerVisionFile.id,
          thumbnailUrl: el.computerVisionFile.media.publicUrl,
          objectDetection: el.objectDetection,
          visualSearch: el.visualSearch,
        })
      }
      //console.debug("[store.history] available items:", availableItems)

      await Storage.set({
        key: HISTORY_STORAGE_KEY,
        value: JSON.stringify(
          availableItems.map((item) => ({
            cvFileId: item.cvFileId,
            objectDetectionId: item.objectDetection?.id!,
            visualSearchId: item.visualSearch?.id!,
          }))
        ),
      })
      actions._setItems(availableItems)
    } catch (err) {
      console.error("[store.history] error when getting full data of user's requests", err)
      actions.setReqStatus(RequestStatus.Failed)
    }

    actions.setReqStatus(RequestStatus.Completed)
  }),
}
