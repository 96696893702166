import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../atoms/Button/Button'
import { ReactComponent as DefaultProjectIcon } from '../../../assets/icons/star-icon.svg'
import { ReactComponent as UserProjectIcon } from '../../../assets/icons/hashtag-icon.svg'
import { ReactComponent as ProductIcon } from '../../../assets/icons/cardboard-icon.svg'
import { ReactComponent as MemberIcon } from '../../../assets/icons/user-icon.svg'
import { getFormattedDate, getFormattedPrice } from '../../../utils/tool'
import { IonBackdrop } from '@ionic/react'
import { CenteredLoading } from '../../molecules/CenteredLoading/CenteredLoading'
import './ProjectCard.css'

export interface ProjectCardProps {
  isLoading: boolean
  name?: string
  budget?: number
  membersCount?: number
  productsCount?: number
  creationDate?: Date
  isDefaultProject?: boolean
  onOpen: () => void
}

export const ProjectCard: React.FC<ProjectCardProps> = ({
  isLoading,
  name,
  budget,
  membersCount,
  productsCount,
  creationDate,
  isDefaultProject,
  onOpen,
}) => {
  const { t } = useTranslation()

  // hover
  const [mouseOverCounter, setMouseOverCounter] = useState<number>(0)
  const [mouseOver, setMouseOver] = useState<boolean>(false)

  useEffect(() => {
    if (mouseOverCounter > 0 && !mouseOver) setMouseOver(true)
    else if (mouseOverCounter === 0 && mouseOver) setMouseOver(false)
  }, [mouseOverCounter])

  // date
  const formattedDate = creationDate ? getFormattedDate(creationDate, 'DMY') : ''

  return (
    <div className={'project-card'}>
      {isLoading ? (
        <CenteredLoading className={'content'} isLight={true} />
      ) : (
        <>
          <IonBackdrop
            visible={true}
            tappable={true}
            onIonBackdropTap={onOpen}
            onMouseEnter={() => setMouseOverCounter((prevValue) => prevValue + 1)}
            onMouseLeave={() => setMouseOverCounter((prevValue) => prevValue - 1)}
          />

          <div className={'content'}>
            <h3>
              {isDefaultProject ? (
                <>
                  <DefaultProjectIcon />
                  {t('project.defaultProjectName')}
                </>
              ) : (
                <>
                  <UserProjectIcon />
                  {name}
                </>
              )}
            </h3>

            {!isDefaultProject && <span className={'date light-txt'}>{formattedDate}</span>}

            {!isDefaultProject && (
              <div className={'infos-wrapper'}>
                {membersCount && (
                  <div>
                    <MemberIcon />
                    <span>{membersCount}</span>
                  </div>
                )}

                {productsCount !== undefined && (
                  <div>
                    <ProductIcon />
                    <span>{productsCount}</span>
                  </div>
                )}

                {budget !== undefined && budget > 0 && (
                  <span className={'budget'}>{getFormattedPrice(budget, 'EUR')}</span>
                )}
              </div>
            )}

            <div
              className={'buttons-wrapper'}
              onMouseEnter={() => setMouseOverCounter((prevValue) => prevValue + 1)}
              onMouseLeave={() => setMouseOverCounter((prevValue) => prevValue - 1)}
            >
              <Button onClick={onOpen} filled={mouseOver}>
                {t('projects.openButton')}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
