import axios from 'axios'

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // The default timeout is 1000ms, which is clearly insufficient for slow connections
  // On a weak 3G connection, a single request can easily take more than 5 secondes to complete
  // Which is why we choose a higher timeout as it's obviously preferable to get a longer loading screen than an error
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
})

export default axiosApi

/**
 * Determines whether the payload is an error thrown by Axios
 * from: https://github.com/axios/axios/blob/v1.3.4/lib/helpers/isAxiosError.js
 *
 * @param {*} payload The value to test
 *
 * @returns {boolean} True if the payload is an error thrown by Axios, otherwise false
 */
export function isAxiosError(payload: any) {
  return payload !== null && typeof payload === 'object' && (payload.isAxiosError === true);
}