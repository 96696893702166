export const downloadURL = (url: string, fileName: string) => {
  const link_tag = document.createElement('a')
  link_tag.href = url
  link_tag.setAttribute('download', fileName)
  document.body.appendChild(link_tag)
  link_tag.click()
  document.body.removeChild(link_tag)
}

export const downloadBlob = (blob: BlobPart, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  downloadURL(url, fileName)
}
