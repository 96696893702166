import React from 'react'
import { useStoreState } from '../../../store/hooks'
import './ProfessionalInfoModal.css'
import { Modal } from '../../organisms/Modal/Modal'
import { getFormattedAddress } from '../../../utils/tool'
import { Button } from '../../atoms/Button/Button'
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash-icon.svg'
import { ProfessionalDTO } from '@fynde/dtos'
import { useTranslation } from 'react-i18next'

export interface ProfessionalInfoModalProps {
  professionalId: string
  isOpen: boolean
  isLocked: boolean
  onEdit: (professional: ProfessionalDTO) => void
  onDelete: (professional: ProfessionalDTO) => void
  onCancel: () => void
}

export const ProfessionalInfoModal: React.FC<ProfessionalInfoModalProps> = ({
  professionalId,
  isOpen,
  isLocked,
  onEdit,
  onDelete,
  onCancel,
}) => {
  const { t } = useTranslation()

  const professional = useStoreState((state) => state.professionals.professional(professionalId))

  return (
    <>
      {isOpen ? (
        <Modal
          className={'professional-info-modal'}
          isOpen={true}
          onCancel={onCancel}
          withCross={true}
          withButtons={false}
        >
          {professional && (
            <>
              <div>
                <h4 className={'main-name condensed-txt bold'}>{professional.companyName}</h4>
                <p className={'job condensed-txt'}>{professional.job}</p>
              </div>

              {(professional.firstName ||
                professional.lastName ||
                professional.phone ||
                professional.email) && (
                <div>
                  <p className={'address condensed-txt'}>{`${professional.firstName || ''} ${
                    professional.lastName || ''
                  }`}</p>
                  <p className={'address condensed-txt'}>{professional.phone}</p>
                  <p className={'address condensed-txt'}>{professional.email}</p>
                </div>
              )}

              {professional.website && (
                <p className={'address condensed-txt'}>{professional.website}</p>
              )}

              {professional.companyAddress && (
                <div className={'address condensed-txt'}>
                  {getFormattedAddress(professional.companyAddress).map((line, index) => {
                    return <p key={index}>{line}</p>
                  })}
                </div>
              )}

              <div className={'cta-wrapper'}>
                <Button
                  Picto={EditIcon}
                  onClick={() => onEdit(professional)}
                  filled={true}
                  fontSize={'medium'}
                  isLocked={isLocked}
                >
                  {t('professionalInfoModal.editButton')}
                </Button>
                <Button
                  Picto={DeleteIcon}
                  onClick={() => onDelete(professional)}
                  filled={false}
                  fontSize={'medium'}
                  isLocked={isLocked}
                >
                  {t('professionalInfoModal.deleteButton')}
                </Button>
              </div>
            </>
          )}
        </Modal>
      ) : null}
    </>
  )
}
