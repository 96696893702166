import React from 'react'
import { ReactComponent as BackIcon } from '../../../assets/icons/arrow-down-icon.svg'
import './Nav.css'

export interface NavProps {
  iconDirection?: 'left' | 'bottom'
  onIconClick?: () => void
  className?: string
}

export const Nav: React.FC<NavProps> = ({
  onIconClick,
  iconDirection = 'left',
  children,
  className,
}) => {
  return (
    <div className={'nav' + (className ? ' ' + className : '')}>
      <div className={'part'}>
        <BackIcon
          onClick={onIconClick}
          className={'icon ' + iconDirection}
        />
      </div>
      <div className={'part'}>
        <span>
          {children}
        </span>
      </div>
    </div>
  )
}
