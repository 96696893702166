import React from 'react'
import styled from 'styled-components'
import './LoadingBar.css'

const ProgressBar = styled.div<{ width: number }>`
  width: ${(props) => props.width + '%'};
`

export type LoadingBarProps = {
  progress: number
  withText: boolean
}

export const LoadingBar: React.FC<LoadingBarProps> = ({ progress, withText }) => {
  return (
    <div className={'loading-bar'}>
      <div className={'bar'}>
        <ProgressBar className={'progress'} width={progress}/>
        <div className={'background'} />
      </div>
      {withText && <span className={'value'}>{progress.toFixed(0) + ' %'}</span>}
    </div>
  )
}
