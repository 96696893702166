import React, { useState, useCallback } from 'react'
import { useStoreActions, useStoreState } from '../store/hooks'
import styled from 'styled-components'
import { BaseDesktopLayout } from '../components/organisms/BaseDesktopLayout/BaseDesktopLayout'
import SizeCalculator from '../components/atoms/SizeCalculator/SizeCalculator'
import { CenteredLoading } from '../components/molecules/CenteredLoading/CenteredLoading'
import { TapToFynde } from '../components/molecules/TapToFynde/TapToFynde'
import { useTranslation } from 'react-i18next'
import { RequestStatus } from '../utils/reqStatus'
import { useHistory } from 'react-router-dom'
import { Routes } from '../utils/Routes'
import { ProjectCardsContainer } from '../components/organisms/ProjectCardsContainer/ProjectCardsContainer'
import { Ordering } from '@fynde/dtos'
import { ProjectProperty } from '../store/projects'

const Section = styled.section`
  width: fit-content;
  display: flex;
  flex-flow: column;
  margin-bottom: 5em;

  &:first-of-type {
    align-items: center;
    width: 100%;
    padding: 3rem 0 0 0;
  }

  & h2 {
    margin-top: 0;
  }

  & > div.header {
    display: flex;
    align-items: center;
    flex-flow: row;
    justify-content: flex-start;
    margin-bottom: 0.4em;
  }
`
const SeeMoreButton = styled.button`
  padding: 0 0 0.3em 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  white-space: nowrap;
  margin-left: 1rem;
  margin-top: -0.1rem;
  line-height: 1em;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
`
const AlignedCardsContainer = styled.div<{ centered: boolean; width: string; childWidth?: string }>`
  display: flex;
  flex-flow: row;
  justify-content: ${(props) => (props.centered ? 'center' : 'start')};
  width: ${(props) => props.width};

  & > * {
    width: ${(props) => (props.childWidth ? props.childWidth : 'auto')};
  }
  & > *:not(:first-child) {
    margin-left: 1rem;
  }
`

const HomeDesktop: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  // aligned cards
  const [maxAlignedItemsCount, setMaxLikedItemsCount] = useState<number>(4)
  const [visibleProjectCardsCount, setVisibleProjectCardsCount] = useState<number>(2)
  const handleSizeChange = useCallback(
    (width: number | null) => {
      if (width != null) {
        const newWidth = width > 0 ? width : window.innerWidth - 2 * 32

        const newCount = Math.floor((newWidth + 16) / (200 + 16))
        if (newCount !== maxAlignedItemsCount) {
          setMaxLikedItemsCount(newCount)
          setVisibleProjectCardsCount(Math.floor(newCount / 2))
        }
        //console.log("[HomeDesktop] new width:", newWidth, "new count:", newCount);
      }
    },
    [maxAlignedItemsCount]
  )

  // CV
  const clearComputerVision = useStoreActions(
    (actions) => actions.computerVision.clearComputerVision
  )
  const clearSearch = useStoreActions((actions) => actions.search.clear)
  const handleNewSearch = useCallback(() => {
    clearComputerVision()
    clearSearch()
    history.push(Routes.Search)
  }, [clearComputerVision, clearSearch, history])

  // projects
  const userProjects = useStoreState((state) =>
    state.projects.orderedProjects(Ordering.CreationDateDesc, ProjectProperty.Own)
  )
  const userProjectsStatus = useStoreState((state) => state.projects.projectsLoadingStatus)

  return (
    <BaseDesktopLayout maxWidth={'(400px * 4 + 7rem)'}>
      {[RequestStatus.Idle, RequestStatus.Loading].includes(userProjectsStatus) ? (
        <CenteredLoading height={'calc(100vh - 4rem - 4rem)'} />
      ) : (
        <>
          <SizeCalculator onSize={({ width }) => handleSizeChange(width)} />

          <Section>
            <TapToFynde onClick={handleNewSearch} isMobile={false} />
          </Section>

          {userProjectsStatus === RequestStatus.Completed && userProjects.length === 0 ? null : (
            <Section>
              <div className="header">
                <h2>{t('homeLayout.projectsHeader')}</h2>
                {userProjects.length > visibleProjectCardsCount && (
                  <SeeMoreButton onClick={() => history.push(Routes.Projects)}>
                    {t('homeLayout.seeMoreButton')}
                  </SeeMoreButton>
                )}
              </div>

              <AlignedCardsContainer
                centered={false}
                width={`calc((200px + 1rem) * ${maxAlignedItemsCount} - 1rem)`}
              >
                <ProjectCardsContainer
                  projects={userProjects}
                  maxCount={visibleProjectCardsCount}
                  centered={false}
                />
              </AlignedCardsContainer>
            </Section>
          )}
        </>
      )}
    </BaseDesktopLayout>
  )
}

export default HomeDesktop
