import { Thunk, thunk, action, Action, Computed, computed } from 'easy-peasy'
import {
  UserProjectProductOpinionCreationDTO,
  UserProjectProductOpinionDTO,
  UserProjectProductOpinionUpdateDTO,
} from '@fynde/dtos'
import { StoreModel } from './store'
import {
  getUserProjectProductOpinions,
  patchUserProjectProductOpinion,
  postUserProjectProductOpinion,
} from '../services/user-project-product-opinion'
import { RequestStatus } from '../utils/reqStatus'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'

export interface UserProjectProductOpinionsModel {
  // store
  opinions: UserProjectProductOpinionDTO[]
  loadingStatus: { projectId: string; status: RequestStatus }[]

  // computed
  userProjectProductOpinions: Computed<
    UserProjectProductOpinionsModel,
    (userProjectProductId: string) => UserProjectProductOpinionDTO[],
    StoreModel
  >
  projectLoadingStatus: Computed<
    UserProjectProductOpinionsModel,
    (projectId: string) => RequestStatus | null
  >

  // actions
  _setLoadingStatus: Action<
    UserProjectProductOpinionsModel,
    { projectId: string; status: RequestStatus }
  >
  _addUserProjectProductOpinion: Action<
    UserProjectProductOpinionsModel,
    UserProjectProductOpinionDTO
  >
  _patchUserProjectProductOpinion: Action<
    UserProjectProductOpinionsModel,
    UserProjectProductOpinionDTO
  >

  // with api calls
  postUserProjectProductOpinion: Thunk<
    UserProjectProductOpinionsModel,
    UserProjectProductOpinionCreationDTO,
    any,
    StoreModel,
    Promise<UserProjectProductOpinionDTO | null>
  >
  fetchProjectProductsOpinions: Thunk<
    UserProjectProductOpinionsModel,
    string,
    any,
    StoreModel,
    Promise<boolean>
  >
  patchUserProjectProductOpinion: Thunk<
    UserProjectProductOpinionsModel,
    UserProjectProductOpinionUpdateDTO,
    any,
    StoreModel,
    Promise<UserProjectProductOpinionDTO | null>
  >
}

export const userProjectProductOpinions: UserProjectProductOpinionsModel = {
  // store
  opinions: [],
  loadingStatus: [],

  // computed
  userProjectProductOpinions: computed((state) => {
    return (userProjectProductId) => {
      return state.opinions.filter(
        (opinion) => opinion.userProjectProductId === userProjectProductId
      )
    }
  }),

  projectLoadingStatus: computed((state) => {
    return (projectId) => {
      for (const loadingStatus of state.loadingStatus) {
        if (loadingStatus.projectId === projectId) return loadingStatus.status
      }
      return null
    }
  }),

  // actions
  _setLoadingStatus: action((state, { projectId, status }) => {
    // if it exists, update
    for (const loadingStatus of state.loadingStatus) {
      if (loadingStatus.projectId === projectId) {
        loadingStatus.status = status
        return
      }
    }
    // else, create
    state.loadingStatus.push({
      projectId: projectId,
      status: status,
    })
  }),

  _addUserProjectProductOpinion: action((state, newOpinion) => {
    if (
      state.opinions.some(
        (opinion) =>
          opinion.userId === newOpinion.userId &&
          opinion.userProjectProductId === newOpinion.userProjectProductId
      )
    )
      return
    newOpinion.createdAt = new Date(newOpinion.createdAt)
    newOpinion.updatedAt = new Date(newOpinion.updatedAt)
    state.opinions.push(newOpinion)
  }),

  _patchUserProjectProductOpinion: action((state, patchedOpinion) => {
    for (const opinion of state.opinions) {
      if (opinion.userProjectProductId === patchedOpinion.userProjectProductId) {
        opinion.opinion = patchedOpinion.opinion
        opinion.updatedAt = new Date(patchedOpinion.updatedAt)
        return
      }
    }
  }),

  // Thunks
  postUserProjectProductOpinion: thunk(async (actions, dto, { getStoreState }) => {
    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.user-project-product-opinion] cannot post a new user-project-product-opinion because user's token is missing"
      )
      return null
    }

    const newOpinion = await postUserProjectProductOpinion(storeState.user.token, dto)
    if (newOpinion === null) {
      return null
    }

    actions._addUserProjectProductOpinion(newOpinion)

    Mixpanel.track(MixpanelEvents.ProjectProductOpinionCreated, {
      userId: dto.userId,
      userProjectProductId: dto.userProjectProductId,
      opinion: dto.opinion,
    })

    return newOpinion
  }),

  fetchProjectProductsOpinions: thunk(async (actions, projectId, { getState, getStoreState }) => {
    console.debug(
      `[store.user-project-product-opinion] fetch project's product-opinions, projectId: '${projectId}'`
    )

    // check token
    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.user-project-product-opinion] cannot fetch project's product-opinions because user's token is missing"
      )
      return false
    }

    // check loading status
    if (
      [RequestStatus.Completed, RequestStatus.Loading].includes(
        getState().projectLoadingStatus(projectId) || RequestStatus.Idle
      )
    ) {
      console.warn(
        '[store.project-product-opinion] user-project-product-opinions were already fetched'
      )
      return false
    }
    actions._setLoadingStatus({
      projectId: projectId,
      status: RequestStatus.Loading,
    })

    // fetch
    const results = await getUserProjectProductOpinions(storeState.user.token, projectId)
    console.debug(
      `[store.user-project-product-opinion] fetched project's product-opinions:`,
      results
    )
    if (results === null) return false

    // add to store
    for (const result of results) {
      for (const opinion of result.userOpinions) {
        actions._addUserProjectProductOpinion({
          userProjectProductId: result.userProjectProductId,
          userId: opinion.userId,
          opinion: opinion.opinion,
          createdAt: opinion.createdAt,
          updatedAt: opinion.updatedAt,
        })
      }
    }

    // resolve loading status
    actions._setLoadingStatus({
      projectId: projectId,
      status: RequestStatus.Completed,
    })

    return true
  }),

  patchUserProjectProductOpinion: thunk(async (actions, dto, { getStoreState }) => {
    console.debug(`[store.user-project-product-opinion] patch project's product-opinion:`, dto)

    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.user-project-product-opinion] cannot patch a user-project-product-opinion because user's token is missing"
      )
      return null
    }
    const patchedOpinion = await patchUserProjectProductOpinion(storeState.user.token, dto)
    if (patchedOpinion === null) return null

    actions._patchUserProjectProductOpinion(patchedOpinion)

    Mixpanel.track(MixpanelEvents.ProjectProductOpinionUpdated, {
      userId: dto.userId,
      userProjectProductId: dto.userProjectProductId,
      opinion: dto.opinion,
    })

    return patchedOpinion
  }),
}
