const config = {
  imageMaxSize:
    process.env.REACT_APP_IMAGE_MAX_SIZE != null
      ? parseInt(process.env.REACT_APP_IMAGE_MAX_SIZE, 10)
      : 1600,
  cropOverlapThreshold:
    process.env.REACT_APP_CROP_OVERLAP_THRESHOLD != null
      ? parseInt(process.env.REACT_APP_CROP_OVERLAP_THRESHOLD, 10) / 100
      : 70 / 100,
  blurredImageMaxSize: 32,
  pageTitlePrefix: "Fynde - ",
  website: {
    url: 'https://www.fynde.app',
    routes: {
      tos: '/privacy-policy'
    },
  },
  api: {
    routes: {
      minimalAppVersion: '/min-app-version'
    },
  },
  appStores: {
    android: process.env.REACT_APP_PLAY_STORE_URL,
    ios: process.env.REACT_APP_APP_STORE_URL,
  },
  cloudImage: {
    token: process.env.REACT_APP_CLOUD_IMAGE_TOKEN,
    use: process.env.REACT_APP_USE_CLOUD_IMAGE === 'true' ? true : false
  }
}

export default config
