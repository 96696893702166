import { action, Action, Thunk, thunk, Computed, computed } from 'easy-peasy'
import {
  ComputerVisionFileDTO,
  VisualSearchCreationDTO,
  ObjectDetectionRegionDTO,
  VisualSearchDTO,
  ObjectDetectionDTO,
  ComputerVisionRequestStatus,
  ObjectDetectionObjectDTO,
  ObjectDetectionCreationDTO,
} from '@fynde/dtos'
import { RequestStatus } from '../utils/reqStatus'
import { StoreModel } from './store'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'
import { resizeImage, sleep } from '../utils/tool'
import config from '../config'
import {
  getObjectDetection,
  getVisualSearch,
  postCVFile,
  postObjectDetection,
  postVisualSearch,
} from '../services/computer-vision'

export interface ComputerVisionModel {
  // store
  reqFile: string | null // local file
  cvFile: ComputerVisionFileDTO | null // DB file
  cvFileUploadPercentage: number
  previousRegion: ObjectDetectionRegionDTO | undefined // previous request's crop
  region: ObjectDetectionRegionDTO | undefined // current request crop
  objectDetection: ObjectDetectionDTO | null // OD request
  objectDetectionStatus: RequestStatus
  visualSearch: VisualSearchDTO | null // VS request
  visualSearchStatus: RequestStatus

  // computed
  objectDetectionObjects: Computed<ComputerVisionModel, ObjectDetectionObjectDTO[] | undefined>
  isVisualSearchDone: Computed<ComputerVisionModel, boolean | null>

  // actions
  setReqFile: Action<ComputerVisionModel, string | null>
  setCVFile: Action<ComputerVisionModel, ComputerVisionFileDTO | null>
  setCVFileUploadPercentage: Action<ComputerVisionModel, number>
  clearComputerVision: Action<ComputerVisionModel>
  setPreviousRegion: Action<ComputerVisionModel, ObjectDetectionRegionDTO>
  setRegion: Action<ComputerVisionModel, ObjectDetectionRegionDTO>
  clearRegion: Action<ComputerVisionModel>
  setObjectDetection: Action<ComputerVisionModel, ObjectDetectionDTO | null>
  setObjectDetectionStatus: Action<ComputerVisionModel, RequestStatus>
  setVisualSearch: Action<ComputerVisionModel, VisualSearchDTO | null>
  setVisualSearchStatus: Action<ComputerVisionModel, RequestStatus>

  // thunks
  postCVFile: Thunk<ComputerVisionModel, string>
  clearCVFile: Thunk<ComputerVisionModel>
  postObjectDetection: Thunk<ComputerVisionModel>
  fetchObjectDetectionResults: Thunk<ComputerVisionModel, void, any, StoreModel>
  postVisualSearch: Thunk<ComputerVisionModel, void, undefined, StoreModel>
  fetchVisualSearchResults: Thunk<ComputerVisionModel, void, undefined, StoreModel>
}

export const computerVision: ComputerVisionModel = {
  // ===================
  // Local file
  // ===================

  // store
  reqFile: null,

  // actions
  setReqFile: action((state, payload) => {
    console.debug('[store.computer-vision] set reqFile')
    state.reqFile = payload
  }),

  // =========================
  // Computer Vision File
  // =========================

  // store
  cvFile: null,
  cvFileUploadPercentage: 0,

  // actions
  setCVFile: action((state, cvFile) => {
    console.debug('[store.computer-vision] setCVFile', cvFile)
    state.cvFile = cvFile
  }),

  setCVFileUploadPercentage: action((state, cvFileUploadPercentage) => {
    console.debug('[store.computer-vision] cvFileUploadPercentage', cvFileUploadPercentage)
    state.cvFileUploadPercentage = cvFileUploadPercentage
  }),

  // thunks
  postCVFile: thunk(async (actions, imageData) => {
    console.debug('[store.computer-vision] postCVFile')
    actions.setReqFile(imageData)
    actions.setCVFile(null)
    actions.setCVFileUploadPercentage(0)
    // resize if needed
    const maxSize = config.imageMaxSize
    imageData = await resizeImage(imageData!, maxSize)
    // post
    const cvFileDTO = await postCVFile(imageData!, (progressEvent) => {
      actions.setCVFileUploadPercentage((progressEvent.loaded * 100) / progressEvent.total)
    })
    if (cvFileDTO) actions.setCVFile(cvFileDTO)
    // launch OD
    actions.postObjectDetection()
  }),

  clearCVFile: thunk(async (actions) => {
    console.debug('[store.computer-vision] clearCVFile')
    actions.setCVFile(null)
    actions.setReqFile(null)
    actions.setCVFileUploadPercentage(0)
  }),

  // ====================
  // Object detection
  // ====================

  // store
  objectDetection: null,
  objectDetectionStatus: RequestStatus.Idle,

  // computed
  objectDetectionObjects: computed([(state) => state.objectDetection], (objectDetection) => {
    return objectDetection && objectDetection.processingResult
      ? objectDetection.processingResult.objects
      : undefined
  }),

  // actions
  setObjectDetection: action((state, objectDetection) => {
    state.objectDetection = objectDetection
  }),

  setObjectDetectionStatus: action((state, status) => {
    state.objectDetectionStatus = status
  }),

  // thunks
  postObjectDetection: thunk(async (actions, payload, { getState }) => {
    console.debug('[store.computer-vision] postObjectDetection')
    const state = getState()

    if (!state.cvFile) {
      console.error('[store.computer-vision] cannot post a new object-detection: cvFile is missing')
      return
    }

    // If the current object detection is not linked to the same CVFile
    const currentOD = state.objectDetection
    const newOD = !currentOD || currentOD.computerVisionFileId !== state.cvFile.id
    if (!newOD) {
      console.warn(
        '[store.computer-vision] cannot post a new object-detection: the current cvFile already have one'
      )
      return
    }

    actions.setObjectDetection(null)

    const odCreationDto: ObjectDetectionCreationDTO = {
      computerVisionFileId: state.cvFile.id,
    }
    const od = await postObjectDetection(odCreationDto)
    if (!od) return

    actions.setObjectDetection(od)

    Mixpanel.track(MixpanelEvents.ObjectDetectionCreated, {
      ...odCreationDto,
      computerVisionRequestId: od.id,
    })

    await actions.fetchObjectDetectionResults()
  }),

  fetchObjectDetectionResults: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    const currentOD = state.objectDetection
    if (!currentOD) {
      console.error(
        '[store.computer-vision] cannot get the object-detection: object-detection is missing'
      )
      return
    }

    if (state.objectDetectionStatus === RequestStatus.Loading) return
    actions.setObjectDetectionStatus(RequestStatus.Loading)

    let attempts = 10
    while (attempts-- > 0) {
      console.debug('[store.computer-vision] get the object-detection, attempt:', 10 - attempts)
      const od = await getObjectDetection(currentOD.id)
      if (od && od.status !== ComputerVisionRequestStatus.Processing) {
        console.debug('[store.computer-vision] fetched object-detection:', od)
        actions.setObjectDetection(od)
        actions.setObjectDetectionStatus(RequestStatus.Completed)

        Mixpanel.track(MixpanelEvents.ObjectDetectionResultsReceived, {
          computerVisionRequestId: od.id,
          status: od.status,
        })
        return
      } else {
        await sleep(250)
      }
    }

    actions.setObjectDetectionStatus(RequestStatus.Failed)
  }),

  // ===================
  // Visual search
  // ===================

  // store
  visualSearch: null,
  visualSearchStatus: RequestStatus.Idle,
  previousRegion: undefined,
  region: undefined,

  // computed
  isVisualSearchDone: computed([(state) => state.visualSearch], (visualSearch) => {
    return !visualSearch
      ? null
      : visualSearch.status === ComputerVisionRequestStatus.Completed ||
          visualSearch.status === ComputerVisionRequestStatus.Failed
  }),

  // actions
  setVisualSearchStatus: action((state, status) => {
    state.visualSearchStatus = status
  }),

  setVisualSearch: action((state, visualSearch) => {
    console.debug('[store.computer-vision] setVisualSearch', visualSearch)
    state.visualSearch = visualSearch
  }),

  setPreviousRegion: action((state, region) => {
    state.previousRegion = region
  }),

  setRegion: action((state, region) => {
    state.region = region
  }),

  clearRegion: action((state) => {
    state.region = undefined
  }),

  // thunks
  postVisualSearch: thunk(async (actions, payload, { getState }) => {
    console.debug('[store.computer-vision] postVisualSearch')
    const state = getState()

    if (!state.cvFile) {
      console.error('[store.computer-vision] cannot post a new visual-search: cvFile is missing')
      return
    }

    // continue only if...
    // - the current visual-search is not linked to the current CVFile
    // - or the region has changed
    // If the current object detection is not linked to the same CVFile
    const previousVS = state.visualSearch
    const hasImageChanged = !previousVS || previousVS.computerVisionFileId !== state.cvFile.id

    // get the current region or use the default one
    let region = state.region
    if (!region) {
      if (!!state.cvFile.width && !!state.cvFile.height)
        region = {
          x: 0,
          y: 0,
          width: state.cvFile.width,
          height: state.cvFile.height,
        }
      else {
        console.error('[store.computer-vision] cannot post a new visual-search: region is missing')
        return
      }
    }
    const previousRegion = state.previousRegion
    const hasRegionChanged =
      !previousRegion ||
      region.x !== previousRegion.x ||
      region.y !== previousRegion.y ||
      region.width !== previousRegion.width ||
      region.height !== previousRegion.height

    if (!hasImageChanged && !hasRegionChanged) {
      console.warn(
        '[store.computer-vision] cannot post a new visual-search: image and region have not changed'
      )
      return
    }

    const dto: VisualSearchCreationDTO = {
      computerVisionFileId: state.cvFile.id,
      region: {
        x: Math.round(region.x),
        y: Math.round(region.y),
        width: Math.round(region.width),
        height: Math.round(region.height),
      },
    }
    const visualSearch = await postVisualSearch(dto)
    if (!visualSearch) return

    actions.setVisualSearch(visualSearch)
    actions.setPreviousRegion(region)

    Mixpanel.track(MixpanelEvents.VisualSearchCreated, {
      ...dto,
      computerVisionRequestId: visualSearch.id,
    })

    await actions.fetchVisualSearchResults()
  }),

  fetchVisualSearchResults: thunk(async (actions, payload, { getState }) => {
    const state = getState()
    const currentVS = state.visualSearch
    if (!currentVS) {
      console.error(
        '[store.computer-vision] cannot get the visual-search: visual-search is missing'
      )
      return
    }

    if (state.visualSearchStatus === RequestStatus.Loading) return
    actions.setVisualSearchStatus(RequestStatus.Loading)

    let attempts = 10
    while (attempts-- > 0) {
      console.debug('[store.computer-vision] get the visual-search, attempt:', 10 - attempts)
      const vs = await getVisualSearch(currentVS.id)
      if (vs && vs.status !== ComputerVisionRequestStatus.Processing) {
        console.debug('[store.computer-vision] fetched visual-search:', vs)
        actions.setVisualSearch(vs)
        actions.setVisualSearchStatus(RequestStatus.Completed)

        Mixpanel.track(MixpanelEvents.VisualSearchResultsReceived, {
          computerVisionRequestId: vs.id,
          computerVisionRequestStatus: vs.status,
        })
        return
      } else {
        await sleep(250)
      }
    }

    actions.setVisualSearchStatus(RequestStatus.Failed)
  }),

  // ====================
  // Computer Vision
  // ====================

  clearComputerVision: action((state) => {
    state.cvFile = null
    state.reqFile = null
    state.visualSearch = null
    state.objectDetection = null
    state.region = undefined
  }),
}
