import React from 'react'
import { useHistory } from 'react-router-dom'
import { Routes } from '../../../utils/Routes'
import { DesktopHeader, HeaderTabs } from './DesktopHeader'

export interface DesktopHeaderWrapperProps {
  selectedTab: HeaderTabs | null
}

export const DesktopHeaderWrapper: React.FC<DesktopHeaderWrapperProps> = ({
  selectedTab = null,
}) => {
  const history = useHistory()

  return (
    <DesktopHeader
      selectedTab={selectedTab}
      onHomeClick={() => history.push(Routes.Home)}
      onSearchClick={() => history.push(Routes.Search)}
      onProjectsClick={() => history.push(Routes.Projects)}
      onAccountClick={() => history.push(Routes.Account)}
    />
  )
}
