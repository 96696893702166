import React, { useMemo, useCallback } from 'react'
import { RequestStatus } from '../../../utils/reqStatus'
import { areArraysEqual } from '../../../utils/tool'
import { useStoreState, useStoreActions } from '../../../store/hooks'
import { ColorFilterCard, ColorTagData } from './ColorFilterCard'

export interface ColorFilterCardWrapperProps {
  applyOnChange: boolean
  onChange?: (ids: string[]) => void
}

export const ColorFilterCardWrapper: React.FC<ColorFilterCardWrapperProps> = ({
  applyOnChange,
  onChange,
}) => {
  // is ready
  const colorTagsStatus = useStoreState((state) => state.colorTags.reqStatus)
  const isLoading = useMemo<boolean>(() => {
    return colorTagsStatus !== RequestStatus.Completed
  }, [colorTagsStatus])

  // logic
  const colorTags = useStoreState((state) => state.colorTags.localizedTags)
  const filtersColorTagIds = useStoreState((state) => state.filters.selectedColorTagIds)
  const updateFilter = useStoreActions((actions) => actions.filters.setNewFilters)

  const applyFilter = useCallback(
    (colorIds: string[]) => {
      if (!areArraysEqual(filtersColorTagIds, colorIds, true)) {
        updateFilter({
          selectedColorTagIds: colorIds,
        })
      }
    },
    [updateFilter, filtersColorTagIds]
  )

  const handleSelectionChange = useCallback(
    (colors: ColorTagData[]) => {
      console.log('[ColorFilterCardWrapper]', 'handleSelectionChange')
      const selection = colors.map((color) => color.id)
      if (applyOnChange === true) applyFilter(selection)

      if (onChange && !areArraysEqual(filtersColorTagIds, selection, true)) {
        onChange(selection)
      }
    },
    [applyOnChange, applyFilter, onChange, filtersColorTagIds]
  )

  return (
    <ColorFilterCard
      colors={
        isLoading
          ? []
          : colorTags
              .filter((color) => color.enabled)
              .sort((color1, color2) => color2.priority - color1.priority)
              .map((mat): ColorTagData => {
                return {
                  id: mat.id,
                  name: mat.name,
                  hexaColor: mat.hexaColor,
                }
              })
      }
      selectedColorIds={filtersColorTagIds}
      onSelectionChange={handleSelectionChange}
    />
  )
}
