import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '../IconButton/IconButton'
import { ReactComponent as FileIcon } from '../../../assets/icons/file-icon.svg'
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu-icon.svg'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-icon.svg'
import { ReactComponent as RenameIcon } from '../../../assets/icons/pencil-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash-icon.svg'
import './ChatMessageFile.css'
import { IonBackdrop } from '@ionic/react'
import { isTheFileAnImage, splitFileExtension, toReadableFileSize } from '../../../utils/tool'

export interface ChatMessageFileData {
  id: string
  name: string
  url: string
  mimetype: string
  filesize: number
}

export interface LoadingChatMessageFileProps {
  size: 'small' | 'medium'
}

export interface ChatMessageFileProps extends ChatMessageFileData {
  size: 'small' | 'medium'
  onDownload?: (id: string) => void
  onRename?: (id: string) => void
  onDelete?: (id: string) => void
  withHover?: boolean
}

export const LoadingChatMessageFile: React.FC<LoadingChatMessageFileProps> = ({ size }) => {
  return (
    <div className={`chat-message-file loading ${size}`}>
      <div className={'content'}>
        <div className={'gradient'} />
      </div>
    </div>
  )
}

export const ChatMessageFile: React.FC<ChatMessageFileProps> = ({
  id,
  name,
  url,
  mimetype,
  filesize,
  size,
  onDownload,
  onRename,
  onDelete,
  withHover = true,
}) => {
  const { t } = useTranslation()

  const isImage = useMemo(() => {
    return isTheFileAnImage(mimetype)
  }, [mimetype])

  const fileRef = useRef<HTMLDivElement>(null)

  // menu
  const menuRef = useRef<HTMLDivElement>(null)
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false)
  const handleMenuClick = useCallback(() => {
    setIsMenuOpened((previousValue) => !previousValue)
    if (fileRef.current && menuRef.current) {
      const fileRect = fileRef.current.getBoundingClientRect()
      const menuRect = menuRef.current.getBoundingClientRect()
      menuRef.current.style.top = `calc(${fileRect.top - menuRect.height}px - 0.8rem)`
      menuRef.current.style.left = `${fileRect.right - fileRect.width / 2 - menuRect.width / 2}px`
      const newMenuRect = menuRef.current.getBoundingClientRect()
      if (newMenuRect.top < 0) menuRef.current.style.top = `calc(${fileRect.top}px + 2.2rem)`
    }
  }, [])

  // actions
  const handleDownload = useCallback(
    (id: string) => {
      setIsMenuOpened(false)
      if (onDownload) onDownload(id)
    },
    [onDownload]
  )

  const handleRename = useCallback(
    (id: string) => {
      setIsMenuOpened(false)
      if (onRename) onRename(id)
    },
    [onRename]
  )

  const handleDelete = useCallback(
    (id: string) => {
      setIsMenuOpened(false)
      if (onDelete) onDelete(id)
    },
    [onDelete]
  )

  return (
    <div className={`chat-message-file ${size}`}>
      <div className={'content'} ref={fileRef}>
        {isImage ? (
          <img alt={''} src={url} />
        ) : (
          <div className={'placeholder'}>
            <FileIcon />
            <span>{splitFileExtension(url)[1]}</span>
          </div>
        )}
      </div>

      {withHover && (
        <>
          <div className={'hover-content'}>
            <div className={'icon-wrapper'}>
              {[onDownload, onRename, onDelete].filter((el) => el !== undefined).length > 1 ? (
                <IconButton
                  icon={<MenuIcon />}
                  onClick={handleMenuClick}
                  bg={'light'}
                  size={'small'}
                />
              ) : onDownload ? (
                <IconButton
                  icon={<DownloadIcon />}
                  onClick={() => handleDownload(id)}
                  bg={'light'}
                  size={'small'}
                />
              ) : (
                onDelete && (
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => handleDelete(id)}
                    bg={'light'}
                    size={'small'}
                  />
                )
              )}
            </div>
          </div>

          <div className={'hover-bottom'}>
            <div className={'filename'}>{name}</div>
            <div className={'filesize'}>{toReadableFileSize(filesize)}</div>
          </div>

          {[onDownload, onRename, onDelete].filter((el) => el !== undefined).length > 1 && (
            <div className={'menu' + (isMenuOpened ? ' opened' : '')}>
              <div className={'menu-content'} ref={menuRef}>
                {onDownload && (
                  <button className={'action'} onClick={() => handleDownload(id)}>
                    <DownloadIcon />
                    <span>{t('chatMessageFile.download')}</span>
                  </button>
                )}

                {onRename && (
                  <button className={'action'} onClick={() => handleRename(id)}>
                    <RenameIcon />
                    <span>{t('chatMessageFile.rename')}</span>
                  </button>
                )}

                {onDelete && (
                  <button className={'action'} onClick={() => handleDelete(id)}>
                    <DeleteIcon />
                    <span>{t('chatMessageFile.delete')}</span>
                  </button>
                )}
              </div>

              <IonBackdrop
                visible={true}
                tappable={true}
                onIonBackdropTap={() => setIsMenuOpened(false)}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}
