import React, { useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

export interface TextEmphasisProps {
  max?: number
  children: string
  onMoreClicked?: () => void
}

const P = styled.p`
  padding: 0px;
  margin: 0px;
  display: inline;
  white-space: pre-wrap;
`

const Container = styled.div`
  display: inline;
`

const Button = styled.button`
  border: none;
  background-color: transparent;
  outline: 0px;
  color: rgba(0,0,0,0.3);
  padding-left: 0;
  font-size: 0.9rem;
`

export const TextEmphasis: React.FC<TextEmphasisProps> = ({ children, max = 0, onMoreClicked }) => {
  const { t } = useTranslation()

  const [isExtended, setIsExtended] = useState(false)

  const text = useMemo(() => {
    return isExtended ? children : children.slice(0, max)
  }, [max, isExtended, children])

  const haveMore = useMemo(() => {
    return children.length > max
  }, [children, max])

  const onButtonClick = useCallback(() => {
    if (onMoreClicked != null && !isExtended)
      onMoreClicked()
    setIsExtended(!isExtended)
  }, [isExtended, onMoreClicked])

  return (
    <Container>
      <P>
        {text}
        {haveMore && !isExtended ? '...' : ''}
      </P>
      {haveMore && (
        <Button onClick={onButtonClick}>
          &nbsp;{!isExtended ? t('textEmphasis.readMore') : t('textEmphasis.readLess')}
        </Button>
      )}
    </Container>
  )
}
