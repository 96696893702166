import React, { useState } from 'react'
import styled from 'styled-components'
import { cloudImageUrl } from '../../../utils/cloudImage'
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-down-icon.svg'
import { useCallback } from 'react'
import './DesktopCarousel.css'
import { IconButton } from '../../atoms/IconButton/IconButton'
import config from '../../../config'

const Img = styled.img<{ bgColor: string | null }>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : 'none')};
`

export interface ProductPictureDTO {
  mediaId: string
  publicUrl: string
  hexaColor: string | null
}
export interface DesktopCarouselProps {
  imagesData: ProductPictureDTO[]
  onChange?: (slide: number) => void
  useCloudImage?: boolean
}

export const DesktopCarousel: React.FC<DesktopCarouselProps> = ({
  imagesData,
  onChange,
  useCloudImage = true,
}) => {
  const [currentImage, setCurrentImage] = useState<ProductPictureDTO>(imagesData[0])
  const maxThumbnailsCount = 5
  const [firstThumbnailIndex, setFirstThumbnailIndex] = useState<number>(0)

  const handleThumbnailClick = (image: ProductPictureDTO) => {
    setCurrentImage(image)
  }

  const moveThumbnails = (forward: boolean) => {
    let nextFirstIndex = firstThumbnailIndex

    if (forward) {
      nextFirstIndex = firstThumbnailIndex + maxThumbnailsCount
      const remainingImages = imagesData.length - nextFirstIndex
      if (remainingImages < maxThumbnailsCount) {
        nextFirstIndex -= maxThumbnailsCount - remainingImages
      }
    } else {
      nextFirstIndex = firstThumbnailIndex - maxThumbnailsCount
      if (nextFirstIndex < 0) {
        nextFirstIndex = 0
      }
    }

    setFirstThumbnailIndex(nextFirstIndex)
  }

  const getImageUrl = useCallback((url: string, width:number, extension: 'jpg' | 'png' | 'webp') => {
    return config.cloudImage.use && useCloudImage
      ? cloudImageUrl(url, width, undefined, extension)
      : url
  }, [useCloudImage])

  return (
    <div className={'desktop-carousel'}>
      <picture key={currentImage.mediaId} className={'main-img'}>
        <source
          srcSet={getImageUrl(currentImage.publicUrl, 800, 'webp')}
          type="image/webp"
        />
        <source
          srcSet={getImageUrl(currentImage.publicUrl, 800, 'jpg')}
          type="image/jpeg"
        />{' '}
        <Img src={currentImage.publicUrl} bgColor={currentImage.hexaColor} />
      </picture>

      {imagesData.length > 1 && (
        <div className={'thumbnails-wrapper'}>
          
          <div
            className={'arrow'}
          >
            <IconButton
              icon={<ArrowIcon />}
              className={'previous'}
              onClick={() => moveThumbnails(false)}
              enabled={firstThumbnailIndex > 0}
              size={'small'}
              bg={'none'}
            />
          </div>

          {imagesData.map((data, index) =>
            index < firstThumbnailIndex ||
            index >= firstThumbnailIndex + maxThumbnailsCount ? null : (
              <picture key={data.mediaId}>
                <source
                  srcSet={getImageUrl(data.publicUrl, 800, 'webp')}
                  type="image/webp"
                />
                <source
                  srcSet={getImageUrl(data.publicUrl, 800, 'jpg')}
                  type="image/jpeg"
                />{' '}
                <Img
                  src={data.publicUrl}
                  bgColor={data.hexaColor}
                  onClick={() => handleThumbnailClick(data)}
                />
              </picture>
            )
          )}

          <div className={'arrow'}>
            <IconButton
              icon={<ArrowIcon />}
              className={'next'}
              onClick={() => moveThumbnails(true)}
              enabled={firstThumbnailIndex + maxThumbnailsCount <= imagesData.length - 1}
              size={'small'}
              bg={'none'}
            />
          </div>
        </div>
      )}
    </div>
  )
}
