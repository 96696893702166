import { UserOpinion } from '@fynde/dtos'
import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { Routes } from '../../../utils/Routes'
import { AddToProjectModalWrapper } from '../AddToProjectModal/AddToProjectModalWrapper'
import { CardProductVariationProps } from '../CardProductVariation/CardProductVariation'
import { UserProductModal } from '../UserProductModal/UserProductModal'
import {
  LoadingProjectProductVariationCard,
  ProjectProductVariationCard,
} from './ProjectProductVariationCard'

export interface ProjectProductVariationCardWrapperProps {
  userProjectProductId: string
  productVariationCardProps: CardProductVariationProps
  isDefaultProject: boolean
  onChatClick?: () => void
}

export const ProjectProductVariationCardWrapper: React.FC<ProjectProductVariationCardWrapperProps> =
  ({ userProjectProductId, productVariationCardProps, isDefaultProject, onChatClick }) => {
    const history = useHistory()

    const product = useStoreState((store) =>
      store.projectProducts.projectProduct(userProjectProductId)
    )

    // quantity
    const updateProductQuantity = useStoreActions(
      (store) => store.projectProducts.updateProductQuantity
    )
    const handleQuantityChange = useCallback(
      (quantity: number) => {
        updateProductQuantity({
          userProjectProductId,
          quantity: quantity,
        })
      },
      [userProjectProductId, updateProductQuantity]
    )

    // add to project
    const [addToProjectAlertState, setAddToProjectAlertState] = useState(false)

    // update user-product
    const [newUserProductAlertState, setNewUserProductAlertState] = useState(false)

    // deletion
    const deleteProjectProductVariation = useStoreActions(
      (store) => store.projectProducts.deleteProduct
    )
    const handleDeletion = useCallback(() => {
      console.debug(`[ProjectProductWrapper] delete project-product:`, userProjectProductId)
      deleteProjectProductVariation(userProjectProductId)
    }, [userProjectProductId, deleteProjectProductVariation])

    // product page
    const productPageUrl =
      !product || !product.productVariation
        ? undefined
        : `${Routes.ProductVariation}/${product?.productVariationId}`

    // bullets
    const messages = useStoreState((store) =>
      store.userMessages.userMessages(null, userProjectProductId)
    )

    // validation
    const updateValidationState = useStoreActions(
      (store) => store.projectProducts.setProductValidationState
    )

    const handleValidation = useCallback(() => {
      updateValidationState({
        userProjectProductId,
        isValidated: true,
      })
    }, [userProjectProductId, updateValidationState])

    const handleInvalidation = useCallback(() => {
      updateValidationState({
        userProjectProductId,
        isValidated: false,
      })
    }, [userProjectProductId, updateValidationState])

    return (
      <div className={'project-product-card-wrapper'}>
        <AddToProjectModalWrapper
          isOpen={!!addToProjectAlertState}
          onCancel={() => setAddToProjectAlertState(false)}
          productVariationProps={productVariationCardProps}
        />

        {product && (
          <UserProductModal
            isOpen={newUserProductAlertState}
            onCancel={() => setNewUserProductAlertState(false)}
            userProjectId={product.projectId}
            userProduct={product.userProduct}
          />
        )}

        {product ? (
          <ProjectProductVariationCard
            productVariationCardProps={productVariationCardProps}
            isDefaultProject={isDefaultProject}
            onDelete={handleDeletion}
            quantity={product.quantity}
            isQuantityUpdating={product.isQuantityUpdating}
            onQuantityChange={handleQuantityChange}
            hasMessages={messages.length > 0}
            positiveOpinionsCount={
              product.opinions.filter((o) => o.opinion === UserOpinion.Good).length
            }
            negativeOpinionsCount={
              product.opinions.filter((o) => o.opinion === UserOpinion.Bad).length
            }
            onPushToProject={() => setAddToProjectAlertState(true)}
            onProductPageClick={productPageUrl ? () => history.push(productPageUrl) : undefined}
            onChatClick={onChatClick}
            onUpdate={product.userProduct ? () => setNewUserProductAlertState(true) : undefined}
            onValidate={product.validated === false ? handleValidation : undefined}
            onInvalidate={product.validated === true ? handleInvalidation : undefined}
          />
        ) : (
          <LoadingProjectProductVariationCard />
        )}
      </div>
    )
  }
