import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../Modal/Modal'
import ReactMarkdown from 'react-markdown'
import { useStoreActions } from '../../../store/hooks'

export interface SearchDeletionModalProps {
  isOpen: boolean
  onCancel: () => void
  cvFileId: string
}

export const SearchDeletionModal: React.FC<SearchDeletionModalProps> = ({
  isOpen,
  onCancel,
  cvFileId,
}) => {
  const { t } = useTranslation()

  const removeHistoryItem = useStoreActions((actions) => actions.history.removeItem)
  const handleDeletionValidation = useCallback(() => {
      console.debug(`[Projects] delete search:`, cvFileId)
      removeHistoryItem(cvFileId)
      onCancel()
  }, [removeHistoryItem, onCancel, cvFileId])

  const message = t('searchDeletionAlert.message')
    .replace(/\n/g, '  \n') // markdown carriage return

  return (
    <Modal
      isOpen={isOpen}
      header={t('searchDeletionAlert.header')}
      cancelButton={t('searchDeletionAlert.cancel')}
      validateButton={t('searchDeletionAlert.confirm')}
      onCancel={onCancel}
      onValidate={handleDeletionValidation}
      isValidateButtonLocked={false}
      maxWidth={'600px'}
    >
      <ReactMarkdown>{message}</ReactMarkdown>
    </Modal>
  )
}
