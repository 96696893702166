import React, { SVGProps } from 'react'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import './Button.css'

const Container = styled.button<{ bg: string | undefined }>`
  ${(props) => props.bg
    ? css`background-color: ${props.bg}`
    : css``
  }
`

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void
  className?: string
  filled?: boolean
  fontSize?: "small" | "medium" | "large"
  fontCase?: "uppercase" | "lowercase" | "capitalize" | undefined
  bg?: string
  // if button
  type?: "button" | "submit" | "reset" | undefined
  isLocked?: boolean
  // if link
  href?: string
  target?: string
  // if picto
  Picto?: FunctionComponent<SVGProps<SVGSVGElement>>
}

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  className,
  type,
  filled = true,
  isLocked = false,
  fontSize = 'small',
  bg,
  href,
  target,
  Picto,
}) => {
  const button = (
    <Container
      className={`button ${fontSize} ${filled ? 'filled' : ''} ${className}`}
      type={type}
      onClick={onClick}
      disabled={isLocked}
      bg={bg}
    >
      {Picto && (
        <div className={'picto-wrapper'}>
          <Picto className={`picto ${filled ? 'light' : ''}`}/>
        </div>
      )}
      <span>
        {children}
      </span>
    </Container>
  )
  
  return (
    <>
      {href ? (
        <a
          className={'button-link'}
          href={href}
          target={target}
        >
          {button}
        </a>
      ) : (
        button
      )}
    </>
  )
}
