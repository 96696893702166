import React from 'react'
import styled from 'styled-components'
import sizeMe from 'react-sizeme'

const StyledDiv = styled.div`
  width: 100%;
  height: 0px;
`

export interface Size {
  width: number
  height: number
}

const SizeCalculator: React.FC = () => {
  return (
    <StyledDiv></StyledDiv>
  )
}

export default sizeMe({ monitorWidth: true, monitorHeight: true, noPlaceholder: true })(SizeCalculator)
