import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { RequestStatus } from '../utils/reqStatus'
import { useStoreState } from '../store/hooks'
import { BaseAppLayout } from '../components/organisms/BaseAppLayout/BaseAppLayout'
import { GridCard } from '../components/organisms/GridCard/GridCard'
import { ContainerCardProductVariation } from '../components/organisms/ContainerCardProductVariation/ContainerCardProductVariation'
import { CenteredLoading } from '../components/molecules/CenteredLoading/CenteredLoading'
import { useIonViewDidEnter } from '@ionic/react'
import { BackButtonEvent } from '@ionic/core'
import config from '../config'

export interface LikesProps {
  onBackClicked?: () => void
}
const Likes: React.FC<LikesProps> = ({ onBackClicked }) => {
  const { t } = useTranslation()

  // title
  useIonViewDidEnter(() => {
    document.title = config.pageTitlePrefix + t('pageTitle.Likes')
  }, [t])

  // user data
  const likedItems = useStoreState((state) => state.saved.items)
  const likedItemsStatus = useStoreState((state) => state.saved.reqStatus)

  // Android back button
  const handleBackButton = useCallback(
    (ev: BackButtonEvent) => {
      ev.preventDefault()
      ev.detail.register(10, () => {
        if (onBackClicked !== undefined) onBackClicked()
      })
    },
    [onBackClicked]
  )

  useEffect(() => {
    document.addEventListener('ionBackButton', handleBackButton as EventListener)
    return () => {
      document.removeEventListener('ionBackButton', handleBackButton as EventListener)
    }
  }, [handleBackButton])

  return (
    <BaseAppLayout noPaddingTop={true} label={t('savedLayout.title')} onBackClicked={onBackClicked}>
      {likedItemsStatus === RequestStatus.Loading && <CenteredLoading />}
      {likedItemsStatus !== RequestStatus.Loading && (
        <GridCard justify={'center'}>
          {likedItems.map((i) => (
            <ContainerCardProductVariation key={i.id} data={i} />
          ))}
        </GridCard>
      )}
    </BaseAppLayout>
  )
}

export default Likes
