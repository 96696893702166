import {
  Ordering,
  ProductVariationDTO,
  UserProductCreationDTO,
  UserProductDTO,
  UserProductUpdateDTO,
  UserProjectProductCreationDTO,
  UserProjectProductDTO,
  UserProjectProductOpinionDTO,
  UserProjectProductUpdateDTO,
} from '@fynde/dtos'
import { Action, action, Thunk, thunk, Computed, computed } from 'easy-peasy'
import { getProductVariation, getProductVariationsByIds } from '../services/product-variation'
import { patchUserProduct, postUserProduct } from '../services/user-product'
import {
  deleteUserProjectProduct,
  getUserProjectProducts,
  patchUserProjectProduct,
  postUserProjectProduct,
} from '../services/user-project-products'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'
import { RequestStatus } from '../utils/reqStatus'
import { StoreModel } from './store'

export interface LocalProjectProduct extends UserProjectProductDTO {
  isQuantityUpdating: boolean
}

export interface LocalComputedProjectProduct extends LocalProjectProduct {
  opinions: UserProjectProductOpinionDTO[]
}

export interface ProjectProductsModel {
  // store
  products: LocalProjectProduct[]
  productsLoadingStatus: { projectId: string; status: RequestStatus }[]
  productVariationsLoadingStatus: { projectId: string; status: RequestStatus }[]

  // computed
  projectProducts: Computed<
    ProjectProductsModel,
    (projectId: string) => LocalComputedProjectProduct[],
    StoreModel
  >
  projectProduct: Computed<
    ProjectProductsModel,
    (userProjectProductId: string) => LocalComputedProjectProduct | null,
    StoreModel
  >
  orderedProjectProducts: Computed<
    ProjectProductsModel,
    (projectId: string, order: Ordering) => LocalComputedProjectProduct[]
  >
  projectProductsCount: Computed<ProjectProductsModel, (projectId: string) => number>
  projectProductsLoadingStatus: Computed<
    ProjectProductsModel,
    (projectId: string) => RequestStatus | null
  >
  projectProductVariationsLoadingStatus: Computed<
    ProjectProductsModel,
    (projectId: string) => RequestStatus | null
  >

  // actions
  _setProductsLoadingStatus: Action<
    ProjectProductsModel,
    { projectId: string; status: RequestStatus }
  >
  _setProductVariationsLoadingStatus: Action<
    ProjectProductsModel,
    { projectId: string; status: RequestStatus }
  >
  _addProduct: Action<ProjectProductsModel, LocalProjectProduct>
  _deleteProduct: Action<ProjectProductsModel, string>
  _updateProductQuantityLoadingStatus: Action<
    ProjectProductsModel,
    { userProjectProductId: string; isLoading: boolean }
  >
  _updateUserProjectProduct: Action<ProjectProductsModel, UserProjectProductDTO>
  _updateUserProduct: Action<ProjectProductsModel, UserProductDTO>
  _updateProductVariation: Action<ProjectProductsModel, ProductVariationDTO>

  // thunks
  createProduct: Thunk<
    ProjectProductsModel,
    UserProjectProductCreationDTO,
    any,
    StoreModel,
    Promise<boolean>
  >
  createProductFromUserProduct: Thunk<
    ProjectProductsModel,
    { projectId: string; dto: UserProductCreationDTO },
    any,
    StoreModel,
    Promise<boolean>
  >
  addProductToLocal: Thunk<ProjectProductsModel, UserProjectProductDTO, any, StoreModel>
  deleteProduct: Thunk<ProjectProductsModel, string, any, StoreModel, Promise<boolean>>
  updateProductQuantity: Thunk<
    ProjectProductsModel,
    { userProjectProductId: string; quantity: number },
    any,
    StoreModel,
    Promise<boolean>
  >
  setProductValidationState: Thunk<
    ProjectProductsModel,
    { userProjectProductId: string; isValidated: boolean },
    any,
    StoreModel,
    Promise<boolean>
  >
  patchUserProduct: Thunk<
    ProjectProductsModel,
    { userProductId: string; dto: UserProductUpdateDTO },
    any,
    StoreModel,
    Promise<boolean>
  >
  fetchProjectProducts: Thunk<ProjectProductsModel, string, any, StoreModel, Promise<boolean>>
  fetchProjectProductVariations: Thunk<
    ProjectProductsModel,
    string,
    any,
    StoreModel,
    Promise<boolean>
  >
  fetchProductData: Thunk<ProjectProductsModel, string, any, StoreModel>
}

export const projectProducts: ProjectProductsModel = {
  products: [],
  productsLoadingStatus: [],
  productVariationsLoadingStatus: [],

  // computed
  projectProducts: computed(
    [(state) => state, (state, storeState) => storeState],
    (state, storeState) => {
      return (projectId) => {
        const results: LocalComputedProjectProduct[] = []
        const products = state.products.filter((product) => product.projectId === projectId)
        for (const product of products) {
          results.push({
            ...product,
            opinions: storeState.userProjectProductOpinions.userProjectProductOpinions(product.id),
          })
        }
        return results
      }
    }
  ),

  projectProduct: computed(
    [(state) => state, (state, storeState) => storeState],
    (state, storeState) => {
      return (userProjectProductId) => {
        const products = state.products.filter((product) => product.id === userProjectProductId)
        const result =
          products.length === 0
            ? null
            : {
                ...products[0],
                opinions: storeState.userProjectProductOpinions.userProjectProductOpinions(
                  products[0].id
                ),
              }
        return result
      }
    }
  ),

  orderedProjectProducts: computed((state) => {
    return (projectId, order) => {
      let products = state.projectProducts(projectId)
      if (products.length === 0) return products

      switch (order) {
        case Ordering.CreationDateAsc:
          products.sort((first, second) => (first.createdAt < second.createdAt ? -1 : 1))
          break
        case Ordering.CreationDateDesc:
          products.sort((first, second) => (first.createdAt > second.createdAt ? -1 : 1))
          break
        case Ordering.QuantityAsc:
          products.sort((first, second) => first.quantity - second.quantity)
          break
        case Ordering.QuantityDesc:
          products.sort((first, second) => second.quantity - first.quantity)
          break
        case Ordering.PriceAsc:
          products.sort(
            (first, second) =>
              (first.eshopProductVariation?.price || 0) - (second.eshopProductVariation?.price || 0)
          )
          break
        case Ordering.PriceDesc:
          products.sort(
            (first, second) =>
              (second.eshopProductVariation?.price || 0) - (first.eshopProductVariation?.price || 0)
          )
          break
      }
      return products
    }
  }),

  projectProductsCount: computed((state) => {
    return (projectId) => {
      return state.projectProducts(projectId).length
    }
  }),

  projectProductsLoadingStatus: computed((state) => {
    return (projectId) => {
      for (const loadingStatus of state.productsLoadingStatus) {
        if (loadingStatus.projectId === projectId) return loadingStatus.status
      }
      return null
    }
  }),

  projectProductVariationsLoadingStatus: computed((state) => {
    return (projectId) => {
      for (const loadingStatus of state.productVariationsLoadingStatus) {
        if (loadingStatus.projectId === projectId) return loadingStatus.status
      }
      return null
    }
  }),

  // actions
  _setProductsLoadingStatus: action((state, { projectId, status }) => {
    // if it exists, update
    for (const loadingStatus of state.productsLoadingStatus) {
      if (loadingStatus.projectId === projectId) {
        loadingStatus.status = status
        return
      }
    }
    // else, create
    state.productsLoadingStatus = [
      ...state.productsLoadingStatus,
      {
        projectId: projectId,
        status: status,
      },
    ]
  }),

  _setProductVariationsLoadingStatus: action((state, { projectId, status }) => {
    // if it exists, update
    for (const loadingStatus of state.productVariationsLoadingStatus) {
      if (loadingStatus.projectId === projectId) {
        loadingStatus.status = status
        return
      }
    }
    // else, create
    state.productVariationsLoadingStatus = [
      ...state.productVariationsLoadingStatus,
      {
        projectId: projectId,
        status: status,
      },
    ]
  }),

  _addProduct: action((state, newProduct) => {
    if (
      state.products.filter(
        (product) =>
          product.projectId === newProduct.projectId &&
          product.productVariationId === newProduct.productVariationId &&
          product.userProductId === newProduct.userProductId
      ).length === 0
    ) {
      state.products = [newProduct, ...state.products]
    }
  }),

  _deleteProduct: action((state, userProjectProductId) => {
    state.products = state.products.filter((product) => product.id !== userProjectProductId)
  }),

  _updateProductQuantityLoadingStatus: action((state, { userProjectProductId, isLoading }) => {
    for (let product of state.products) {
      if (product.id === userProjectProductId) {
        product.isQuantityUpdating = isLoading
        return
      }
    }
  }),

  _updateUserProjectProduct: action((state, patchedProduct) => {
    for (let product of state.products) {
      if (
        product.projectId === patchedProduct.projectId &&
        product.productVariationId === patchedProduct.productVariationId
      ) {
        product.quantity = patchedProduct.quantity
        product.validated = patchedProduct.validated
        return
      }
    }
  }),

  _updateUserProduct: action((state, patchedProduct) => {
    for (let product of state.products) {
      if (product.userProductId === patchedProduct.id) {
        product.userProduct = patchedProduct
        // don't stop the loop: the user-product can be in multiple projects
      }
    }
  }),

  _updateProductVariation: action((state, productVariation) => {
    for (let product of state.products) {
      if (!product.productVariationId) continue
      if (product.productVariationId === productVariation.id) {
        product.productVariation = productVariation
        // don't stop the loop: the product-variation can be in multiple projects
      }
    }
  }),

  // thunks
  addProductToLocal: thunk(async (actions, newProduct) => {
    const newLocalProduct: LocalProjectProduct = {
      ...newProduct,
      isQuantityUpdating: false,
    }
    actions._addProduct(newLocalProduct)
  }),

  createProduct: thunk(async (actions, payload, { getStoreState }) => {
    const storeState = getStoreState()
    if (storeState.user.token == null) {
      console.error(
        "[store.project-products] project's product creation impossible: user data is missing"
      )
      return false
    }

    const newProduct = await postUserProjectProduct(storeState.user.token, payload)
    if (newProduct === null) return false

    if (newProduct.productVariationId)
      newProduct.productVariation = await actions.fetchProductData(newProduct.productVariationId)

    actions.addProductToLocal(newProduct)

    return true
  }),

  createProductFromUserProduct: thunk(async (actions, { projectId, dto }, { getStoreState }) => {
    const storeState = getStoreState()
    if (storeState.user.token == null) {
      console.error(
        "[store.project-products] project's product creation impossible: user token is missing"
      )
      return false
    }
    const newUserProduct = await postUserProduct(storeState.user.token, dto)
    if (newUserProduct === null) return false

    const newProduct = await postUserProjectProduct(storeState.user.token, {
      projectId,
      userProductId: newUserProduct.id,
    })
    if (newProduct === null) return false

    actions.addProductToLocal(newProduct)

    Mixpanel.track(MixpanelEvents.UserProductCreated, {
      userId: dto.userId,
      brand: dto.brand || '',
      name: dto.name,
      price: dto.price,
      currency: dto.currency,
      url: dto.url,
    })

    return true
  }),

  deleteProduct: thunk(async (actions, userProjectProductId, { getState, getStoreState }) => {
    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.project-products] cannot delete a user-project-product because user's token is missing"
      )
      return false
    }

    const userProjectProduct = getState().projectProduct(userProjectProductId)
    if (userProjectProduct === null) {
      console.error(
        '[store.project-products] cannot delete a user-project-product because it does not exist'
      )
      return false
    }

    const success = await deleteUserProjectProduct(storeState.user.token, userProjectProductId)
    if (success === false) return false

    actions._deleteProduct(userProjectProductId)

    Mixpanel.track(MixpanelEvents.ProductRemovedFromProject, {
      projectId: userProjectProduct.projectId,
      userProjectProductId: userProjectProduct.id,
    })

    return true
  }),

  updateProductQuantity: thunk(
    async (actions, { userProjectProductId, quantity }, { getState, getStoreState }) => {
      const userProjectProduct = getState().projectProduct(userProjectProductId)
      if (!userProjectProduct) {
        console.error(
          '[store.project-products] cannot patch a user-project-product because it does not exist'
        )
        return false
      }
      if (userProjectProduct.quantity === quantity) return false

      console.debug(
        `[store.project-products] update project's product quantity, product: ${userProjectProductId}, qty: ${quantity}`
      )
      actions._updateProductQuantityLoadingStatus({
        userProjectProductId,
        isLoading: true,
      })

      const storeState = getStoreState()
      if (storeState.user.token === null) {
        console.error(
          "[store.project-products] cannot patch a user-project-product because user's token is missing"
        )
        return false
      }

      // update online DB
      const dto: UserProjectProductUpdateDTO = {
        quantity: quantity,
      }
      const patchedProduct = await patchUserProjectProduct(
        storeState.user.token,
        userProjectProductId,
        dto
      )
      if (patchedProduct === null) {
        actions._updateProductQuantityLoadingStatus({
          userProjectProductId,
          isLoading: false,
        })
        return false
      }

      // update offline state
      actions._updateUserProjectProduct(patchedProduct)
      actions._updateProductQuantityLoadingStatus({
        userProjectProductId,
        isLoading: false,
      })

      Mixpanel.track(MixpanelEvents.ProjectProductQuantityUpdated, {
        userProjectProductId,
        quantity,
      })

      return true
    }
  ),

  setProductValidationState: thunk(
    async (actions, { userProjectProductId, isValidated }, { getState, getStoreState }) => {
      const userProjectProduct = getState().projectProduct(userProjectProductId)
      if (!userProjectProduct) {
        console.error(
          '[store.project-products] cannot patch a user-project-product because it does not exist'
        )
        return false
      }
      if (userProjectProduct.validated === isValidated) return false

      console.debug(
        `[store.project-products] update project's product validation state, product: ${userProjectProductId}, value: ${isValidated}`
      )

      const storeState = getStoreState()
      if (storeState.user.token === null) {
        console.error(
          "[store.project-products] cannot patch a user-project-product because user's token is missing"
        )
        return false
      }

      // update online DB
      const dto: UserProjectProductUpdateDTO = {
        validated: isValidated,
      }
      const patchedProduct = await patchUserProjectProduct(
        storeState.user.token,
        userProjectProductId,
        dto
      )
      if (patchedProduct === null) return false

      // update offline state
      actions._updateUserProjectProduct(patchedProduct)

      return true
    }
  ),

  patchUserProduct: thunk(async (actions, { userProductId, dto }, { getStoreState }) => {
    console.debug(`[store.project-products] update user-product`, userProductId, dto)

    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.project-products] cannot patch a user-product because user's token is missing"
      )
      return false
    }

    // update online DB
    const patchedProduct = await patchUserProduct(storeState.user.token, userProductId, dto)
    if (patchedProduct === null) return false
    console.debug(`[store.project-products] patched user-product:`, patchedProduct)

    // update offline state
    actions._updateUserProduct(patchedProduct)

    Mixpanel.track(MixpanelEvents.UserProductUpdated, {
      userId: patchedProduct.userId,
      userProductId: patchedProduct.id,
      brandUpdated: !!dto.brand,
      nameUpdated: !!dto.name,
      priceUpdated: !!dto.price,
      currencyUpdated: !!dto.currency,
      urlUpdated: !!dto.url,
    })

    return true
  }),

  fetchProjectProducts: thunk(async (actions, projectId, { getState, getStoreState }) => {
    console.debug('[store.project-products]', `fetch products of the project '${projectId}'`)

    if (
      [RequestStatus.Completed, RequestStatus.Loading].includes(
        getState().projectProductsLoadingStatus(projectId) || RequestStatus.Idle
      )
    ) {
      console.warn('[store.project-products] user-project-products were already fetched')
      return false
    }

    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.project-products] cannot fetch user-project-products because user's token is missing"
      )
      return false
    }

    actions._setProductsLoadingStatus({
      projectId: projectId,
      status: RequestStatus.Loading,
    })

    const products = await getUserProjectProducts(storeState.user.token, projectId)
    if (products === null) {
      actions._setProductsLoadingStatus({
        projectId: projectId,
        status: RequestStatus.Failed,
      })
      return false
    }
    console.debug(
      '[store.project-products]',
      `fetched user-project-products '${projectId}':`,
      products
    )

    for (let product of products) await actions.addProductToLocal(product)

    actions._setProductsLoadingStatus({
      projectId: projectId,
      status: RequestStatus.Completed,
    })
    return true
  }),

  fetchProjectProductVariations: thunk(async (actions, projectId, { getState, getStoreState }) => {
    console.debug(`[store.project-products] fetch product-variations of the project '${projectId}'`)

    if (
      [RequestStatus.Completed, RequestStatus.Loading].includes(
        getState().projectProductVariationsLoadingStatus(projectId) || RequestStatus.Idle
      )
    ) {
      console.warn('[store.project-products] user-project-product-variations were already fetched')
      return false
    }

    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.project-products] cannot fetch user-project-product-variations because user's token is missing"
      )
      return false
    }
    if (storeState.user.worldArea === null) {
      console.error(
        "[store.project-products] cannot fetch user-project-product-variations because user's world area is missing"
      )
      return false
    }

    actions._setProductVariationsLoadingStatus({
      projectId: projectId,
      status: RequestStatus.Loading,
    })

    const projectProducts = getState().projectProducts(projectId)
    const productVariations = await getProductVariationsByIds(
      storeState.user.token,
      projectProducts.filter((pp) => !!pp.productVariationId).map((pp) => pp.productVariationId!),
      storeState.user.worldArea
    )
    console.debug('[store.project-products] fetched product-variations:', productVariations)

    if (productVariations === null) {
      actions._setProductVariationsLoadingStatus({
        projectId: projectId,
        status: RequestStatus.Failed,
      })
      return false
    }

    if (productVariations.length > 0) {
      for (const productVariation of productVariations) {
        actions._updateProductVariation(productVariation)
      }
    }

    actions._setProductVariationsLoadingStatus({
      projectId: projectId,
      status: RequestStatus.Completed,
    })
    return true
  }),

  fetchProductData: thunk(async (actions, productVariationId, { getStoreState }) => {
    console.debug('[store.project-products] fetch product data:', productVariationId)

    const storeState = getStoreState()
    if (storeState.user.worldArea === null) {
      console.error(
        "[store.project-products] cannot fetch a user-project-product-variation because user's world area is missing"
      )
      return undefined
    }

    const data = await getProductVariation(productVariationId, storeState.user.worldArea)
    return data === null ? undefined : data
  }),
}
