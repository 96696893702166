import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseDesktopLayout } from '../components/organisms/BaseDesktopLayout/BaseDesktopLayout'
import { HeaderTabs } from '../components/molecules/DesktopHeader/DesktopHeader'
import { useIonViewDidEnter } from '@ionic/react'
import config from '../config'
import { useLocation, useParams } from 'react-router'
import { useStoreActions, useStoreState } from '../store/hooks'
import { CenteredLoading } from '../components/molecules/CenteredLoading/CenteredLoading'
import { ProjectPageProductsTab } from '../components/organisms/ProjectPageProductsTab/ProjectPageProductsTab'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'
import { TabHeader } from '../components/atoms/TabHeader/TabHeader'
import { ProjectPageMembersTab } from '../components/organisms/ProjectPageMembersTab/ProjectPageMembersTab'
import { ProjectPageChatTab } from '../components/organisms/ProjectPageChatTab/ProjectPageChatTab'
import { io, Socket } from 'socket.io-client'
import { ProjectPageFilesTab } from '../components/organisms/ProjectPageFilesTab/ProjectPageFilesTab'
import { ProjectPageSettingsTab } from '../components/organisms/ProjectPageSettingsTab/ProjectPageSettingsTab'
import { ProjectHeader } from '../components/organisms/ProjectHeader/ProjectHeader'

export interface ProjectProps {}

const Project: React.FC<ProjectProps> = () => {
  const { t } = useTranslation()
  const location = useLocation()

  // title
  useIonViewDidEnter(() => {
    document.title = config.pageTitlePrefix + t('pageTitle.Project')
  }, [t])

  // data
  const { projectId } = useParams<{ projectId: string }>()
  const project = useStoreState((store) => store.projects.project(projectId))
  const projectMembersCount = useStoreState((store) =>
    store.projectMembers.projectMembersCount(projectId)
  )

  const fetchProjectUserMessages = useStoreActions(
    (store) => store.userMessages.fetchProjectUserMessages
  )
  const fetchProjectFiles = useStoreActions((store) => store.projectFiles.fetchProjectFiles)

  useEffect(() => {
    fetchProjectUserMessages(projectId)
    fetchProjectFiles(projectId)
  }, [])

  // socket
  const [socket, setSocket] = useState<Socket | undefined>(undefined)

  useEffect(() => {
    let endpoint = process.env.REACT_APP_API_URL || ''
    const newSocket = io(endpoint, { transports: ['websocket'] })
    setSocket(newSocket)

    return () => {
      newSocket.disconnect()
    }
  }, [])

  // tab header
  const [selectedTab, setSelectedTab] = useState<number>(0)

  // mixpanel
  useEffect(() => {
    console.debug('[Project] open project page')
    Mixpanel.track(MixpanelEvents.ProjectOpened, {
      projectId: projectId,
    })
  }, [location, projectId])

  return (
    <BaseDesktopLayout selectedHeaderTab={HeaderTabs.Projects}>
      {!project ? (
        <CenteredLoading height={'calc(100vh - 4rem - 4rem)'} />
      ) : (
        <>
          <ProjectHeader
            isDefault={project.isDefault}
            name={project.name}
            description={
              project.isDefault ? t('project.defaultProjectDescription') : project.description || ''
            }
            creationDate={project.createdAt}
          />

          <TabHeader
            tabTexts={
              project.isDefault
                ? [t('project.productsTab'), t('project.filesTab'), t('project.chatTab')]
                : [
                    t('project.productsTab'),
                    t('project.filesTab'),
                    t('project.chatTab'),
                    `${t('project.membersTab')} (${projectMembersCount})`,
                    t('project.settingsTab'),
                  ]
            }
            selectedTab={selectedTab}
            onChange={setSelectedTab}
          />

          <ProjectPageProductsTab isDefaultProject={project.isDefault} projectId={project.id} show={selectedTab === 0} socket={socket} />

          <ProjectPageFilesTab userProjectId={project.id} show={selectedTab === 1} />

          <ProjectPageChatTab projectId={projectId} show={selectedTab === 2} socket={socket} />

          {selectedTab === 3 && <ProjectPageMembersTab projectId={project.id} />}

          <ProjectPageSettingsTab projectId={projectId} show={selectedTab === 4} />
        </>
      )}
    </BaseDesktopLayout>
  )
}

export default Project
