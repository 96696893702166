import { IonPage, useIonViewDidEnter } from '@ionic/react'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '../components/atoms/Button/Button'
import { LanguageSelect } from '../components/molecules/LanguageSelect/LanguageSelect'
import { useTranslation } from 'react-i18next'
import axiosApi from '../utils/axios'
import { Routes } from '../utils/Routes'
import { PasswordResetQueryDTO } from '@fynde/dtos'
import config from '../config'
import { TextInput } from '../components/atoms/TextInput/TextInput'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'

const CenteredContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`
const LanguageSelectContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`
const Logo = styled.img`
  width: 230px;
  margin-bottom: 1.5em;
`
const Title = styled.h2`
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 0.8em;
  text-align: center;
`
const Info = styled.p`
  color: ${(props) => props.theme.colors.black};
  width: 400px;
  margin: 0 0 2em 0;
  text-align: center;
`
const Form = styled.div`
  width: 400px;
`
const Feedback = styled.p`
  margin: 0.5em 0 1em 0;
  text-align: left;
  width: 400px;
  text-align: center;

  &.success {
    color: ${(props) => props.theme.colors.success};
  }
  &.error {
    color: ${(props) => props.theme.colors.error};
  }
`
const SubmmitButton = styled(Button)`
  width: 400px;
  margin-top: 1.5em;
  margin-bottom: 2.5em;
`
const Line = styled.p`
  margin: 0 0 0.8em 0;
`
const Link = styled.a`
  color: ${(props) => props.theme.colors.black};
  font-weight: 700;
`

const ResetPassword: React.FC = () => {
  const { t } = useTranslation()

  // title
  useIonViewDidEnter(() => {
    document.title = config.pageTitlePrefix + t('pageTitle.ResetPassword')
  }, [t])

  const [email, setEmail] = useState<string | undefined>(undefined)
  const [accountExists, setAccountExists] = useState<boolean | undefined>(undefined)

  const handleSubmit = async () => {
    try {
      await axiosApi.get<PasswordResetQueryDTO>(`/auth/reset-password?email=${email}`)
      setAccountExists(true)

      Mixpanel.track(MixpanelEvents.UserPasswordReset, {
        email,
      })
    } catch (err) {
      setAccountExists(false)
    }
  }

  return (
    <IonPage>
      <CenteredContainer>
        <Logo src="assets/branding/logo_fynde_noMargin_500px.png" alt="Fynde logo" />

        <Title>{t('resetPassword.title')}</Title>
        <Info>{t('resetPassword.info')}</Info>

        <Form>
          <TextInput
            value={email}
            placeholder={t('resetPassword.emailPlaceholder')}
            onChange={setEmail}
            autoComplete={'email'}
            size={'medium'}
          />
          {accountExists === true && (
            <Feedback className="success">{t('resetPassword.success')}</Feedback>
          )}
          {accountExists === false && (
            <Feedback className="error">{t('resetPassword.fail')}</Feedback>
          )}

          <SubmmitButton type="button" onClick={handleSubmit}>
            {t('resetPassword.submitButton')}
          </SubmmitButton>
        </Form>

        <Line>
          {t('link.logIn.prefix')}&nbsp;
          <Link href={Routes.Login}>{t('link.logIn.access')}</Link>
        </Line>
      </CenteredContainer>

      <LanguageSelectContainer>
        <LanguageSelect />
      </LanguageSelectContainer>
    </IonPage>
  )
}

export default ResetPassword
