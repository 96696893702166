import React, { useCallback, useMemo } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { FileListItem, FileListItemData } from '../../atoms/FileListItem/FileListItem'
import { FileDroppingArea } from '../../molecules/FileDroppingArea/FileDroppingArea'
import './ProjectPageFilesTab.css'

export interface ProjectPageFilesTabProps {
  userProjectId: string
  show: boolean
}

export const ProjectPageFilesTab: React.FC<ProjectPageFilesTabProps> = ({
  userProjectId,
  show,
}) => {
  const userId = useStoreState((store) => store.user.userId())
  const projectUserFiles = useStoreState((store) =>
    store.projectFiles.projectUserFiles(userProjectId)
  )
  const projectLocalUserFiles = useStoreState((store) =>
    store.projectFiles.projectLocalUserFiles(userProjectId)
  )
  const projectMemberName = useStoreState((store) => store.projectMembers.projectMemberFullName)

  const addLocalUserFile = useStoreActions((store) => store.userFiles.addLocalUserFile)
  const patchUserFile = useStoreActions((store) => store.userFiles.patchUserFile)
  const downloadUserFile = useStoreActions((store) => store.userFiles.downloadUserFile)
  const deleteProjectFile = useStoreActions((store) => store.projectFiles.deleteProjectFile)
  const postProjectFile = useStoreActions((store) => store.projectFiles.postProjectFile)

  const handleFileAddition = useCallback(
    async (files: File[]) => {
      console.debug('[FileDroppingAreaWrapper] handle file addition:', files)
      for (const file of files) {
        const localFile = await addLocalUserFile({
          file: file,
          name: file.name,
          dataUrl: null,
          linkedTo: 'drive',
          projectId: userProjectId,
          projectProductId: null,
          userMessageId: null,
        })
        postProjectFile({
          userProjectId: userProjectId,
          localFile: localFile,
        })
      }
    },
    [addLocalUserFile, postProjectFile, userProjectId]
  )

  const handleFileRenaming = useCallback(
    async (userFileId: string, newName: string) => {
      console.log('[FilesTab] handleFileRenaming, userFileId:', userFileId, 'newName:', newName)
      await patchUserFile({ userFileId, dto: { name: newName } })
    },
    [patchUserFile]
  )

  const handleFileDownload = useCallback(
    async (userFileId: string) => {
      console.log('[FilesTab] handleFileDownload, userFileId:', userFileId)
      downloadUserFile(userFileId)
    },
    [downloadUserFile]
  )

  const handleFileDeletion = useCallback(
    async (userFileId: string) => {
      console.log('[FilesTab] handleFileDeletion, userFileId:', userFileId)
      await deleteProjectFile({ userProjectId, userFileId })
    },
    [userProjectId, deleteProjectFile]
  )

  // displayed files
  const files = useMemo(() => {
    const results: FileListItemData[] = []
    for (const file of projectUserFiles) {
      results.push({
        uploadProgress: 100,
        fileId: file.userFile.id,
        imgData: file.userFile.media.publicUrl,
        name: file.userFile.name,
        mimetype: file.userFile.media.mimetype,
        size: file.userFile.media.size,
        creationDate: file.userFile.createdAt,
        owner: projectMemberName(file.userFile.userId) || '',
      })
    }
    for (const file of projectLocalUserFiles) {
      results.push({
        uploadProgress: file.uploadProgress,
        fileId: file.id,
        imgData: file.dataUrl,
        name: file.name,
        mimetype: file.file.type,
        size: file.file.size,
        creationDate: new Date(),
        owner: userId ? projectMemberName(userId) || '' : '',
      })
    }
    return results
  }, [projectUserFiles, projectLocalUserFiles, projectMemberName, userId])

  return (
    <div className={'project-files-wrapper' + (show ? '' : ' hidden')}>
      <div className={'drop-area-wrapper'}>
        <FileDroppingArea onFileAddition={handleFileAddition} />
      </div>
      <div className={'files-list'}>
        {files
          .sort((first, second) => (first.name.toLowerCase() < second.name.toLowerCase() ? -1 : 1))
          .map((file) => (
            <FileListItem
              key={file.fileId}
              isLoading={false}
              {...file}
              onNameEdit={handleFileRenaming}
              onUploadCancel={() => console.log('cancel upload')}
              onDownload={handleFileDownload}
              onDelete={handleFileDeletion}
            />
          ))}
      </div>
    </div>
  )
}
