import axiosApi from '../utils/axios'
import {
  UserProjectProductCreationDTO,
  UserProjectProductDTO,
  UserProjectProductUpdateDTO,
} from '@fynde/dtos'

// GET
export const getUserProjectProducts = async (
  userToken: string,
  userProjectId: string
): Promise<UserProjectProductDTO[] | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    params: {
      userProjectId,
    },
  }
  try {
    const resp = await axiosApi.get<UserProjectProductDTO[]>(`/user-project-product/`, config)
    return resp.data
  } catch (err) {
    console.error('An error occured while getting user-project-products:', err)
  }
  return null
}

// POST
export const postUserProjectProduct = async (
  userToken: string,
  dto: UserProjectProductCreationDTO
): Promise<UserProjectProductDTO | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.post<UserProjectProductDTO>(`/user-project-product/`, dto, config)
    return resp.data
  } catch (err) {
    console.error('An error occured while posting a new user-project-product:', err)
  }
  return null
}

// PATCH
export const patchUserProjectProduct = async (
  userToken: string,
  userProjectProductId: string,
  dto: UserProjectProductUpdateDTO
): Promise<UserProjectProductDTO | null> => {
  console.log('patchUserProjectProduct', userProjectProductId, dto)
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.patch<UserProjectProductDTO>(
      `/user-project-product/${userProjectProductId}`,
      dto,
      config
    )
    return resp.data
  } catch {
    console.error('An error occured while patching a user-project-product')
    return null
  }
}

// DELETE
export const deleteUserProjectProduct = async (
  userToken: string,
  userProjectProductId: string
): Promise<boolean> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    await axiosApi.delete(`/user-project-product/${userProjectProductId}`, config)
    return true
  } catch (err) {
    console.error('An error occured while removing a user-project-product:', err)
    return false
  }
}
