import React, { UIEventHandler } from 'react'
import './GridCard.css'

export interface GridCardProps {
  onScroll?: UIEventHandler
  className?: string
  children?: React.ReactNode
  locked?: boolean
  justify?: 'left' | 'center'
}

export const GridCard = React.forwardRef<HTMLDivElement, GridCardProps>(
  ({ children, onScroll, className, locked = false, justify = 'center' }, ref) => {
    return (
      <div
        ref={ref}
        onScroll={onScroll}
        className={
          'grid-card' +
          (className ? ' ' + className : '') +
          (locked ? ' locker' : '') +
          (justify === 'center' ? ' centered' : '')
        }
      >
        {children}
      </div>
    )
  }
)
