import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Project } from '../../../store/projects'
import { ProjectCardWrapper } from '../ProjectCard/ProjectCardWrapper'

const Container = styled.div<{ centered: boolean }>`
  display: grid;
  /* background-color: rgba(255,0,0,0.2); */
  justify-content: 'center';

  grid-gap: 1rem 1rem;
  margin: ${(props) => (props.centered ? '0 auto' : '0')};

  width: calc(400px + 1rem);
  grid-template-columns: repeat(1, 0);

  @media (min-width: 896px) {
    width: calc((400px + 1rem) * 2 + 1rem);
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1344px) {
    width: calc((400px + 1rem) * 3 + 2rem);
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1776px) {
    width: calc((400px + 1rem) * 4 + 3rem);
    grid-template-columns: repeat(4, 1fr);
  }
`

const NothingToDisplay = styled.p`
  text-align: center;
  height: 280px;
  line-height: 280px;
  width: 100%;
  color: ${(props) => props.theme.colors.grey300};
`

export interface ProjectCardsContainerProps {
  projects: Project[]
  maxCount?: number | undefined
  centered?: boolean
}

export const ProjectCardsContainer: React.FC<ProjectCardsContainerProps> = ({
  projects,
  maxCount = undefined,
  centered = true,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {projects.length === 0 ? (
        <NothingToDisplay>{t('project.noProjectToDisplay')}</NothingToDisplay>
      ) : (
        <Container centered={centered}>
          {projects.map((project, index) =>
            maxCount && index + 1 > maxCount ? null : (
              <ProjectCardWrapper key={project.id} projectId={project.id} />
            )
          )}
        </Container>
      )}
    </>
  )
}
