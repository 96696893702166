export interface Crop {
  x: number
  y: number
  width: number
  height: number
  objectTypes?: string[]
  customCenter?: { x: number; y: number }
}

export const getCenterPosition = (c: Crop) => {
  return [Math.round(c.x) + c.width / 2, Math.round(c.y) + c.height / 2]
}

export const setCropCenter = (c: Crop, center: { x: number; y: number }) => {
  c.customCenter = center
}

export const calculateBoxOverlap = (firstBox: Crop, secondBox: Crop) => {
  const XA1 = firstBox.x
  const YA1 = firstBox.y
  const XA2 = firstBox.x + firstBox.width
  const YA2 = firstBox.y + firstBox.height

  const XB1 = secondBox.x
  const YB1 = secondBox.y
  const XB2 = secondBox.x + secondBox.width
  const YB2 = secondBox.y + secondBox.height

  const roi_area = firstBox.width * firstBox.height
  const bbox_area = (XB2 - XB1) * (YB2 - YB1)

  const rect_intersection_area =
    Math.max(0, Math.min(XA2, XB2) - Math.max(XA1, XB1)) *
    Math.max(0, Math.min(YA2, YB2) - Math.max(YA1, YB1))

  const rectUnion = roi_area + bbox_area - rect_intersection_area
  const overlapRatio = rect_intersection_area / rectUnion

  return overlapRatio
}

export const areCropsColliding = (c1: Crop, c2: Crop, collisionDistance: number) => {
  return distanceBetweenCrops(c1, c2) < collisionDistance
}

export const distanceBetweenCrops = (c1: Crop, c2: Crop) => {
  var c1Coords = getCenterPosition(c1)
  var c2Coords = getCenterPosition(c2)

  var dx = c1Coords[0] - c2Coords[0]
  var dy = c1Coords[1] - c2Coords[1]
  return Math.sqrt(dx * dx + dy * dy)
}

// Mutate
export const pushAwayCrops = (c1: Crop, c2: Crop, collisionDistance: number) => {
  if (!areCropsColliding(c1, c2, collisionDistance)) return

  const d = distanceBetweenCrops(c1, c2)

  var c1Coords = getCenterPosition(c1)
  var c2Coords = getCenterPosition(c2)

  const missingDistance = collisionDistance - d
  const vectorD = missingDistance / d / 2

  const c1x = c1Coords[0] + vectorD * (c1Coords[0] - c2Coords[0])
  const c1y = c1Coords[1] + vectorD * (c1Coords[1] - c2Coords[1])

  const c2x = c2Coords[0] + vectorD * (c2Coords[0] - c1Coords[0])
  const c2y = c2Coords[1] + vectorD * (c2Coords[1] - c1Coords[1])

  setCropCenter(c1, { x: c1x, y: c1y })
  setCropCenter(c2, { x: c2x, y: c2y })
}
