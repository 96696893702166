import React from 'react'
import { Card } from '../../atoms/Card/Card'
import { useTranslation } from 'react-i18next'
import './FilterCard.css'

export interface StyledButtonProps {
  active?: boolean
}

export interface CardFilterProps {
  onClearCliked?: () => void
  title?: string
  noSection?: boolean
  disabled?: boolean
  className?: string
}

export const CardFilter: React.FC<CardFilterProps> = ({
  children,
  title,
  noSection,
  disabled = false,
  onClearCliked,
  className,
}) => {
  const { t } = useTranslation()

  return (
    <Card className={'filter-card' + (className ? ' ' + className : '')}>
      {title && (
        <header>
          <h3>{title}</h3>
          <button className={'clear-button'} onClick={onClearCliked} disabled={disabled}>
            {t('cardFilter.clear')}
          </button>
        </header>
      )}
      {noSection
        ? children
        : <section>{children}</section>
      }
    </Card>
  )
}
