import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import { HelperBullet } from '../HelperBullet/HelperBullet'
import { ReactComponent as CheckedIcon } from '../../../assets/icons/success-bullet-icon.svg'
import './Checkbox.css'
import { useCallback } from 'react'


const Label = styled.label`
  color: ${(props) => props.theme.colors.black};
`

export interface CheckboxProps {
  name?: string
  label?: string | null
  isChecked: boolean
  helperInfo?: string | undefined
  onChange: (value: boolean) => void
}

export const Checkbox: React.FC<CheckboxProps> = ({ 
  name = undefined,
  label = null,
  isChecked,
  helperInfo = undefined,
  onChange, 
}) => {
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    // console.debug("[Checkbox] handleChange, new value:", e.target.checked)
    onChange(e.target.checked)
  }, [onChange])

  return (
    <div className={"checkbox-wrapper"}>
        <Label>
          {label}
          <input
            name={name}
            type={"checkbox"}
            checked={isChecked}
            onChange={handleChange}
          />
          <span className={"empty-checkmark"}></span>
          {isChecked && <CheckedIcon className={"checkmark"}/>}
        </Label>
        {helperInfo && <HelperBullet text={helperInfo} />}
    </div>
  )
}
