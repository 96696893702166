import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState, useStoreActions } from '../../store/hooks'
import { BaseDesktopLayout } from '../../components/organisms/BaseDesktopLayout/BaseDesktopLayout'
import { HeaderTabs } from '../../components/molecules/DesktopHeader/DesktopHeader'
import { Button } from '../../components/atoms/Button/Button'
import {
  AddressUpdateDTO,
  PasswordResetQueryDTO,
  ProfessionalUpdateDTO,
  UserUpdateDTO,
} from '@fynde/dtos'
import axiosApi from '../../utils/axios'
import { useIonViewDidEnter } from '@ionic/react'
import config from '../../config'
import { Checkbox } from '../../components/atoms/Checkbox/Checkbox'
import { UnregisterModal } from '../../components/organisms/UnregisterModal/UnregisterModal'
import { LogOutModal } from '../../components/organisms/LogOutModal/LogOutModal'
import { TextInput } from '../../components/atoms/TextInput/TextInput'
import { validateName } from '../../utils/login'
import { CenteredLoading } from '../../components/molecules/CenteredLoading/CenteredLoading'
import { RequestStatus } from '../../utils/reqStatus'
import { Feedback, FeedbackStatus } from '../../components/atoms/Feedback/Feedback'
import { SelectWithTextInput } from '../../components/atoms/SelectWithTextInput/SelectWithTextInput'
import './Account.css'

const Likes: React.FC = () => {
  const { t } = useTranslation()

  // title
  const setPageTitle = useCallback(() => {
    document.title = config.pageTitlePrefix + t('pageTitle.Account')
  }, [t])
  useIonViewDidEnter(() => {
    setPageTitle()
  }, [setPageTitle])
  useEffect(() => {
    setPageTitle()
  }, [setPageTitle])

  // local data
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState<boolean | undefined>(undefined)
  const handlePasswordChange = async () => {
    try {
      await axiosApi.get<PasswordResetQueryDTO>(`/auth/reset-password?email=${user.email}`)
      setPasswordChangeSuccess(true)
    } catch (err) {
      console.error('[Account] error when sending reset-password request:', err)
      setPasswordChangeSuccess(false)
    }
  }

  const userLanguage = useStoreState((store) => store.user.language)

  // online data
  const user = useStoreState((store) => store.user.user)

  // personal info
  const [firstname, setFirstname] = useState<string>('')
  const isFirstNameOk = useMemo(() => {
    return validateName(firstname)
  }, [firstname])
  const hasFirstNameChanged = useMemo(() => {
    return firstname !== (user.firstname || '')
  }, [user.firstname, firstname])

  const [lastname, setLastname] = useState<string>('')
  const isLastNameOk = useMemo(() => {
    return validateName(lastname)
  }, [lastname])
  const hasLastNameChanged = useMemo(() => {
    return lastname !== (user.lastname || '')
  }, [user.lastname, lastname])

  const [country, setCountry] = useState<string>('')
  const hasCountryChanged = useMemo(() => {
    return country !== (user.country || '')
  }, [user.country, country])

  // professional info
  const [isAProfessional, setIsAProfessional] = useState<boolean>(false)

  const jobOptions = [
    t('job.architect'),
    t('job.interiorDesigner'),
    t('job.designer'),
    t('job.promoter'),
    t('job.hotelier'),
    t('job.other'),
  ]
  const [selectedJob, setSelectedJob] = useState<string>('')
  const isJobOk = useMemo(() => {
    return selectedJob.search(/\w/) > -1
  }, [selectedJob])
  const hasJobChanged = useMemo(() => {
    return !!user.professional && selectedJob !== (user.professional.job || '')
  }, [user.professional, selectedJob])

  const [companyName, setCompanyName] = useState<string>('')
  const isCompanyNameOk = useMemo(() => {
    return companyName.search(/\w/) > -1
  }, [companyName])
  const hasCompanyNameChanged = useMemo(() => {
    return !!user.professional && companyName !== (user.professional.companyName || '')
  }, [user.professional, companyName])

  const [website, setWebsite] = useState<string>('')
  const hasWebsiteChanged = useMemo(() => {
    return !!user.professional && website !== (user.professional.website || '')
  }, [user.professional, website])

  const [phone, setPhone] = useState<string>('')
  const isPhoneOk = useMemo(() => {
    return phone === '' || phone.search(/^\+?[\d() ]*$/) > -1
  }, [phone])
  const hasPhoneChanged = useMemo(() => {
    return !!user.professional && phone !== (user.professional.phone || '')
  }, [user.professional, phone])

  const [addressLine1, setAddressLine1] = useState<string>('')
  const [addressLine2, setAddressLine2] = useState<string>('')
  const [addressZipcode, setAddressZipcode] = useState<string>('')
  const [addressCity, setAddressCity] = useState<string>('')
  const [addressRegion, setAddressRegion] = useState<string>('')
  const [addressCountry, setAddressCountry] = useState<string>('')
  const hasAddressChanged = useMemo(() => {
    return (
      !!user.professional &&
      (addressLine1 !== (user.professional.companyAddress?.line1 || '') ||
        addressLine2 !== (user.professional.companyAddress?.line2 || '') ||
        addressZipcode !== (user.professional.companyAddress?.zipcode || '') ||
        addressCity !== (user.professional.companyAddress?.city || '') ||
        addressRegion !== (user.professional.companyAddress?.region || '') ||
        addressCountry !== (user.professional.companyAddress?.country || ''))
    )
  }, [user, addressLine1, addressLine2, addressZipcode, addressCity, addressRegion, addressCountry])

  const hasProfessionalDataChanged = useMemo(() => {
    return (
      isAProfessional !== !!user.professional ||
      hasJobChanged ||
      hasCompanyNameChanged ||
      hasPhoneChanged ||
      hasWebsiteChanged ||
      hasAddressChanged
    )
  }, [
    user.professional,
    isAProfessional,
    hasJobChanged,
    hasCompanyNameChanged,
    hasPhoneChanged,
    hasWebsiteChanged,
    hasAddressChanged,
  ])

  // other
  const [allowsNewsletters, setAllowsNewsletters] = useState<boolean>(false)
  const hasNewsletterChanged = useMemo(() => {
    return allowsNewsletters !== user.allowsNewsletters
  }, [user.allowsNewsletters, allowsNewsletters])

  // validation
  const isFormValid = useMemo(() => {
    return (
      isFirstNameOk &&
      isLastNameOk &&
      isPhoneOk &&
      (!isAProfessional || (isCompanyNameOk && isJobOk))
    )
  }, [isFirstNameOk, isLastNameOk, isAProfessional, isPhoneOk, isCompanyNameOk, isJobOk])

  const hasDataChanged = useMemo(() => {
    return (
      hasFirstNameChanged ||
      hasLastNameChanged ||
      hasCountryChanged ||
      hasProfessionalDataChanged ||
      hasJobChanged ||
      hasCompanyNameChanged ||
      hasPhoneChanged ||
      hasWebsiteChanged ||
      hasAddressChanged ||
      hasNewsletterChanged
    )
  }, [
    hasFirstNameChanged,
    hasLastNameChanged,
    hasCountryChanged,
    hasProfessionalDataChanged,
    hasJobChanged,
    hasCompanyNameChanged,
    hasPhoneChanged,
    hasWebsiteChanged,
    hasAddressChanged,
    hasNewsletterChanged,
  ])

  // feedback
  const [feedbackContent, setFeedbackContent] = useState<[string, FeedbackStatus] | undefined>(
    undefined
  )

  // save
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const patchUser = useStoreActions((store) => store.user.patchUser)

  const getUpdateDTO = useCallback(() => {
    const addressUpdateDTO: AddressUpdateDTO | undefined = !hasAddressChanged
      ? undefined
      : {
          line1: addressLine1,
          line2: addressLine2,
          zipcode: addressZipcode,
          city: addressCity,
          region: addressRegion,
          country: addressCountry,
        }
    const professionalUpdateDTO: ProfessionalUpdateDTO | null | undefined =
      !hasProfessionalDataChanged
        ? undefined
        : !isAProfessional
        ? null
        : {
            job: selectedJob,
            companyName: companyName,
            phone: phone,
            website: website,
            companyAddress: addressUpdateDTO,
          }
    let userUpdateDTO: UserUpdateDTO = {
      firstname: hasFirstNameChanged ? firstname : undefined,
      lastname: hasLastNameChanged ? lastname : undefined,
      country: hasCountryChanged ? country : undefined,
      allowsNewsletters: allowsNewsletters,
      professional: professionalUpdateDTO,
    }

    // remove undefined and empty string values
    userUpdateDTO = JSON.parse(
      JSON.stringify(userUpdateDTO, (key, value) => {
        if (value === undefined) return undefined
        if (typeof value === 'string' && value.search(/\w/) === -1) return null
        return value
      })
    )

    return userUpdateDTO
  }, [
    firstname,
    hasFirstNameChanged,
    lastname,
    hasLastNameChanged,
    country,
    hasCountryChanged,
    allowsNewsletters,
    isAProfessional,
    hasProfessionalDataChanged,
    selectedJob,
    companyName,
    phone,
    website,
    hasAddressChanged,
    addressLine1,
    addressLine2,
    addressZipcode,
    addressCity,
    addressRegion,
    addressCountry,
  ])

  const handleSubmit = useCallback(async () => {
    if (!(hasDataChanged && isFormValid)) return

    const updateDTO = getUpdateDTO()
    console.debug('[Account] update user:', JSON.stringify(updateDTO))

    setIsSubmitting(true)
    const success = await patchUser(updateDTO)
    setIsSubmitting(false)

    if (success) {
      setFeedbackContent([t('account.userPatchSuccess'), FeedbackStatus.Success])
    } else {
      setFeedbackContent([t('account.userPatchFail'), FeedbackStatus.Error])
    }
  }, [hasDataChanged, isFormValid, getUpdateDTO, patchUser, setIsSubmitting, setFeedbackContent, t])

  // log out
  const [logOutAlertState, setLogOutAlertState] = useState<boolean>(false)

  // unregisteration
  const [unregistrationAlertState, setUnregistrationAlertState] = useState<boolean>(false)

  // data loading
  const localUserDataStatus = useStoreState((store) => store.user.localDataStatus)
  const onlineUserDataStatus = useStoreState((store) => store.user.onlineDataStatus)

  useEffect(() => {
    console.debug('[Account] onlineUserDataStatus:', onlineUserDataStatus)
    setFirstname(user.firstname || '')
    setLastname(user.lastname || '')
    setCountry(user.country || '')
    setIsAProfessional(!!user.professional)
    setSelectedJob(user.professional?.job || '')
    setPhone(user.professional?.phone || '')
    setWebsite(user.professional?.website || '')
    setCompanyName(user.professional?.companyName || '')
    setAllowsNewsletters(user.allowsNewsletters)
  }, [onlineUserDataStatus])

  return (
    <BaseDesktopLayout selectedHeaderTab={HeaderTabs.Account} className={'account-page'}>
      {localUserDataStatus !== RequestStatus.Completed ||
      onlineUserDataStatus !== RequestStatus.Completed ? (
        <CenteredLoading height={'calc(100vh - 4rem - 4rem)'} />
      ) : (
        <>
          <LogOutModal isOpen={!!logOutAlertState} onCancel={() => setLogOutAlertState(false)} />

          <UnregisterModal
            isOpen={!!unregistrationAlertState}
            onCancel={() => setUnregistrationAlertState(false)}
          />

          <Feedback
            isVisible={!!feedbackContent}
            onComplete={() => setFeedbackContent(undefined)}
            message={feedbackContent ? feedbackContent[0] : ''}
            status={feedbackContent ? feedbackContent[1] : FeedbackStatus.Idle}
          />

          <div className={'content'}>
            <div className={'category log-out'}>
              <Button onClick={() => setLogOutAlertState(true)} fontSize={'medium'}>
                {t('account.logOutButton')}
              </Button>
            </div>

            <div className={'category'}>
              <h2>{t('account.personalInfo.header')}</h2>

              {/* Email */}
              <div className={'row'}>
                <p className={'label'}>{t('account.personalInfo.email.label')}</p>
                <div className={'data'}>
                  <span>{user.email}</span>
                </div>
              </div>

              {/* Password */}
              <div className={'row password'}>
                <p className={'label'}>{t('account.personalInfo.password.label')}</p>
                <div className={'data'}>
                  <Button onClick={handlePasswordChange}>{t('account.resetPasswordButton')}</Button>
                  {passwordChangeSuccess === true && (
                    <p className={'feedback-txt success'}>{t('resetPassword.success')}</p>
                  )}
                  {passwordChangeSuccess === false && (
                    <p className={'feedback-txt error'}>{t('unknowError')}</p>
                  )}
                </div>
              </div>

              {/* First Name */}
              <div className={'row'}>
                <p className={'label'}>{t('account.personalInfo.firstname.label')}</p>
                <div className={'data'}>
                  <TextInput
                    type={'text'}
                    placeholder={t('account.personalInfo.firstname.placeholder')}
                    size={'medium'}
                    value={firstname}
                    onChange={setFirstname}
                    isWrong={!isFirstNameOk}
                  />
                </div>
              </div>
              {/* Last Name */}
              <div className={'row'}>
                <p className={'label'}>{t('account.personalInfo.lastname.label')}</p>
                <div className={'data'}>
                  <TextInput
                    type={'text'}
                    placeholder={t('account.personalInfo.lastname.placeholder')}
                    size={'medium'}
                    value={lastname}
                    onChange={setLastname}
                    isWrong={!isLastNameOk}
                  />
                </div>
              </div>

              {/* Country */}
              <div className={'row'}>
                <p className={'label'}>{t('account.personalInfo.country.label')}</p>
                <div className={'data'}>
                  <TextInput
                    type={'text'}
                    placeholder={t('account.personalInfo.country.placeholder')}
                    size={'medium'}
                    value={country}
                    onChange={setCountry}
                  />
                </div>
              </div>
            </div>

            <div className={'category'}>
              <h2>{t('account.professionalInfo.header')}</h2>

              <div className={'row'}>
                <Checkbox
                  label={t('account.professionalInfo.iAmAProfessional')}
                  isChecked={isAProfessional}
                  onChange={(value) => setIsAProfessional(value)}
                />
              </div>

              {/* Job */}
              <div className={'row mandatory job'}>
                <p className={'label'}>{t('account.professionalInfo.job.label')}</p>
                <div className={'data'}>
                  <SelectWithTextInput
                    value={
                      isAProfessional ? selectedJob : t('account.professionalInfo.job.placeholder')
                    }
                    onChange={setSelectedJob}
                    placeholder={t('account.professionalInfo.job.placeholder')}
                    fontSize={'medium'}
                    options={jobOptions}
                    // isWrong={showEmailToInviteError && !isInvitedRoleOk}
                    optionsPosition={'top'}
                    isLocked={!isAProfessional || isSubmitting}
                    isWrong={isAProfessional && !isJobOk}
                  />
                </div>
              </div>

              {/* Company Name */}
              <div className={'row'}>
                <p className={'label'}>{t('account.professionalInfo.companyName.label')}</p>
                <div className={'data'}>
                  <TextInput
                    type={'text'}
                    placeholder={t('account.professionalInfo.companyName.placeholder')}
                    size={'medium'}
                    value={
                      isAProfessional
                        ? companyName
                        : t('account.professionalInfo.companyName.placeholder')
                    }
                    onChange={setCompanyName}
                    isLocked={!isAProfessional || isSubmitting}
                    isWrong={isAProfessional && !isCompanyNameOk}
                  />
                </div>
              </div>

              {/* Phone */}
              <div className={'row'}>
                <p className={'label'}>{t('account.professionalInfo.phone.label')}</p>
                <div className={'data'}>
                  <TextInput
                    type={'text'}
                    placeholder={t('account.professionalInfo.phone.placeholder')}
                    size={'medium'}
                    value={
                      isAProfessional ? phone : t('account.professionalInfo.phone.placeholder')
                    }
                    onChange={setPhone}
                    isLocked={!isAProfessional || isSubmitting}
                    isWrong={!isPhoneOk}
                  />
                </div>
              </div>

              {/* Website */}
              <div className={'row'}>
                <p className={'label'}>{t('account.professionalInfo.website.label')}</p>
                <div className={'data'}>
                  <TextInput
                    type={'text'}
                    placeholder={t('account.professionalInfo.website.placeholder')}
                    size={'medium'}
                    value={
                      isAProfessional ? website : t('account.professionalInfo.website.placeholder')
                    }
                    onChange={setWebsite}
                    isLocked={!isAProfessional || isSubmitting}
                  />
                </div>
              </div>

              {/* Address */}
              <div className={'row'}>
                <span className={'label'}>{t('project.settings.projectAddress.label')}</span>
                <div className={'rows'}>
                  <div className={'row'}>
                    <span className={'label'}>{t('address.line1.label')}</span>
                    <div className={'data'}>
                      <TextInput
                        type={'text'}
                        value={isAProfessional ? addressLine1 : t('address.line1.placeholder')}
                        onChange={setAddressLine1}
                        placeholder={t('address.line1.placeholder')}
                        size={'medium'}
                        isLocked={!isAProfessional || isSubmitting}
                      />
                    </div>
                  </div>
                  <div className={'row'}>
                    <span className={'label'}>{t('address.line2.label')}</span>
                    <div className={'data'}>
                      <TextInput
                        type={'text'}
                        value={isAProfessional ? addressLine2 : t('address.line2.placeholder')}
                        onChange={setAddressLine2}
                        placeholder={t('address.line2.placeholder')}
                        size={'medium'}
                        isLocked={!isAProfessional || isSubmitting}
                      />
                    </div>
                  </div>
                  <div className={'row'}>
                    <span className={'label'}>{t('address.zipcode.label')}</span>
                    <div className={'data'}>
                      <TextInput
                        type={'text'}
                        value={isAProfessional ? addressZipcode : t('address.zipcode.placeholder')}
                        onChange={setAddressZipcode}
                        placeholder={t('address.zipcode.placeholder')}
                        size={'medium'}
                        isLocked={!isAProfessional || isSubmitting}
                      />
                    </div>
                  </div>
                  <div className={'row'}>
                    <span className={'label'}>{t('address.city.label')}</span>
                    <div className={'data'}>
                      <TextInput
                        type={'text'}
                        value={isAProfessional ? addressCity : t('address.city.placeholder')}
                        onChange={setAddressCity}
                        placeholder={t('address.city.placeholder')}
                        size={'medium'}
                        isLocked={!isAProfessional || isSubmitting}
                      />
                    </div>
                  </div>
                  <div className={'row'}>
                    <span className={'label'}>{t('address.region.label')}</span>
                    <div className={'data'}>
                      <TextInput
                        type={'text'}
                        value={isAProfessional ? addressRegion : t('address.region.placeholder')}
                        onChange={setAddressRegion}
                        placeholder={t('address.region.placeholder')}
                        size={'medium'}
                        isLocked={!isAProfessional || isSubmitting}
                      />
                    </div>
                  </div>
                  <div className={'row'}>
                    <span className={'label'}>{t('address.country.label')}</span>
                    <div className={'data'}>
                      <TextInput
                        type={'text'}
                        value={isAProfessional ? addressCountry : t('address.country.placeholder')}
                        onChange={setAddressCountry}
                        placeholder={t('address.country.placeholder')}
                        size={'medium'}
                        isLocked={!isAProfessional || isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={'category'}>
              <h2>{t('account.other.header')}</h2>

              <div>
                <Checkbox
                  label={t('register.newslettersCheckbox')}
                  isChecked={allowsNewsletters}
                  onChange={(value) => setAllowsNewsletters(value)}
                />
              </div>
              <p>{t('account.help')}</p>
              <p>
                <a
                  className={'underlined-link'}
                  href={`${config.website.url}/${userLanguage}${config.website.routes.tos}`}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  {t('account.privacyPolicy')}
                </a>
              </p>
              <p>
                <a className={'underlined-link'} onClick={() => setUnregistrationAlertState(true)}>
                  {t('account.unregister')}
                </a>
              </p>
            </div>

            <div className={'submit-button ' + (hasDataChanged && isFormValid ? ' enabled' : '')}>
              <Button
                fontSize={'medium'}
                onClick={handleSubmit}
                isLocked={!hasDataChanged || !isFormValid || isSubmitting}
              >
                {t('account.updateInfoButton')}
              </Button>
            </div>
          </div>
        </>
      )}
    </BaseDesktopLayout>
  )
}

export default Likes
