import React, { useState } from 'react'
import { getFormattedDate } from '../../../utils/tool'
import { IconButton } from '../IconButton/IconButton'
import { SanitizedHTML } from '../SanitizedHTML/SanitizedHTML'
import { LoadingUserIcon, UserIcon } from '../UserIcon/UserIcon'
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil-icon.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg'
import './ChatMessage.css'
import { useTranslation } from 'react-i18next'
import { Button } from '../Button/Button'
import { useCallback } from 'react'
import { ChatMessageFile, ChatMessageFileData } from '../ChatMessageFile/ChatMessageFile'

export interface ChatMessageData {
  id: string
  text: string
  userName: string
  creationDate: Date
  updateDate: Date
  uiFirstColor?: string
  uiSecondColor?: string
  files?: ChatMessageFileData[]
}

export interface ChatMessageProps extends ChatMessageData {
  onUpdate?: (id: string) => void
  onCancelUpdate?: () => void
  onDelete?: (id: string) => void
  beingEdited?: boolean
  onFileDownload?: (fileId: string) => void
  onFileRename?: (fileId: string) => void
  onFileDeletion?: (userMessageId: string, userFileId: string) => void
}

export const LoadingChatMessage: React.FC = () => {
  return (
    <div className={'loading-chat-message chat-message'}>
      <div className={'left-part'}>
        <LoadingUserIcon />
      </div>

      <div className={'right-part'}>
        <div className={'writer'} />
        <div className={'message'} />
      </div>

      <div className={'gradient'} />
    </div>
  )
}

export const ChatMessage: React.FC<ChatMessageProps> = ({
  id,
  text,
  userName,
  creationDate,
  updateDate,
  uiFirstColor,
  uiSecondColor,
  files,
  onUpdate,
  onCancelUpdate,
  onDelete,
  beingEdited = false,
  onFileDownload,
  onFileRename,
  onFileDeletion,
}) => {
  const { t } = useTranslation()

  const [askForDeletion, setAskForDeletion] = useState<boolean>(false)

  const handleDeletion = useCallback(() => {
    setAskForDeletion(false)
    if (onDelete) onDelete(id)
  }, [setAskForDeletion, onDelete, id])

  const handleFileDeletion = useCallback(
    (userFileId: string) => {
      if (onFileDeletion) onFileDeletion(id, userFileId)
    },
    [id, onFileDeletion]
  )

  return (
    <div
      className={
        'chat-message' + (beingEdited ? ' edition' : '') + (askForDeletion ? ' deletion' : '')
      }
    >
      {!askForDeletion ? (
        <>
          <div className={'left-part'}>
            <UserIcon
              name={userName}
              withOverlay={false}
              bgFirstColor={uiFirstColor}
              bgSecondColor={uiSecondColor}
            />
          </div>

          <div className={'right-part'}>
            <div className={'writer'}>
              <span className={'bold'}>{userName}</span>
              <span className={'date light-txt small-txt'}>
                {getFormattedDate(creationDate, 'DMY')}
              </span>
              <span className={'hour light-txt small-txt'}>
                {getFormattedDate(creationDate, 'HM')}
              </span>
              {updateDate > creationDate && (
                <span className={'light-txt small-txt'}>{`(${t('chat.updatedMessage')})`}</span>
              )}
            </div>
            <div className={'message'}>
              {beingEdited ? (
                <>
                  <p className={'light-txt'}>{t('chat.editMessage.info')}</p>
                  <Button filled={false} fontSize={'small'} onClick={onCancelUpdate}>
                    {t('chat.editMessage.cancel')}
                  </Button>
                </>
              ) : (
                <SanitizedHTML unsafeHTML={text} />
              )}
            </div>

            {files && files.length > 0 && (
              <div className={'files-wrapper'}>
                {files.map((file) => (
                  <ChatMessageFile
                    key={file.id}
                    id={file.id}
                    mimetype={file.mimetype}
                    filesize={file.filesize}
                    name={file.name}
                    url={file.url}
                    onDownload={onFileDownload}
                    onRename={onFileRename}
                    onDelete={handleFileDeletion}
                    size={'medium'}
                  />
                ))}
              </div>
            )}
          </div>

          {onUpdate && onDelete && (
            <div className={'hover-part'}>
              <IconButton
                icon={<EditIcon />}
                onClick={() => onUpdate(id)}
                enabled={!beingEdited}
                size={'small'}
                bg={'alpha'}
              />
              <IconButton
                icon={<TrashIcon />}
                onClick={() => setAskForDeletion(true)}
                enabled={!beingEdited}
                size={'small'}
                bg={'alpha'}
              />
            </div>
          )}
        </>
      ) : (
        <div className={'deletion-wrapper'}>
          <p>{t('chat.deleteMessage.message')}</p>
          <div>
            <Button filled={false} onClick={() => setAskForDeletion(false)} fontSize={'small'}>
              {t('chat.deleteMessage.cancel')}
            </Button>
            <Button filled={true} onClick={handleDeletion} fontSize={'small'}>
              {t('chat.deleteMessage.apply')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
