import { createStore, Thunk, thunk, ThunkOn, thunkOn } from 'easy-peasy'
import { computerVision, ComputerVisionModel } from './computer-vision'
import { ColorTagsModel, colorTags } from './colorTags'
import { MaterialTagsModel, materialTags } from './materialTags'
import { FiltersModel, filters } from './filters'
import { HistoryModel, history } from './history'
import { SavedModel, saved } from './saved'
import { SearchModel, search } from './search'
import { SimilarProductsModel, similarProducts } from './similar-products'
import { UserModel, user } from './user'
import { ProjectsModel, projects } from './projects'
import { ProjectProductsModel, projectProducts } from './project-products'
import { ProjectMembersModel, projectMembers } from './project-members'
import { ProjectFilesModel, projectFiles } from './project-files'
import { UserFilesModel, userFiles } from './user-files'
import { UserMessagesModel, userMessages } from './user-messages'
import {
  UserProjectProductOpinionsModel,
  userProjectProductOpinions,
} from './project-product-opinions'
import { ProfessionalsModel, professionals } from './professionals'
import { ProjectProfessionalsModel, projectProfessionals } from './project-professionals'
import { BrandsModel, brands } from './brands'

export interface StoreModel {
  computerVision: ComputerVisionModel
  brands: BrandsModel
  colorTags: ColorTagsModel
  materialTags: MaterialTagsModel
  filters: FiltersModel
  history: HistoryModel
  saved: SavedModel
  search: SearchModel
  similarProducts: SimilarProductsModel
  user: UserModel
  userFiles: UserFilesModel
  userMessages: UserMessagesModel
  projects: ProjectsModel
  projectProducts: ProjectProductsModel
  userProjectProductOpinions: UserProjectProductOpinionsModel
  projectMembers: ProjectMembersModel
  projectFiles: ProjectFilesModel
  projectProfessionals: ProjectProfessionalsModel
  professionals: ProfessionalsModel
  initializeApp: Thunk<StoreModel, void, any, StoreModel>
  fetchUIData: Thunk<StoreModel, void, any, StoreModel>
  fetchUserData: Thunk<StoreModel, void, any, StoreModel>
  onUserChange: ThunkOn<StoreModel, any, StoreModel>
}

export const store = createStore<StoreModel>({
  computerVision,
  brands,
  colorTags,
  materialTags,
  filters,
  history,
  saved,
  search,
  similarProducts,
  user,
  userFiles,
  userMessages,
  projects,
  projectProducts,
  userProjectProductOpinions,
  projectMembers,
  projectFiles,
  projectProfessionals,
  professionals,

  initializeApp: thunk(async (state, payload, { getStoreActions }) => {
    console.debug('[store] initialize app')
    const actions = getStoreActions()

    await actions.user.fetchLocalUserData()
    await actions.fetchUIData()
    await actions.fetchUserData()
  }),

  fetchUIData: thunk(async (state, payload, { getStoreActions }) => {
    console.debug('[store] fetch UI data')
    const actions = getStoreActions()
    return Promise.all([actions.colorTags.fetchTags(), actions.materialTags.fetchTags()])
  }),

  fetchUserData: thunk(async (state, payload, { getStoreActions }) => {
    console.debug("[store] fetch user's data")
    const actions = getStoreActions()
    await actions.user.fetchOnlineUserData()
    return Promise.all([
      actions.brands.fetchBrands(),
      actions.saved.fetchItems(),
      actions.projects.fetchProjects(),
      actions.history.fetchItems(),
    ])
  }),

  onUserChange: thunkOn(
    (actions, storeActions) => [storeActions.user.setToken],
    async (actions) => {
      console.debug('[store] onUserChange')
      await actions.user.fetchLocalUserData()
      await actions.fetchUserData()
    }
  ),
})
