import React from 'react'
import { ReactComponent as HeartIcon } from '../../../assets/icons/stroked-heart-icon.svg'
import { ReactComponent as HeartFilledIcon } from '../../../assets/icons/filled-heart-icon.svg'
import { IconButton } from '../../atoms/IconButton/IconButton'
import './LikeButton.css'

export interface LikeButtonProps {
  isLike?: boolean
  className?: string
  onClick?: () => void
  iconSize?: string
}

export const LikeButton: React.FC<LikeButtonProps> = ({
  isLike = false,
  onClick,
  className,
}) => {
  return (
    <IconButton
      icon={isLike ? <HeartFilledIcon /> : <HeartIcon />}
      className={`like-button${className ? ' ' + className : ''}`}
      onClick={() => onClick!()}
      bg={'none'}
      size={'small'}
    />
  )
}
