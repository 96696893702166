import React, { useState } from 'react'
import styled from 'styled-components'
import { LikeButton } from '../LikeButton/LikeButton'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useCallback } from 'react'
import Mixpanel, { getCurrentPageName, MixpanelEvents } from '../../../utils/mixpanel'
import { isApp } from '../../../utils/platform'
import { Button } from '../../atoms/Button/Button'
import { ReactComponent as ProjectIcon } from '../../../assets/icons/hashtag-icon.svg'
import './CardProductVariation.css'
import { cloudImageUrl } from '../../../utils/cloudImage'
import { Card } from '../../atoms/Card/Card'
import { getFormattedPrice } from '../../../utils/tool'
import { Routes } from '../../../utils/Routes'
import config from '../../../config'

const StyledImg = styled.img<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
`

export interface CardProductVariationProps {
  productVariationId?: string
  userProductId?: string
  brandId?: string
  productId?: string
  displayName: string
  brandName?: string
  price?: number
  eshopProductVariationId?: string
  buyLink?: string
  thumbnail?: string
  thumbnailBgColor?: string
  onLikeClick?: () => void
  onProjectClick?: () => void
  onBuyClick?: () => void
  onDelete?: () => void
  isLike?: boolean
  withLikeButton?: boolean
  withBuyButton?: boolean
  className?: string | undefined
  withCloudImage?: boolean
}

export const CardProductVariation: React.FC<CardProductVariationProps> = ({
  brandId,
  productId,
  displayName,
  brandName,
  price,
  eshopProductVariationId,
  buyLink,
  thumbnail,
  thumbnailBgColor,
  onLikeClick,
  onProjectClick,
  onBuyClick,
  onDelete,
  isLike,
  productVariationId,
  userProductId,
  withLikeButton = true,
  withBuyButton = true,
  className,
  withCloudImage = true,
}) => {
  const { t } = useTranslation()
  const location = useLocation()

  const useAppLayout = isApp()

  const productVariationUrl = `${Routes.ProductVariation}/${productVariationId}`

  const handleBuyClick = useCallback(() => {
    console.debug('[CardProductVariation] handleBuyClick')

    let from = getCurrentPageName(location ? location?.pathname : null)
    if (from === 'product variation') from += ' / similar products'

    const mixPanelData = {
      brandId: brandId,
      brandName: brandName,
      productId: productId,
      productName: displayName,
      productVariationId: productVariationId,
      userProductId: userProductId,
      productVariationName: displayName,
      eshopProductVariationId: eshopProductVariationId,
      from: from,
    }
    Mixpanel.track(MixpanelEvents.BuyButtonClicked, mixPanelData)

    if (onBuyClick) onBuyClick()
  }, [
    brandId,
    brandName,
    displayName,
    eshopProductVariationId,
    productVariationId,
    userProductId,
    onBuyClick,
    productId,
    location,
  ])

  // DOM
  const [useCloudImage, setUseCloudImage] = useState<boolean>(withCloudImage)
  const image =
    thumbnail &&
    (config.cloudImage.use && useCloudImage ? (
      <picture onError={() => setUseCloudImage(false)}>
        <source srcSet={cloudImageUrl(thumbnail, 320, undefined, 'webp')} type="image/webp" />
        <source srcSet={cloudImageUrl(thumbnail, 320, undefined, 'jpg')} type="image/jpeg" />
        <StyledImg src={thumbnail} backgroundColor={thumbnailBgColor || 'white'} />
      </picture>
    ) : (
      <picture>
        <StyledImg src={thumbnail} backgroundColor={thumbnailBgColor || 'white'} />
      </picture>
    ))

  return (
    <Card className={`product-card${className ? ' ' + className : ''}`}>
      <div className={'top-part condensed-txt'}>
        <div className={'image-wrapper'}>
          {productVariationUrl ? <Link to={productVariationUrl}>{image}</Link> : image}
        </div>

        <section className={'name'}>
          <Link to={productVariationUrl}>
            <div className={'title'}>{displayName}</div>
            {brandName && <div className={'subtitle'}>{brandName}</div>}
          </Link>
        </section>

        {price && (
          <section className={'price'}>
            <Link to={productVariationUrl}>{getFormattedPrice(price, 'EUR')}</Link>
          </section>
        )}
      </div>

      {(withBuyButton || withLikeButton) && (
        <section>
          <div className={'actions-wrapper'}>
            {withLikeButton &&
              (useAppLayout ? (
                <LikeButton iconSize={'1.45rem'} onClick={onLikeClick} isLike={isLike} />
              ) : (
                <Button
                  filled={false}
                  Picto={ProjectIcon}
                  onClick={onProjectClick}
                  className={'on-hover'}
                  fontSize={'small'}
                >
                  {t('cardProductVariation.addToProject')}
                </Button>
              ))}
            <div className={'space'}></div>
            {buyLink !== undefined && (
              <Button onClick={handleBuyClick} href={buyLink} target="_blank" fontSize={'small'}>
                {t('cardProductVariation.buy')}
              </Button>
            )}
          </div>
        </section>
      )}
    </Card>
  )
}
