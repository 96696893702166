import React from 'react'
// import Mixpanel, { MixpanelEvents } from '../../../utils/mixpanel'
// import { useStoreActions } from '../../../store/hooks'
// import { useHistory } from 'react-router-dom'
// import { Routes } from '../../../utils/Routes'
import { VisualSearchCard } from './VisualSearchCard'

export interface HistoryCardProps {
  objectDetectionId: string
  visualSearchId: string
  createdAt: Date
  imageUrl: string
  onClick?: () => void
  onRemove: () => void
}

export const VisualSearchCardWrapper: React.FC<HistoryCardProps> = ({
  objectDetectionId,
  visualSearchId,
  createdAt,
  imageUrl,
  onRemove,
  onClick,
}) => {
  // const history = useHistory()

  // const loadComputerVision = useStoreActions((actions) => actions.computerVision.loadComputerVision)
  // const setResults = useStoreActions((actions) => actions.search.setResults)
  // const clearFiltersWithoutFetch = useStoreActions(
  //   (actions) => actions.filters.clearFiltersWithoutFetch
  // )
  // const clearComputerVision = useStoreActions(
  //   (actions) => actions.computerVision.clearComputerVision
  // )

  const handleOpening = () => {
    console.debug('[VSCardWrapper] handleOpening')

    // clearFiltersWithoutFetch()
    // setResults(null)
    // clearComputerVision()

    // Mixpanel.track(MixpanelEvents.VisualSearchReOpened, {
    //   objectDetectionId: objectDetectionId,
    //   visualSearchId: visualSearchId,
    // });

    // loadComputerVision({
    //   odId: objectDetectionId,
    //   vsId: visualSearchId,
    // }).then(() => {
    //   history.push(Routes.Search)
    // })

    // if (onClick != null)
    //   onClick();
  }

  return (
    <VisualSearchCard
      imageUrl={imageUrl}
      createdAt={createdAt}
      onOpen={handleOpening}
      onRemove={onRemove}
    />
  )
}
