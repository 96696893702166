import React from 'react'
import { ReactComponent as NoResult } from '../../../assets/icons/no-result.svg'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const NoResultsSVG = styled(NoResult)`
  width: 2rem !important;
  height: 2rem !important;
`

const NoResultsText = styled('p')`
  color: ${(props) => props.theme.colors.grey300};
  margin-top: 1rem;
  font-size: 0.8rem;
`

export const NoResults: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <NoResultsSVG />
      <NoResultsText>{t('search.noResults')}</NoResultsText>
    </>
  )
}
