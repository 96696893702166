import React, { useCallback, useMemo, useState } from 'react'
import {
  CardProductVariation,
  CardProductVariationProps,
} from '../CardProductVariation/CardProductVariation'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus-icon.svg'
import { ReactComponent as MinusIcon } from '../../../assets/icons/minus-icon.svg'
import { ReactComponent as ProjectIcon } from '../../../assets/icons/hashtag-icon.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg'
import { ReactComponent as CommentIcon } from '../../../assets/icons/talk-icon.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil-icon.svg'
import { IconButton } from '../../atoms/IconButton/IconButton'
import { Button } from '../../atoms/Button/Button'
import { useTranslation } from 'react-i18next'
import { IonBackdrop } from '@ionic/react'
import './ProjectProductVariationCard.css'
import ReactMarkdown from 'react-markdown'

export interface ProjectProductVariationCardProps {
  productVariationCardProps: CardProductVariationProps
  quantity: number
  isQuantityUpdating: boolean
  onQuantityChange: (count: number) => void
  hasMessages: boolean
  positiveOpinionsCount: number
  negativeOpinionsCount: number
  isDefaultProject: boolean
  onPushToProject?: () => void
  onProductPageClick?: () => void
  onChatClick?: () => void
  onUpdate?: () => void
  onDelete?: () => void
  onValidate?: () => void
  onInvalidate?: () => void
}

export const LoadingProjectProductVariationCard: React.FC = () => {
  return (
    <div className={'project-product-card loading'}>
      <div className={'gradient'} />
    </div>
  )
}

export const ProjectProductVariationCard: React.FC<ProjectProductVariationCardProps> = ({
  productVariationCardProps,
  quantity,
  isQuantityUpdating,
  onQuantityChange,
  hasMessages,
  positiveOpinionsCount,
  negativeOpinionsCount,
  isDefaultProject,
  onPushToProject,
  onProductPageClick,
  onChatClick,
  onUpdate,
  onDelete,
  onValidate,
  onInvalidate,
}) => {
  const { t } = useTranslation()

  // quantity
  const handleQuantityChange = useCallback(
    (add: boolean) => {
      if (add) {
        onQuantityChange(quantity + 1)
      } else {
        onQuantityChange(Math.max(1, quantity - 1))
      }
    },
    [quantity, onQuantityChange]
  )

  // bullets
  const hasBullets = useMemo(() => {
    return hasMessages || positiveOpinionsCount > 0 || negativeOpinionsCount > 0
  }, [hasMessages, positiveOpinionsCount, negativeOpinionsCount])

  // delete
  const [showDeletionWrapper, setShowDeletionWrapper] = useState<boolean>(false)
  const handleDeletion = useCallback(() => {
    setShowDeletionWrapper(false)
    if (onDelete) onDelete()
  }, [setShowDeletionWrapper, onDelete])

  return (
    <div className={'project-product-card' + (hasBullets ? '' : ' no-bullet')}>
      <div className={'content'}>
        <div className={'shadow'} />
        <div className={'product-data'}>
          <div>
            <div className={'hover-content'}>
              {onDelete && (
                <div className={'delete'}>
                  <IconButton
                    icon={<TrashIcon />}
                    size={'medium'}
                    onClick={() => setShowDeletionWrapper(true)}
                  />
                </div>
              )}
              <div className={'buttons-wrapper'}>
                {onPushToProject && (
                  <Button
                    Picto={ProjectIcon}
                    onClick={onPushToProject}
                    fontSize={'small'}
                    filled={false}
                    bg={'white'}
                  >
                    {t('cardProductVariation.addToProject')}
                  </Button>
                )}
                {onChatClick && (
                  <Button
                    Picto={CommentIcon}
                    onClick={onChatClick}
                    fontSize={'small'}
                    filled={false}
                    bg={'white'}
                  >
                    {t('cardProductVariation.commentButton')}
                  </Button>
                )}
                {onUpdate && (
                  <Button
                    Picto={EditIcon}
                    onClick={onUpdate}
                    fontSize={'small'}
                    filled={false}
                    bg={'white'}
                  >
                    {t('cardProductVariation.updateButton')}
                  </Button>
                )}
                {onProductPageClick && (
                  <Button
                    onClick={onProductPageClick}
                    fontSize={'small'}
                    filled={false}
                    bg={'white'}
                  >
                    {t('cardProductVariation.productPage')}
                  </Button>
                )}
                {!isDefaultProject && onValidate && (
                  <Button onClick={onValidate} fontSize={'small'} filled={false} bg={'white'}>
                    {t('cardProductVariation.validateButton')}
                  </Button>
                )}
                {!isDefaultProject && onInvalidate && (
                  <Button onClick={onInvalidate} fontSize={'small'} filled={false} bg={'white'}>
                    {t('cardProductVariation.invalidateButton')}
                  </Button>
                )}
                <Button
                  href={productVariationCardProps.buyLink}
                  target="_blank"
                  fontSize={'small'}
                  filled={false}
                  bg={'white'}
                >
                  {t('cardProductVariation.buy')}
                </Button>
              </div>
              <IonBackdrop
                visible={true}
                tappable={true}
                onIonBackdropTap={() => console.debug('comment')}
              />
            </div>

            <CardProductVariation
              {...productVariationCardProps}
              withLikeButton={false}
              withBuyButton={false}
            />
          </div>
        </div>

        {!isDefaultProject && (
          <section className={'counter' + (isQuantityUpdating ? ' pending' : '')}>
            <div>
              <IconButton
                icon={<MinusIcon />}
                size={'small'}
                enabled={!isQuantityUpdating && quantity > 1}
                onClick={() => handleQuantityChange(false)}
              />
              <div>{quantity}</div>
              <IconButton
                icon={<PlusIcon />}
                size={'small'}
                enabled={!isQuantityUpdating}
                onClick={() => handleQuantityChange(true)}
              />
            </div>
          </section>
        )}

        {hasBullets && (
          <section className={'bullets'}>
            <div>
              {hasMessages && <CommentIcon />}
              {positiveOpinionsCount > 0 && (
                <div className={'bullet good'}>{positiveOpinionsCount}</div>
              )}
              {negativeOpinionsCount > 0 && (
                <div className={'bullet bad'}>{negativeOpinionsCount}</div>
              )}
            </div>
          </section>
        )}

        {showDeletionWrapper && (
          <div className={'deletion-wrapper'}>
            <ReactMarkdown className={'condensed-txt'}>
              {t('projectProductDeletion.message').replace(/\n/g, '  \n')}
            </ReactMarkdown>
            <Button
              onClick={() => setShowDeletionWrapper(false)}
              fontSize={'small'}
              filled={false}
              bg={'white'}
            >
              {t('projectProductDeletion.cancel')}
            </Button>
            <Button onClick={handleDeletion} fontSize={'small'} filled={true}>
              {t('projectProductDeletion.confirm')}
            </Button>
          </div>
        )}
      </div>
      {!hasBullets && <div className={'empty-bullets'} />}
    </div>
  )
}
