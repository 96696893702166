import phone from 'phone'

export const validateName = (value: string): boolean => {
  const result: boolean = value.search(/\w/) > -1
  return result
}

export const validatePassword = (password: string): boolean => {
  const result: boolean = password.length >= 6
  return result
}

export const validateEmail = (email: string): boolean => {
  const result: boolean = email.length > 0 && new RegExp('^\\S+@\\S+\\.\\S+').test(email)
  return result
}

export const validateURL = (url: string) => {
  let urlObj: URL | undefined = undefined
  try {
    urlObj = new URL(url)
  } catch (_) {
    return false
  }

  const result: boolean = urlObj.protocol === 'http:' || urlObj.protocol === 'https:'
  return result
}

export const validatePhone = (value: string): [boolean, string] => {
  if (value !== '') {
    // without dialling code
    if (value[0] !== '+') {
      const formattedValue = value.replace(/[^0-9]/g, '')
      console.log('--formatted phone:', formattedValue)
      if (formattedValue[0] === '0' && formattedValue.length === 10) {
        return [true, formattedValue]
      }
    }
    // with dialling code
    else {
      const result = phone(value, '', true)
      if (result.length === 0) {
        return [false, '']
      } else {
        return [true, result[0]]
      }
    }
  }
  return [false, '']
}
