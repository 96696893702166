import React, { useMemo } from 'react'
import { getFormattedPrice } from '../../../utils/tool'
import './UserProjectProductOpinionReminder.css'
import { ReactComponent as GoodIcon } from '../../../assets/icons/thumbs-up-icon.svg'
import { ReactComponent as NeutralIcon } from '../../../assets/icons/balance-icon.svg'
import { ReactComponent as BadIcon } from '../../../assets/icons/thumbs-down-icon.svg'
import { useTranslation } from 'react-i18next'
import { UserOpinion } from '@fynde/dtos'

export type UserProjectProductOpinionReminderProps = {
  userProjectProductId: string
  imgData?: string
  productName: string
  brandName: string
  price: number
  onReview: (userProjectProductId: string, opinion: UserOpinion) => void
  isLocked?: boolean
}

export const UserProjectProductOpinionReminder: React.FC<UserProjectProductOpinionReminderProps> =
  ({
    userProjectProductId,
    imgData,
    productName,
    brandName,
    price,
    onReview,
    isLocked,
  }) => {
    const { t } = useTranslation()

    const isLoading = useMemo(() => {
      return imgData === undefined
    }, [imgData])

    return (
      <div className={'project-product-opinion-reminder'}>
        <div className={'left-part'}>
          {isLoading ? (
            <div className={'loading-placeholder'}>
              <div className={'gradient'} />
            </div>
          ) : (
            <>
              <img src={imgData} alt={'product-preview'} />
              <div className={'product-infos condensed-txt'}>
                <div>
                  <span className={'product-name'}>{productName}</span>
                  <span className={'brand-name light-txt'}>{brandName}</span>
                </div>
                <div>
                  <span className={'price'}>{getFormattedPrice(price, 'EUR')}</span>
                </div>
              </div>
            </>
          )}
        </div>

        <div className={'right-part'}>
          <h2>{t('projectProductOpinionReminder.title')}</h2>
          <p>{t('projectProductOpinionReminder.message')}</p>
          <div className={'cta-wrapper'}>
            <button
              className={'cta good'}
              onClick={() => onReview(userProjectProductId, UserOpinion.Good)}
              disabled={isLoading || isLocked}
            >
              <GoodIcon />
              <div className={'bg'} />
            </button>
            <button
              className={'cta neutral'}
              onClick={() => onReview(userProjectProductId, UserOpinion.Neutral)}
              disabled={isLoading || isLocked}
            >
              <NeutralIcon />
              <div className={'bg'} />
            </button>
            <button
              className={'cta bad'}
              onClick={() => onReview(userProjectProductId, UserOpinion.Bad)}
              disabled={isLoading || isLocked}
            >
              <BadIcon />
              <div className={'bg'} />
            </button>
          </div>
        </div>
      </div>
    )
  }
