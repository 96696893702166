import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Project } from '../../../store/projects'
import { ProjectListItemWrapper } from '../ProjectListItem/ProjectListItemWrapper'

const Container = styled.div<{ centered: boolean }>`
  display: block;
  width: calc(100vw - 4rem);
  /* background-color: rgba(255,0,0,0.2); */

  margin: ${(props) => (props.centered ? '0 auto' : '0')};
`

const NothingToDisplay = styled.p`
  text-align: center;
  line-height: 4rem;
  width: 100%;
  color: ${(props) => props.theme.colors.grey300};
`

export interface ProjectsListProps {
  projects: Project[]
  maxCount?: number | undefined
  centered?: boolean
}

export const ProjectsList: React.FC<ProjectsListProps> = ({
  projects,
  maxCount = undefined,
  centered = true,
}) => {
  const { t } = useTranslation()

  return (
    <Container centered={centered}>
      {projects.length === 0 ? (
        <NothingToDisplay>{t('project.noProjectToDisplay')}</NothingToDisplay>
      ) : (
        projects.map((project, index) =>
          maxCount && index + 1 > maxCount ? null : (
            <ProjectListItemWrapper key={project.id} projectId={project.id} />
          )
        )
      )}
    </Container>
  )
}
