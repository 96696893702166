import React from 'react'
import styled from 'styled-components'
import { PriceFilterCardWrapper } from '../PriceFilterCard/PriceFilterCardWrapper'
import { PriceRange } from '../PriceFilterCard/PriceFilterCard'
import { ColorFilterCardWrapper } from '../ColorFilterCard/ColorFilterCardWrapper'
import { MaterialFilterCardWrapper } from '../MaterialFilterCard/MaterialFilterCardWrapper'

const Grid = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1rem;
  }
`

export interface FilterContainerData {
  selectedColorTagIds?: string[]
  selectedMaterialTagIds?: string[]
  selectedPriceRange?: { min: number | null; max: number | null }
}

export interface FilterContainerProps {
  onPriceChange?: (range: PriceRange) => void
  onColorChange?: (tagIds: string[]) => void
  onMaterialChange?: (tagIds: string[]) => void
}

export const FilterContainer: React.FC<FilterContainerProps> = ({
  onPriceChange,
  onColorChange,
  onMaterialChange,
}) => {
  const onPriceChangeCallback = (priceRange: PriceRange) => {
    if (onPriceChange !== undefined) {
      onPriceChange(priceRange)
    }
  }

  const onColorChangeCallback = (colorTagIds: string[]) => {
    if (onColorChange !== undefined) {
      onColorChange(colorTagIds)
    }
  }

  const onMaterialChangeCallback = (materialTagIds: string[]) => {
    if (onMaterialChange !== undefined) {
      onMaterialChange(materialTagIds)
    }
  }

  return (
    <Grid>
      <PriceFilterCardWrapper
        applyOnChange={false}
        onChange={onPriceChange ? onPriceChangeCallback : undefined}
      />
      <ColorFilterCardWrapper
        applyOnChange={false}
        onChange={onColorChange ? onColorChangeCallback : undefined}
      />
      <MaterialFilterCardWrapper
        applyOnChange={false}
        onChange={onMaterialChange ? onMaterialChangeCallback : undefined}
      />
    </Grid>
  )
}
