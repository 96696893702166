import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LogoY } from '../../../assets/home_y.svg'
import { ReactComponent as CircleOutline } from '../../../assets/home_dot_outline.svg'
import { ReactComponent as Circle } from '../../../assets/home_dot.svg'
import './TapToFynde.css'

export interface TapToFyndeProps {
  onClick: () => void
  isMobile: boolean
}

export const TapToFynde: React.FC<TapToFyndeProps> = ({
  onClick,
  isMobile,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={'tap-to-find-button'}>
        {isMobile && <LogoY className={'y-top'} />}
        <div className={'camera-button'}>
          <div className={'circle-wrapper'}>
            <div onClick={onClick} className={'action-area'}/>
            <svg
              className={'circle-text'}
              version="1.1"
              id="curved-text"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 80 80"
            >
              <path
                id="curve"
                className="st1"
                d="M 0 0
                    m -35,0
                    a 35,35 0 1,0 70,0
                    a 35,35 0 1,0 -70,0"
                transform="rotate(90 0 0) translate(40 -40)"
              />

              <text>
                <textPath startOffset="50%" xlinkHref="#curve" textAnchor={"middle"}>
                  {isMobile
                    ? t('homeLayout.appNewSearchButton').toUpperCase()
                    : t('homeLayout.desktopNewSearchButton').toUpperCase()
                  }
                </textPath>
              </text>
            </svg>
            <CircleOutline id={"outside-circle"}/>
            <Circle id={"inside-circle"}/>
          </div>
        </div>
      </div>
    </>
  )
}
