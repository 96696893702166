import React from 'react'
import DOMPurify from 'dompurify';

export interface SanitizedHTMLProps {
  unsafeHTML: string
}

export const SanitizedHTML: React.FC<SanitizedHTMLProps> = ({
  unsafeHTML
}) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(unsafeHTML) }}/>
  )
}
