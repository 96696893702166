import React, { useCallback, useMemo } from 'react'
import { areArraysEqual } from '../../../utils/tool'
import { useStoreState, useStoreActions } from '../../../store/hooks'
import { MaterialTagData, MaterialFilterCard } from './MaterialFilterCard'
import './MaterialFilterCard.css'
import { RequestStatus } from '../../../utils/reqStatus'

export interface MaterialFilterCardWrapperProps {
  applyOnChange: boolean
  onChange?: (ids: string[]) => void
}

export const MaterialFilterCardWrapper: React.FC<MaterialFilterCardWrapperProps> = ({
  applyOnChange,
  onChange,
}) => {
  // is ready
  const materialTagsStatus = useStoreState((state) => state.materialTags.reqStatus)
  const isLoading = useMemo<boolean>(() => {
    return materialTagsStatus !== RequestStatus.Completed
  }, [materialTagsStatus])

  // logic
  const materialTags = useStoreState((state) => state.materialTags.localizedTags)
  const filtersMaterialTagIds = useStoreState((state) => state.filters.selectedMaterialTagIds)
  const updateFilter = useStoreActions((actions) => actions.filters.setNewFilters)

  const applyFilter = useCallback(
    (materialIds: string[]) => {
      if (!areArraysEqual(filtersMaterialTagIds, materialIds, true)) {
        updateFilter({
          selectedMaterialTagIds: materialIds,
        })
      }
    },
    [updateFilter, filtersMaterialTagIds]
  )

  const handleSelectionChange = useCallback(
    (materials: MaterialTagData[]) => {
      console.log('[MaterialFilterCardWrapper]', 'handleSelectionChange')
      const selection = materials.map((mat) => mat.id)
      if (applyOnChange === true) applyFilter(selection)

      if (onChange && !areArraysEqual(filtersMaterialTagIds, selection, true)) {
        onChange(selection)
      }
    },
    [applyOnChange, applyFilter, onChange, filtersMaterialTagIds]
  )

  return (
    <MaterialFilterCard
      materials={
        isLoading
          ? []
          : materialTags
              .filter((mat) => mat.enabled)
              .sort((mat1, mat2) => mat2.priority - mat1.priority)
              .map((mat): MaterialTagData => {
                return {
                  id: mat.id,
                  name: mat.name,
                }
              })
      }
      selectedMaterialIds={filtersMaterialTagIds}
      onSelectionChange={handleSelectionChange}
    />
  )
}
