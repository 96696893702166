import axiosApi from '../utils/axios'
import {
  UserProjectInvitationCreationDTO,
  UserProjectInvitationDeletionDTO,
  UserProjectInvitationDTO,
  UserProjectInvitationFiltersDTO,
} from '@fynde/dtos'

// GET
export const getUserProjectInvitations = async (
  userToken: string,
  dto: UserProjectInvitationFiltersDTO
): Promise<UserProjectInvitationDTO[] | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    params: {
      userProjectId: dto.userProjectId,
    },
  }
  try {
    const resp = await axiosApi.get<UserProjectInvitationDTO[]>(`/user-project-invitation/`, config)
    return resp.data
  } catch (err) {
    console.error('An error occured while getting a user-project-invitation:', err)
  }
  return null
}

// POST
export const postUserProjectInvitation = async (
  userToken: string,
  dto: UserProjectInvitationCreationDTO
): Promise<UserProjectInvitationDTO | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.post<UserProjectInvitationDTO>(
      `/user-project-invitation/`,
      dto,
      config
    )
    return resp.data
  } catch (err) {
    console.error('An error occured while posting a new user-project-invitation:', err)
  }
  return null
}

// DELETE
export const deleteUserProjectInvitation = async (
  userToken: string,
  dto: UserProjectInvitationDeletionDTO
): Promise<boolean> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data: dto,
  }
  try {
    await axiosApi.delete(`/user-project-invitation/`, config)
    return true
  } catch (err) {
    console.error('An error occured while removing a user-project-invitation:', err)
    return false
  }
}
