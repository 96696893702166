import React, { useMemo, useState } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down-icon.svg'
import { TextInput, TextInputRef } from '../TextInput/TextInput'
import './SelectWithTextInput.css'

export interface SelectWithTextInputProps {
  options: string[]
  value: string
  placeholder: string
  onChange: (selectedValue: string) => void
  isLocked?: boolean
  isWrong?: boolean // style as feedback
  fontSize?: 'small' | 'medium'
  optionsPosition?: 'top' | 'bottom'
}

export const SelectWithTextInput: React.FC<SelectWithTextInputProps> = ({
  options,
  value,
  placeholder,
  onChange,
  isLocked = false,
  isWrong = false,
  fontSize = 'small',
  optionsPosition = 'top',
}) => {
  const { t } = useTranslation()

  const [isOpened, setIsOpened] = useState(false)

  const textInput = React.useRef<TextInputRef>(null)

  function handleOptionClick(option: string) {
    console.debug('[SelectWithTextInput] click on an option:', option)
    setIsOpened(false)
    onChange(option)
  }

  function clear() {
    setIsOpened(false)
    onChange('')
    textInput.current && textInput.current.focus()
  }

  const handleMouseLeave = useCallback(() => {
    if (isOpened) setIsOpened(false)
  }, [isOpened])

  const completedOptions = useMemo(() => {
    const result = [...options]
    result.push(t('select.manualEntryPlaceholder'))
    return result
  }, [options, t])

  let wrapperClassNames = ''
  if (isOpened) wrapperClassNames += ' opened'
  wrapperClassNames += optionsPosition === 'top' ? ' open-above' : ' open-below'

  return (
    <div
      className={`select-with-text-input ${fontSize} ${wrapperClassNames} ${
        isLocked ? 'locked' : ''
      }`}
      onMouseLeave={handleMouseLeave}
    >
      <div className={'selection-wrapper'}>
        <TextInput
          ref={textInput}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          size={fontSize}
          isWrong={isWrong}
          onClick={() => setIsOpened(false)}
          isLocked={isLocked}
        />
        <div
          className={'arrow-wrapper'}
          onClick={isLocked ? undefined : () => setIsOpened(!isOpened)}
        >
          <ArrowDownIcon className={`arrow ${isOpened ? 'opened' : 'closed'}`} />
        </div>
      </div>
      {isOpened && (
        <div className={'options-wrapper'}>
          {completedOptions.map((option, index) =>
            index === completedOptions.length - 1 ? (
              <div className={'option light-txt'} key={option} onClick={clear}>
                <span>{option}</span>
              </div>
            ) : (
              <div className={'option'} key={option} onClick={(e) => handleOptionClick(option)}>
                <span>{option}</span>
              </div>
            )
          )}
        </div>
      )}
    </div>
  )
}
