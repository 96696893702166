import * as React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  overflow: hidden;
  display: inline-block;

  box-shadow: 0 0 10px rgba(0,0,0,0);
  transition: transform 0.3s cubic-bezier(0,0,0.3,1), box-shadow 0.3s cubic-bezier(0,0,0.3,1);

  &:hover {
    transform: translateY(-0.3rem);
    box-shadow: 0 10px 10px rgba(0,0,0,0.03);
    transition: transform 0.1s cubic-bezier(0,0,0.3,1), box-shadow 0.1s cubic-bezier(0,0,0.3,1);
  }

  & > * {
    background-color: ${(props) => props.theme.colors.white};
  }
`

export interface CardProps {
  className?: string
  onClick?: () => void
}

export const Card: React.FC<CardProps> = ({ children, className, onClick }) => {
  return (
    <Container
      className={`card${className ? ' ' + className : ''}`}
      onClick={onClick}
    >
      {children}
    </Container>
  )
}
