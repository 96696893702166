import React, { useCallback, useMemo } from 'react'
import { IonRange } from '@ionic/react'
import './RangeSlider.css'
import { RangeChangeEventDetail } from '@ionic/core'

export type RangeSliderProps = {
  steps: number[]
  selectedMin: number
  selectedMax: number
  isLocked?: boolean
  onChange?: (min: number, max: number) => void
}

export const RangeSlider: React.FC<RangeSliderProps> = ({
  steps,
  selectedMin,
  selectedMax,
  onChange,
  isLocked = false,
}) => {
  // to allow non linear values:
  // we stores all values in an array of number and use their indexes as the slider values
  // we finally swap indexes and related values before sending them to the onChange callback
  const selected = useMemo(() => {
    return {
      lower: steps.indexOf(selectedMin),
      upper: steps.indexOf(selectedMax),
    }
  }, [steps, selectedMin, selectedMax])

  // events
  const handleRangeChange = useCallback(
    (event: CustomEvent<RangeChangeEventDetail>) => {
      const newRangeIndexes = event.detail.value as { lower: number; upper: number }
      const min = steps[newRangeIndexes.lower]
      const max = steps[newRangeIndexes.upper]
      if (min !== selectedMin || max !== selectedMax) {
        if (onChange) onChange(min, max)
      }
    },
    [steps, onChange, selectedMin, selectedMax]
  )

  return (
    <div className={`range-slider ${isLocked ? 'locked' : ''}`}>
      <IonRange
        dualKnobs
        value={selected}
        snaps={true}
        min={0}
        max={steps.length - 1}
        step={1}
        ticks={false}
        mode={'ios'}
        onIonChange={handleRangeChange}
        disabled={isLocked}
      />
    </div>
  )
}
