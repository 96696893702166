import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { RequestStatus } from '../utils/reqStatus'
import { useStoreState } from '../store/hooks'
import { BaseAppLayout } from '../components/organisms/BaseAppLayout/BaseAppLayout'
import { BaseDesktopLayout } from '../components/organisms/BaseDesktopLayout/BaseDesktopLayout'
import { GridCard } from '../components/organisms/GridCard/GridCard'
import { CenteredLoading } from '../components/molecules/CenteredLoading/CenteredLoading'
import { HeaderTabs } from '../components/molecules/DesktopHeader/DesktopHeader'
import SizeCalculator from '../components/atoms/SizeCalculator/SizeCalculator'
import { useIonViewDidEnter } from '@ionic/react'
import { BackButtonEvent } from '@ionic/core'
import config from '../config'
import styled from 'styled-components'
import { SearchDeletionModal } from '../components/organisms/SearchDeletionModal/SearchDeletionModal'
import { VisualSearchCardWrapper } from '../components/organisms/VisualSearchCard/VisualSearchCardWrapper'

const CenteredText = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 5rem - 5rem);
`

export interface SearchesProps {
  onBackClicked?: () => void
}
const Searches: React.FC<SearchesProps> = ({ onBackClicked }) => {
  const { t } = useTranslation()

  // title
  useIonViewDidEnter(() => {
    document.title = config.pageTitlePrefix + t('pageTitle.Searches')
  }, [t])

  // user data
  const searchItems = useStoreState((state) => state.history.items)
  const searchItemsStatus = useStoreState((state) => state.history.reqStatus)

  // available width for results
  const [availableWidth, setAvailableWidth] = useState<number>(0)

  const handleSizeChange = (width: number | null) => {
    if (width != null) {
      const newWidth = width > 0 ? width : window.innerWidth - 2 * 30
      setAvailableWidth(newWidth)
    }
  }

  // media queries
  const [useAppLayout, setUseAppLayout] = useState<boolean>(window.innerWidth < 768)

  const handleResize = useCallback(() => {
    setUseAppLayout(window.innerWidth < 768)
  }, [])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  // alert
  const [searchToDelete, setSearchToDelete] = useState<string | undefined>(undefined)

  // Android back button
  const handleBackButton = useCallback(
    (ev: BackButtonEvent) => {
      ev.preventDefault()
      ev.detail.register(10, () => {
        if (onBackClicked !== undefined) onBackClicked()
      })
    },
    [onBackClicked]
  )

  useEffect(() => {
    document.addEventListener('ionBackButton', handleBackButton as EventListener)
    return () => {
      document.removeEventListener('ionBackButton', handleBackButton as EventListener)
    }
  }, [handleBackButton])

  // DOM
  const cards: JSX.Element = useMemo(() => {
    return (
      <>
        {searchItems.map((searchItem) => (
          <VisualSearchCardWrapper
            key={`history_${searchItem.cvFileId}`}
            objectDetectionId={searchItem.objectDetection.id}
            visualSearchId={searchItem.visualSearch.id}
            imageUrl={searchItem.thumbnailUrl}
            createdAt={searchItem.visualSearch.createdAt}
            onRemove={() => setSearchToDelete(searchItem.cvFileId)}
          />
        ))}
      </>
    )
  }, [searchItems])

  return (
    <>
      {searchToDelete && (
        <SearchDeletionModal
          isOpen={searchToDelete !== undefined}
          onCancel={() => setSearchToDelete(undefined)}
          cvFileId={searchToDelete}
        />
      )}

      {useAppLayout ? (
        <BaseAppLayout
          noPaddingTop={true}
          label={t('historyLayout.history')}
          onBackClicked={onBackClicked}
        >
          <SizeCalculator onSize={({ width }) => handleSizeChange(width)}></SizeCalculator>

          {searchItemsStatus === RequestStatus.Loading ? (
            <CenteredLoading />
          ) : (
            <GridCard justify={'center'}>
              {cards}
            </GridCard>
          )}
        </BaseAppLayout>
      ) : (
        <BaseDesktopLayout selectedHeaderTab={HeaderTabs.Searches}>
          <SizeCalculator onSize={({ width }) => handleSizeChange(width)}></SizeCalculator>

          {![RequestStatus.Failed, RequestStatus.Completed].includes(searchItemsStatus) ? (
            <CenteredLoading height={'calc(100vh - 4rem - 4rem)'} />
          ) : availableWidth === 0 ? null : searchItems.length === 0 ? (
            <CenteredText>{t('searches.noSearches')}</CenteredText>
          ) : (
            <GridCard justify={'center'}>
              {cards}
            </GridCard>
          )}
        </BaseDesktopLayout>
      )}
    </>
  )
}

export default Searches
