import React from 'react'
import { Button } from '../Button/Button'
import { TextInput } from '../TextInput/TextInput'
import './TextInputWithButton.css'

export type TextInputWithButtonProps = {
    picto?: JSX.Element
    label?: string
    placeholder?: string
    buttonText: string
    value: string
    onChange: (newValue: string) => void
    onSubmit: () => void
    isInputWrong?: boolean
    isLocked?: boolean
    className?: string
}

export const TextInputWithButton: React.FC<TextInputWithButtonProps> = ({
    picto,
    label,
    placeholder,
    buttonText,
    value,
    onChange,
    onSubmit,
    isInputWrong = false,
    isLocked = false,
    className,
}) => {

  return (
    <div className={`t-input-w-button${className ? ` ${className}` : ''}`}>
      {label && (
        <label>
          {label}
        </label>
      )}
      <div className={`input-wrapper${isInputWrong ? ' error' : '' }`}>
        {picto && (
          <div className={`picto-wrapper${value.length === 0 ? ' empty' : ''}`}>
            {picto}
          </div>
        )}
        <TextInput
          placeholder={placeholder ? placeholder : ''}
          onChange={onChange}
          value={value}
          borderOnFocus={false}
          size={'medium'}
        />
        <div className={"button-wrapper"}>
          <Button
            onClick={onSubmit}
            isLocked={isLocked}
            fontSize={'small'}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}