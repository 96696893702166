import * as React from 'react'
import './IconButton.css'

export interface IconButtonProps {
  icon: JSX.Element
  size?: 'small' | 'medium'
  bg?: 'none' | 'alpha' | 'light'
  enabled?: boolean
  onClick: () => void
  className?: string
}

export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  size = 'medium',
  bg = 'light',
  enabled = true,
  onClick,
  className,
}) => {
  return (
    <button
      className={
        'icon-button '
        + size
        + (className ? ' ' + className : '')
        + (bg !== 'none' ? ' with-bg ' + bg : '' )
      }
      onClick={onClick}
      disabled={!enabled}
    >
      {icon}
    </button>
  )
}
