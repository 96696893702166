import { MaterialTagDTO, ProfessionalDTO } from '@fynde/dtos'
import { Action, action, Computed, computed } from 'easy-peasy'
import { StoreModel } from './store'

export type MaterialTagApiResponse = [MaterialTagDTO[], number]

export interface ProfessionalsModel {
  // store
  professionals: ProfessionalDTO[]

  // computed
  professional: Computed<
    ProfessionalsModel,
    (professionalId: string) => ProfessionalDTO | null,
    StoreModel
  >

  // actions
  _addProfessional: Action<ProfessionalsModel, ProfessionalDTO>
  _updateProfessional: Action<ProfessionalsModel, ProfessionalDTO>
}

export const professionals: ProfessionalsModel = {
  // store
  professionals: [],

  // computed
  professional: computed((state) => {
    return (professionalId) => {
      for (const professional of state.professionals) {
        if (professional.id === professionalId) return professional
      }
      return null
    }
  }),

  // actions
  _addProfessional: action((state, newProfessional) => {
    if (state.professionals.some((p) => p.id === newProfessional.id)) return
    newProfessional.createdAt = new Date(newProfessional.createdAt)
    newProfessional.updatedAt = new Date(newProfessional.updatedAt)
    state.professionals.push(newProfessional)
  }),

  _updateProfessional: action((state, patchedProfessional) => {
    for (let i = 0; i < state.professionals.length; i++) {
      if (state.professionals[i].id === patchedProfessional.id) {
        state.professionals[i] = patchedProfessional
      }
    }
  }),
}
