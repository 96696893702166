import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../Modal/Modal'
import {
  CardProductVariation,
  CardProductVariationProps,
} from '../CardProductVariation/CardProductVariation'
import { ReactComponent as DefaultProjectIcon } from '../../../assets/icons/star-icon.svg'
import { ReactComponent as UserProjectIcon } from '../../../assets/icons/hashtag-icon.svg'
import { ReactComponent as CheckedIcon } from '../../../assets/icons/success-bullet-icon.svg'
import { SearchBar } from '../../atoms/SearchBar/SearchBar'
import { TextInputWithButton } from '../../atoms/TextInputWithButton/TextInputWithButton'
import './AddToProjectModal.css'
import { useEffect } from 'react'

export interface MinimalProject {
  id: string
  name: string
  isDefault: boolean
}

export interface AddToProjectModalProps {
  isOpen: boolean
  onCancel: () => void
  onSubmit: (projectsIds: string[]) => void
  productVariationProps: CardProductVariationProps
  ownProjects: MinimalProject[]
  sharedProjects: MinimalProject[]
  projectLikeChecker: (projectId: string, productVarationId?: string, userProductId?: string) => boolean
  newProjectNameChecker: (newProjectName: string) => boolean
  onNewProject: (newProjectName: string) => Promise<boolean>
}

export const AddToProjectModal: React.FC<AddToProjectModalProps> = ({
  isOpen,
  onCancel,
  onSubmit,
  productVariationProps,
  ownProjects,
  sharedProjects,
  projectLikeChecker,
  newProjectNameChecker,
  onNewProject,
}) => {
  const { t } = useTranslation()

  // search
  const [searchValue, setSearchValue] = useState<string>('')
  const handleSearchChange = useCallback((searchValue: string) => {
    setSearchValue(searchValue)
  }, [])

  // selection
  const [selectedProjects, setSelectedProjects] = useState<MinimalProject[]>([])

  const handleProjectClick = useCallback(
    (project: MinimalProject) => {
      if (!selectedProjects.includes(project)) {
        setSelectedProjects([...selectedProjects, project])
      } else {
        setSelectedProjects(selectedProjects.filter((el) => el.id !== project.id))
      }
    },
    [selectedProjects]
  )

  // project creation
  const [newProjectName, setNewProjectName] = useState<string>('')
  const [isNewProjectNameValid, setIsNewProjectNameValid] = useState<boolean>(false)
  useEffect(() => {
    const result = newProjectNameChecker(newProjectName)
    if (result !== isNewProjectNameValid) setIsNewProjectNameValid(result)
  }, [newProjectName, newProjectNameChecker, isNewProjectNameValid])

  const handleNewProjectCreation = useCallback(async () => {
    const success = await onNewProject(newProjectName)
    if (success) setNewProjectName('')
  }, [newProjectName, setNewProjectName, onNewProject])

  // cancel
  const handleCancel = useCallback(() => {
    setSelectedProjects([])
    setSearchValue('')
    onCancel()
  }, [onCancel])

  const submitMessage = t('addProductVariationToProject.confirm')
    .replace('{count}', selectedProjects.length.toString())
    .replace(/\n/g, '  \n') // markdown carriage return

  // submit
  const handleSubmit = useCallback(() => {
    onSubmit(selectedProjects.map((project) => project.id))
    handleCancel()
  }, [selectedProjects, onSubmit, handleCancel])

  // DOM
  const createProjectList = (ownedProjects: boolean) => {
    const projectList = ownedProjects ? ownProjects : sharedProjects

    return projectList.map((project) => {
      const projectName = project.isDefault ? t('project.defaultProjectName') : project.name
      if (projectName.search(new RegExp(searchValue, 'i')) === -1) return null

      const isLocked = projectLikeChecker(project.id, productVariationProps.productVariationId, productVariationProps.userProductId)

      return (
        <button
          key={project.id}
          className={
            'project-item ' +
            (isLocked ? 'light-txt' : '') +
            (!isLocked && selectedProjects.includes(project) ? 'selected' : '')
          }
          onClick={() => (isLocked ? null : handleProjectClick(project))}
          disabled={isLocked}
        >
          <>
            {project.isDefault ? <DefaultProjectIcon /> : <UserProjectIcon />}
            <span>{projectName}</span>
            {isLocked ? (
              <div className={'check-icon'}>
                <CheckedIcon />
              </div>
            ) : null}
          </>
        </button>
      )
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      header={t('addProductVariationToProject.header')}
      cancelButton={t('addProductVariationToProject.cancel')}
      validateButton={submitMessage}
      onCancel={handleCancel}
      onValidate={handleSubmit}
      isValidateButtonLocked={selectedProjects.length === 0}
      className={'add-to-projects-modal'}
    >
      <div>
        <div className={'card-wrapper'}>
          <div>
            <CardProductVariation
              {...productVariationProps}
              withLikeButton={false}
              withBuyButton={false}
            />
          </div>
        </div>
        <div className={'projects-wrapper'}>
          <SearchBar
            placeholder={t('addProductVariationToProject.searchPlaceholder')}
            size={'medium'}
            onChange={handleSearchChange}
          />
          <div className={'projects-list'}>
            <>
              <h4>Mes projets</h4>
              {createProjectList(true)}
              <h4>Partagés avec moi</h4>
              {createProjectList(false)}
            </>
          </div>

          <div className={'new-project-wrapper'}>
            <TextInputWithButton
              picto={<UserProjectIcon />}
              placeholder={t('projectCreationButton.placeholder')}
              value={newProjectName}
              onChange={setNewProjectName}
              buttonText={t('projectCreationButton.confirm')}
              onSubmit={handleNewProjectCreation}
              isLocked={!isNewProjectNameValid}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
