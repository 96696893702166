import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { RequestStatus } from '../utils/reqStatus'
import { useStoreState } from '../store/hooks'
import { BaseDesktopLayout } from '../components/organisms/BaseDesktopLayout/BaseDesktopLayout'
import { CenteredLoading } from '../components/molecules/CenteredLoading/CenteredLoading'
import { HeaderTabs } from '../components/molecules/DesktopHeader/DesktopHeader'
import { useIonViewDidEnter } from '@ionic/react'
import { BackButtonEvent } from '@ionic/core';
import config from '../config';
import styled from 'styled-components'
import { Button } from '../components/atoms/Button/Button'
import { ProjectCardsContainer } from '../components/organisms/ProjectCardsContainer/ProjectCardsContainer'
import { Select, SelectOption } from '../components/atoms/Select/Select'
import { Ordering } from '@fynde/dtos'
import { ProjectCreationModal } from '../components/organisms/ProjectCreationModal/ProjectCreationModal'
import { SearchBar } from '../components/atoms/SearchBar/SearchBar'
import { ReactComponent as BlocksViewIcon } from '../assets/icons/block-view-icon.svg'
import { ReactComponent as ListViewIcon } from '../assets/icons/list-view-icon.svg'
import { ProjectsList } from '../components/organisms/ProjectsList/ProjectsList'
import { ProjectProperty } from '../store/projects'

const CenteredContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const Section = styled.div`
  margin-bottom: 5rem;
`
const SectionTitle = styled.h2`
  margin-bottom: 1.2em;
`
const StyledButton = styled(Button)`
  margin: 2rem 0 1rem 0;
`
const SearchBarContainer = styled.div`
  width: calc(400px + 1rem);
  margin: 0 auto 2.5rem auto;
`
const CardsContainer = styled.div`
  width: max-content;
`
const OrderingSelectContainer = styled.div`
  margin: 0 auto 0.5rem auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > .svg-button {
    height: 2.5rem;
    width: 2.5rem;
    margin-left: 2px;
    cursor: pointer
  }
  & > .svg-button.selected {
    background-color: rgba(0,0,0,0.05);
  }
  & > .svg-button:hover {
    background-color: rgba(0,0,0,0.05);
  }
  & > .svg-button.selected:hover {
    background-color: rgba(0,0,0,0.1);
  }
  & > .svg-button > svg {
    height: 1.4rem;
    width: 1.4rem;
    margin: 0.55rem;
  }
  & > .svg-button > svg .st0 {
    stroke-width: 1;
    stroke: ${(props) => props.theme.colors.black}
  }
`

enum ProjectsView {
  Blocks = 0,
  List = 1
}

export interface ProjectsProps {
  onBackClicked?: () => void
}
const Projects: React.FC<ProjectsProps> = ({ 
  onBackClicked
}) => {
  const { t } = useTranslation()

  // title
  useIonViewDidEnter(() => {
    document.title = config.pageTitlePrefix + t('pageTitle.Projects');
  }, [t])

  // view
  const [view, setView] = useState<ProjectsView>(ProjectsView.Blocks)

  // ordering
  const orderingOptions: SelectOption[] = [
    { displayedText: t('ordering.creationDateDesc'), value: Ordering.CreationDateDesc },
    { displayedText: t('ordering.creationDateAsc'), value: Ordering.CreationDateAsc },
    { displayedText: t('ordering.nameAsc'), value: Ordering.NameAsc },
    { displayedText: t('ordering.nameDesc'), value: Ordering.NameDesc },
  ]
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(orderingOptions[0])
  const [currentOrder, setCurrentOrder] = useState<Ordering>(selectedOption!.value as number)

  const handleOrderingChange = useCallback((newOrder: SelectOption) => {
    setSelectedOption(newOrder)
    setCurrentOrder(newOrder.value as number)
  }, [])

  // user data
  const ownProjects = useStoreState((state) => state.projects.orderedProjects(currentOrder, ProjectProperty.Own));
  const sharedProjects = useStoreState((state) => state.projects.orderedProjects(currentOrder, ProjectProperty.Shared));
  const projectsLoadingStatus = useStoreState((state) => state.projects.projectsLoadingStatus);

  // project creation
  const [projectCreationAlertState, setProjectCreationAlertState] = useState<boolean>(false)

  // search
  const [searchValue, setSearchValue] = useState<string>('')
  const handleSearchChange = useCallback((searchValue: string) => {
    setSearchValue(searchValue)
  }, [])

  const displayedOwnProjects = useMemo(() => {
    return ownProjects.filter((project) => {
      const projectName = project.isDefault
          ? t('project.defaultProjectName')
          : project.name
      return projectName.search(new RegExp(searchValue, 'i')) > -1
    })
  }, [ownProjects, searchValue, t])

  const displayedSharedProjects = useMemo(() => {
    return sharedProjects.filter((project) => {
      const projectName = project.isDefault
          ? t('project.defaultProjectName')
          : project.name
      return projectName.search(new RegExp(searchValue, 'i')) > -1
    })
  }, [sharedProjects, searchValue, t])
  
  // Android back button
  const handleBackButton = useCallback(
    (ev: BackButtonEvent) => {
      ev.preventDefault()
      ev.detail.register(10, () => {
        if (onBackClicked !== undefined) onBackClicked()
      });
    }, [onBackClicked]
  )

  useEffect(() => {
    document.addEventListener('ionBackButton', handleBackButton as EventListener);
    return () => {
      document.removeEventListener('ionBackButton', handleBackButton as EventListener);
    }
  }, [handleBackButton])

  return (
    <BaseDesktopLayout selectedHeaderTab={HeaderTabs.Projects}>
      
      {![RequestStatus.Failed, RequestStatus.Completed].includes(projectsLoadingStatus)  ? (

        <CenteredLoading height={'calc(100vh - 4rem - 4rem)'}/>

      ) : (
        <>
          <ProjectCreationModal
            isOpen={!!projectCreationAlertState}
            onCancel={() => setProjectCreationAlertState(false)}
          />

          <CenteredContainer>
            <StyledButton onClick={() => setProjectCreationAlertState(true)} fontSize={"large"}>
              {t('projects.newProjectButton')}
            </StyledButton>
          </CenteredContainer>

          {ownProjects.length >= 3 && (
            <SearchBarContainer>
              <SearchBar
                placeholder={t('addProductVariationToProject.searchPlaceholder')}
                onChange={handleSearchChange}
                size={'large'}
              />
            </SearchBarContainer>
          )}

          {ownProjects.length >= 3 && (
            <OrderingSelectContainer>
              <Select
                options={orderingOptions}
                selectedOption={selectedOption}
                onChange={(newSelection) => handleOrderingChange(newSelection!)}
                isClearable={false}
                fontSize={'small'}
                choicesAnchor={'right'}
              />
              <div
                className={`svg-button${view === ProjectsView.Blocks ? ' selected' : ''}`}
                onClick={() => setView(ProjectsView.Blocks)}
              >
                <BlocksViewIcon />
              </div>
              <div
                className={`svg-button${view === ProjectsView.List ? ' selected' : ''}`}
                onClick={() => setView(ProjectsView.List)}
              >
                <ListViewIcon />
              </div>
            </OrderingSelectContainer>
          )}

          <Section>
            <SectionTitle>
              {t('projects.ownProjectsTitle')}
            </SectionTitle>
            <CardsContainer>
              {view === ProjectsView.Blocks ? (
                <ProjectCardsContainer projects={displayedOwnProjects}/>
              ) : (
                <ProjectsList projects={displayedOwnProjects}/>
              )}
            </CardsContainer>
          </Section>

          <Section>
            <SectionTitle>
              {t('projects.sharedProjectsTitle')}
            </SectionTitle>
            <CardsContainer>
              {view === ProjectsView.Blocks ? (
                <ProjectCardsContainer projects={displayedSharedProjects}/>
              ) : (
                <ProjectsList projects={displayedSharedProjects}/>
              )}
            </CardsContainer>
          </Section>
        </>
      )}
    </BaseDesktopLayout>
  )
}

export default Projects
