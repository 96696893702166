import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState, useStoreActions } from '../../../store/hooks'
import { useInterval } from '../../../utils/useInterval'
import { ProductsSearchBar } from '../../molecules/ProductsSearchBar/ProductsSearchBar'
import { ContainerCardProductVariation } from '../ContainerCardProductVariation/ContainerCardProductVariation'
import { RequestStatus } from '../../../utils/reqStatus'
import 'react-circular-progressbar/dist/styles.css'
import './SearchLayout.css'
import { Loading } from '../../atoms/Loading/Loading'

const POLLING_INTERVAL = 1000

export interface SearchLayoutProps {
  onBottomHit?: () => void
}
export const SearchLayout: React.FC<SearchLayoutProps> = ({ onBottomHit }) => {
  const { t } = useTranslation()

  // visual-search
  const objectDetectionStatus = useStoreState((state) => state.computerVision.objectDetectionStatus)
  const visualSearchStatus = useStoreState((state) => state.computerVision.visualSearchStatus)
  const fetchOD = useStoreActions((actions) => actions.computerVision.fetchObjectDetectionResults)
  const fetchVS = useStoreActions((actions) => actions.computerVision.fetchVisualSearchResults)

  // search results
  const searchStatus = useStoreState((actions) => actions.search.reqStatus)
  const searchMoreStatus = useStoreState((actions) => actions.search.reqStatusMore)
  const results = useStoreState((actions) => actions.search.results)
  const resultsCount = useStoreState((actions) => actions.search.total)
  const areAllResultsLoaded = useStoreState((state) => state.search.areAllResultsLoaded)

  // Loading and results
  useInterval(
    async () => {
      console.debug('[SearchLayout] refetch object-detection results')
      await fetchOD()
    },
    objectDetectionStatus === RequestStatus.Failed ? POLLING_INTERVAL : null
  )

  useInterval(
    async () => {
      console.debug('[SearchLayout] refetch visual-search results')
      await fetchVS()
    },
    visualSearchStatus === RequestStatus.Failed ? POLLING_INTERVAL : null
  )

  const noSearchYet = useMemo(() => {
    return searchStatus === RequestStatus.Idle
  }, [searchStatus])

  const isSearching = useMemo(() => {
    return searchStatus === RequestStatus.Loading
  }, [searchStatus])

  const isSearchingMore = useMemo(() => {
    return searchMoreStatus === RequestStatus.Loading
  }, [searchMoreStatus])

  const hasSearchFailed = useMemo(() => {
    return searchStatus === RequestStatus.Failed
  }, [searchStatus])

  return (
    <>
      <div className={'search-layout'}>
        {/* Search bar */}
        <ProductsSearchBar />

        {/* Results */}
        <div className={'results-wrapper'}>
          {/* Results header */}
          <div className={'results-header'}>
            {noSearchYet ? (
              <p className={'feedback-txt light-txt'}>{t('search.noSearch')}</p>
            ) : isSearching ? (
              <Loading />
            ) : hasSearchFailed ? (
              <p className={'feedback-txt'}>{t('search.fail')}</p>
            ) : (
              <p className={'results-count'}>
                {!resultsCount || resultsCount === 0
                  ? t('search.noResults')
                  : resultsCount === 1
                  ? '1 ' + t('search.results.singular')
                  : (resultsCount > 500 ? '+500' : resultsCount) + ' ' + t('search.results.plural')}
              </p>
            )}
          </div>

          {/* Results cards */}
          {!!results && results.length > 0 && (
            <div className={'results'}>
              {results.map((result) => (
                <ContainerCardProductVariation key={result.id} data={result} />
              ))}
            </div>
          )}

          {/* Fetching more */}
          {isSearchingMore && (
            <div className={'fetch-more-wrapper'}>
              <Loading />
            </div>
          )}

          {/* All results loaded */}
          {areAllResultsLoaded && (
            <div className={'all-results-wrapper'}>
              <p className={'feedback-txt light-txt'}>{t('search.allResults')}</p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
