import { IonPage, IonContent } from '@ionic/react'
import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import { BaseAppLayout } from '../components/organisms/BaseAppLayout/BaseAppLayout'
import { FilterContainer } from '../components/organisms/FilterContainer/FilterContainer'
import { useTranslation } from 'react-i18next'
import { PriceRange } from '../components/organisms/PriceFilterCard/PriceFilterCard'
import { useStoreActions } from '../store/hooks'

const Filters: React.FC = () => {
  const history = useHistory()

  const { t } = useTranslation()

  const [priceRange, setPriceRange] = useState<PriceRange | undefined>(undefined)
  const [colorTagIds, setColorTagIds] = useState<string[] | undefined>(undefined)
  const [materialTagIds, setMaterialTagIds] = useState<string[] | undefined>(undefined)
  const setNewFilters = useStoreActions((actions) => actions.filters.setNewFilters)

  const onApplyFilterClicked = useCallback(() => {
    console.debug('[Filters] onApplyFilterClicked')
    setNewFilters({
      selectedPriceRange: priceRange,
      selectedColorTagIds: colorTagIds,
      selectedMaterialTagIds: materialTagIds,
    })
    history.goBack()
  }, [history, priceRange, colorTagIds, materialTagIds, setNewFilters])

  const onPriceChange = (range: PriceRange) => {
    console.debug('[Filters] onPriceChange')
    setPriceRange(range);
  }

  const onColorChange = (tagIds: string[]) => {
    console.debug('[Filters] onColorChange')
    setColorTagIds(tagIds);
  }

  const onMaterialChange = (tagIds: string[]) => {
    console.debug('[Filters] onMaterialChange')
    setMaterialTagIds(tagIds);
  }

  return (
    <IonPage>
      <IonContent style={{ position: 'relative' }}>
        <BaseAppLayout 
          noPaddingTop={true}
          onBackClicked={onApplyFilterClicked}
          label={t('filters.title')}
        >
          <FilterContainer 
            onPriceChange={onPriceChange}
            onColorChange={onColorChange}
            onMaterialChange={onMaterialChange}
          />
        </BaseAppLayout>
      </IonContent>
    </IonPage>
  )
}

export default Filters
