import React, { useMemo, useCallback, useState } from 'react'
import { IonRange } from '@ionic/react'
import { CardFilter } from '../../molecules/FilterCard/FilterCard'
import { useTranslation } from 'react-i18next'
import './PriceFilterCard.css'

export interface PriceRange {
  min: number,
  max: number,
}

export interface PriceFilterCardProps {
  values: number[]
  selectedRange: PriceRange
  onClear?: () => void
  onRangeChange?: (range: PriceRange) => void
  onMouseUp?: (range: PriceRange) => void
}

export const PriceFilterCard: React.FC<PriceFilterCardProps> = ({ 
  values,
  selectedRange,
  onClear,
  onRangeChange,
  onMouseUp,
}) => {
  const { t } = useTranslation()

  const minValue = useMemo(() => values[0], [values])
  const maxValue = useMemo(() => values[values.length - 1], [values])
  const [selectedPriceRange, setSelectedPriceRange] = useState(selectedRange)

  const selected = useMemo(() => {
    console.debug('[PriceFilterCard] update selected values')
    return {
      lower: values.indexOf(selectedPriceRange.min),
      upper: values.indexOf(selectedPriceRange.max),
    }
  }, [values, selectedPriceRange])

  // events
  const handleRangeChange = useCallback((newRange: { lower: number; upper: number }) => {
    console.debug('[PriceFilterCard] handleRangeChange')
    var min = values[newRange.lower]
    var max = values[newRange.upper]
    if (min !== selectedPriceRange.min || max !== selectedPriceRange.max) {
      setSelectedPriceRange({ min: min, max: max })
      if (onRangeChange)
        onRangeChange({ min: min, max: max })
    }
  }, [values, selectedPriceRange, onRangeChange])

  // Seems not to be fired if the MouseUp occurs outside of the knob after dragging it
  const handleMouseUp = useCallback(() => {
    console.debug('[PriceFilterCard] handleMouseUp')
    if (onMouseUp)
      onMouseUp(selectedPriceRange)
  }, [onMouseUp, selectedPriceRange])

  const handleClear = useCallback(() => {
    console.debug('[PriceFilterCard] handleClear')
    setSelectedPriceRange({
      min: minValue,
      max: maxValue,
    })
    if (onClear)
      onClear()
  }, [onClear, minValue, maxValue])

  return (
    <CardFilter
      className={'price-filter-card'}
      title={t('filters.price.title')}
      onClearCliked={handleClear}
      disabled={selected.lower === 0 && selected.upper === values.length - 1}
    >
      <IonRange
        dualKnobs
        value={selected}
        snaps={true}
        min={0}
        max={values.length - 1}
        step={1}
        ticks={false}
        mode={'ios'}
        onIonChange={(event) =>
          handleRangeChange(event.detail.value as { lower: number; upper: number })
        }
        onMouseUp={handleMouseUp}
      ></IonRange>
      <div className={'labels'}>
        <span>
          {values[selected.lower]} €
        </span>
        <span>
          {
            `${values[selected.upper] === maxValue ? '+ ' : ''}
            ${values[selected.upper]} €`
          }
        </span>
      </div>
    </CardFilter>
  )
}
