import axiosApi from '../utils/axios'
import {
  UserProjectProductOpinionCreationDTO,
  UserProjectProductOpinionDTO,
  UserProjectProductOpinionsDTO,
  UserProjectProductOpinionUpdateDTO,
} from '@fynde/dtos'

// GET
export const getUserProjectProductOpinions = async (
  userToken: string,
  userProjectId: string
): Promise<UserProjectProductOpinionsDTO[] | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    params: {
      projectId: userProjectId,
    },
  }
  try {
    const resp = await axiosApi.get<UserProjectProductOpinionsDTO[]>(
      `/user-project-product-opinion/`,
      config
    )
    return resp.data
  } catch (err) {
    console.error('An error occured while getting user-project-product-opinions:', err)
  }
  return null
}

// POST
export const postUserProjectProductOpinion = async (
  userToken: string,
  dto: UserProjectProductOpinionCreationDTO
): Promise<UserProjectProductOpinionDTO | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.post<UserProjectProductOpinionDTO>(
      `/user-project-product-opinion`,
      dto,
      config
    )
    return resp.data
  } catch (err) {
    console.error('An error occured while posting a new user-project-product-opinion:', err)
  }
  return null
}

// PATCH
export const patchUserProjectProductOpinion = async (
  userToken: string,
  dto: UserProjectProductOpinionUpdateDTO
): Promise<UserProjectProductOpinionDTO | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.patch<UserProjectProductOpinionDTO>(
      `/user-project-product-opinion/`,
      dto,
      config
    )
    return resp.data
  } catch {
    console.error('An error occured while patching a user-project-product-opinion')
    return null
  }
}
