import { IonPage, useIonViewDidEnter } from '@ionic/react'
import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { Loading } from '../components/atoms/Loading/Loading'
import { LanguageSelect } from '../components/molecules/LanguageSelect/LanguageSelect'
import { useTranslation } from 'react-i18next'
import axiosApi from '../utils/axios'
import { Routes } from '../utils/Routes'
import config from '../config';
import { EmailValidationResponseDTO } from '@fynde/dtos'

const CenteredContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  line-height: 1.35em;

  @media (max-width: 480px) {
    padding: 0 1rem;
  }
`
const LanguageSelectContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`
const Logo = styled.img`
  width: 230px;
  margin-bottom: 1.5em;
`
const Title = styled.h2`
  font-size: 2.0em;
  font-weight: 700;
  margin-bottom: 0.8em;
  text-align: center;
`
const Info = styled.p `
  color: ${(props) => props.theme.colors.black};
  width: 400px;
  margin: 0 0 2em 0;
  text-align: center;

  @media (max-width: 480px) {
    width: 100%;
  }
`
const Line = styled.p `
  margin: 0 0 0.8em 0;
`
const Link = styled.a`
  color: ${(props) => props.theme.colors.black};
  font-weight: 700;
`

const EmailValidation: React.FC = () => {
  const { t } = useTranslation()

  // title
  useIonViewDidEnter(() => {
    document.title = config.pageTitlePrefix + t('pageTitle.EmailValidation');
  }, [t])

  // token

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const [isTokenValid, setTokenValidity] = useState<boolean | undefined>(undefined);
  const [accountEmail, setAccountEmail] = useState<string | undefined>(undefined);

  useMemo( async () => {
    try {
      console.debug("[EmailValidation] send token validation request...");
      const resp = await axiosApi.get<EmailValidationResponseDTO>(`/auth/validate-email?token=${token}`);
      setTokenValidity(resp.data.success);
      if (resp.data.success === true) setAccountEmail(resp.data.email);
    } catch (err) {
      console.error("[EmailValidation] error when validating account:", err);
    }
  }, [token])

  return (
    <IonPage>
      <CenteredContainer>
          <Logo src="assets/branding/logo_fynde_noMargin_500px.png" alt="Fynde logo"/>
          
          <Title>{t('emailValidation.title')}</Title>

          {isTokenValid === undefined ? (
            <>
              <Loading/>
            </>
          ) : (
            isTokenValid === false ? (
              <>
                <Info>{t('emailValidation.invalidToken.info')}</Info>
              </>
            ) : (
              <>
                <Info>{t('emailValidation.info')}</Info>
                <Line>
                  <Link href={`${Routes.Login}?email=${accountEmail}`}>{t('link.logIn.access')}</Link>
                </Line>
              </>
            )
          )}
          
        </CenteredContainer>

      <LanguageSelectContainer>
        <LanguageSelect />
      </LanguageSelectContainer>
    </IonPage>
  )
}

export default EmailValidation
