import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Plugins, CameraResultType, CameraSource, CameraPhoto } from '@capacitor/core'
import { useStoreActions } from '../../../store/hooks'
import { useTranslation } from 'react-i18next'
import Mixpanel, { MixpanelEvents } from '../../../utils/mixpanel'
import { ImageSourceChooser } from '../ImageSourceChooser/ImageSourceChooser'
import { CenteredLoading } from '../../molecules/CenteredLoading/CenteredLoading'
import { Routes } from '../../../utils/Routes'
import { AppHomeLayout } from '../../molecules/AppHomeLayout/AppHomeLayout'

const { Camera } = Plugins

export type HomeLayoutProps = {
  onHistoryClicked: () => void
  onSavedCliked: () => void
}

export const HomeLayout: React.FC<HomeLayoutProps> = ({
  onHistoryClicked,
  onSavedCliked,
}) => {
  const { t } = useTranslation()

  let history = useHistory()

  const clearComputerVision = useStoreActions(
    (actions) => actions.computerVision.clearComputerVision
  )

  const clearFiltersWithoutFetch = useStoreActions(
    (actions) => actions.filters.clearFiltersWithoutFetch
  )
  const setResults = useStoreActions((actions) => actions.search.setResults)
  const setReqFile = useStoreActions((actions) => actions.computerVision.setReqFile)

  const [isChoiceRequested, setIsChoiceRequested] = useState(false);
  const [isImageProcessing, setIsImageProcessing] = useState(false);

  const launchVisualSearchFromBase64 = useCallback(
    async (source: CameraSource) => {
      setIsChoiceRequested(false);
      Mixpanel.track(MixpanelEvents.UserImageChosen, {
        'source': source === CameraSource.Camera ? 'camera' : 'gallery',
      })

      setIsImageProcessing(true)
      
      let photo = null;
      try {
        photo = (await Camera.getPhoto({
          resultType: CameraResultType.DataUrl,
          source: source,
          quality: 100,
        })) as CameraPhoto
      } catch (error) {
        console.error(error)
      }

      if (photo?.dataUrl) {
        clearFiltersWithoutFetch()
        setResults([])
        clearComputerVision()
        setReqFile(photo.dataUrl)
        history.push(Routes.Search)
      }

      setIsImageProcessing(false)
    },
    [history, clearFiltersWithoutFetch, clearComputerVision, setReqFile, setResults]
  )

  function handleImageSourceChooserBackButton() {
    setIsChoiceRequested(false);
  }

  return (
    <>
      {!isChoiceRequested && !isImageProcessing && (
        <AppHomeLayout
          onTapToFind={() => setIsChoiceRequested(true)}
          onLikesClick={onSavedCliked}
          onHistoryClick={onHistoryClicked}
        />
      )}

      {isChoiceRequested && (
        <ImageSourceChooser
          cameraLabel={t('homeLayout.camera')}
          galleryLabel={t('homeLayout.gallery')}
          callback={launchVisualSearchFromBase64}
          onBackClicked={handleImageSourceChooserBackButton}
        />
      )}

      {isImageProcessing && <CenteredLoading />}
    </>
  )
}
