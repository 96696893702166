import axiosApi from '../utils/axios'
import {
  UserProjectProfessionalCreationDTO,
  UserProjectProfessionalDeletionDTO,
  UserProjectProfessionalDTO,
  UserProjectProfessionalFilterDTO,
  UserProjectProfessionalsDTO,
  UserProjectProfessionalUpdateDTO,
} from '@fynde/dtos'

// POST
export const postUserProjectProfessional = async (
  userToken: string,
  dto: UserProjectProfessionalCreationDTO
): Promise<UserProjectProfessionalDTO | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.post<UserProjectProfessionalDTO>(
      `/user-project-professional/`,
      dto,
      config
    )
    return resp.data
  } catch (err) {
    console.error('An error occured while posting a new user-project-professional:', err)
  }
  return null
}

//GET
export const getUserProjectProfessionals = async (
  userToken: string,
  userProjectId: string
): Promise<UserProjectProfessionalsDTO | null> => {
  const dto: UserProjectProfessionalFilterDTO = {
    userProjectId,
  }
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    params: dto,
  }
  try {
    const resp = await axiosApi.get<UserProjectProfessionalsDTO>(
      `/user-project-professional/`,
      config
    )
    return resp.data
  } catch (err) {
    console.error('An error occured while getting a user-project-professional:', err)
  }
  return null
}

// PATCH
export const patchUserProjectProfessional = async (
  userToken: string,
  dto: UserProjectProfessionalUpdateDTO
): Promise<UserProjectProfessionalDTO | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.patch<UserProjectProfessionalDTO>(
      `/user-project-professional/`,
      dto,
      config
    )
    return resp.data
  } catch {
    console.error('An error occured while patching a user-project-professional')
    return null
  }
}

// DELETE
export const deleteUserProjectProfessional = async (
  userToken: string,
  dto: UserProjectProfessionalDeletionDTO
): Promise<boolean> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data: dto,
  }
  try {
    await axiosApi.delete(`/user-project-professional/`, config)
    return true
  } catch (err) {
    console.error('An error occured while deleting a user-project-professional:', err)
    return false
  }
}
