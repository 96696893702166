import React from 'react'
import loader from '../../../assets/loader_160px.gif'
import './Loading.css'

export interface LoadingProps {
  isLight?: boolean
  className?: string
}

export const Loading: React.FC<LoadingProps> = ({
  isLight,
  className,
}) => (
  <img
    width="80px"
    height="80px"
    src={loader}
    alt="loading"
    className={'loader'
      + (className ? ' ' + className : '')
      + (isLight ? ' light' : '')
    }
  />
)
