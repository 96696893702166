import React, { useState, useCallback } from 'react'
import { IonAlert } from '@ionic/react'
import { ProductVariationDTO } from '@fynde/dtos'
import {
  CardProductVariationProps,
  CardProductVariation,
} from '../CardProductVariation/CardProductVariation'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { useTranslation } from 'react-i18next'
import { AddToProjectModalWrapper } from '../AddToProjectModal/AddToProjectModalWrapper'

export const mapDTOToProps = (dto: ProductVariationDTO): CardProductVariationProps => {
  return {
    productVariationId: dto.id,
    brandId: dto.product.brand?.id,
    productId: dto.product.id,
    eshopProductVariationId: dto.eshopProductVariation?.id,
    displayName: dto.displayName,
    brandName: dto.product.brand?.name,
    buyLink: dto.eshopProductVariation?.url,
    price: dto.eshopProductVariation?.price,
    thumbnail: dto.thumbnail?.media.publicUrl,
    thumbnailBgColor: dto.thumbnail?.hexaColor || undefined
  }
}

export const ContainerCardProductVariation: React.FC<{ data: ProductVariationDTO }> = ({
  data,
}) => {
  const { t } = useTranslation()

  const props = mapDTOToProps(data)

  const addItemToFavorites = useStoreActions((actions) => actions.saved.addItem)
  const removeItemFromFavorites = useStoreActions((actions) => actions.saved.removeItem)
  const isLike = useStoreState((actions) => actions.saved.isLike(data.id))

  // add to projects
  const [addToProjectAlertState, setAddToProjectAlertState] = useState(false)

  const handleProjectClick = useCallback(() => {
    setAddToProjectAlertState(true)
  }, [])

  // like
  const [unlikeAlertState, setUnlikeAlertState] = useState(false)

  const onLikeClick = () => {
    return isLike ? setUnlikeAlertState(true) : addItemToFavorites(data)
  }

  const onUnlikeConfirmed = useCallback(() => {
    removeItemFromFavorites(data.id)
  }, [removeItemFromFavorites, data])

  return (
    <>
      <IonAlert
        isOpen={!!unlikeAlertState}
        onDidDismiss={() => setUnlikeAlertState(false)}
        header={t('removalAlert.header')}
        message={t('removalAlert.fromSavedProducts')}
        buttons={[
          {
            text: t('removalAlert.cancel'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => setUnlikeAlertState(false),
          },
          {
            text: t('removalAlert.confirm'),
            handler: () =>
              unlikeAlertState
                ? onUnlikeConfirmed()
                : console.error(
                    "Cannot confirm removal from history as removal alert's state is false"
                  ),
          },
        ]}
      />

      <AddToProjectModalWrapper
        isOpen={!!addToProjectAlertState}
        onCancel={() => setAddToProjectAlertState(false)}
        productVariationProps={props}
      />

      <CardProductVariation
        {...props}
        onLikeClick={onLikeClick}
        onProjectClick={handleProjectClick}
        isLike={isLike}
      />
    </>
  )
}
