import React from 'react'
import styled from 'styled-components'

export interface InformationSectionProps {
  title?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 0.05rem;
  }
`

const Section = styled.div``

const Title = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  line-height: 33px;
  margin: 0px;
  padding: 0px;
`

export const InformationSection: React.FC<InformationSectionProps> = ({ title, children }) => {
  return (
    <Container>
      {title && (
        <Section>
          <Title>{title}</Title>
        </Section>
      )}
      <Section>{children}</Section>
    </Container>
  )
}
