import { Thunk, thunk, action, Action, Computed, computed } from 'easy-peasy'
import {
  UserMessageDTO,
  UserMessageLightDTO,
  UserProjectMessageLightDTO,
  UserProjectProductMessageLightDTO,
} from '@fynde/dtos'
import { StoreModel } from './store'
import {
  deleteUserMessage,
  fetchUserProjectMessages,
  fetchUserProjectProductMessages,
  patchUserMessage,
  postUserProjectMessage,
  postUserProjectProductMessage,
} from '../services/user-messages'
import { LocalUserFile } from './user-files'
import { RequestStatus } from '../utils/reqStatus'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'

export interface UserMessagesModel {
  // store
  projectMessages: UserProjectMessageLightDTO[]
  projectProductMessages: UserProjectProductMessageLightDTO[]
  loadingStatus: { projectId: string; status: RequestStatus }[]

  // computed
  userMessage: Computed<
    UserMessagesModel,
    (userMessageId: string) => UserMessageDTO | null,
    StoreModel
  >
  userMessages: Computed<
    UserMessagesModel,
    (userProjectId: string | null, userProjectProductId: string | null) => UserMessageDTO[],
    StoreModel
  >
  projectLoadingStatus: Computed<UserMessagesModel, (projectId: string) => RequestStatus | null>

  // actions
  _addProjectMessage: Action<UserMessagesModel, UserProjectMessageLightDTO>
  _addProjectProductMessage: Action<UserMessagesModel, UserProjectProductMessageLightDTO>
  _patchUserMessage: Action<
    UserMessagesModel,
    { userMessageId: string; text: string; updatedAt: Date | string }
  >
  _removeUserMessage: Action<UserMessagesModel, string>
  _removeUserMessageFile: Action<UserMessagesModel, { userMessageId: string; userFileId: string }>

  // update local store
  addProjectMessage: Thunk<UserMessagesModel, UserProjectMessageLightDTO, any>
  addProjectProductMessage: Thunk<UserMessagesModel, UserProjectProductMessageLightDTO, any>
  modifyUserMessage: Thunk<
    UserMessagesModel,
    { userMessageId: string; text: string; updatedAt: Date | string },
    any,
    Promise<UserMessageDTO | null>
  >
  removeUserMessage: Thunk<UserMessagesModel, string, any>

  // with api calls
  postProjectMessage: Thunk<
    UserMessagesModel,
    { projectId: string; message: string; files: LocalUserFile[] },
    any,
    StoreModel,
    Promise<UserMessageLightDTO | null>
  >
  postProjectProductMessage: Thunk<
    UserMessagesModel,
    { userProjectProductId: string; message: string; files: LocalUserFile[] },
    any,
    StoreModel,
    Promise<UserMessageLightDTO | null>
  >
  patchUserMessage: Thunk<
    UserMessagesModel,
    { userMessageId: string; text: string },
    any,
    StoreModel,
    Promise<UserMessageLightDTO | null>
  >
  deleteUserMessage: Thunk<UserMessagesModel, string, any, StoreModel, Promise<boolean>>
  deleteUserMessageFile: Thunk<
    UserMessagesModel,
    { userMessageId: string; userFileId: string },
    any,
    StoreModel,
    Promise<boolean>
  >
  fetchProjectUserMessages: Thunk<UserMessagesModel, string, any, StoreModel, Promise<boolean>>
}

export const userMessages: UserMessagesModel = {
  // store
  projectMessages: [],
  projectProductMessages: [],
  loadingStatus: [],

  // computed
  userMessage: computed(
    [(state) => state, (state, storeState) => storeState.userFiles],
    (state, userFiles) => {
      return (userMessageId) => {
        let lightResult = null
        for (const pm of state.projectMessages) {
          if (pm.userMessage.id === userMessageId) {
            lightResult = pm.userMessage
            break
          }
        }
        if (lightResult === null) {
          for (const ppm of state.projectProductMessages) {
            if (ppm.userMessage.id === userMessageId) {
              lightResult = ppm.userMessage
              break
            }
          }
        }
        if (lightResult === null) return null
        const result: UserMessageDTO = {
          ...lightResult,
          files: userFiles.userFiles(lightResult.userFilesIds),
        }
        return result
      }
    }
  ),

  userMessages: computed(
    [(state) => state, (state, storeState) => storeState.userFiles],
    (state, userFiles) => {
      return (userProjectId, userProjectProductId) => {
        const results: UserMessageDTO[] = []
        const lightResults =
          userProjectProductId === null
            ? state.projectMessages
                .filter((message) => message.projectId === userProjectId)
                .map((message) => message.userMessage)
            : state.projectProductMessages
                .filter((message) => message.userProjectProductId === userProjectProductId)
                .map((message) => message.userMessage)
        for (let result of lightResults) {
          results.push({
            ...result,
            files: userFiles.userFiles(result.userFilesIds),
          })
        }
        return results
      }
    }
  ),

  projectLoadingStatus: computed((state) => {
    return (projectId) => {
      for (const loadingStatus of state.loadingStatus) {
        if (loadingStatus.projectId === projectId) return loadingStatus.status
      }
      return null
    }
  }),

  // actions
  _addProjectMessage: action((state, item) => {
    if (state.projectMessages.some((msg) => msg.userMessage.id === item.userMessage.id)) return
    item.userMessage.createdAt = new Date(item.userMessage.createdAt)
    item.userMessage.updatedAt = new Date(item.userMessage.updatedAt)
    state.projectMessages = [...state.projectMessages, item]
  }),

  _addProjectProductMessage: action((state, item) => {
    if (state.projectProductMessages.some((msg) => msg.userMessage.id === item.userMessage.id))
      return
    item.userMessage.createdAt = new Date(item.userMessage.createdAt)
    item.userMessage.updatedAt = new Date(item.userMessage.updatedAt)
    state.projectProductMessages = [...state.projectProductMessages, item]
  }),

  _patchUserMessage: action((state, { userMessageId, text, updatedAt }) => {
    // search in user-project-messages
    for (const projectMessage of state.projectMessages) {
      if (projectMessage.userMessage.id === userMessageId) {
        projectMessage.userMessage.text = text
        projectMessage.userMessage.updatedAt = new Date(updatedAt)
        return
      }
    }
    // search in user-project-product-messages
    for (const projectProductMessage of state.projectProductMessages) {
      if (projectProductMessage.userMessage.id === userMessageId) {
        projectProductMessage.userMessage.text = text
        projectProductMessage.userMessage.updatedAt = new Date(updatedAt)
        return
      }
    }
  }),

  _removeUserMessage: action((state, userMessageId) => {
    // search in user-project-messages
    const projectMessagesCount = state.projectMessages.length
    state.projectMessages = state.projectMessages.filter(
      (item) => item.userMessage.id !== userMessageId
    )
    if (state.projectMessages.length !== projectMessagesCount) return
    // search in user-project-product-messages
    state.projectProductMessages = state.projectProductMessages.filter(
      (item) => item.userMessage.id !== userMessageId
    )
  }),

  _removeUserMessageFile: action((state, { userMessageId, userFileId }) => {
    // search in user-project-messages
    for (let projectMessage of state.projectMessages) {
      if (projectMessage.userMessage.id === userMessageId) {
        projectMessage.userMessage.userFilesIds = projectMessage.userMessage.userFilesIds.filter(
          (fileId) => fileId !== userFileId
        )
        return
      }
    }
    // search in user-project-product-messages
    for (let projectProductMessage of state.projectProductMessages) {
      if (projectProductMessage.userMessage.id === userMessageId) {
        projectProductMessage.userMessage.userFilesIds =
          projectProductMessage.userMessage.userFilesIds.filter((fileId) => fileId !== userFileId)
        return
      }
    }
  }),

  // Thunks
  addProjectMessage: thunk(async (actions, projectMessage) => {
    actions._addProjectMessage(projectMessage)
  }),

  addProjectProductMessage: thunk(async (actions, projectProductMessage) => {
    actions._addProjectProductMessage(projectProductMessage)
  }),

  modifyUserMessage: thunk(async (actions, { userMessageId, text, updatedAt }) => {
    actions._patchUserMessage({ userMessageId, text, updatedAt })
  }),

  removeUserMessage: thunk(async (actions, userMessageId) => {
    actions._removeUserMessage(userMessageId)
  }),

  postProjectMessage: thunk(
    async (actions, { projectId, message, files }, { getStoreState, getStoreActions }) => {
      const storeState = getStoreState()
      const userId = storeState.user.userId()
      const storeActions = getStoreActions()
      if (!userId || storeState.user.token === null) {
        console.error(
          "[store.user-messages] cannot post a new user-project-message because user's id or token is missing"
        )
        return null
      }
      // post user-files
      const userFiles = []
      for (const file of files) {
        const userFile = await storeActions.userFiles.postUserFile(file)
        if (userFile !== null) userFiles.push(userFile)
      }
      // post user-message
      const newMessage = await postUserProjectMessage(
        userId,
        storeState.user.token,
        projectId,
        message,
        userFiles.map((uf) => uf.id)
      )
      if (newMessage !== null) {
        actions._addProjectMessage(newMessage)

        Mixpanel.track(MixpanelEvents.ProjectMessageCreated, {
          projectId,
          characters: message.length,
          filesCount: files.length,
        })

        // remove local files
        const localFileIds = files.map((file) => file.id)
        if (localFileIds.length > 0) storeActions.userFiles.removeManyLocalUserFiles(localFileIds)

        return newMessage.userMessage
      } else {
        return null
      }
    }
  ),

  postProjectProductMessage: thunk(
    async (
      actions,
      { userProjectProductId, message, files },
      { getStoreState, getStoreActions }
    ) => {
      const storeState = getStoreState()
      const userId = storeState.user.userId()
      const storeActions = getStoreActions()
      if (!userId || !storeState.user.token) {
        console.error(
          "[store.user-messages] cannot post a new user-project-product-message because user's id or token is missing"
        )
        return null
      }
      // post user-files
      const userFiles = []
      for (const file of files) {
        const userFile = await storeActions.userFiles.postUserFile(file)
        if (userFile !== null) userFiles.push(userFile)
      }
      // post user-message
      const newMessage = await postUserProjectProductMessage(
        userId,
        storeState.user.token,
        userProjectProductId,
        message,
        userFiles.map((uf) => uf.id)
      )
      if (newMessage !== null) {
        actions._addProjectProductMessage(newMessage)

        Mixpanel.track(MixpanelEvents.ProjectProductMessageCreated, {
          projectId:
            storeState.projectProducts.projectProduct(userProjectProductId)?.projectId || '',
          projectProductId: userProjectProductId,
          characters: message.length,
          filesCount: files.length,
        })

        // remove local files
        const localFileIds = files.map((file) => file.id)
        if (localFileIds.length > 0) storeActions.userFiles.removeManyLocalUserFiles(localFileIds)

        return newMessage.userMessage
      } else {
        return null
      }
    }
  ),

  patchUserMessage: thunk(async (actions, { userMessageId, text }, { getStoreState }) => {
    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.user-messages] cannot patch a user-message because user's token is missing"
      )
      return null
    }
    const patchedMessage = await patchUserMessage(storeState.user.token, userMessageId, text)
    if (patchedMessage !== null) {
      actions._patchUserMessage({
        userMessageId: patchedMessage.id,
        text: patchedMessage.text,
        updatedAt: patchedMessage.updatedAt,
      })

      Mixpanel.track(MixpanelEvents.MessageUpdated, {
        userMessageId,
        characters: text.length,
      })

      return patchedMessage
    } else {
      return null
    }
  }),

  deleteUserMessage: thunk(async (actions, userMessageId, { getStoreState }) => {
    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.user-messages] cannot delete a user-message because user's token is missing"
      )
      return false
    }
    console.debug('[store.user-messages] delete user-message:', userMessageId)
    const success = await deleteUserMessage(storeState.user.token, userMessageId)
    if (success) {
      actions._removeUserMessage(userMessageId)

      Mixpanel.track(MixpanelEvents.MessageDeleted, {
        userMessageId,
      })
    }
    return success
  }),

  deleteUserMessageFile: thunk(
    async (
      actions,
      { userMessageId, userFileId },
      { getState, getStoreState, getStoreActions }
    ) => {
      const storeState = getStoreState()
      if (storeState.user.token === null) {
        console.error(
          "[store.user-messages] cannot delete a user-message-file because user's token is missing"
        )
        return false
      }
      const storeActions = getStoreActions()
      const success = await storeActions.userFiles.deleteUserFile(userFileId)
      if (success) {
        actions._removeUserMessageFile({ userMessageId, userFileId })

        Mixpanel.track(MixpanelEvents.MessageFileDeleted, {
          userMessageId,
          userFileId,
        })

        const userMessage = getState().userMessage(userMessageId)
        if (userMessage && userMessage.files.length === 0 && userMessage.text === '')
          await actions.deleteUserMessage(userMessageId)
      }

      return success
    }
  ),

  fetchProjectUserMessages: thunk(
    async (actions, projectId, { getStoreState, getStoreActions }) => {
      const storeState = getStoreState()
      if (storeState.user.token === null) {
        console.error(
          "[store.user-messages] cannot fetch project's message because user's token is missing"
        )
        return false
      }

      let userFileIds: string[] = []

      // fetch project's messages
      console.debug('[store.user-messages]', `fetch project's message, projectId: '${projectId}'`)
      const projectMessages = await fetchUserProjectMessages(storeState.user.token, projectId)
      console.debug('[store.user-messages]', `fetched project's messages:`, projectMessages)
      if (projectMessages !== null) {
        for (let userMessage of projectMessages.userMessages) {
          actions._addProjectMessage({
            projectId: projectMessages.projectId,
            userMessage: userMessage,
          })
          userFileIds = userFileIds.concat(userMessage.userFilesIds)
        }
      }

      // fetch project-product's messages
      console.debug(
        '[store.user-messages]',
        `fetch project-products's message, projectId: '${projectId}'`
      )
      const projectProductsMessages = await fetchUserProjectProductMessages(
        storeState.user.token,
        projectId
      )
      console.debug(
        '[store.user-messages]',
        `fetched project-products's messages:`,
        projectProductsMessages
      )
      if (projectProductsMessages !== null) {
        for (const projectMessages of projectProductsMessages) {
          for (let userMessage of projectMessages.userMessages) {
            actions._addProjectProductMessage({
              userProjectProductId: projectMessages.userProjectProductId,
              userMessage: userMessage,
            })
            userFileIds = userFileIds.concat(userMessage.userFilesIds)
          }
        }
      }

      // fetch files
      const storeActions = getStoreActions()
      storeActions.userFiles.fetchUserFiles(userFileIds)

      return true
    }
  ),
}
