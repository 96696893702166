import { IonPage, IonContent, IonSlides, IonSlide } from '@ionic/react'
import React, { useCallback, useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import Likes from './Likes'
import Searches from './Searches'
import { HomeLayout } from '../components/organisms/HomeLayout/HomeLayout'
import { TutorialContainer } from '../components/organisms/TutorialContainer/TutorialContainer'
import { useStoreState } from '../store/hooks'
import { Plugins } from '@capacitor/core'
import { AppVersionModal } from '../components/organisms/AppVersionModal/AppVersionModal'
import { isAppVersionOutdated } from '../utils/platform'
import { CenteredLoading } from '../components/molecules/CenteredLoading/CenteredLoading'

const { SplashScreen } = Plugins

const Mask = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: ${(props) => props.theme.colors.greyBg};
  z-index: 100;
`

const HomeApp: React.FC = () => {
  const ionSlidesRef = useRef<HTMLIonSlidesElement>(null)

  const onHistoryClicked = useCallback(() => ionSlidesRef!.current!.slideTo(2), [ionSlidesRef])
  const onSavedClicked = useCallback(() => ionSlidesRef!.current!.slideTo(0), [ionSlidesRef])
  const goBackHome = useCallback(() => ionSlidesRef!.current!.slideTo(1), [ionSlidesRef])

  const tutorialWasShown = useStoreState((state) => state.user.tutorialWasShown)

  // Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.
  const slideOpts = {
    initialSlide: 1,
    speed: 400,
  }

  const ionSlideStyle = {
    height: '100%',
  }

  const ionSlidesStyles = {
    height: '100%',
  }

  const [areSlidesReady, setSlidesAsReady] = useState(false)

  function handleSlidesLoad() {
    console.debug("[HomeApp] slides are ready")
    setSlidesAsReady(true)
  }

  // is app outdated
  const appVersion = useStoreState((store) => store.user.appVersion)
  const [isAppOutdated, setIsAppOutdated] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    async function checkAppVersion() {
      setIsAppOutdated(await isAppVersionOutdated(appVersion!))
    }
    if (appVersion !== null)
      checkAppVersion()
  }, [appVersion])

  // Splash Screens
  const [splashScreenTimeoutId, setSplashScreenTimeoutId] = useState<number|undefined>(undefined)

  useEffect(() => {
    if (splashScreenTimeoutId === undefined) {
      const firstTimeoutId = window.setTimeout(() => {
        SplashScreen.hide();
        clearTimeout(splashScreenTimeoutId);
      }, 1500);
      setSplashScreenTimeoutId(firstTimeoutId);
    }
    return () => {
      if (splashScreenTimeoutId !== undefined)
        clearTimeout(splashScreenTimeoutId)
    }
  }, [tutorialWasShown, splashScreenTimeoutId])

  return (
    <IonPage>
      {isAppOutdated === undefined ? (

        <CenteredLoading />

      ) : isAppOutdated === true ? (

        <AppVersionModal />

      ) : (
        <>
          {!areSlidesReady && (
            <Mask className={'mask'} />
          )}

          <IonContent>

            {!tutorialWasShown && <TutorialContainer steps={[0, 1, 2, 3, 4]}/>}

            <IonSlides style={ionSlidesStyles} pager={false} options={slideOpts} ref={ionSlidesRef} onIonSlidesDidLoad={handleSlidesLoad}>
              <IonSlide style={ionSlideStyle}>
                <Likes onBackClicked={goBackHome} />
              </IonSlide>
              <IonSlide style={ionSlideStyle}>
                <HomeLayout onSavedCliked={onSavedClicked} onHistoryClicked={onHistoryClicked} />
              </IonSlide>
              <IonSlide style={ionSlideStyle}>
                <Searches onBackClicked={goBackHome} />
              </IonSlide>
            </IonSlides>
          </IonContent>
        </>
      )}
    </IonPage>
  )
}

export default HomeApp
