import React from 'react'
import styled from 'styled-components'
import { Loading } from '../../atoms/Loading/Loading'

const LoadingWrapper = styled.div<{ height: string|null }>`
  width: 100%;
  height: ${(props) => props.height != null ? props.height : '100%'};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`

export interface CenteredLoadingProps {
  height?: string | null
  isLight?: boolean
  className?: string
}
export const CenteredLoading: React.FC<CenteredLoadingProps> = ({
  height = null,
  isLight,
  className,
}) => {
  return (
    <LoadingWrapper height={height} className={`centered-loader ${className}`}>
      <Loading isLight={isLight}/>
    </LoadingWrapper>
  )
}
