import mixpanel from 'mixpanel-browser'
import { Routes } from './Routes'

export interface MixpanelProductVariationViewedDTO {
  productId: string
  productVariationId: string
  productName: string
  productVariationName: string
  brandId?: string
  brandName?: string
  from?: string
}

export enum MixpanelEvents {
  // app
  AppLaunched = 'App launched',
  UserImageChosen = 'User image chosen',
  ReturnButtonClicked = 'Return button clicked',
  // geolocalization
  LocationUpdated = 'Location updated',
  // user
  UserSignIn = 'User signed in',
  UserSignInError = 'Error when signing in',
  UserSignOut = 'User signed out',
  UserLogIn = 'User logged in',
  UserLogOut = 'User logged out',
  WrongUserPassword = 'User set a wrong password',
  AlreadyUsedUserEmail = "User tried to sign in with another user's email",
  UserPasswordReset = "User's password reset",
  UserUpdated = "User's informations updated",
  // product
  ProductVariationViewed = 'Product variation viewed',
  BuyButtonClicked = 'Buy button clicked',
  ProductLiked = 'Product liked',
  ProductDisliked = 'Product disliked',
  ReadMoreClicked = 'Read more clicked',
  ProductVariationImageSlided = 'Product variation image slided',
  // search
  ObjectDetectionCreated = 'Object detection created',
  ObjectDetectionResultsReceived = 'Object detection results received',
  VisualSearchCreated = 'Visual search created',
  VisualSearchResultsReceived = 'Visual search results received',
  SearchLaunched = 'Search launched',
  SearchResultsReceived = 'Results of a search received',
  LoadResultsPagination = 'Search paginated results loaded',
  LoadSimilarProductPagination = 'Paginated similar products loaded',
  // users' project
  ProjectCreated = 'Project created',
  ProjectOpened = 'Project opened',
  ProjectDeleted = 'Project deleted',
  ProjectUpdated = 'Informations of project updated',
  // product of a project
  UserProductCreated = "User's Product created",
  UserProductUpdated = "User's Product updated",
  ProductAddedToProjects = 'Product added to projects',
  ProductRemovedFromProject = 'Product removed from a project',
  ProjectProductOpinionCreated = 'Opinion of a product created',
  ProjectProductOpinionUpdated = 'Opinion of a product updated',
  ProjectProductQuantityUpdated = 'Quantity of a product updated',
  ProjectProductValidated = 'Product of a project validated',
  ProjectProductUnvalidated = 'Product of a project unvalidated',
  // members of a project
  ProjectInvitationSend = 'Invitation for a project send',
  ProjectMemberDeleted = 'Member of a project deleted',
  // professionals of a project
  ProjectProfessionalCreated = 'Professional of a project created',
  ProjectProfessionalUpdated = 'Professional of a project updated',
  ProjectProfessionalDeleted = 'Professional of a project deleted',
  // users' messages
  MessageUpdated = 'Message updated',
  MessageDeleted = 'Message deleted',
  MessageFileDeleted = 'File of a message deleted',
  ProjectMessageCreated = 'Message of a project created',
  ProjectProductMessageCreated = 'Message of a product created',
  // users' files
  LocalFileRenamed = 'Local file renamed',
  UserFileRenamed = "User's file renamed",
  ProjectFileCreated = 'File of a project created',
  ProjectFileDeleted = 'File of a project deleted',
}

const shouldSendEvent =
  process.env.REACT_APP_MIXPANEL_TOKEN &&
  process.env.REACT_APP_ENV &&
  ['PREPROD', 'PROD'].includes(process.env.REACT_APP_ENV)

const shouldLog =
  process.env.REACT_APP_ENV && ['PREPROD', 'DEV'].includes(process.env.REACT_APP_ENV)

if (shouldSendEvent) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '')
}

const Mixpanel = {
  track: (name: MixpanelEvents, props?: mixpanel.Dict) => {
    if (shouldLog) console.log(`Mixpanel tracking - ${name} - ${JSON.stringify(props)}`)
    if (shouldSendEvent)
      mixpanel.track(name, {
        ...props,
        eventTime: Date.now(),
        env: process.env.REACT_APP_ENV,
      })
  },
  register: (props: mixpanel.Dict, days?: number | undefined) => {
    if (shouldLog) console.log(`Mixpanel registering - ${JSON.stringify(props)}`)
    if (shouldSendEvent) mixpanel.register(props, days)
  },
  trackProductVariationViewed: (props: MixpanelProductVariationViewedDTO) => {
    if (shouldLog) console.log(`Mixpanel tracking ProductVariationView - ${props.from}`)
    if (shouldSendEvent)
      mixpanel.track(MixpanelEvents.ProductVariationViewed, {
        ...props,
        eventTime: Date.now(),
        env: process.env.REACT_APP_ENV,
      })
  },
}
export default Mixpanel

export const getCurrentPageName = (path: string | null): string => {
  console.debug('[utils/mixpanels] getCurrentPageName', path)
  return path === null
    ? ''
    : path === Routes.Search
    ? 'search'
    : path === Routes.Projects
    ? 'projects'
    : path.includes(Routes.Projects + '/')
    ? 'project'
    : path === Routes.Home
    ? 'home'
    : path.includes(Routes.ProductVariation + '/')
    ? 'product variation'
    : ''
}
