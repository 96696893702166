export enum Routes {
    Home = '/',
    Login = '/login',
    Register = '/register',
    EmailValidation = '/email-validation',
    ResetPassword = '/reset-password',
    NewPassword = '/new-password',
    Search = '/search',
    Filters = '/filters',
    Projects = '/projects',
    Searches = '/searches',
    Account = '/account',
    ProductVariation = '/product-variations',
    StoreRedirection = '/download-our-app',
}
  