import { IonPage, useIonViewDidEnter } from '@ionic/react'
import React, { FormEvent, useCallback, useMemo, useState } from 'react'
import { Button } from '../../components/atoms/Button/Button'
import { PasswordInput } from '../../components/atoms/PasswordInput/PasswordInput'
import { LanguageSelect } from '../../components/molecules/LanguageSelect/LanguageSelect'
import { useTranslation } from 'react-i18next'
import { validatePassword, validateEmail, validateName } from '../../utils/login'
import axiosApi, { isAxiosError } from '../../utils/axios'
import { Routes } from '../../utils/Routes'
import { LanguageCode, UserCreationDTO, UserDTO } from '@fynde/dtos'
import config from '../../config'
import { TextInput } from '../../components/atoms/TextInput/TextInput'
import { useEffect } from 'react'
import { Checkbox } from '../../components/atoms/Checkbox/Checkbox'
import { useStoreState } from '../../store/hooks'
import { SelectWithTextInput } from '../../components/atoms/SelectWithTextInput/SelectWithTextInput'
import './Register.css'
import { getFormattedAddress } from '../../utils/tool'
import ReactMarkdown from 'react-markdown'
import Mixpanel, { MixpanelEvents } from '../../utils/mixpanel'
import { AxiosError } from 'axios'

enum AccountCreationResult {
  Idle = 0,
  Success = 1,
  EmailAlreadyExists = 2,
  UndefinedError = 3,
}

const Register: React.FC = () => {
  const { t } = useTranslation()

  // title
  useIonViewDidEnter(() => {
    document.title = config.pageTitlePrefix + t('pageTitle.Register')
  }, [t])

  // form
  const [showErrors, setShowErrors] = useState<boolean>(false)
  const [accountCreationResult, setAccountCreationResult] = useState<AccountCreationResult>(
    AccountCreationResult.Idle
  )

  const [step, setStep] = useState<number>(0)

  // first name
  const [firstName, setFirstName] = useState<string>('')
  const isFirstNameOk = useMemo(() => {
    return validateName(firstName)
  }, [firstName])

  // last name
  const [lastName, setLastName] = useState<string>('')
  const isLastNameOk = useMemo(() => {
    return validateName(lastName)
  }, [lastName])

  // email
  const [email, setEmail] = useState<string>('')
  const handleEmailChange = (newEmail: string) => {
    setEmail(newEmail);
    if (accountCreationResult === AccountCreationResult.EmailAlreadyExists)
      setAccountCreationResult(AccountCreationResult.Idle)
  }
  const isEmailOk = useMemo(() => {
    return validateEmail(email)
  }, [email])

  // password
  const [password, setPassword] = useState<string>('')
  const isPasswordOk = useMemo(() => {
    return validatePassword(password)
  }, [password])

  // newsletters
  const [allowNewsletters, setAllowNewsletters] = useState<boolean>(false)

  // is a professional
  const [isAProfessional, setIsAProfessional] = useState<boolean>(false)

  // job
  const jobOptions = [
    t('job.architect'),
    t('job.interiorDesigner'),
    t('job.designer'),
    t('job.promoter'),
    t('job.hotelier'),
    t('job.other'),
  ]
  const [selectedJob, setSelectedJob] = useState<string>('')
  const isJobOk = useMemo(() => {
    return selectedJob.search(/\w/) > -1
  }, [selectedJob])

  // company
  const [companyName, setCompanyName] = useState<string>('')
  const isCompanyNameOk = useMemo(() => {
    return companyName.search(/\w/) > -1
  }, [companyName])

  // website
  const [website, setWebsite] = useState<string>('')

  // phone
  const [phone, setPhone] = useState<string>('')
  const isPhoneOk = useMemo(() => {
    return phone === '' || phone.search(/^\+?[\d() ]*$/) > -1
  }, [phone])

  // address
  const [addressLine1, setAddressLine1] = useState<string>('')
  const [addressLine2, setAddressLine2] = useState<string>('')
  const [addressZipcode, setAddressZipcode] = useState<string>('')
  const [addressCity, setAddressCity] = useState<string>('')
  const [addressRegion, setAddressRegion] = useState<string>('')
  const [addressCountry, setAddressCountry] = useState<string>('')

  const [formattedAddress, setFormattedAddress] = useState<string>('')
  useEffect(() => {
    if (step === 2) {
      const tmp = getFormattedAddress({
        id: '',
        line1: addressLine1,
        line2: addressLine2,
        zipcode: addressZipcode,
        city: addressCity,
        region: addressRegion,
        country: addressCountry,
      })
      setFormattedAddress(tmp.some((el) => el !== '') ? tmp.join('\n') : '')
    }
  }, [step])

  // accept Terms of Use
  const [acceptTOS, setAcceptTOS] = useState<boolean>(false)

  // language
  const userLanguage = useStoreState((store) => store.user.language)
  const [currentLanguage, setCurrentLanguage] = useState<string | undefined>(undefined)
  const handleLanguageChange = (local: string) => {
    setCurrentLanguage(local)
  }

  // validation
  const isStep0Valid = useMemo(() => {
    return isFirstNameOk && isLastNameOk && isEmailOk && isPasswordOk
  }, [isFirstNameOk, isLastNameOk, isEmailOk, isPasswordOk])

  const isStep1Valid = useMemo(() => {
    return !isAProfessional || (isJobOk && isCompanyNameOk)
  }, [isAProfessional, isJobOk, isCompanyNameOk])

  // submit
  const getPostDTO = useCallback(() => {
    let dto: UserCreationDTO = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      password: password,
      professional: isAProfessional
        ? {
            companyName: companyName,
            job: selectedJob,
            phone: phone,
            email: email,
            website: website,
            companyAddress: {
              line1: addressLine1,
              line2: addressLine2,
              zipcode: addressZipcode,
              city: addressCity,
              region: addressRegion,
              country: addressCountry,
            },
          }
        : undefined,
      language: currentLanguage as LanguageCode,
      allowsNewsletters: allowNewsletters,
    }

    // remove undefined and empty string values
    dto = JSON.parse(
      JSON.stringify(dto, (key, value) => {
        if (value === undefined) return undefined
        if (typeof value === 'string' && value.search(/\w/) === -1) return null
        return value
      })
    )

    return dto
  }, [
    firstName,
    lastName,
    email,
    password,
    companyName,
    isAProfessional,
    selectedJob,
    phone,
    website,
    addressLine1,
    addressLine2,
    addressZipcode,
    addressCity,
    addressRegion,
    addressCountry,
    currentLanguage,
    allowNewsletters,
  ])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.debug('[Register] handle form submit')

    // first step validation
    if (step === 0) {
      console.debug('[Register] handle form submit, check step 0')
      if (!isStep0Valid) {
        setShowErrors(true)
        return
      } else {
        setShowErrors(false)
        setStep(1)
        return
      }
    }

    // second step validation
    else if (step === 1) {
      console.debug('[Register] handle form submit, check step 1')
      if (isStep1Valid) {
        setStep(2)
        return
      }
    }

    // third step
    console.debug('[Register] handle form submit, validate last step')

    if (isAProfessional && (!isCompanyNameOk || !isPhoneOk || !selectedJob)) {
      console.warn('[Register] professional data is incorrect')
      return
    }

    // post
    const dto = getPostDTO()

    axiosApi
      .post<UserDTO>(`/user`, dto)
      .then((response) => {
        console.debug('[Register] created user', response.data)
        setAccountCreationResult(AccountCreationResult.Success)

        Mixpanel.track(MixpanelEvents.UserSignIn, {
          userId: response.data.id,
        })
      })
      .catch((error: any | AxiosError) => {
        console.error('[Register] error when created user:', error)
        if (isAxiosError(error) && (error as AxiosError).response?.status === 409) {
          setAccountCreationResult(AccountCreationResult.EmailAlreadyExists)
          Mixpanel.track(MixpanelEvents.AlreadyUsedUserEmail, {
            email: dto.email,
          })
        } else {
          setAccountCreationResult(AccountCreationResult.UndefinedError)
          Mixpanel.track(MixpanelEvents.UserSignInError, {
            response: error.response,
          })
        }
      })
  }

  return (
    <IonPage className={'register-page'}>
      <header>
        <img
          className={'logo'}
          src="assets/branding/logo_fynde_noMargin_500px.png"
          alt="Fynde logo"
        />
        <div className={'language-select-wrapper'}>
          <LanguageSelect onChange={(local) => handleLanguageChange(local)} />
        </div>
      </header>

      <div className={'content'}>
        <h2>{t('register.title')}</h2>

        <p className={'info'}>{t('register.info')}</p>

        <form onSubmit={handleSubmit}>
          {step === 0 && (
            <>
              <div className={`row firstname mandatory ${isFirstNameOk ? 'good' : 'wrong'}`}>
                <span>{t('account.personalInfo.firstname.label')}</span>
                <div className={'data'}>
                  <TextInput
                    type="text"
                    value={firstName}
                    placeholder={t('account.personalInfo.firstname.placeholder')}
                    isWrong={showErrors && !isFirstNameOk}
                    onChange={setFirstName}
                    autoComplete={'given-name'}
                    size={'medium'}
                  />
                  {showErrors && !isFirstNameOk && (
                    <p className="feedback-txt error">{t('register.nameError')}</p>
                  )}
                </div>
              </div>

              <div className={`row lastname mandatory ${isLastNameOk ? 'good' : 'wrong'}`}>
                <span>{t('account.personalInfo.lastname.label')}</span>
                <div className={'data'}>
                  <TextInput
                    type="text"
                    value={lastName}
                    placeholder={t('account.personalInfo.lastname.placeholder')}
                    isWrong={showErrors && !isLastNameOk}
                    onChange={setLastName}
                    autoComplete={'family-name'}
                    size={'medium'}
                  />
                  {showErrors && !isLastNameOk && (
                    <p className="feedback-txt error">{t('register.nameError')}</p>
                  )}
                </div>
              </div>

              <div className={`row email mandatory ${isEmailOk ? 'good' : 'wrong'}`}>
                <span>{t('account.personalInfo.email.label')}</span>
                <div className={'data'}>
                  <TextInput
                    type="text"
                    value={email}
                    placeholder={t('account.personalInfo.email.placeholder')}
                    isWrong={showErrors && !isEmailOk}
                    onChange={handleEmailChange}
                    autoComplete={'email'}
                    size={'medium'}
                  />
                  {showErrors && !isEmailOk && (
                    <p className="feedback-txt error">{t('register.emailError')}</p>
                  )}
                </div>
              </div>

              <div className={`row password mandatory ${isPasswordOk ? 'good' : 'wrong'}`}>
                <span>{t('account.personalInfo.password.label')}</span>
                <div className={'data'}>
                  <PasswordInput
                    placeholder={t('account.personalInfo.password.placeholder')}
                    isWrong={showErrors && !isPasswordOk}
                    onChange={setPassword}
                    withEye={true}
                    autoComplete={'off'}
                    value={password}
                  />
                  {showErrors && !isPasswordOk && (
                    <p className="feedback-txt error">{t('register.passwordError')}</p>
                  )}
                </div>
              </div>

              <div>
                <Checkbox
                  isChecked={allowNewsletters}
                  onChange={(value) => setAllowNewsletters(value)}
                  label={t('register.newslettersCheckbox')}
                />
              </div>
            </>
          )}

          {step === 1 && (
            <>
              <div>
                <Checkbox
                  label={t('account.professionalInfo.iAmAProfessional')}
                  isChecked={isAProfessional}
                  onChange={(value) => setIsAProfessional(value)}
                />
              </div>

              <div
                className={`row job mandatory ${
                  isAProfessional ? (isJobOk ? 'good' : 'wrong') : 'locked'
                }`}
              >
                <span>{t('account.professionalInfo.job.label')}</span>
                <div className={'data'}>
                  <SelectWithTextInput
                    value={selectedJob}
                    onChange={setSelectedJob}
                    placeholder={t('account.professionalInfo.job.placeholder')}
                    fontSize={'medium'}
                    options={jobOptions}
                    optionsPosition={'top'}
                    isLocked={!isAProfessional}
                    isWrong={isAProfessional && !isJobOk}
                  />
                </div>
              </div>

              <div
                className={`row company-name mandatory ${
                  isAProfessional ? (isCompanyNameOk ? 'good' : 'wrong') : 'locked'
                }`}
              >
                <span>{t('account.professionalInfo.companyName.label')}</span>
                <div className={'data'}>
                  <TextInput
                    type="text"
                    value={companyName}
                    placeholder={t('account.professionalInfo.companyName.placeholder')}
                    onChange={setCompanyName}
                    autoComplete={'organization'}
                    size={'medium'}
                    isLocked={!isAProfessional}
                    isWrong={isAProfessional && !isCompanyNameOk}
                  />
                </div>
              </div>

              <div className={`row phone ${isAProfessional ? '' : 'locked'}`}>
                <span>{t('account.professionalInfo.phone.label')}</span>
                <div className={'data'}>
                  <TextInput
                    type="text"
                    value={phone}
                    placeholder={t('account.professionalInfo.phone.placeholder')}
                    isWrong={showErrors && !isPhoneOk}
                    onChange={setPhone}
                    autoComplete={'tel'}
                    size={'medium'}
                    isLocked={!isAProfessional}
                  />
                </div>
              </div>

              <div className={`row website ${isAProfessional ? '' : 'locked'}`}>
                <span>{t('account.professionalInfo.website.label')}</span>
                <div className={'data'}>
                  <TextInput
                    type={'text'}
                    placeholder={t('account.professionalInfo.website.placeholder')}
                    size={'medium'}
                    value={website}
                    onChange={setWebsite}
                    isLocked={!isAProfessional}
                  />
                </div>
              </div>

              <div className={`row address ${isAProfessional ? '' : 'locked'}`}>
                <span>{t('account.professionalInfo.companyAddress.label')}</span>
                <div className={'data'}>
                  <TextInput
                    type={'text'}
                    value={isAProfessional ? addressLine1 : t('address.line1.label')}
                    onChange={setAddressLine1}
                    placeholder={t('address.line1.label')}
                    size={'medium'}
                    isLocked={!isAProfessional}
                  />

                  <TextInput
                    type={'text'}
                    value={isAProfessional ? addressLine2 : t('address.line2.placeholder')}
                    onChange={setAddressLine2}
                    placeholder={t('address.line2.placeholder')}
                    size={'medium'}
                    isLocked={!isAProfessional}
                  />

                  <TextInput
                    type={'text'}
                    value={isAProfessional ? addressZipcode : t('address.zipcode.label')}
                    onChange={setAddressZipcode}
                    placeholder={t('address.zipcode.label')}
                    size={'medium'}
                    isLocked={!isAProfessional}
                  />

                  <TextInput
                    type={'text'}
                    value={isAProfessional ? addressCity : t('address.city.label')}
                    onChange={setAddressCity}
                    placeholder={t('address.city.label')}
                    size={'medium'}
                    isLocked={!isAProfessional}
                  />

                  <TextInput
                    type={'text'}
                    value={isAProfessional ? addressRegion : t('address.region.label')}
                    onChange={setAddressRegion}
                    placeholder={t('address.region.label')}
                    size={'medium'}
                    isLocked={!isAProfessional}
                  />

                  <TextInput
                    type={'text'}
                    value={isAProfessional ? addressCountry : t('address.country.label')}
                    onChange={setAddressCountry}
                    placeholder={t('address.country.label')}
                    size={'medium'}
                    isLocked={!isAProfessional}
                  />
                </div>
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <p className={'subtitle'}>{t('register.step2Subtitle')}</p>

              <table className={'summary'}>
                <tbody>
                  <tr>
                    <td>{t('account.personalInfo.lastname.label')}</td>
                    <td>
                      {firstName} {lastName}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('account.personalInfo.email.label')}</td>
                    <td>{email}</td>
                  </tr>
                  <tr>
                    <td>{t('account.professionalInfo.job.label')}</td>
                    <td>{isAProfessional && selectedJob.length > 0 ? selectedJob : '-'}</td>
                  </tr>
                  <tr>
                    <td>{t('account.professionalInfo.companyName.label')}</td>
                    <td>{isAProfessional && companyName.length > 0 ? companyName : '-'}</td>
                  </tr>
                  <tr>
                    <td>{t('account.professionalInfo.phone.label')}</td>
                    <td>{isAProfessional && phone.length > 0 ? phone : '-'}</td>
                  </tr>
                  <tr>
                    <td>{t('account.professionalInfo.website.label')}</td>
                    <td>{isAProfessional && website.length > 0 ? website : '-'}</td>
                  </tr>
                  <tr>
                    <td>{t('account.professionalInfo.companyAddress.label')}</td>
                    <td>
                      {isAProfessional && formattedAddress.length > 0 ? (
                        <ReactMarkdown>{formattedAddress.replace(/\n/g, '  \n')}</ReactMarkdown>
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className={'checkbox-with-link'}>
                <Checkbox isChecked={acceptTOS} onChange={(value) => setAcceptTOS(value)} />
                <p>
                  {t('register.acceptTermsOfService.message') + ' ('}
                  <a
                    href={`${config.website.url}/${userLanguage}${config.website.routes.tos}`}
                    target="_blank"
                    rel={'noopener noreferrer'}
                  >
                    {t('register.acceptTermsOfService.link')}
                  </a>
                  {')'}
                </p>
              </div>

              {accountCreationResult === AccountCreationResult.Success && (
                <>
                  <div className={'final-feedback'}>
                    <p className="feedback-txt success">{t('register.success')}</p>
                  </div>
                </>
              )}
              {accountCreationResult === AccountCreationResult.UndefinedError && (
                <>
                  <div className={'final-feedback'}>
                    <p className="feedback-txt error">{t('register.fail')}</p>
                  </div>
                </>
              )}
              {accountCreationResult === AccountCreationResult.EmailAlreadyExists && (
                <>
                  <div className={'final-feedback'}>
                    <p className="feedback-txt error">{t('register.accountAlreadyExistsError')}</p>
                    <p className="centered">
                      <a className={'link'} href={Routes.ResetPassword}>
                        {t('link.forgottenPassword.access')}
                      </a>
                    </p>
                  </div>
                </>
              )}
            </>
          )}

          <div className={'submit-button-wrapper'}>
            {step > 0 && (
              <Button filled={false} onClick={() => setStep(step - 1)} type={'button'}>
                {t('register.backButton')}
              </Button>
            )}
            <Button
              className={'submit-button'}
              type="submit"
              isLocked={
                (step === 0 &&
                  !(
                    firstName.length > 0 &&
                    lastName.length > 0 &&
                    email.length > 0 &&
                    password.length > 0
                  )) ||
                (step === 1 && !isStep1Valid) ||
                (step === 2 && !acceptTOS)
              }
            >
              {step === 0
                ? t('register.continueButton')
                : step === 1
                ? isAProfessional
                  ? t('register.continueButton')
                  : t('register.skipButton')
                : t('register.submitButton')}
            </Button>
          </div>
        </form>

        <p>
          {t('link.logIn.prefix')}&nbsp;
          <a className={'link'} href={Routes.Login}>
            {t('link.logIn.access')}
          </a>
        </p>
      </div>
    </IonPage>
  )
}

export default Register
