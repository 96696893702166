import axiosApi from '../utils/axios'
import { UserProductCreationDTO, UserProductDTO, UserProductUpdateDTO } from '@fynde/dtos'

// POST
export const postUserProduct = async (
  userToken: string,
  dto: UserProductCreationDTO
): Promise<UserProductDTO | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.post<UserProductDTO>(`/user-product/`, dto, config)
    return resp.data
  } catch (err) {
    console.error('An error occured while posting a new user-product:', err)
  }
  return null
}

// PATCH
export const patchUserProduct = async (
  userToken: string,
  userProductId: string,
  dto: UserProductUpdateDTO
): Promise<UserProductDTO | null> => {
  console.log('patchUserProjectProduct', userProductId, dto)
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.patch<UserProductDTO>(`/user-product/${userProductId}`, dto, config)
    return resp.data
  } catch {
    console.error('An error occured while patching a user-product')
    return null
  }
}
