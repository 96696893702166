import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DefaultProjectIcon } from '../../../assets/icons/star-icon.svg'
import { ReactComponent as UserProjectIcon } from '../../../assets/icons/hashtag-icon.svg'
import { ReactComponent as ProductIcon } from '../../../assets/icons/cardboard-icon.svg'
import { ReactComponent as MemberIcon } from '../../../assets/icons/user-icon.svg'
import { getFormattedDate, getFormattedPrice } from '../../../utils/tool'
import { ListItem } from '../../atoms/ListItem/ListItem'
import './ProjectListItem.css'

export interface ProjectListItemProps {
  isLoading: boolean
  name?: string
  budget?: number
  membersCount?: number
  productsCount?: number
  creationDate?: Date
  isDefaultProject?: boolean
  onOpen: () => void
}

export const ProjectListItem: React.FC<ProjectListItemProps> = ({
  isLoading,
  name,
  budget,
  membersCount,
  productsCount,
  creationDate,
  isDefaultProject,
  onOpen,
}) => {
  const { t } = useTranslation()

  const emptyChar = ''

  const formattedDate = creationDate
    ? getFormattedDate(creationDate, 'DMY')
    : emptyChar

  return (
    <ListItem
      onClick={onOpen}
      allowHover={true}
      isContentLoading={isLoading}
      className={'project-li-item'}
    >
      <div className={"title"}>
        {isDefaultProject ? (
          <>
            <DefaultProjectIcon />
            <span>
              {t('project.defaultProjectName')}
            </span>
          </>
        ) : (
          <>
            <UserProjectIcon />
            <span>
              {name}
            </span>
          </>
        )}
      </div>

      <div className={"date"}>
        {(!isDefaultProject && formattedDate)
          ? formattedDate
          : emptyChar
        }
      </div>

      <div className={"members"}>
        {!isDefaultProject ? (
          <>
            <MemberIcon />
            <span>{membersCount}</span>
          </>
        ) : (
          emptyChar
        )}
      </div>

      <div className={"products"}>
        {!isDefaultProject ? (
          <>
            <ProductIcon />
            <span>{productsCount}</span>
          </>
        ) : (
          emptyChar
        )}
      </div>

      <div className={"budget"}>
        {(!isDefaultProject && budget) ? (
          getFormattedPrice(budget, 'EUR')
        ) : (
          emptyChar
        )}
      </div>
    </ListItem>
  )
}
