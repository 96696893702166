import React, { useCallback } from 'react'
import { Card } from '../../atoms/Card/Card'
import { IconButton } from '../../atoms/IconButton/IconButton'
import { ReactComponent as InfoIcon } from '../../../assets/icons/info-icon.svg'

import './ProfessionalCard.css'

export interface ProfessionalCardProps {
  name?: string
  job?: string
  onShowMore: () => void
}

export const ProfessionalCard: React.FC<ProfessionalCardProps> = ({ name, job, onShowMore }) => {
  const isPending = !name || !job

  const handleShowMore = useCallback(
    (e?: MouseEvent) => {
      if (e) e.stopPropagation()
      onShowMore()
    },
    [onShowMore]
  )

  return (
    <Card
      className={'professional-card' + (isPending ? ' pending' : '')}
      onClick={isPending ? undefined : handleShowMore}
    >
      <section>
        {isPending ? (
          <>
            <div className={'gradient'} />
          </>
        ) : (
          <>
            <p className={'name condensed-txt bold'}>{name}</p>
            <p className={'job condensed-txt'}>{job}</p>
            <div className={'cta-wrapper'}>
              <IconButton
                icon={<InfoIcon />}
                onClick={handleShowMore}
                bg={'alpha'}
                size={'small'}
              />
            </div>
          </>
        )}
      </section>
    </Card>
  )
}
