import axiosApi from '../utils/axios'
import {
  UserFileDTO,
  UserProjectFileCreationDTO,
  UserProjectFileDTO,
  UserProjectFilesDTO,
} from '@fynde/dtos'

export const postUserProjectFile = async (
  userToken: string,
  dto: UserProjectFileCreationDTO
): Promise<UserProjectFileDTO | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.post<UserProjectFileDTO>(`/user-project-file/`, dto, config)
    return resp.data
  } catch (err) {
    console.error('An error occured while posting a new user-project-file:', err)
  }
  return null
}

export const getUserProjectFiles = async (
  userToken: string,
  userProjectId: string
): Promise<UserFileDTO[]> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    params: {
      userProjectId: userProjectId,
    },
  }
  try {
    const resp = await axiosApi.get<UserProjectFilesDTO>(`/user-project-file/`, config)
    return resp.data.userFiles
  } catch (err) {
    console.error('An error occured while getting user-project-file of a project:', err)
    return []
  }
}
