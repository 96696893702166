import * as Sentry from '@sentry/browser'
import React from 'react'
import { IonApp, setupConfig } from '@ionic/react'
import { ThemeProvider } from 'styled-components'
import { StoreProvider } from 'easy-peasy'
import { theme } from './theme/styled-components'
import { store } from './store/store'
import * as inobounce from './utils/noBounce'
import { Plugins } from '@capacitor/core'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

import './theme/app.css'

/* Theme variables */
import './theme/variables.css'

import { AppInit } from './utils/AppInit'
import Router from './Router'

// Load inobounce
console.log(inobounce)

// disable console debug level
if (process.env.REACT_APP_ENV === 'PROD') {
  console.log = function(){}
  console.debug = function(){}
}

setupConfig({
  swipeBackEnabled: false,
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_SENTRY_RELEASE,
})

const { SplashScreen } = Plugins

SplashScreen.show({
  autoHide: false
})

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <StoreProvider store={store}>
        <AppInit>
          <IonApp>
            <Router />
          </IonApp>
        </AppInit>
      </StoreProvider>
    </ThemeProvider>
  )
}

export default App
