import {
  DeletedUserMessageSocketDTO,
  EditedUserMessageSocketDTO,
  NewUserMessageSocketDTO,
  UserMessageLightDTO,
} from '@fynde/dtos'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Socket } from 'socket.io-client'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { LocalUserFile } from '../../../store/user-files'
import { RequestStatus } from '../../../utils/reqStatus'
import { isTheFileAnImage, readAsDataURL } from '../../../utils/tool'
import { Chat } from './Chat'
import './Chat.css'

export interface ChatWrapperProps {
  projectId: string
  userProjectProductId: string | null
  socket: Socket | undefined
  maxHeight?: string
  scrollOnEditorSizeChange: boolean
}

export const ChatWrapper: React.FC<ChatWrapperProps> = ({
  projectId,
  userProjectProductId,
  socket,
  maxHeight,
  scrollOnEditorSizeChange,
}) => {
  const user = useStoreState((store) => store.user.user)
  const projectMembers = useStoreState((store) => store.projectMembers.projectMembers(projectId))

  const roomId = useMemo(() => {
    return userProjectProductId === null ? projectId : `${projectId}_${userProjectProductId}`
  }, [projectId, userProjectProductId])

  // load messages
  const messagesStatus = useStoreState((store) =>
    store.userMessages.projectLoadingStatus(projectId)
  )
  const messages = useStoreState((store) =>
    store.userMessages.userMessages(projectId, userProjectProductId)
  )

  // online files
  const patchUserFile = useStoreActions((store) => store.userFiles.patchUserFile)
  const downloadUserFile = useStoreActions((store) => store.userFiles.downloadUserFile)
  const deleteUserMessageFile = useStoreActions((store) => store.userMessages.deleteUserMessageFile)

  const handleMessageFileDownload = useCallback(
    (userFileId: string) => {
      downloadUserFile(userFileId)
    },
    [downloadUserFile]
  )

  const handleMessageFileRenaming = useCallback(
    async (userFileId: string, newName: string) => {
      await patchUserFile({
        userFileId: userFileId,
        dto: { name: newName },
      })
    },
    [patchUserFile]
  )

  const handleMessageFileDeletion = useCallback(
    async (userMessageId: string, userFileId: string) => {
      await deleteUserMessageFile({ userMessageId, userFileId })
    },
    [deleteUserMessageFile]
  )

  // local files
  const temporaryFiles = useStoreState((store) => store.userFiles.linkedLocalFiles)
  const addLocalUserFile = useStoreActions((store) => store.userFiles.addLocalUserFile)
  const renameLocalUserFile = useStoreActions((store) => store.userFiles.renameLocalUserFile)
  const removeOneLocalUserFile = useStoreActions((store) => store.userFiles.removeOneLocalUserFile)

  const handleFileUpload = useCallback(
    async (files: File[], userMessageId: string | null) => {
      for (const file of files) {
        console.debug(`[ChatWrapper] file: ${file}`)
        const dataUrl = isTheFileAnImage(file.type) ? await readAsDataURL(file) : null

        addLocalUserFile({
          file: file,
          name: file.name,
          dataUrl: dataUrl,
          linkedTo: 'message',
          projectId: projectId,
          projectProductId: userProjectProductId,
          userMessageId: userMessageId,
        })
      }
    },
    [addLocalUserFile, projectId, userProjectProductId]
  )

  const handleFileRenaming = useCallback(
    (fileId: string, newName: string) => {
      console.debug(`[ChatWrapper] rename file ${fileId} to '${newName}'`)
      renameLocalUserFile({
        fileId: fileId,
        newName: newName,
      })
    },
    [renameLocalUserFile]
  )

  const handleFileDelete = useCallback(
    (fileId: string) => {
      console.debug(`[ChatWrapper] remove file ${fileId}`)
      removeOneLocalUserFile(fileId)
    },
    [removeOneLocalUserFile]
  )

  // new message
  const [pendingMessages, setPendingMessages] = useState<number>(0)
  const addProjectMessage = useStoreActions((store) => store.userMessages.addProjectMessage)
  const addProjectProductMessage = useStoreActions(
    (store) => store.userMessages.addProjectProductMessage
  )
  const postProjectMessage = useStoreActions((store) => store.userMessages.postProjectMessage)
  const postProjectProductMessage = useStoreActions(
    (store) => store.userMessages.postProjectProductMessage
  )

  const postNewMessage = useCallback(
    async (messageText: string, messageFiles: LocalUserFile[]) => {
      console.debug(
        '[ChatWrapper]',
        `post a new message '${messageText}' with files (${messageFiles.length})`
      )
      setPendingMessages((prev) => prev + 1)

      let postedUserMessage: UserMessageLightDTO | null = null
      if (userProjectProductId === null) {
        postedUserMessage = await postProjectMessage({
          projectId: projectId,
          message: messageText,
          files: messageFiles,
        })
      } else {
        postedUserMessage = await postProjectProductMessage({
          userProjectProductId: userProjectProductId,
          message: messageText,
          files: messageFiles,
        })
      }
      if (postedUserMessage !== null) {
        if (socket) {
          socket.emit('newMessageToServer', {
            roomId: roomId,
            messageId: postedUserMessage.id,
            messageText: postedUserMessage.text,
            userFirstName: postedUserMessage.user.firstname,
            userLastName: postedUserMessage.user.lastname,
            creationDate: new Date(postedUserMessage.createdAt),
          })
        }
      }
      setPendingMessages((prev) => prev - 1)
    },
    [roomId, projectId, userProjectProductId, socket, postProjectMessage, postProjectProductMessage]
  )

  // edit message
  const patchUserMessage = useStoreActions((store) => store.userMessages.patchUserMessage)
  const modifyStoredUserMessage = useStoreActions((store) => store.userMessages.modifyUserMessage)

  const patchMessage = useCallback(
    async (messageId: string, messageText: string) => {
      console.debug('[ChatWrapper]', `patch message ${messageId}`)
      const patchedMessage = await patchUserMessage({
        userMessageId: messageId,
        text: messageText,
      })
      if (patchedMessage !== null && socket) {
        socket.emit('editedMessageToServer', {
          roomId: roomId,
          messageId: patchedMessage.id,
          messageText: patchedMessage.text,
          updateDate: new Date(patchedMessage.updatedAt),
        })
      }
    },
    [socket, roomId, patchUserMessage]
  )

  // delete message
  const deleteUserMessage = useStoreActions((store) => store.userMessages.deleteUserMessage)
  const removeStoredUserMessage = useStoreActions((store) => store.userMessages.removeUserMessage)

  const deleteMessage = useCallback(
    async (messageId: string) => {
      console.debug('[ChatWrapper]', `delete message ${messageId}`)
      const success = await deleteUserMessage(messageId)
      if (success && socket) {
        socket.emit('deletedMessageToServer', {
          roomId: roomId,
          messageId: messageId,
        })
      }
    },
    [socket, roomId, deleteUserMessage]
  )

  // submit
  const handleSubmit = useCallback(
    (messageId: string | undefined, messageText: string, messageFiles: LocalUserFile[]) => {
      console.debug(
        '[ChatWrapper]',
        `submitted message, id:${messageId}, text: ${messageText}, file: ${messageFiles.length}`
      )
      // new message
      if (messageId === undefined) {
        postNewMessage(messageText, messageFiles)
      } else {
        // correct message
        if (messageText.length > 0) {
          patchMessage(messageId, messageText)
        }
        // delete message
        else {
          deleteMessage(messageId)
        }
      }
    },
    [postNewMessage, patchMessage, deleteMessage]
  )

  // socket
  const handleNewMessageFromSocket = (dto: NewUserMessageSocketDTO) => {
    console.debug('[ChatWrapper]', 'new message received:', dto)
    const member = projectMembers.filter((member) => member.user.email === dto.userEmail)[0]
    const newMessage: UserMessageLightDTO = {
      id: dto.messageId,
      text: dto.messageText,
      userFilesIds: [],
      user: {
        id: '',
        email: '',
        firstname: member.user.firstname,
        lastname: member.user.lastname,
        professional: member.user.professional,
        uiFirstColor: member.user.uiFirstColor,
        uiSecondColor: member.user.uiSecondColor,
      },
      createdAt: new Date(dto.creationDate),
      updatedAt: new Date(dto.creationDate),
    }
    if (userProjectProductId === null) {
      addProjectMessage({
        projectId: projectId,
        userMessage: newMessage,
      })
    } else {
      addProjectProductMessage({
        userProjectProductId,
        userMessage: newMessage,
      })
    }
  }
  const handleEditedMessageFromSocket = (dto: EditedUserMessageSocketDTO) => {
    console.debug('[ChatWrapper]', 'edited message received:', dto)
    modifyStoredUserMessage({
      userMessageId: dto.messageId,
      text: dto.messageText,
      updatedAt: dto.updateDate,
    })
  }
  const handleDeletedMessageFromSocket = (dto: DeletedUserMessageSocketDTO) => {
    console.debug('[ChatWrapper]', 'deleted message received:', dto)
    removeStoredUserMessage(dto.messageId)
  }

  useEffect(() => {
    if (socket) {
      console.debug('[ChatWrapper]', 'register to socket events')
      socket.emit('joinRoom', roomId)
      socket.on(`newMessageFromServer`, handleNewMessageFromSocket)
      socket.on(`editedMessageFromServer`, handleEditedMessageFromSocket)
      socket.on(`deletedMessageFromServer`, handleDeletedMessageFromSocket)
    }
  }, [socket])

  return (
    <Chat
      isLoading={messagesStatus === RequestStatus.Loading}
      userName={`${user.firstname} ${user.lastname}`}
      messages={messages.map((message) => {
        return {
          id: message.id,
          text: message.text,
          files: message.files.map((file) => {
            return {
              id: file.id,
              mimetype: file.media.mimetype,
              filesize: file.media.size,
              name: file.name,
              url: file.media.publicUrl,
            }
          }),
          userName: `${message.user.firstname} ${message.user.lastname}`,
          creationDate: message.createdAt,
          updateDate: message.updatedAt,
          uiFirstColor: message.user.uiFirstColor || undefined,
          uiSecondColor: message.user.uiSecondColor || undefined,
          onUpdate: () => console.log('update'),
          onCancelUpdate: () => console.log('cancel update'),
          onDelete: deleteMessage,
        }
      })}
      pendingMessages={pendingMessages}
      onSubmit={handleSubmit}
      onNewFileUpload={handleFileUpload}
      onNewFileRenaming={handleFileRenaming}
      onNewFileDelete={handleFileDelete}
      localFiles={temporaryFiles('message', projectId, userProjectProductId)}
      onMessageFileDownload={handleMessageFileDownload}
      onMessageFileRenaming={handleMessageFileRenaming}
      onMessageFileDeletion={handleMessageFileDeletion}
      maxHeight={maxHeight}
      scrollOnEditorSizeChange={scrollOnEditorSizeChange}
    />
  )
}
