import { IonPage, useIonViewDidEnter } from '@ionic/react'
import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { Button } from '../components/atoms/Button/Button'
import { Loading } from '../components/atoms/Loading/Loading'
import { PasswordInput } from '../components/atoms/PasswordInput/PasswordInput'
import { LanguageSelect } from '../components/molecules/LanguageSelect/LanguageSelect'
import { useTranslation } from 'react-i18next'
import axiosApi from '../utils/axios'
import { PasswordResetDTO } from '@fynde/dtos'
import { validatePassword } from '../utils/login'
import { Routes } from '../utils/Routes'
import config from '../config';

const CenteredContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`
const LanguageSelectContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`
const Logo = styled.img`
  width: 230px;
  margin-bottom: 1.5em;
`
const Title = styled.h2`
  font-size: 2.0em;
  font-weight: 700;
  margin-bottom: 0.8em;
  text-align: center;
`
const Info = styled.p `
    color: ${(props) => props.theme.colors.black};
    width: 400px;
    margin: 0 0 2em 0;
    text-align: center;
`
const Feedback = styled.p `
    margin: 0 0 1em 0;
    text-align: left;
    width: 400px;
    text-align: center;

    &.success {
      color: ${(props) => props.theme.colors.success};
    }
    &.error {
      color: ${(props) => props.theme.colors.error};
    }
`
const SubmmitButton = styled(Button)`
  width: 400px;
  margin-top: 1.5em;
  margin-bottom: 2.5em;
`
const Line = styled.p `
  margin: 0 0 0.8em 0;
`
const Link = styled.a`
  color: ${(props) => props.theme.colors.black};
  font-weight: 700;
`

const NewPassword: React.FC = () => {
  const { t } = useTranslation()

  // title
  useIonViewDidEnter(() => {
    document.title = config.pageTitlePrefix + t('pageTitle.NewPassword');
  }, [t])

  // token

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const [isTokenValid, setTokenValidity] = useState<boolean | undefined>(undefined);
  useMemo( async () => {
    try {
      const resp = await axiosApi.get(`/auth/is-reset-password-token-valid?token=${token}`);
      setTokenValidity(resp.data);
    } catch (err) {
      console.error(err);
    }
  }, [token])

  // password

  const [filledPassword, setPassword] = useState<string | null>(null);
  const [isPasswordOk, setIsPasswordOk] = useState<boolean | undefined>(undefined);
  const handlePasswordChange = (value: string) => {
    setPassword(value);
    if (isPasswordOk === false && validatePassword(value))
      setIsPasswordOk(true);
  }
  
  // submit

  const [updateSuccess, setUpdateResult] = useState<boolean | undefined>(undefined);
  const handleSubmit = async () => {

    if (filledPassword == null) {
      setIsPasswordOk(false);
      return;
    }
    
    const validationResult = validatePassword(filledPassword);
    setIsPasswordOk(validationResult);
    if (validationResult === false) return;
    
    const payload = {
      "newPassword": filledPassword,
      "token": token,
    }
    try {
      await axiosApi.post<PasswordResetDTO>(`/auth/reset-password`, payload);
      setUpdateResult(true);
    } catch (err) {
      setUpdateResult(false);
    }
  }
  
  return (
    <IonPage>
      <CenteredContainer>
          <Logo src="assets/branding/logo_fynde_noMargin_500px.png" alt="Fynde logo"/>
          
          <Title>{t('newPassword.title')}</Title>

          {isTokenValid === undefined ? (
            <>
              <Loading/>
            </>
          ) : (
            isTokenValid === false ? (
              <>
                <Info>{t('newPassword.invalidToken.info')}</Info>
                
                <Line>
                  <Link href={Routes.ResetPassword}>{t('link.forgottenPassword.access')}</Link>
                </Line>
              </>
            ) : (
              <>
                <Info>{t('newPassword.info')}</Info>

                <PasswordInput
                  placeholder={t('newPassword.passwordPlaceholder')}
                  className={isPasswordOk === false ? "error" : ""}
                  onChange={handlePasswordChange}
                  withEye={true}
                  autoComplete={"off"}
                />
                {isPasswordOk === false && (
                  <Feedback className="error">{t('register.passwordError')}</Feedback>
                )}
                {updateSuccess === true && (
                  <Feedback className="success">{t('newPassword.success')}</Feedback>
                )}
                {updateSuccess === false && (
                  <Feedback className="error">{t('newPassword.fail')}</Feedback>
                )}
                  
                {updateSuccess === true ? (
                  <>
                    <Line>
                      <Link href={Routes.Login}>{t('link.logIn.access')}</Link>
                    </Line>
                  </>
                ) : (
                  <>
                    <SubmmitButton type="button" onClick={handleSubmit}>{t('newPassword.submitButton')}</SubmmitButton>

                    <Line>
                      {t('link.logIn.prefix')}&nbsp;
                      <Link href={Routes.Login}>{t('link.logIn.access')}</Link>
                    </Line>
                  </>
                )}
              </>
            )
          )}
          
        </CenteredContainer>

      <LanguageSelectContainer>
        <LanguageSelect />
      </LanguageSelectContainer>
    </IonPage>
  )
}

export default NewPassword
