import axiosApi from '../utils/axios'
import {
  UserProjectMemberDeletionDTO,
  UserProjectMemberDTO,
  UserProjectMemberFiltersDTO,
  UserProjectMemberUpdateDTO,
} from '@fynde/dtos'

// GET
export const getUserProjectMember = async (
  userToken: string,
  dto: UserProjectMemberFiltersDTO
): Promise<UserProjectMemberDTO | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    params: {
      userProjectId: dto.userProjectId,
      userId: dto.userId,
    },
  }
  try {
    const resp = await axiosApi.get<UserProjectMemberDTO>(`/user-project-member/`, config)
    return resp.data
  } catch (err) {
    console.error('An error occured while getting a user-project-member:', err)
  }
  return null
}

export const getUserProjectMembers = async (
  userToken: string,
  dto: UserProjectMemberFiltersDTO
): Promise<UserProjectMemberDTO[] | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    params: {
      userProjectId: dto.userProjectId,
    },
  }
  try {
    const resp = await axiosApi.get<UserProjectMemberDTO[]>(`/user-project-member/`, config)
    return resp.data
  } catch (err) {
    console.error('An error occured while getting a user-project-member:', err)
  }
  return null
}

// PATCH
export const patchUserProjectMember = async (
  userToken: string,
  dto: UserProjectMemberUpdateDTO
): Promise<UserProjectMemberDTO | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.patch<UserProjectMemberDTO>(`/user-project-member/`, dto, config)
    return resp.data
  } catch {
    console.error('An error occured while patching a user-project-member')
    return null
  }
}

// DELETE
export const deleteUserProjectMember = async (
  userToken: string,
  dto: UserProjectMemberDeletionDTO
): Promise<boolean> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data: dto,
  }
  try {
    await axiosApi.delete(`/user-project-member/`, config)
    return true
  } catch (err) {
    console.error('An error occured while removing a user-project-member:', err)
    return false
  }
}
