import React, { ChangeEvent, DragEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FileIcon } from '../../../assets/icons/file-icon.svg'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus-icon.svg'
import { Button } from '../../atoms/Button/Button'
import './FileDroppingArea.css'

export interface FileDroppingAreaProps {
  onFileAddition: (files: File[]) => void
}

export const FileDroppingArea: React.FC<FileDroppingAreaProps> = ({ onFileAddition }) => {
  const { t } = useTranslation()

  // click
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleClick = useCallback(() => {
    console.debug('[FileDroppingArea] handle click')
    if (hiddenFileInput.current) hiddenFileInput.current.click()
  }, [])

  const handleFilesFromClick = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      let files: File[] = []
      for (const file of event.target.files) files.push(file)
      onFileAddition(files)
    }
  }

  // drag & drop
  const [dragCounter, setDragCounter] = useState<number>(0)
  const [isDragging, setIsDragging] = useState<boolean>(false)

  useEffect(() => {
    if (dragCounter > 0 && !isDragging) setIsDragging(true)
    else if (dragCounter === 0 && isDragging) setIsDragging(false)
  }, [dragCounter, isDragging, setIsDragging])

  const handleDragEnter = useCallback(
    (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      setDragCounter((previousValue) => previousValue + 1)
    },
    [setDragCounter]
  )

  const handleDragLeave = useCallback(
    (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      setDragCounter((previousValue) => Math.max(0, previousValue - 1))
    },
    [setDragCounter]
  )

  const handleDragOver = useCallback((e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      console.debug('[FileDroppingArea] handle drop')
      setDragCounter(0)
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        let files: File[] = []
        for (const file of e.dataTransfer.files) files.push(file)
        onFileAddition(files)
      }
    },
    [setDragCounter, onFileAddition]
  )

  return (
    <div
      className={'file-dropping-area' + (isDragging ? ' drag' : '')}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className={'content'}>
        <FileIcon className={'file-icon'} />
        <p>{t('fileDroppingArea.message')}</p>
        <Button Picto={PlusIcon} filled={true} fontSize={'medium'} onClick={handleClick}>
          {t('fileDroppingArea.button')}
        </Button>
        <input
          type="file"
          multiple
          ref={hiddenFileInput}
          onChange={handleFilesFromClick}
          style={{ display: 'none' }}
        />
      </div>
      <div className={'background'} />
    </div>
  )
}
