import { IonBackdrop } from '@ionic/react'
import React from 'react'
import styled from 'styled-components'
import { Button } from '../../atoms/Button/Button'
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross-icon.svg'
import './Modal.css'

const Content = styled.div<{ maxWidth: string | undefined; withButtons: boolean }>`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'calc(100vw - 8rem)')};

  @media (max-width: 480px) {
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'calc(100vw - 4rem)')};
  }
`

export interface ModalProps {
  isOpen: boolean
  header?: string
  cancelButton?: string
  validateButton?: string
  onCancel: () => void
  onValidate?: () => void
  isValidateButtonLocked?: boolean
  altValidateButton?: JSX.Element
  maxWidth?: string
  children: React.ReactNode
  className?: string
  withButtons?: boolean
  withCross?: boolean
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  header,
  cancelButton = '',
  validateButton = '',
  onCancel,
  onValidate,
  isValidateButtonLocked = false,
  altValidateButton = undefined,
  maxWidth = undefined,
  children,
  className = undefined,
  withButtons = true,
  withCross = false,
}) => {
  return (
    <>
      {isOpen && (
        <div className={`modal ${className ? className : ''}`}>
          <IonBackdrop
            visible={true}
            style={{ opacity: 0.3 }}
            tappable={true}
            onIonBackdropTap={onCancel}
          />

          <Content className={'content'} maxWidth={maxWidth} withButtons={withButtons}>
            {header ? (
              <div className={'header' + (withCross ? ' with-cross' : '')}>
                <h2>{header}</h2>
                {withCross && (
                  <div className={'cross-wrapper'} onClick={onCancel}>
                    <CrossIcon />
                  </div>
                )}
              </div>
            ) : withCross ? (
              <div className={'cross-wrapper'} onClick={onCancel}>
                <CrossIcon />
              </div>
            ) : null}

            <div className={'children-wrapper'}>{children}</div>

            {withButtons && (
              <div className={'buttons-wrapper'}>
                {cancelButton.length > 0 && (
                  <Button onClick={onCancel} filled={false} fontSize={'medium'}>
                    {cancelButton}
                  </Button>
                )}

                {altValidateButton ? (
                  altValidateButton
                ) : (
                  <Button
                    onClick={onValidate}
                    filled={true}
                    isLocked={isValidateButtonLocked}
                    fontSize={'medium'}
                  >
                    {validateButton}
                  </Button>
                )}
              </div>
            )}
          </Content>
        </div>
      )}
    </>
  )
}
