import axiosApi from '../utils/axios'
import { MediaDTO } from '@fynde/dtos'

export const postMedia = async (
  file: File,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
  userToken?: string
): Promise<MediaDTO | null> => {
  const formData = new FormData()
  formData.append('file', file)

  const config = {
    headers:
      userToken === undefined
        ? null
        : {
            Authorization: `Bearer ${userToken}`,
          },
    onUploadProgress: (progressEvent: ProgressEvent) => onUploadProgress(progressEvent),
    timeout: 120000,
  }

  try {
    const resp = await axiosApi.post<MediaDTO>('medias', formData, config)
    return resp.data
  } catch (err) {
    console.error('An error occured while posting a new media:', err)
  }
  return null
}
