import React from 'react'
import styled from 'styled-components'
import { getInitials } from '../../../utils/tool'
import './UserIcon.css'

const Wrapper = styled.div<{ firstColor: string; secondColor: string }>`
  background: linear-gradient(
    45deg,
    ${(props) => props.firstColor} 0%,
    ${(props) => props.secondColor} 100%
  );
`

const OnOverLabel = styled.span<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
`

export interface UserIconProps {
  name: string
  withOverlay?: boolean
  bgFirstColor?: string
  bgSecondColor?: string
}

export const LoadingUserIcon: React.FC = () => {
  return <div className={'loading-user-icon user-icon'} />
}

export const UserIcon: React.FC<UserIconProps> = ({
  name,
  withOverlay = false,
  bgFirstColor = '#d0d0d0',
  bgSecondColor = '#e0e0e0',
}) => {
  const cleanedName = name.replace(/^\s+|\s+$/g, '')

  return (
    <Wrapper
      className={'user-icon' + (withOverlay ? ' with-hover' : '')}
      firstColor={bgFirstColor}
      secondColor={bgSecondColor}
    >
      {withOverlay && (
        <>
          <div className={'overlay'} />
          <OnOverLabel className={'info'} bgColor={bgFirstColor}>
            {cleanedName}
          </OnOverLabel>
        </>
      )}
      <span className={'initials'}>{cleanedName === '' ? '...' : getInitials(cleanedName, 2)}</span>
    </Wrapper>
  )
}
