import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../Modal/Modal'
import { ChatMessageFile } from '../../atoms/ChatMessageFile/ChatMessageFile'
import { isTheFileAnImage, readAsDataURL } from '../../../utils/tool'
import { TextInput } from '../../atoms/TextInput/TextInput'
import './UserProductModal.css'
import { Currency, UserProductDTO } from '@fynde/dtos'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { Select, SelectOption } from '../../atoms/Select/Select'
import { Button } from '../../atoms/Button/Button'
import { validateURL } from '../../../utils/login'

export interface UserProductModalProps {
  isOpen: boolean
  onCancel: () => void
  userProjectId: string
  userProduct?: UserProductDTO
}

export const UserProductModal: React.FC<UserProductModalProps> = ({
  isOpen,
  onCancel,
  userProjectId,
  userProduct,
}) => {
  const { t } = useTranslation()

  // data
  const [productName, setProductName] = useState<string>('')
  const [brandName, setBrandName] = useState<string>('')
  const [price, setPrice] = useState<string>('')
  const currencyOptions: SelectOption[] = [
    { displayedText: '€', value: Currency.Euro },
    { displayedText: '$', value: Currency.UnitedStatesDollar },
    { displayedText: '£', value: Currency.PoundSterling },
    { displayedText: '¥', value: Currency.JapaneseYen },
  ]
  const [selectedCurrency, setSelectedCurrency] = useState<SelectOption>(currencyOptions[0])
  const [url, setUrl] = useState<string>('')
  const [image, setImageData] = useState<
    { file: File | null; data: string; mimetype: string; name: string; size: number } | undefined
  >(undefined)

  useEffect(() => {
    if (userProduct) {
      setProductName(userProduct.name)
      setBrandName(userProduct.brand || '')
      setPrice(userProduct.price.toString())
      setSelectedCurrency(currencyOptions.filter((opt) => opt.value === userProduct.currency)[0])
      setUrl(userProduct.url)
      setImageData({
        file: null,
        data: userProduct.thumbnail.publicUrl,
        mimetype: userProduct.thumbnail.mimetype,
        name: '',
        size: userProduct.thumbnail.size,
      })
    } else {
      setProductName('')
      setBrandName('')
      setPrice('')
      setSelectedCurrency(currencyOptions[0])
      setUrl('')
      setImageData(undefined)
    }
  }, [userProduct])

  // image buttons
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleImageImport = () => {
    console.log('[UserProductModal] handleImageImport')
    if (hiddenFileInput.current) hiddenFileInput.current.click()
  }

  const handleImageChoice = async (event: ChangeEvent<HTMLInputElement>) => {
    let file = undefined
    if (event.target.files) file = event.target.files[0]
    if (!file) return
    if (!isTheFileAnImage(file.type)) {
      console.log('[UserProductModal] the chosen file is not an image')
      return
    }
    const imageData = await readAsDataURL(file)
    setImageData({
      file: file,
      data: imageData,
      mimetype: file.type,
      name: file.name,
      size: file.size,
    })
  }

  // validation
  const isProductNameValid = useMemo(() => {
    return productName.search(/\w/) > -1
  }, [productName])

  const isPriceValid = useMemo(() => {
    return Number(price) > 0
  }, [price])

  const isUrlValid = useMemo(() => {
    return validateURL(url)
  }, [url])

  const isImageValid = useMemo(() => {
    return image !== undefined
  }, [image])

  const hasDataChanged = useMemo(() => {
    if (!userProduct) return false
    return (
      userProduct.name !== productName ||
      userProduct.brand !== brandName ||
      userProduct.price !== Number(price) ||
      userProduct.currency !== selectedCurrency.value ||
      userProduct.url !== url ||
      (!!image && !!image.file)
    )
  }, [userProduct, productName, brandName, price, selectedCurrency.value, url, image])

  const isFormValid = useMemo(() => {
    let result = isProductNameValid && isPriceValid && isUrlValid && isImageValid
    if (userProduct) result = result && hasDataChanged
    return result
  }, [userProduct, isProductNameValid, isPriceValid, isUrlValid, isImageValid, hasDataChanged])

  // cancel
  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  // submit
  const userId = useStoreState((store) => store.user.userId())
  const addLocalUserFile = useStoreActions((store) => store.userFiles.addLocalUserFile)
  const postUserFile = useStoreActions((store) => store.userFiles.postUserFile)
  const postUserProduct = useStoreActions(
    (store) => store.projectProducts.createProductFromUserProduct
  )
  const patchUserProduct = useStoreActions((store) => store.projectProducts.patchUserProduct)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSubmit = async () => {
    console.debug('[UserProductModal] handleSubmit')
    if (!isFormValid) return
    if (!userId) {
      console.error('[UserProductModal] cannot post/patch a new product: userId is missing')
      return
    }

    const floatPrice = Number(price)
    const currency = selectedCurrency.value as Currency

    // new image
    let newUserFile = undefined
    if (image!.file !== null) {
      const localFile = await addLocalUserFile({
        dataUrl: image!.data,
        file: image!.file,
        linkedTo: 'user-product',
        name: image!.name,
        projectId: userProjectId,
        projectProductId: null,
        userMessageId: null,
      })

      newUserFile = await postUserFile(localFile)
      if (!newUserFile) return
    }

    let success = false
    // post
    if (!userProduct) {
      if (!newUserFile) return
      setIsSubmitting(true)
      success = await postUserProduct({
        projectId: userProjectId,
        dto: {
          name: productName,
          brand: brandName.length > 0 ? brandName : undefined,
          price: floatPrice,
          currency,
          url,
          userId,
          userFileId: newUserFile.id,
        },
      })
      setIsSubmitting(false)
    }
    // or patch
    else {
      setIsSubmitting(true)
      success = await patchUserProduct({
        userProductId: userProduct.id,
        dto: {
          name: userProduct.name !== productName ? productName : undefined,
          brand: userProduct.brand !== brandName ? brandName : undefined,
          price: userProduct.price !== floatPrice ? floatPrice : undefined,
          currency: userProduct.currency !== currency ? currency : undefined,
          url: userProduct.url !== url ? url : undefined,
          userFileId: newUserFile ? newUserFile.id : undefined,
        },
      })
      setIsSubmitting(false)
    }

    if (success) handleCancel()
  }

  return (
    <Modal
      isOpen={isOpen}
      header={userProduct ? t('userProductModal.update.header') : t('userProductModal.add.header')}
      withCross={true}
      validateButton={
        userProduct
          ? t('userProductModal.update.submitButton')
          : t('userProductModal.add.submitButton')
      }
      onCancel={handleCancel}
      onValidate={handleSubmit}
      isValidateButtonLocked={!isFormValid || isSubmitting}
      className={'add-user-product-modal'}
    >
      <>
        <div className={'form'}>
          {/* Product Name */}
          <div className={'row mandatory' + (isProductNameValid ? ' good' : '')}>
            <div className={'field'}>{t('userProductModal.productName.field')}</div>
            <div className={'input'}>
              <TextInput
                type={'text'}
                placeholder={t('userProductModal.productName.placeholder')}
                size={'medium'}
                value={productName}
                onChange={setProductName}
              />
            </div>
          </div>
          {/* Brand Name */}
          <div className={'row'}>
            <div className={'field'}>{t('userProductModal.brandName.field')}</div>
            <div className={'input'}>
              <TextInput
                type={'text'}
                placeholder={t('userProductModal.brandName.placeholder')}
                size={'medium'}
                value={brandName}
                onChange={setBrandName}
              />
            </div>
          </div>
          {/* Price */}
          <div className={'row mandatory' + (isPriceValid ? ' good' : '')}>
            <div className={'field'}>{t('userProductModal.price.field')}</div>
            <div className={'input'}>
              <TextInput
                type={'price'}
                className={'price'}
                placeholder={t('userProductModal.price.placeholder')}
                size={'medium'}
                value={price}
                onChange={setPrice}
              />
              <Select
                options={currencyOptions}
                selectedOption={selectedCurrency}
                onChange={(option) => setSelectedCurrency(option!)}
                fontSize={'medium'}
                isClearable={false}
              />
            </div>
          </div>
          {/* URL */}
          <div className={'row mandatory' + (isUrlValid ? ' good' : '')}>
            <div className={'field'}>{t('userProductModal.url.field')}</div>
            <div className={'input'}>
              <TextInput
                type={'text'}
                placeholder={t('userProductModal.url.placeholder')}
                size={'medium'}
                value={url}
                onChange={setUrl}
              />
            </div>
          </div>
          {/* Image */}
          <div className={'row image mandatory' + (isImageValid ? ' good' : '')}>
            <div className={'field'}>{t('userProductModal.image.field')}</div>
            <div className={'input'}>
              {image === undefined ? (
                <Button
                  onClick={handleImageImport}
                  isLocked={false}
                  filled={true}
                  fontSize={'small'}
                >
                  {t('userProductModal.image.importButton')}
                </Button>
              ) : (
                <>
                  <ChatMessageFile
                    id={'preview'}
                    mimetype={image.mimetype}
                    name={image.name}
                    url={image.data}
                    filesize={image.size}
                    size={'medium'}
                    withHover={false}
                  />
                  <Button
                    onClick={handleImageImport}
                    isLocked={false}
                    filled={true}
                    fontSize={'small'}
                  >
                    {t('userProductModal.image.changeButton')}
                  </Button>
                </>
              )}
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleImageChoice}
                style={{ display: 'none' }}
              />
            </div>
          </div>
        </div>
      </>
    </Modal>
  )
}
