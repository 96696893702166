import { isPlatform } from '@ionic/react'
import config from '../config';
import axiosApi from './axios';

export const isApp = (): boolean => {
  const result: boolean = !isPlatform('mobileweb') && !isPlatform('desktop');
  return result;
}

export const isMobile = (): boolean => {
  const result: boolean = isPlatform('mobileweb') || isPlatform('mobile') || isPlatform('android') || isPlatform('ios');
  return result;
}

export const isAndroid = (): boolean => {
  const result: boolean = isPlatform('android');
  return result;
}

export async function isAppVersionOutdated(appVersion: string) {
  // app version format = 'X.Y.Z' where X,Y,Z are integers
  let result = false

  let response = undefined
  try {
    response = await axiosApi.get<string>(`${config.api.routes.minimalAppVersion}`)
  } catch (err) {
    console.error("[isAppVersionOutdated]", "an error occured while getting minimal app version:", err)
    return result;
  }

  const currentAppVersion = appVersion.split(".").map((el) => Number(el))
  if (currentAppVersion.length !== 3) {
    console.warn("[isAppVersionOutdated]", "app version not found")
    return result;
  }

  const minAppVersion = response.data.split(".").map((el) => Number(el))
  for (let i of [0, 1 ,2]) {
    if (currentAppVersion[i] < minAppVersion[i]) {
      result = true;
      break;
    } else if (currentAppVersion[i] > minAppVersion[i]) {
      result = false;
      break;
    }
  }

  console.debug("[isAppVersionOutdated]", `app version: '${appVersion}', min app version: '${response.data}', is outdated? ${result}`)
  return result;
}