import { Currency, ProfessionalDTO, UserProjectUpdateDTO } from '@fynde/dtos'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { RequestStatus } from '../../../utils/reqStatus'
import { Routes } from '../../../utils/Routes'
import { getFormattedDate } from '../../../utils/tool'
import { Button } from '../../atoms/Button/Button'
import { DateInput } from '../../atoms/DateInput/DateInput'
import { Feedback, FeedbackStatus } from '../../atoms/Feedback/Feedback'
import { Loading } from '../../atoms/Loading/Loading'
import { Select, SelectOption } from '../../atoms/Select/Select'
import { TextInput } from '../../atoms/TextInput/TextInput'
import { ProfessionalCard } from '../../molecules/ProfessionalCard/ProfessionalCard'
import { ProfessionalModal } from '../../molecules/ProfessionalModal/ProfessionalModal'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash-icon.svg'
import './ProjectPageSettingsTab.css'
import { ProfessionalInfoModal } from '../../molecules/ProfessionalInfoModal/ProfessionalInfoModal'

export interface ProjectPageSettingsTabProps {
  projectId: string
  show: boolean
}

export const ProjectPageSettingsTab: React.FC<ProjectPageSettingsTabProps> = ({
  projectId,
  show,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  // feedback
  const [feedbackContent, setFeedbackContent] = useState<[string, FeedbackStatus] | undefined>(
    undefined
  )

  // stored data
  const project = useStoreState((state) => state.projects.project(projectId))
  const projectProfessionals = useStoreState((state) =>
    state.projectProfessionals.userProjectProfessionals(projectId)
  )
  const projectProfessionalsLoadingStatus = useStoreState((state) =>
    state.projectProfessionals.userProjectLoadingStatus(projectId)
  )
  const fetchProfessionals = useStoreActions(
    (actions) => actions.projectProfessionals.fetchUserProjectProfessionals
  )

  useEffect(() => {
    fetchProfessionals(projectId)
  }, [])

  // current data
  const [projectName, setProjectName] = useState<string>('')
  const hasProjectNameChanged = useMemo(() => {
    return !!project && projectName !== (project.name || '')
  }, [project, projectName])
  const isProjectNameValid = useMemo(() => {
    return projectName.search(/\w/) > -1
  }, [projectName])

  const [projectDescription, setProjectDescription] = useState<string>('')
  const hasProjectDescriptionChanged = useMemo(() => {
    return !!project && projectDescription !== (project.description || '')
  }, [project, projectDescription])

  const [projectStartDate, setProjectStartDate] = useState<Date | undefined>(undefined)
  const hasProjectStartDateChanged = useMemo(() => {
    return !!project && projectStartDate !== (project.startDate || undefined)
  }, [project, projectStartDate])

  const [projectEndDate, setProjectEndDate] = useState<Date | undefined>(undefined)
  const hasProjectEndDateChanged = useMemo(() => {
    return !!project && projectEndDate !== (project.endDate || undefined)
  }, [project, projectEndDate])

  const [projectBudget, setProjectBudget] = useState<string>('')
  const currencyOptions: SelectOption[] = [
    { displayedText: '€', value: Currency.Euro },
    { displayedText: '$', value: Currency.UnitedStatesDollar },
    { displayedText: '£', value: Currency.PoundSterling },
    { displayedText: '¥', value: Currency.JapaneseYen },
  ]
  const [projectCurrency, setProjectCurrency] = useState<SelectOption>(currencyOptions[0])
  const hasProjectBudgetChanged = useMemo(() => {
    const isBudgetNull = projectBudget === '' || Number(projectBudget) === 0
    if (!project) return false
    if (isBudgetNull) return !!project.budgetValue
    return (
      Number(projectBudget) !== (project.budgetValue || 0) ||
      (projectCurrency.value as Currency) !== (project.budgetCurrency || Currency.Euro)
    )
  }, [project, projectBudget, projectCurrency])

  const [projectAddressLine1, setProjectAddressLine1] = useState<string>('')
  const [projectAddressLine2, setProjectAddressLine2] = useState<string>('')
  const [projectAddressZipcode, setProjectAddressZipcode] = useState<string>('')
  const [projectAddressCity, setProjectAddressCity] = useState<string>('')
  const [projectAddressRegion, setProjectAddressRegion] = useState<string>('')
  const [projectAddressCountry, setProjectAddressCountry] = useState<string>('')
  const hasProjectAddressChanged = useMemo(() => {
    return (
      !!project &&
      (projectAddressLine1 !== (project.projectAddress?.line1 || '') ||
        projectAddressLine2 !== (project.projectAddress?.line2 || '') ||
        projectAddressZipcode !== (project.projectAddress?.zipcode || '') ||
        projectAddressCity !== (project.projectAddress?.city || '') ||
        projectAddressRegion !== (project.projectAddress?.region || '') ||
        projectAddressCountry !== (project.projectAddress?.country || ''))
    )
  }, [
    project,
    projectAddressLine1,
    projectAddressLine2,
    projectAddressZipcode,
    projectAddressCity,
    projectAddressRegion,
    projectAddressCountry,
  ])

  const [deliveryAddressLine1, setDeliveryAddressLine1] = useState<string>('')
  const [deliveryAddressLine2, setDeliveryAddressLine2] = useState<string>('')
  const [deliveryAddressZipcode, setDeliveryAddressZipcode] = useState<string>('')
  const [deliveryAddressCity, setDeliveryAddressCity] = useState<string>('')
  const [deliveryAddressRegion, setDeliveryAddressRegion] = useState<string>('')
  const [deliveryAddressCountry, setDeliveryAddressCountry] = useState<string>('')
  const hasDeliveryAddressChanged = useMemo(() => {
    return (
      !!project &&
      (deliveryAddressLine1 !== (project.deliveryAddress?.line1 || '') ||
        deliveryAddressLine2 !== (project.deliveryAddress?.line2 || '') ||
        deliveryAddressZipcode !== (project.deliveryAddress?.zipcode || '') ||
        deliveryAddressCity !== (project.deliveryAddress?.city || '') ||
        deliveryAddressRegion !== (project.deliveryAddress?.region || '') ||
        deliveryAddressCountry !== (project.deliveryAddress?.country || ''))
    )
  }, [
    project,
    deliveryAddressLine1,
    deliveryAddressLine2,
    deliveryAddressZipcode,
    deliveryAddressCity,
    deliveryAddressRegion,
    deliveryAddressCountry,
  ])

  useEffect(() => {
    if (!project) return
    setProjectName(project.name)
    setProjectDescription(project.description || '')
    setProjectStartDate(project.startDate || undefined)
    setProjectEndDate(project.endDate || undefined)
    setProjectBudget(project.budgetValue?.toString() || '')
    setProjectCurrency(
      currencyOptions.filter((opt) => opt.value === project.budgetCurrency)[0] || currencyOptions[0]
    )
    setProjectAddressLine1(project.projectAddress?.line1 || '')
    setProjectAddressLine2(project.projectAddress?.line2 || '')
    setProjectAddressZipcode(project.projectAddress?.zipcode || '')
    setProjectAddressCity(project.projectAddress?.city || '')
    setProjectAddressRegion(project.projectAddress?.region || '')
    setProjectAddressCountry(project.projectAddress?.country || '')
    setDeliveryAddressLine1(project.deliveryAddress?.line1 || '')
    setDeliveryAddressLine2(project.deliveryAddress?.line2 || '')
    setDeliveryAddressZipcode(project.deliveryAddress?.zipcode || '')
    setDeliveryAddressCity(project.deliveryAddress?.city || '')
    setDeliveryAddressRegion(project.deliveryAddress?.region || '')
    setDeliveryAddressCountry(project.deliveryAddress?.country || '')
  }, [project])

  const hasDataChanged = useMemo(() => {
    // console.debug(
    //   '[ProjectPageSettingsTab] hasDataChanged?',
    //   `ProjectName: ${hasProjectNameChanged}, ProjectDescription: ${hasProjectDescriptionChanged}, ProjectStartDate: ${hasProjectStartDateChanged}, ProjectEndDate: ${hasProjectEndDateChanged}, ProjectBudget: ${hasProjectBudgetChanged}, ProjectBudget: ${hasProjectBudgetChanged}, ProjectAddress: ${hasProjectAddressChanged}, DeliveryAddress: ${hasDeliveryAddressChanged}`
    // )
    return (
      hasProjectNameChanged ||
      hasProjectDescriptionChanged ||
      hasProjectStartDateChanged ||
      hasProjectEndDateChanged ||
      hasProjectBudgetChanged ||
      hasProjectAddressChanged ||
      hasDeliveryAddressChanged
    )
  }, [
    hasProjectNameChanged,
    hasProjectDescriptionChanged,
    hasProjectStartDateChanged,
    hasProjectEndDateChanged,
    hasProjectBudgetChanged,
    hasProjectAddressChanged,
    hasDeliveryAddressChanged,
  ])

  // save
  const patchUserProject = useStoreActions((actions) => actions.projects.patchProject)
  const [isPatchingProject, setIsPatchingProject] = useState<boolean>(false)

  const isSaveButtonLocked = useMemo(() => {
    return isPatchingProject || !isProjectNameValid || !hasDataChanged
  }, [isPatchingProject, isProjectNameValid, hasDataChanged])

  const getUserProjectUpdateDTO = useCallback(() => {
    if (!project) return {}

    const isBudgetNull = projectBudget === ''

    let result: UserProjectUpdateDTO = {
      name: hasProjectNameChanged ? projectName : undefined,
      description: hasProjectDescriptionChanged ? projectDescription : undefined,
      startDate: hasProjectStartDateChanged ? projectStartDate || null : undefined,
      endDate: hasProjectEndDateChanged ? projectEndDate || null : undefined,
      budgetValue: hasProjectBudgetChanged
        ? isBudgetNull
          ? null
          : Number(projectBudget)
        : undefined,
      budgetCurrency: hasProjectBudgetChanged
        ? isBudgetNull
          ? null
          : (projectCurrency.value as Currency)
        : undefined,
      projectAddress: hasProjectAddressChanged
        ? {
            line1: projectAddressLine1,
            line2: projectAddressLine2,
            zipcode: projectAddressZipcode,
            city: projectAddressCity,
            region: projectAddressRegion,
            country: projectAddressCountry,
          }
        : undefined,
      deliveryAddress: hasDeliveryAddressChanged
        ? {
            line1: deliveryAddressLine1,
            line2: deliveryAddressLine2,
            zipcode: deliveryAddressZipcode,
            city: deliveryAddressCity,
            region: deliveryAddressRegion,
            country: deliveryAddressCountry,
          }
        : undefined,
    }

    // remove undefined and empty string values
    result = JSON.parse(
      JSON.stringify(result, (key, value) => {
        if (value === undefined) return undefined
        if (typeof value === 'string' && value.search(/\w/) === -1) return null
        return value
      })
    )
    return result
  }, [
    project,
    hasProjectNameChanged,
    projectName,
    hasProjectDescriptionChanged,
    projectDescription,
    hasProjectStartDateChanged,
    projectStartDate,
    hasProjectEndDateChanged,
    projectEndDate,
    hasProjectBudgetChanged,
    projectBudget,
    projectCurrency,
    hasProjectAddressChanged,
    projectAddressLine1,
    projectAddressLine2,
    projectAddressZipcode,
    projectAddressCity,
    projectAddressRegion,
    projectAddressCountry,
    hasDeliveryAddressChanged,
    deliveryAddressLine1,
    deliveryAddressLine2,
    deliveryAddressZipcode,
    deliveryAddressCity,
    deliveryAddressRegion,
    deliveryAddressCountry,
  ])

  const handleSave = useCallback(async () => {
    if (!project) return

    const updateDto = getUserProjectUpdateDTO()
    console.debug('[ProjectPageSettingsTab] patch user-project:', updateDto)

    setIsPatchingProject(true)
    const success = await patchUserProject({ projectId: project.id, dto: updateDto })
    if (success)
      setFeedbackContent([t('project.settings.save.successFeedback'), FeedbackStatus.Success])
    else setFeedbackContent([t('project.settings.save.errorFeedback'), FeedbackStatus.Error])
    setIsPatchingProject(false)
  }, [project, getUserProjectUpdateDTO, setFeedbackContent, patchUserProject, t])

  // professionals
  const [showProfessionalModal, setShowProfessionalModal] = useState<boolean>(false)
  const [isProfessionalInfoModalLocked, setIsProfessionalInfoModalLocked] = useState<boolean>(false)
  const [shownProfessionalId, setShownProfessionalId] = useState<string | undefined>(undefined)
  const [professionalToEdit, setProfessionalToEdit] = useState<ProfessionalDTO | undefined>(
    undefined
  )

  const deleteUserProjectProfessional = useStoreActions(
    (actions) => actions.projectProfessionals.deleteUserProjectProfessional
  )
  const handleUserProjectProfessionalDeletion = useCallback(
    async (professional: ProfessionalDTO) => {
      setIsProfessionalInfoModalLocked(true)
      await deleteUserProjectProfessional({
        userProjectId: projectId,
        professionalId: professional.id,
      })
      setIsProfessionalInfoModalLocked(false)
      setShownProfessionalId(undefined)
    },
    [
      setIsProfessionalInfoModalLocked,
      deleteUserProjectProfessional,
      setShownProfessionalId,
      projectId,
    ]
  )

  // deletion
  const isTheProjectOwner = useStoreState((state) => state.projects.isTheProjectOwner)
  const user = useStoreState((state) => state.user.user)
  const deleteProject = useStoreActions((actions) => actions.projects.deleteProject)

  const isCurrentUserTheProjectOwner = useMemo(() => {
    return isTheProjectOwner(projectId, user.email)
  }, [projectId, user, isTheProjectOwner])

  const handleDeletion = useCallback(async () => {
    console.debug('[ProjectPageSettingsTab] delete user-project:', projectId)
    setIsPatchingProject(true)
    const success = await deleteProject(projectId)
    if (success) {
      history.push(Routes.Projects)
    } else {
      setFeedbackContent([t('project.settings.delete.errorFeedback'), FeedbackStatus.Error])
    }
    setIsPatchingProject(false)
  }, [projectId, history, deleteProject, t, setFeedbackContent])

  return (
    <div className={'project-settings-wrapper' + (show ? '' : ' hidden')}>
      <Feedback
        isVisible={!!feedbackContent}
        onComplete={() => setFeedbackContent(undefined)}
        message={feedbackContent ? feedbackContent[0] : ''}
        status={feedbackContent ? feedbackContent[1] : FeedbackStatus.Idle}
      />

      <ProfessionalModal
        isOpen={showProfessionalModal}
        onCancel={() => {
          setShowProfessionalModal(false)
          setProfessionalToEdit(undefined)
        }}
        onSubmit={() => {
          setShowProfessionalModal(false)
          setProfessionalToEdit(undefined)
        }}
        userProjectId={projectId}
        professional={professionalToEdit}
      />

      <ProfessionalInfoModal
        isOpen={!!shownProfessionalId}
        isLocked={isProfessionalInfoModalLocked}
        onEdit={(professional) => {
          setProfessionalToEdit(professional)
          setShownProfessionalId(undefined)
          setShowProfessionalModal(true)
        }}
        onDelete={handleUserProjectProfessionalDeletion}
        onCancel={() => setShownProfessionalId(undefined)}
        professionalId={shownProfessionalId!}
      />

      <h2>{t('project.settings.projectHeader')}</h2>
      {/* Project's name */}
      <div className={'row'}>
        <span className={'label'}>{t('project.settings.name.label')}</span>
        <div className={'data'}>
          <TextInput
            type={'text'}
            value={projectName}
            onChange={setProjectName}
            placeholder={t('project.settings.name.placeholder')}
            size={'medium'}
            isLocked={isPatchingProject}
            isWrong={!isProjectNameValid}
          />
        </div>
      </div>
      {/* Project's description */}
      <div className={'row'}>
        <span className={'label'}>{t('project.settings.description.label')}</span>
        <div className={'data'}>
          <TextInput
            type={'textarea'}
            value={projectDescription}
            onChange={setProjectDescription}
            placeholder={t('project.settings.description.placeholder')}
            size={'medium'}
            textAreaRowsCount={4}
            isLocked={isPatchingProject}
          />
        </div>
      </div>
      {/* Project's duration */}
      <div className={'row duration'}>
        <span className={'label'}>{t('project.settings.duration.label')}</span>
        <div className={'data'}>
          <span className={'label'}>{t('project.settings.duration.from')}</span>
          <DateInput
            value={projectStartDate}
            onChange={setProjectStartDate}
            placeholder={t('project.settings.duration.placeholder')}
            size={'medium'}
            noCross={false}
            isLocked={isPatchingProject}
          />
          <span className={'label'}>{t('project.settings.duration.to')}</span>
          <DateInput
            value={projectEndDate}
            onChange={setProjectEndDate}
            placeholder={t('project.settings.duration.placeholder')}
            size={'medium'}
            noCross={false}
            isLocked={isPatchingProject}
          />
        </div>
      </div>
      {/* Project's budget */}
      <div className={'row'}>
        <span className={'label'}>{t('project.settings.budget.label')}</span>
        <div className={'data'}>
          <TextInput
            className={'project-price-input'}
            type={'price'}
            value={projectBudget}
            onChange={setProjectBudget}
            placeholder={t('project.settings.budget.placeholder')}
            size={'medium'}
            maxPrice={1000000000}
            isLocked={isPatchingProject}
          />
          <Select
            options={currencyOptions}
            selectedOption={projectCurrency}
            onChange={(option) => setProjectCurrency(option!)}
            fontSize={'medium'}
            isClearable={false}
            isLocked={isPatchingProject}
          />
        </div>
      </div>
      {/* Project's address */}
      <div className={'row'}>
        <span className={'label'}>{t('project.settings.deliveryAddress.label')}</span>
        <div className={'rows'}>
          <div className={'row'}>
            <span className={'label'}>{t('address.line1.label')}</span>
            <div className={'data'}>
              <TextInput
                type={'text'}
                value={projectAddressLine1}
                onChange={setProjectAddressLine1}
                placeholder={t('address.line1.placeholder')}
                size={'medium'}
                isLocked={isPatchingProject}
              />
            </div>
          </div>
          <div className={'row'}>
            <span className={'label'}>{t('address.line2.label')}</span>
            <div className={'data'}>
              <TextInput
                type={'text'}
                value={projectAddressLine2}
                onChange={setProjectAddressLine2}
                placeholder={t('address.line2.placeholder')}
                size={'medium'}
                isLocked={isPatchingProject}
              />
            </div>
          </div>
          <div className={'row'}>
            <span className={'label'}>{t('address.zipcode.label')}</span>
            <div className={'data'}>
              <TextInput
                type={'text'}
                value={projectAddressZipcode}
                onChange={setProjectAddressZipcode}
                placeholder={t('address.zipcode.placeholder')}
                size={'medium'}
                isLocked={isPatchingProject}
              />
            </div>
          </div>
          <div className={'row'}>
            <span className={'label'}>{t('address.city.label')}</span>
            <div className={'data'}>
              <TextInput
                type={'text'}
                value={projectAddressCity}
                onChange={setProjectAddressCity}
                placeholder={t('address.city.placeholder')}
                size={'medium'}
                isLocked={isPatchingProject}
              />
            </div>
          </div>
          <div className={'row'}>
            <span className={'label'}>{t('address.region.label')}</span>
            <div className={'data'}>
              <TextInput
                type={'text'}
                value={projectAddressRegion}
                onChange={setProjectAddressRegion}
                placeholder={t('address.region.placeholder')}
                size={'medium'}
                isLocked={isPatchingProject}
              />
            </div>
          </div>
          <div className={'row'}>
            <span className={'label'}>{t('address.country.label')}</span>
            <div className={'data'}>
              <TextInput
                type={'text'}
                value={projectAddressCountry}
                onChange={setProjectAddressCountry}
                placeholder={t('address.country.placeholder')}
                size={'medium'}
                isLocked={isPatchingProject}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Project's creation date */}
      <div className={'row'}>
        <span className={'label'}>{t('project.settings.creationDate.label')}</span>
        <div className={'data'}>
          <span className={'label'}>
            {project ? getFormattedDate(project?.createdAt, 'DMY') : ''}
          </span>
        </div>
      </div>

      <h2>{t('project.settings.deliveryHeader')}</h2>
      {/* Delivery address */}
      <div className={'row'}>
        <span className={'label'}>{t('project.settings.projectAddress.label')}</span>
        <div className={'rows'}>
          <div className={'row'}>
            <span className={'label'}>{t('address.line1.label')}</span>
            <div className={'data'}>
              <TextInput
                type={'text'}
                value={deliveryAddressLine1}
                onChange={setDeliveryAddressLine1}
                placeholder={t('address.line1.placeholder')}
                size={'medium'}
                isLocked={isPatchingProject}
              />
            </div>
          </div>
          <div className={'row'}>
            <span className={'label'}>{t('address.line2.label')}</span>
            <div className={'data'}>
              <TextInput
                type={'text'}
                value={deliveryAddressLine2}
                onChange={setDeliveryAddressLine2}
                placeholder={t('address.line2.placeholder')}
                size={'medium'}
                isLocked={isPatchingProject}
              />
            </div>
          </div>
          <div className={'row'}>
            <span className={'label'}>{t('address.zipcode.label')}</span>
            <div className={'data'}>
              <TextInput
                type={'text'}
                value={deliveryAddressZipcode}
                onChange={setDeliveryAddressZipcode}
                placeholder={t('address.zipcode.placeholder')}
                size={'medium'}
                isLocked={isPatchingProject}
              />
            </div>
          </div>
          <div className={'row'}>
            <span className={'label'}>{t('address.city.label')}</span>
            <div className={'data'}>
              <TextInput
                type={'text'}
                value={deliveryAddressCity}
                onChange={setDeliveryAddressCity}
                placeholder={t('address.city.placeholder')}
                size={'medium'}
                isLocked={isPatchingProject}
              />
            </div>
          </div>
          <div className={'row'}>
            <span className={'label'}>{t('address.region.label')}</span>
            <div className={'data'}>
              <TextInput
                type={'text'}
                value={deliveryAddressRegion}
                onChange={setDeliveryAddressRegion}
                placeholder={t('address.region.placeholder')}
                size={'medium'}
                isLocked={isPatchingProject}
              />
            </div>
          </div>
          <div className={'row'}>
            <span className={'label'}>{t('address.country.label')}</span>
            <div className={'data'}>
              <TextInput
                type={'text'}
                value={deliveryAddressCountry}
                onChange={setDeliveryAddressCountry}
                placeholder={t('address.country.placeholder')}
                size={'medium'}
                isLocked={isPatchingProject}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Professionals */}
      {isCurrentUserTheProjectOwner && (
        <>
          <h2>{t('project.settings.professionalsHeader')}</h2>
          <div className={'professional-cards'}>
            {projectProfessionalsLoadingStatus !== RequestStatus.Completed ? (
              <Loading isLight={true} />
            ) : (
              projectProfessionals.map((professional) => (
                <ProfessionalCard
                  key={professional.id}
                  name={professional.companyName}
                  job={professional.job}
                  onShowMore={() => setShownProfessionalId(professional.id)}
                />
              ))
            )}
          </div>

          <Button
            Picto={PlusIcon}
            onClick={() => setShowProfessionalModal(true)}
            isLocked={isPatchingProject}
            filled={true}
            fontSize={'medium'}
          >
            {t('project.settings.professionals.addButton')}
          </Button>
        </>
      )}

      {/* Management */}
      {isCurrentUserTheProjectOwner && (
        <>
          <h2>{t('project.settings.managementHeader')}</h2>
          <Button
            Picto={DeleteIcon}
            onClick={handleDeletion}
            isLocked={isPatchingProject}
            filled={true}
            fontSize={'medium'}
          >
            {t('project.settings.delete.button')}
          </Button>
        </>
      )}

      {/* Save */}
      <div className={'save-button-wrapper' + (!isSaveButtonLocked ? ' sticky' : '')}>
        <Button onClick={handleSave} isLocked={isSaveButtonLocked} filled={true} fontSize={'large'}>
          {t('project.settings.save.button')}
        </Button>
      </div>
    </div>
  )
}
