import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dots } from '../../atoms/Dots/Dots'
import { TapToFynde } from '../TapToFynde/TapToFynde'
import { ReactComponent as HeartIcon } from '../../../assets/icons/stroked-heart-icon.svg'
import { ReactComponent as HistoryIcon } from '../../../assets/icons/history-icon.svg'
import './AppHomeLayout.css'

export interface AppHomeLayoutProps {
  onTapToFind: () => void
  onLikesClick: () => void
  onHistoryClick: () => void
}

export const AppHomeLayout: React.FC<AppHomeLayoutProps> = ({
  onTapToFind,
  onLikesClick,
  onHistoryClick,
}) => {
  const { t } = useTranslation()

  return (
    <div className={'app-home-layout'}>
      <div className={'top-part'}>
        <TapToFynde
          onClick={onTapToFind}
          isMobile={true}
        />
      </div>
    
      <div className={'bottom-part'}>

        <div className={'cta'}>
          <button onClick={onLikesClick}>
            <HeartIcon />
            <span>
              {t('homeLayout.saved')}
            </span>
          </button>
        </div>

        <div className={'cta'}>
          <Dots number={3} active={1} color="black" />
        </div>

        <div className={'cta'}>
          <button onClick={onHistoryClick}>
            <HistoryIcon />
            <span>
              {t('homeLayout.history')}
            </span>
          </button>
        </div>

      </div>
    </div>
  )
}
