import { IonPage, IonContent, useIonViewDidEnter } from '@ionic/react'
import React from 'react'
import { SearchLayout } from '../components/organisms/SearchLayout/SearchLayout'
import { useStoreState, useStoreActions } from '../store/hooks'
import { BaseDesktopLayout } from '../components/organisms/BaseDesktopLayout/BaseDesktopLayout'
import { HeaderTabs } from '../components/molecules/DesktopHeader/DesktopHeader'
import { useTranslation } from 'react-i18next'
import config from '../config'
import { isApp } from '../utils/platform'

const Search: React.FC = () => {
  const { t } = useTranslation()
  const useAppLayout = isApp()

  // title
  useIonViewDidEnter(() => {
    document.title = config.pageTitlePrefix + t('pageTitle.Search')
  }, [t])

  // scroll
  const areAllResultsLoaded = useStoreState((state) => state.search.areAllResultsLoaded)
  const isFetchingMore = useStoreState((state) => state.search.isFetchingMore)
  const fetchMore = useStoreActions((actions) => actions.search.fetchMore)

  const onBottomHit = () => {
    if (!areAllResultsLoaded && !isFetchingMore) fetchMore()
  }

  return (
    <>
      {useAppLayout ? (
        <IonPage>
          <IonContent forceOverscroll={false}>
            <SearchLayout onBottomHit={onBottomHit} />
          </IonContent>
        </IonPage>
      ) : (
        <BaseDesktopLayout
          selectedHeaderTab={HeaderTabs.Search}
          onBottomHit={onBottomHit}
          onBottomHitOffset={170}
        >
          <SearchLayout />
        </BaseDesktopLayout>
      )}
    </>
  )
}

export default Search
