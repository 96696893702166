import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: row;

  & > * + * {
    margin-left: 8px;
  }
`

const Dot = styled.div<{
  active: boolean
  color: 'white' | 'black'
  small?: boolean
  squared?: boolean
}>`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;

  transition: background-color 0.3s ease-in-out;

  ${(props) =>
    props.color === 'black'
      ? css`
          background-color: ${props.active ? props.theme.colors.black : props.theme.colors.grey400};
        `
      : css`
          background-color: ${props.active ? props.theme.colors.white : '#ffffff60'};
        `}

  ${(props) =>
    props.small
      ? css`
          height: 6px;
          width: 6px;
        `
      : css`
          height: 0.5rem;
          width: 0.5rem;
        `}

  ${(props) =>
    props.squared
      ? css`
          border-radius: 45%;
        `
      : css`
          border-radius: 50%;
        `}        
`

export interface DotsProps {
  number: number
  active: number
  color?: 'white' | 'black'
  small?: boolean
  squared?: boolean
}

export const Dots: React.FC<DotsProps> = ({ number, active, color = 'black', small, squared }) => {
  const [dots, setDots] = useState(
    Array(number)
      .fill(null)
      .map((x, i) => i)
  )

  useEffect(() => {
    setDots(
      Array(number)
        .fill(null)
        .map((x, i) => i)
    )
  }, [number])

  return (
    <Container>
      {dots.map((d, index) => {
        return (
          <Dot
            active={index === active}
            key={`dots_${index}`}
            color={color}
            small={small}
            squared={squared}
          />
        )
      })}
    </Container>
  )
}
