import { Thunk, thunk, Action, action, Computed, computed } from 'easy-peasy'
import { ProductVariationPaginatedDTO } from '@fynde/dtos'
import { RequestStatus } from '../utils/reqStatus'
import axiosApi from '../utils/axios'
import { StoreModel } from './store'

export const ITEM_PER_PAGE = 20

export interface SimilarProductsModel {
  reqStatus: RequestStatus
  setReqStatus: Action<SimilarProductsModel, RequestStatus>

  reqVisualSearchId: string | null
  setReqVisualSearchId: Action<SimilarProductsModel, string>

  reqObjectTypes: string[] | null
  setReqObjectTypes: Action<SimilarProductsModel, string[] | null>

  reqStatusMore: RequestStatus
  setReqStatusMore: Action<SimilarProductsModel, RequestStatus>

  results: ProductVariationPaginatedDTO['data'] | null
  wrongResultsCount: number
  setResults: Action<SimilarProductsModel, ProductVariationPaginatedDTO['data'] | null>
  appendResults: Action<SimilarProductsModel, ProductVariationPaginatedDTO['data']>

  setTotal: Action<SimilarProductsModel, number | null>
  total: number | null

  hasMore: Computed<SimilarProductsModel, boolean>

  fetch: Thunk<SimilarProductsModel, void, any, StoreModel>
  fetchMore: Thunk<SimilarProductsModel, void | (() => void), any, StoreModel>
}

export const similarProducts: SimilarProductsModel = {
  reqStatus: RequestStatus.Idle,
  setReqStatus: action((state, requestStatus) => {
    state.reqStatus = requestStatus
  }),

  reqVisualSearchId: null,
  reqObjectTypes: null,
  reqStatusMore: RequestStatus.Idle,
  setReqStatusMore: action((state, requestStatus) => {
    state.reqStatusMore = requestStatus
  }),

  results: null,
  wrongResultsCount: 0,
  setResults: action((state, results) => {
    if (results == null) {
      state.results = [];
    } else {
      const nonNullResults = results?.filter((result) => result != null);
      state.results = [...nonNullResults];
      state.wrongResultsCount = results.length - nonNullResults.length;
    }
    console.debug("[similarProducts, setResults] results: " + state.results.length + ", wrong: " + state.wrongResultsCount);
  }),
  appendResults: action((state, results) => {
    const nonNullResults = results.filter((result) => result != null);
    state.results = state.results === null ? nonNullResults : [...state.results, ...nonNullResults];
    state.wrongResultsCount += results.length - nonNullResults.length;
    console.debug("[similarProducts, appendResults] results: " + state.results.length + ", wrong: " + state.wrongResultsCount);
  }),

  total: null,
  setTotal: action((state, total) => {
    state.total = total
  }),

  hasMore: computed([(state) => state.total, (state) => state.results?.length, (state) => state.wrongResultsCount], (total, loaded, wrong) => {
    return (total || 0) > (loaded || 0) + (wrong || 0)
  }),

  setReqVisualSearchId: action((state, payload) => {
    state.reqVisualSearchId = payload
    state.reqStatus = RequestStatus.Idle
    state.results = null
  }),

  setReqObjectTypes: action((state, payload) => {
    state.reqObjectTypes = payload
    state.reqStatus = RequestStatus.Idle
    state.results = null
  }),

  fetch: thunk(async (actions, payload, { getState, getStoreState }) => {
    const state = getState()
    const storeState = getStoreState()
    const vsId = state.reqVisualSearchId
    const objectTypes = state.reqObjectTypes

    actions.setReqStatus(RequestStatus.Loading)
    actions.setResults([])

    const reqParams = {
      visualSearchId: vsId,
      objectDetectionTypes: objectTypes,
      skip: 0,
      take: ITEM_PER_PAGE,
      firstVariationOnly: true,
      worldArea: storeState.user.worldArea,
    }
    console.debug('[store.similar-products] Fetch results, params:', JSON.stringify(reqParams));

    const resp = await axiosApi.get<ProductVariationPaginatedDTO>('/product-variations', {
      params: reqParams,
    })
    console.debug(resp.config);

    if (getState().reqVisualSearchId !== vsId) {
      return console.debug(
        "[store.similar-products] Dropping request's results because Visual Search ID changed during request"
      )
    }

    actions.setResults(resp.data.data)
    actions.setTotal(resp.data.total)
    actions.setReqStatus(RequestStatus.Completed)
  }),

  fetchMore: thunk(async (actions, payload, { getState, getStoreState }) => {
    const state = getState()
    const storeState = getStoreState()
    const vsId = state.reqVisualSearchId
    const objectTypes = state.reqObjectTypes

    if (!state.results) {
      return
    }

    actions.setReqStatusMore(RequestStatus.Loading)

    const params = {
      visualSearchId: vsId,
      objectDetectionTypes: objectTypes,
      skip: state.results.length + state.wrongResultsCount,
      take: ITEM_PER_PAGE,
      firstVariationOnly: true,
      worldArea: storeState.user.worldArea,
    }
    console.debug(
      "[store.similar-products] Fetch more results, params:", params
    )

    const resp = await axiosApi.get<ProductVariationPaginatedDTO>('/product-variations', {
      params: params,
    })

    if (getState().reqVisualSearchId !== vsId) {
      return console.debug(
        "[store.similar-products] Dropping request's results because Visual Search ID changed during request"
      )
    }

    actions.appendResults(resp.data.data)
    actions.setReqStatusMore(RequestStatus.Completed)

    if (payload) {
      payload()
    }
  }),
}
