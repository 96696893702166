import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { LocalUserFile } from '../../../store/user-files'
import { scrollToIonContentBottom } from '../../../utils/tool'
import {
  ChatMessage,
  ChatMessageProps,
  LoadingChatMessage,
} from '../../atoms/ChatMessage/ChatMessage'
import { ChatMessageFileData } from '../../atoms/ChatMessageFile/ChatMessageFile'
import { RichTextEditor } from '../../atoms/RichTextEditor/RichTextEditor'
import { UserMessageFileDeletionModal } from '../../organisms/UserMessageFileDeletionModal/UserMessageFileDeletionModal'
import { UserMessageFileRenamingModal } from '../../organisms/UserMessageFileRenamingModal/UserMessageFileRenamingModal'
import { CenteredLoading } from '../CenteredLoading/CenteredLoading'
import './Chat.css'

const Feed = styled.div<{ maxHeight: string }>`
  max-height: ${(props) => props.maxHeight};
`

export interface ChatProps {
  isLoading: boolean
  messages: ChatMessageProps[]
  pendingMessages: number
  localFiles: LocalUserFile[]
  maxHeight?: string
  onSubmit: (
    messageId: string | undefined,
    messageText: string,
    messageFiles: LocalUserFile[]
  ) => void
  onNewFileUpload?: (files: File[], userMessageId: string | null) => void
  onNewFileRenaming?: (fileId: string, newName: string) => void
  onNewFileDelete?: (fileId: string) => void
  onMessageFileDownload?: (fileId: string) => void
  onMessageFileRenaming?: (fileId: string, newName: string) => void
  onMessageFileDeletion?: (userMessageId: string, userFileId: string) => void
  userName: string
  scrollOnEditorSizeChange: boolean
}

export const Chat: React.FC<ChatProps> = ({
  isLoading,
  messages,
  pendingMessages,
  localFiles,
  maxHeight = '100vh',
  onSubmit,
  onNewFileUpload,
  onNewFileRenaming,
  onNewFileDelete,
  onMessageFileDownload,
  onMessageFileRenaming,
  onMessageFileDeletion,
  userName,
  scrollOnEditorSizeChange,
}) => {
  const { t } = useTranslation()

  let withMessages = messages.length > 0 || pendingMessages > 0

  // message update /!\ complex logic
  // RichTextEditor's content is updated when beingEditedMessageId changes from undefined to string
  // If we want to change beingEditedMessageId, we need to set it as undefined before setting a new value
  const [beingEditedMessageId, setBeingEditedMessageId] = useState<string | undefined>(undefined)
  const [nextMessageIdToEdit, setNextMessageIdToEdit] = useState<string | undefined>(undefined)

  const handleMessageUpdate = useCallback(
    (message: ChatMessageProps) => {
      // already editing another message
      if (beingEditedMessageId) {
        setNextMessageIdToEdit(message.id)
        setBeingEditedMessageId(undefined)
      }
      // no message being edited
      else {
        setBeingEditedMessageId(message.id)
        if (message.onUpdate) message.onUpdate(message.id)
      }
    },
    [beingEditedMessageId, setBeingEditedMessageId, setNextMessageIdToEdit]
  )

  useEffect(() => {
    if (beingEditedMessageId === undefined && nextMessageIdToEdit) {
      setBeingEditedMessageId(nextMessageIdToEdit)
      setNextMessageIdToEdit(undefined)
    }
  }, [beingEditedMessageId])

  const handleCancelMessageUpdate = useCallback((message: ChatMessageProps) => {
    setBeingEditedMessageId(undefined)
    if (message.onCancelUpdate) message.onCancelUpdate()
  }, [])

  // edit last message shortkey
  const handleEditLast = useCallback(() => {
    console.debug('[Chat]', 'edit last message')
    if (messages.length === 0) return
    const userMessages = messages
      .filter((message) => message.userName === userName)
      .sort((first, second) => second.creationDate.getTime() - first.creationDate.getTime())
    if (userMessages.length > 0) setBeingEditedMessageId(userMessages[0].id)
  }, [messages, userName])

  // cancel shortkey
  const handleRichTextEditorCancel = useCallback(() => {
    if (messages.length > 0 && beingEditedMessageId !== undefined) {
      handleCancelMessageUpdate(
        messages.filter((message) => message.id === beingEditedMessageId)[0]
      )
    }
  }, [beingEditedMessageId, messages, handleCancelMessageUpdate])

  // submit
  const submitMessage = useCallback(
    (newMessage: string, newFiles: LocalUserFile[]) => {
      console.debug('[Chat]', `submitMessage, ${newMessage.length} chars, ${newFiles.length} files`)
      onSubmit(beingEditedMessageId, newMessage, newFiles)
      if (beingEditedMessageId) setBeingEditedMessageId(undefined)
    },
    [onSubmit, beingEditedMessageId]
  )

  // upload a new file
  const handleNewFileUpload = useCallback(
    (files: File[]) => {
      if (onNewFileUpload) onNewFileUpload(files, beingEditedMessageId || null)
    },
    [beingEditedMessageId, onNewFileUpload]
  )

  // rename a new file
  const [newFileToRename, setNewFileToRename] = useState<ChatMessageFileData | undefined>(undefined)

  const handleNewFileRenaming = useCallback(
    (fileId: string) => {
      const file = localFiles.filter((file) => file.id === fileId)[0]
      setNewFileToRename({
        id: file.id,
        name: file.name,
        filesize: file.file.size,
        mimetype: file.file.type,
        url: file.dataUrl || file.name,
      })
    },
    [localFiles, setNewFileToRename]
  )

  // rename a user-file
  const getUserFile = useCallback(
    (userFileId: string) => {
      for (const message of messages) {
        if (!message.files) continue
        for (const file of message.files) {
          if (file.id === userFileId) return file
        }
      }
    },
    [messages]
  )

  const [messageFileToRename, setMessageFileToRename] = useState<ChatMessageFileData | undefined>(
    undefined
  )
  const handleMessageFileRenaming = useCallback(
    (userFileId: string) => {
      const userFile = getUserFile(userFileId)
      setMessageFileToRename(userFile)
    },
    [getUserFile]
  )

  // delete a user-file
  const [messageFileToDelete, setMessageFileToDelete] = useState<
    { userMessageId: string; messageFile: ChatMessageFileData } | undefined
  >(undefined)

  const handleMessageFileDeletion = useCallback(
    (userMessageId: string, userFileId: string) => {
      const userFile = getUserFile(userFileId)
      if (userFile) setMessageFileToDelete({ userMessageId: userMessageId, messageFile: userFile })
    },
    [getUserFile]
  )

  return (
    <div className={'chat'}>
      <Feed className={'feed'} maxHeight={maxHeight}>
        <>
          {newFileToRename && onNewFileRenaming !== undefined && (
            <UserMessageFileRenamingModal
              isOpen={true}
              onCancel={() => setNewFileToRename(undefined)}
              onSubmit={onNewFileRenaming}
              chatMessageFileData={newFileToRename}
            />
          )}

          {isLoading ? (
            <CenteredLoading isLight={true} />
          ) : withMessages === false ? (
            // no message
            <ReactMarkdown className={'empty-msg light-txt'}>
              {t('chat.noMessage').replace(/\n/g, '  \n')}
            </ReactMarkdown>
          ) : (
            // with message
            <>
              {messageFileToRename && onMessageFileRenaming !== undefined && (
                <UserMessageFileRenamingModal
                  isOpen={true}
                  onCancel={() => setMessageFileToRename(undefined)}
                  onSubmit={onMessageFileRenaming}
                  chatMessageFileData={messageFileToRename}
                />
              )}

              {messageFileToDelete && onMessageFileDeletion !== undefined && (
                <UserMessageFileDeletionModal
                  isOpen={true}
                  onCancel={() => setMessageFileToDelete(undefined)}
                  onSubmit={onMessageFileDeletion}
                  userMessageId={messageFileToDelete.userMessageId}
                  chatMessageFileData={messageFileToDelete.messageFile}
                />
              )}

              {messages.length > 0 &&
                messages
                  .sort(
                    (first, second) => first.creationDate.getTime() - second.creationDate.getTime()
                  )
                  .map((message) => (
                    <ChatMessage
                      key={message.id}
                      {...message}
                      beingEdited={message.id === beingEditedMessageId}
                      onUpdate={
                        userName === message.userName
                          ? () => handleMessageUpdate(message)
                          : undefined
                      }
                      onCancelUpdate={
                        userName === message.userName
                          ? () => handleCancelMessageUpdate(message)
                          : undefined
                      }
                      onFileDownload={onMessageFileDownload}
                      onFileRename={handleMessageFileRenaming}
                      onFileDeletion={handleMessageFileDeletion}
                    />
                  ))}
              {pendingMessages > 0 &&
                [...Array(pendingMessages).keys()].map((key) => (
                  <LoadingChatMessage key={`pending-${key}`} />
                ))}
            </>
          )}
        </>
      </Feed>

      <div className={'grower'} />

      <div className={'new-message'}>
        <RichTextEditor
          initialText={
            beingEditedMessageId
              ? messages.filter((msg) => msg.id === beingEditedMessageId)[0].text
              : undefined
          }
          isEditing={beingEditedMessageId !== undefined}
          onSubmit={submitMessage}
          onEditLast={handleEditLast}
          onCancel={handleRichTextEditorCancel}
          onFileUpload={handleNewFileUpload}
          onFileRename={handleNewFileRenaming}
          onFileDelete={onNewFileDelete}
          files={localFiles.filter((file) => file.userMessageId === (beingEditedMessageId || null))}
          onSizeChange={() => {
            if (scrollOnEditorSizeChange) scrollToIonContentBottom(true)
          }}
          isLocked={pendingMessages > 0}
        />
      </div>
    </div>
  )
}
