import React, { useState, useCallback, useEffect, useRef, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContentState,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import { Button } from '../Button/Button'
import BoldIcon from '../../../assets/icons/rich-text-editor-bold-icon.svg'
import ItalicIcon from '../../../assets/icons/rich-text-editor-italic-icon.svg'
import UnderlineIcon from '../../../assets/icons/rich-text-editor-underline-icon.svg'
import StrikeThroughIcon from '../../../assets/icons/rich-text-editor-strikethrough-icon.svg'
import FileIcon from '../../../assets/icons/file-icon.svg'
import './RichTextEditor.css'
import 'draft-js/dist/Draft.css'
import { ChatMessageFile } from '../ChatMessageFile/ChatMessageFile'
import { LocalUserFile } from '../../../store/user-files'

export enum RichTextStyle {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  UNDERLINE = 'UNDERLINE',
  STRIKETHROUGH = 'STRIKETHROUGH',
}

export interface RichTextEditorProps {
  initialText?: string
  files?: LocalUserFile[]
  isEditing: boolean
  isLocked: boolean
  onSubmit: (message: string, files: LocalUserFile[]) => void
  onEditLast?: () => void
  onCancel?: () => void
  onFileUpload?: (files: File[]) => void
  onFileRename?: (fileId: string) => void
  onFileDelete?: (fileId: string) => void
  onSizeChange?: () => void
}

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  initialText,
  files,
  isEditing,
  isLocked,
  onSubmit,
  onEditLast,
  onCancel,
  onFileUpload,
  onFileRename,
  onFileDelete,
  onSizeChange,
}) => {
  const { t } = useTranslation()

  const containerRef = React.useRef<HTMLDivElement>(null)
  const editor = React.useRef<Editor>(null)

  const editorStateFromText = useCallback((text: string | undefined) => {
    return text === undefined
      ? EditorState.createEmpty()
      : EditorState.createWithContent(stateFromHTML(text))
  }, [])

  const [editorState, setEditorState] = useState<EditorState>(editorStateFromText(initialText))

  // text
  const setEditorStateWithString = useCallback(
    (text: string | undefined) => {
      setEditorState(editorStateFromText(text))
    },
    [setEditorState, editorStateFromText]
  )

  // editing /!\ complex logic
  // - When entering on edit mode, we want to give focus to the end of the new text
  // as setEditorState is asynchronous, we need to be sure it has been set
  // before giving focus with useEffect
  // - stateBeforeEditing is used to set unsaved text back when leaving edit mode
  const [stateBeforeEditing, setStateBeforeEditing] = useState<EditorState | null>(null)
  const [hasFocusAtEditingStart, setHasFocusAtEditingStart] = useState<boolean>(true)

  useEffect(() => {
    if (isEditing) {
      setStateBeforeEditing(editorState)
      setHasFocusAtEditingStart(false)
      setEditorStateWithString(initialText)
    } else if (stateBeforeEditing) {
      setEditorState(stateBeforeEditing!)
    }
  }, [isEditing])

  useEffect(() => {
    if (isEditing && !hasFocusAtEditingStart) {
      focusEditor()
      moveSelectionToEnd()
      setHasFocusAtEditingStart(true)
    }
    checkSizeChange()
  }, [editorState])

  // focus
  const moveSelectionToEnd = useCallback(() => {
    const newState = EditorState.moveSelectionToEnd(editorState)
    setEditorState(EditorState.forceSelection(newState, newState.getSelection()))
  }, [editorState])

  const focusEditor = useCallback(() => {
    if (editor.current) {
      editor.current.focus()
    }
  }, [editor])

  // clear
  const clearMessage = useCallback(() => {
    const newState = EditorState.push(editorState, ContentState.createFromText(''), 'remove-range')
    setEditorState(newState)
  }, [editorState, setEditorState])

  // cleaning
  const removeStartAndEndWhiteSpaces = useCallback(
    (currentState: ContentState) => {
      // First, we need to remove empty blocks to avoid unwanted carriage returns.
      // To remove a block, we need to remove a selection range which contains
      // the block to delete and the next one (if forward) or the previous one (if backward).
      // As the last block cannot be deleted with forward selection, we need to use a backward one
      let contentState = currentState
      let blockMap = contentState.getBlocksAsArray()
      // parse from start
      for (let i = 0; i < blockMap.length; i++) {
        const currentBlock = blockMap[i]
        // is empty ?
        if (currentBlock.getText().match(/\S/) === null) {
          // are all block empty?
          if (i === blockMap.length - 1) {
            return null
          } else {
            const nextBlock = blockMap[i + 1]
            let selection = editorState.getSelection()
            selection = selection.merge({
              anchorKey: currentBlock.getKey(),
              anchorOffset: 0,
              focusKey: nextBlock.getKey(),
              focusOffset: 0,
              isBackward: false,
            })
            contentState = Modifier.removeRange(contentState, selection, 'forward')
          }
        } else {
          break
        }
      }
      // parse from end
      blockMap = contentState.getBlocksAsArray()
      for (let i = blockMap.length - 1; i > 0; i--) {
        const currentBlock = blockMap[i]
        // is empty ?
        if (currentBlock.getText().match(/\S/) === null) {
          // at least one block is not empty
          const previousBlock = blockMap[i - 1]
          let selection = editorState.getSelection()
          selection = selection.merge({
            anchorKey: currentBlock.getKey(),
            anchorOffset: currentBlock.getText().length,
            focusKey: previousBlock.getKey(),
            focusOffset: previousBlock.getText().length,
            isBackward: true,
          })
          contentState = Modifier.removeRange(contentState, selection, 'backward')
        } else {
          break
        }
      }

      // remove start and end white spaces in first and last not empty blocks
      const firstBlock = contentState.getFirstBlock()
      const startWhiteSpaces = firstBlock.getText().match(/^\s+/)
      if (startWhiteSpaces !== null) {
        let selection = SelectionState.createEmpty(firstBlock.getKey())
        selection = selection.merge({
          anchorOffset: 0,
          focusOffset: startWhiteSpaces[0].length,
          isBackward: false,
        })
        contentState = Modifier.removeRange(contentState, selection, 'forward')
      }

      const lastBlock = contentState.getLastBlock()
      const endWhiteSpaces = lastBlock.getText().match(/\s+$/)
      if (endWhiteSpaces !== null) {
        let selection = SelectionState.createEmpty(lastBlock.getKey())
        selection = selection.merge({
          anchorOffset: endWhiteSpaces.index!,
          focusOffset: endWhiteSpaces.index! + endWhiteSpaces[0].length,
          isBackward: false,
        })
        contentState = Modifier.removeRange(contentState, selection, 'forward')
      }

      // return state with changes
      return contentState
    },
    [editorState]
  )

  // submit
  const submitContent = useCallback(() => {
    const hasFiles = files && files.length > 0
    const currentContent = editorState.getCurrentContent()
    const cleanedContent = removeStartAndEndWhiteSpaces(currentContent)

    if (!hasFiles) {
      if (!editorState.getCurrentContent().hasText()) {
        console.warn('[RichTextEditor] cannot submit empty content')
        return
      }

      if (cleanedContent === null) {
        console.warn('[RichTextEditor] cannot submit content that only contains spaces')
        return
      }
    }

    const text = cleanedContent ? stateToHTML(cleanedContent) : ''
    console.debug(`[RichTextEditor] submitted text: '${text}', files: ${files ? files.length : 0}`)

    if (!isEditing) clearMessage()

    onSubmit(text, files || [])
  }, [editorState, onSubmit, isEditing, clearMessage, removeStartAndEndWhiteSpaces, files])

  // shortkeys binding: ctrl+B, ctrl+I, ctrl+U...
  function keyBindingFunction(e: React.KeyboardEvent) {
    if (e.key === 'Escape') {
      return 'cancel'
    }
    if (KeyBindingUtil.hasCommandModifier(e) && e.key === 'ArrowUp') {
      return 'editLast'
    }
    if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.key.toLocaleLowerCase() === 'x') {
      return 'strikethrough'
    }
    if (KeyBindingUtil.hasCommandModifier(e) && e.key === 'Enter') {
      return 'submit'
    }
    return getDefaultKeyBinding(e)
  }

  const handleKeyCommand = useCallback(
    (command: string, editorState: EditorState) => {
      console.debug('[RichTextEditor] handleKeyCommand, command:', command)

      let newState: EditorState | null = null

      switch (command) {
        case 'submit':
          submitContent()
          return 'handled'
        case 'cancel':
          if (onCancel) onCancel()
          return 'handled'
        case 'editLast':
          if (!isEditing && onEditLast) onEditLast()
          return 'handled'
        case 'strikethrough':
          newState = RichUtils.toggleInlineStyle(editorState, RichTextStyle.STRIKETHROUGH)
          break
        default:
          newState = RichUtils.handleKeyCommand(editorState, command)
          break
      }

      if (newState) {
        setEditorState(newState)
        return 'handled'
      }

      return 'not-handled'
    },
    [submitContent, isEditing, onCancel, onEditLast]
  )

  // text styling
  const hasStyle = (style: RichTextStyle) => {
    return editorState.getCurrentInlineStyle().has(style)
  }

  const applyStyle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, style: RichTextStyle) => {
    e.preventDefault()
    setEditorState(RichUtils.toggleInlineStyle(editorState, style))
  }

  // file upload
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleFileClick = () => {
    console.log('[RichTextEditor] handleFileClick')
    if (hiddenFileInput.current) hiddenFileInput.current.click()
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    console.log('[RichTextEditor] handleFileChange')
    if (event.target.files === null) return
    if (onFileUpload === undefined) return
    // we do't want to support FileList format
    const files: File[] = []
    for (const file of event.target.files) files.push(file)
    onFileUpload(files)
  }

  useEffect(() => {
    checkSizeChange()
  }, [files])

  // component's size
  const [previousComponentHeight, setPreviousComponentHeight] = useState<number>(0)

  const checkSizeChange = useCallback(() => {
    if (!onSizeChange) return
    if (!containerRef.current) return
    const height = containerRef.current.clientHeight
    // ignore initialization and exit
    if (previousComponentHeight === 0 || height === 0) {
      setPreviousComponentHeight(height)
    }
    // fired when text or files expend the component
    else if (previousComponentHeight !== height) {
      setPreviousComponentHeight(height)
      onSizeChange()
    }
  }, [previousComponentHeight, onSizeChange])

  return (
    <div className={'rich-text-editor' + (isLocked ? ' locked' : '')} ref={containerRef}>
      {isLocked && <div className={'locking-overlay'} />}

      {files && files.length > 0 && (
        <div className={'files-wrapper'}>
          {files.map((file) => (
            <ChatMessageFile
              key={file.id}
              id={file.id}
              name={file.name}
              mimetype={file.file.type}
              filesize={file.file.size}
              size={'small'}
              url={file.dataUrl || file.name}
              onRename={onFileRename}
              onDelete={onFileDelete}
            />
          ))}
        </div>
      )}

      <div className={'text-area'} onClick={focusEditor}>
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={setEditorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={keyBindingFunction}
          placeholder={
            isEditing ? t('chat.emptyMessage.placeholder') : t('chat.newMessage.placeholder')
          }
        />
      </div>

      <div className={'toolbar'}>
        <div className={'left-part'}>
          <button
            onMouseDown={(e) => applyStyle(e, RichTextStyle.BOLD)}
            className={'rte-button' + (hasStyle(RichTextStyle.BOLD) ? ' active' : '')}
          >
            <img alt="" src={BoldIcon} />
          </button>
          <button
            onMouseDown={(e) => applyStyle(e, RichTextStyle.ITALIC)}
            className={'rte-button' + (hasStyle(RichTextStyle.ITALIC) ? ' active' : '')}
          >
            <img alt="" src={ItalicIcon} />
          </button>
          <button
            onMouseDown={(e) => applyStyle(e, RichTextStyle.UNDERLINE)}
            className={'rte-button' + (hasStyle(RichTextStyle.UNDERLINE) ? ' active' : '')}
          >
            <img alt="" src={UnderlineIcon} />
          </button>
          <button
            onMouseDown={(e) => applyStyle(e, RichTextStyle.STRIKETHROUGH)}
            className={'rte-button' + (hasStyle(RichTextStyle.STRIKETHROUGH) ? ' active' : '')}
          >
            <img alt="" src={StrikeThroughIcon} />
          </button>
        </div>

        <div className={'right-part'}>
          {!isEditing && onFileUpload && (
            <>
              <button className={'file-button-wrapper rte-button'} onClick={handleFileClick}>
                <img alt="" src={FileIcon} />
              </button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileChange}
                multiple
                style={{ display: 'none' }}
              />
            </>
          )}

          {isEditing && (
            <Button onClick={onCancel} filled={false} fontSize={'small'}>
              {t('chat.editMessage.cancel')}
            </Button>
          )}
          <Button
            onClick={submitContent}
            filled={true}
            fontSize={'small'}
            isLocked={
              isLocked ||
              (!isEditing &&
                !editorState.getCurrentContent().hasText() &&
                (!files || files.length === 0))
            }
          >
            {isEditing
              ? editorState.getCurrentContent().getPlainText().length > 0
                ? t('chat.submit.edit')
                : t('chat.submit.delete')
              : t('chat.submit.submit')}
          </Button>
        </div>
      </div>
    </div>
  )
}
