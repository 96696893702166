import React, { useCallback, useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { useTranslation } from 'react-i18next'
import { TextInput } from '../../atoms/TextInput/TextInput'
import { Modal } from '../Modal/Modal'

export interface ProjectCreationModalProps {
  isOpen: boolean
  onCancel: () => void
}

export const ProjectCreationModal: React.FC<ProjectCreationModalProps> = ({ isOpen, onCancel }) => {
  const { t } = useTranslation()

  const [projectName, setProjectName] = useState<string>('')
  const [projectDescription, setProjectDescription] = useState<string>('')
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  const userId = useStoreState((store) => store.user.userId())
  const createProject = useStoreActions((store) => store.projects.createProject)
  const validateNewProjectName = useStoreActions((store) => store.projects.validateNewProjectName)

  const handleCancel = useCallback(() => {
    setProjectName('')
    setProjectDescription('')
    onCancel()
  }, [onCancel])

  const handleProjectValidation = useCallback(async () => {
    console.debug(
      `[Projects] create a new project, name='${projectName}', description='${projectDescription}'`
    )
    if (!userId) return

    const success = await createProject({
      userId,
      name: projectName,
      description: projectDescription.length > 0 ? projectDescription : undefined,
    })
    console.debug('project creation success:', success)
    if (success === true) handleCancel()
  }, [userId, projectName, projectDescription, createProject, handleCancel])

  useEffect(() => {
    const result = projectName.length > 0 && validateNewProjectName({ newName: projectName })
    if (result !== isFormValid) setIsFormValid(result)
  }, [projectName, isFormValid, validateNewProjectName])

  return (
    <Modal
      isOpen={isOpen}
      header={t('projectCreationAlert.header')}
      cancelButton={t('projectCreationAlert.cancel')}
      validateButton={t('projectCreationAlert.confirm')}
      onCancel={handleCancel}
      onValidate={handleProjectValidation}
      isValidateButtonLocked={!isFormValid}
    >
      <TextInput
        type={'text'}
        value={projectName}
        label={t('projectCreationAlert.nameLabel')}
        placeholder={t('projectCreationAlert.namePlaceholder')}
        onChange={setProjectName}
        isMandatory={true}
        width={'800px'}
        size={'medium'}
      />
      <TextInput
        type={'textarea'}
        value={projectDescription}
        label={t('projectCreationAlert.descriptionLabel')}
        placeholder={t('projectCreationAlert.descriptionPlaceholder')}
        onChange={setProjectDescription}
        width={'800px'}
        size={'medium'}
      />
    </Modal>
  )
}
