import {
  ProfessionalDTO,
  UserProjectProfessionalCreationDTO,
  UserProjectProfessionalUpdateDTO,
} from '@fynde/dtos'
import { Action, Thunk, action, thunk, Computed, computed } from 'easy-peasy'
import {
  deleteUserProjectProfessional,
  getUserProjectProfessionals,
  patchUserProjectProfessional,
  postUserProjectProfessional,
} from '../services/user-project-professional'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'
import { RequestStatus } from '../utils/reqStatus'
import { StoreModel } from './store'

export interface StoredProjectProfessional {
  userProjectId: string
  professionalId: string
}

export interface ProjectProfessionalsModel {
  // store
  items: StoredProjectProfessional[]
  loadingStatus: { userProjectId: string; status: RequestStatus }[]

  // computed
  userProjectProfessionals: Computed<
    ProjectProfessionalsModel,
    (userProjectId: string) => ProfessionalDTO[],
    StoreModel
  >
  userProjectLoadingStatus: Computed<
    ProjectProfessionalsModel,
    (userProjectId: string) => RequestStatus | null
  >

  // actions
  _addItem: Action<ProjectProfessionalsModel, StoredProjectProfessional>
  _deleteItem: Action<ProjectProfessionalsModel, StoredProjectProfessional>
  _setProjectLoadingStatus: Action<
    ProjectProfessionalsModel,
    { userProjectId: string; status: RequestStatus }
  >

  // thunks
  postProjectProfessional: Thunk<
    ProjectProfessionalsModel,
    UserProjectProfessionalCreationDTO,
    any,
    StoreModel,
    Promise<boolean>
  >
  fetchUserProjectProfessionals: Thunk<
    ProjectProfessionalsModel,
    string,
    any,
    StoreModel,
    Promise<boolean>
  >
  patchUserProjectProfessional: Thunk<
    ProjectProfessionalsModel,
    UserProjectProfessionalUpdateDTO,
    any,
    StoreModel,
    Promise<boolean>
  >
  deleteUserProjectProfessional: Thunk<
    ProjectProfessionalsModel,
    StoredProjectProfessional,
    any,
    StoreModel,
    Promise<boolean>
  >
}

export const projectProfessionals: ProjectProfessionalsModel = {
  // store
  items: [],
  loadingStatus: [],

  // computed
  userProjectProfessionals: computed(
    [(state) => state, (state, storeState) => storeState],
    (state, storeState) => {
      return (userProjectId) => {
        const professionalsIds = [
          ...new Set(
            state.items
              .filter((i) => i.userProjectId === userProjectId)
              .map((i) => i.professionalId)
          ),
        ]

        const professionals = storeState.professionals.professionals.filter((p) =>
          professionalsIds.includes(p.id)
        )

        return professionals
      }
    }
  ),

  userProjectLoadingStatus: computed((state) => {
    return (userProjectId) => {
      for (const loadingStatus of state.loadingStatus) {
        if (loadingStatus.userProjectId === userProjectId) return loadingStatus.status
      }
      return null
    }
  }),

  // actions
  _addItem: action((state, newItem) => {
    if (
      state.items.some(
        (i) =>
          i.userProjectId === newItem.userProjectId && i.professionalId === newItem.professionalId
      )
    )
      return
    state.items.push(newItem)
  }),

  _deleteItem: action((state, itemToDelete) => {
    state.items = state.items.filter(
      (item) =>
        item.userProjectId !== itemToDelete.userProjectId ||
        item.professionalId !== itemToDelete.professionalId
    )
  }),

  _setProjectLoadingStatus: action((state, { userProjectId, status }) => {
    // if it exists, update
    for (const loadingStatus of state.loadingStatus) {
      if (loadingStatus.userProjectId === userProjectId) {
        loadingStatus.status = status
        return
      }
    }
    // else, create
    state.loadingStatus.push({ userProjectId, status })
  }),

  // thunks
  postProjectProfessional: thunk(async (actions, dto, { getStoreState, getStoreActions }) => {
    console.debug('[store.project-professionals] post a new user-project-professional')

    const storeState = getStoreState()
    const storeActions = getStoreActions()

    if (storeState.user.token === null) {
      console.error(
        "[store.project-professionals] cannot post a new user-project-professional because user's token is missing"
      )
      return false
    }

    const newUserProjectProfessional = await postUserProjectProfessional(storeState.user.token, dto)
    if (newUserProjectProfessional === null) return false

    storeActions.professionals._addProfessional(newUserProjectProfessional.professional)
    actions._addItem({
      userProjectId: newUserProjectProfessional.userProjectId,
      professionalId: newUserProjectProfessional.professional.id,
    })

    Mixpanel.track(MixpanelEvents.ProjectProfessionalCreated, {
      projectId: dto.userProjectId,
      job: dto.professional.job,
      company: !!dto.professional.companyName,
      name: `${dto.professional.firstName || ''} ${dto.professional.lastName || ''}`,
      withEmail: !!dto.professional.email,
      withPhone: !!dto.professional.phone,
      withWebsite: !!dto.professional.website,
      withAddress: !!dto.professional.companyAddress,
    })

    return true
  }),

  fetchUserProjectProfessionals: thunk(
    async (actions, userProjectId, { getStoreState, getStoreActions }) => {
      const storeState = getStoreState()
      const storeActions = getStoreActions()
      if (storeState.user.token === null) {
        console.error(
          "[store.project-professionals] cannot fetch user-project-professionals because user's token is missing"
        )
        return false
      }

      console.debug(
        '[store.project-professionals] fetch user-project-professionals of a project:',
        userProjectId
      )

      actions._setProjectLoadingStatus({ userProjectId, status: RequestStatus.Loading })
      const useProjectProfessionals = await getUserProjectProfessionals(
        storeState.user.token,
        userProjectId
      )
      if (!useProjectProfessionals) {
        actions._setProjectLoadingStatus({ userProjectId, status: RequestStatus.Failed })
        return false
      }

      for (const professional of useProjectProfessionals.professionals) {
        storeActions.professionals._addProfessional(professional)
        actions._addItem({
          userProjectId: userProjectId,
          professionalId: professional.id,
        })
      }

      actions._setProjectLoadingStatus({ userProjectId, status: RequestStatus.Completed })
      return true
    }
  ),

  patchUserProjectProfessional: thunk(async (actions, dto, { getStoreState, getStoreActions }) => {
    const storeState = getStoreState()
    const storeActions = getStoreActions()
    if (storeState.user.token === null) {
      console.error(
        "[store.project-professionals] cannot patch a user-project-professional because user's token is missing"
      )
      return false
    }

    console.debug('[store.project-professionals] patch a user-project-professional:', dto)

    // update online DB
    const patchedUserProjectProfessional = await patchUserProjectProfessional(
      storeState.user.token,
      dto
    )
    if (patchedUserProjectProfessional === null) return false
    console.debug(
      '[store.project-professionals] patched user-project-professional:',
      patchedUserProjectProfessional
    )

    // update offline state
    storeActions.professionals._updateProfessional(patchedUserProjectProfessional.professional)

    Mixpanel.track(MixpanelEvents.ProjectProfessionalUpdated, {
      projectId: dto.userProjectId,
      projectProfessionalId: dto.professionalId,
      jobUpdated: !!dto.professional.job,
      companyUpdated: !!dto.professional.companyName,
      nameUpdated: !!dto.professional.firstName || !!dto.professional.lastName,
      emailUpdated: !!dto.professional.email,
      phoneUpdated: !!dto.professional.phone,
      websiteUpdated: !!dto.professional.website,
      addressUpdated: !!dto.professional.companyAddress,
    })

    return true
  }),

  deleteUserProjectProfessional: thunk(
    async (actions, storedProjectProfessional, { getStoreState }) => {
      const storeState = getStoreState()
      if (storeState.user.token === null) {
        console.error(
          "[store.project-professionals] cannot fetch user-project-professionals because user's token is missing"
        )
        return false
      }

      console.debug(
        '[store.project-professionals] delete user-project-professional:',
        storedProjectProfessional
      )
      const success = await deleteUserProjectProfessional(storeState.user.token, {
        userProjectId: storedProjectProfessional.userProjectId,
        professionalId: storedProjectProfessional.professionalId,
      })
      if (success) {
        actions._deleteItem(storedProjectProfessional)

        Mixpanel.track(MixpanelEvents.ProjectProfessionalDeleted, {
          projectId: storedProjectProfessional.userProjectId,
          projectProfessionalId: storedProjectProfessional.professionalId,
        })
      }

      return true
    }
  ),
}
