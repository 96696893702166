import { LocalizedMaterialTagDTO, MaterialTagDTO } from '@fynde/dtos'
import { Action, Thunk, action, thunk, Computed, computed } from 'easy-peasy'
import { RequestStatus } from '../utils/reqStatus'
import axiosApi from '../utils/axios'
import { StoreModel } from './store'

export type MaterialTagApiResponse = [MaterialTagDTO[], number]

export interface MaterialTagsModel {
  reqStatus: RequestStatus
  tags: MaterialTagDTO[]
  localizedTags: Computed<MaterialTagsModel, LocalizedMaterialTagDTO[], StoreModel>
  setReqStatus: Action<MaterialTagsModel, RequestStatus>
  setTags: Action<MaterialTagsModel, MaterialTagDTO[]>
  fetchTags: Thunk<MaterialTagsModel>
}

export const materialTags: MaterialTagsModel = {
  // store
  reqStatus: RequestStatus.Idle,
  tags: [],

  // computed
  localizedTags: computed(
    [(state) => state, (state, storeState) => storeState],
    (state, storeState) => {
      const language = storeState.user.language
      const results = state.tags.map((tag) => {
        const { names, ...data } = tag
        const localizedNames = names.filter((name) => name.locale === language)
        const localizedName = localizedNames.length > 0 ? localizedNames[0].name : names[0].name
        return {
          ...data,
          name: localizedName,
        } as LocalizedMaterialTagDTO
      })
      return results
    }
  ),

  // actions
  setReqStatus: action((state, status) => {
    state.reqStatus = status
  }),

  setTags: action((state, dtos) => {
    state.tags = dtos
  }),

  // thunks
  fetchTags: thunk(async (actions, _) => {
    actions.setReqStatus(RequestStatus.Loading)

    try {
      const resp = await axiosApi.get<MaterialTagApiResponse>('/material-tags')

      actions.setTags(resp.data[0])
      actions.setReqStatus(RequestStatus.Completed)
    } catch (e) {
      console.error('ERROR while fetching material tags from API', e)
      actions.setReqStatus(RequestStatus.Failed)
      return
    }
  }),
}
