import { Action, action, Computed, computed } from 'easy-peasy'
import { BrandDTO, LocalizedColorTagDTO, LocalizedMaterialTagDTO } from '@fynde/dtos'
import { StoreModel } from './store'

export const budgetSteps = [
  0, 100, 200, 300, 500, 750, 1000, 1500, 2000, 2500, 3000, 4000, 5000, 10000,
]
export const MIN_PRICE = budgetSteps[0]
export const MAX_PRICE = budgetSteps[budgetSteps.length - 1]

export interface FiltersModel {
  // store
  keywords: string
  selectedBrandIds: string[]
  selectedColorTagIds: string[]
  selectedMaterialTagIds: string[]
  selectedPriceRange: { min: number; max: number }
  selectedObjectDetectionTypes: string[] | null

  // computed
  selectedBrands: Computed<FiltersModel, BrandDTO[], StoreModel>
  selectedColorTags: Computed<FiltersModel, LocalizedColorTagDTO[], StoreModel>
  selectedMaterialTags: Computed<FiltersModel, LocalizedMaterialTagDTO[], StoreModel>

  // actions
  setNewFilters: Action<
    FiltersModel,
    {
      selectedMaterialTagIds?: string[]
      selectedColorTagIds?: string[]
      selectedPriceRange?: { min: number; max: number }
    }
  >

  setKeywords: Action<FiltersModel, string>

  setSelectedBrandIds: Action<FiltersModel, string[]>
  removeBrandId: Action<FiltersModel, string>

  setSelectedColorTagIds: Action<FiltersModel, string[]>
  removeColorTagId: Action<FiltersModel, string>

  setSelectedMaterialTagIds: Action<FiltersModel, string[]>
  removeMaterialTagId: Action<FiltersModel, string>

  setSelectedPriceRange: Action<FiltersModel, { min: number; max: number }>
  removePriceRange: Action<FiltersModel, void>

  setSelectedObjectDetectionTypes: Action<FiltersModel, string[] | null>

  clearFiltersWithoutFetch: Action<FiltersModel>
}

export const filters: FiltersModel = {
  keywords: '',
  selectedBrandIds: [],
  selectedColorTagIds: [],
  selectedMaterialTagIds: [],
  selectedPriceRange: { min: MIN_PRICE, max: MAX_PRICE },
  selectedObjectDetectionTypes: null,

  selectedBrands: computed(
    [(state) => state.selectedBrandIds, (_, storeState) => storeState.brands.brands],
    (brandIds, brands) => {
      return brands.filter((brand) => brandIds.includes(brand.id))
    }
  ),

  selectedColorTags: computed(
    [(state) => state.selectedColorTagIds, (_, storeState) => storeState.colorTags.localizedTags],
    (selectedTagIds, tags) => {
      return tags.filter((tag) => selectedTagIds.includes(tag.id))
    }
  ),

  selectedMaterialTags: computed(
    [
      (state) => state.selectedMaterialTagIds,
      (_, storeState) => storeState.materialTags.localizedTags,
    ],
    (selectedTagIds, tags) => {
      return tags.filter((tag) => selectedTagIds.includes(tag.id))
    }
  ),

  setNewFilters: action(
    (state, { selectedMaterialTagIds, selectedColorTagIds, selectedPriceRange }) => {
      if (selectedColorTagIds !== undefined) {
        console.debug('[store.filters] new color filter:', selectedColorTagIds)
        state.selectedColorTagIds = [...selectedColorTagIds!]
      }
      if (selectedMaterialTagIds !== undefined) {
        console.debug('[store.filters] new material filter:', selectedMaterialTagIds)
        state.selectedMaterialTagIds = [...selectedMaterialTagIds!]
      }
      if (selectedPriceRange !== undefined) {
        console.debug('[store.filters] new price filter:', selectedPriceRange)
        state.selectedPriceRange = { ...selectedPriceRange }
      }
    }
  ),

  setKeywords: action((state, keywords) => {
    state.keywords = keywords
  }),

  setSelectedBrandIds: action((state, ids) => {
    state.selectedBrandIds = [...ids]
  }),

  removeBrandId: action((state, id) => {
    state.selectedBrandIds = state.selectedBrandIds.filter((brand) => brand !== id)
  }),

  setSelectedColorTagIds: action((state, ids) => {
    state.selectedColorTagIds = [...ids]
  }),

  removeColorTagId: action((state, id) => {
    state.selectedColorTagIds = state.selectedColorTagIds.filter((tagId) => tagId !== id)
  }),

  setSelectedMaterialTagIds: action((state, ids) => {
    state.selectedMaterialTagIds = [...ids]
  }),

  removeMaterialTagId: action((state, id) => {
    state.selectedMaterialTagIds = state.selectedMaterialTagIds.filter((tagId) => tagId !== id)
  }),

  setSelectedPriceRange: action((state, range) => {
    state.selectedPriceRange = range
  }),

  removePriceRange: action((state) => {
    state.selectedPriceRange = { min: MIN_PRICE, max: MAX_PRICE }
  }),

  setSelectedObjectDetectionTypes: action((state, type) => {
    state.selectedObjectDetectionTypes = type
  }),

  clearFiltersWithoutFetch: action((state) => {
    state.keywords = ''
    state.selectedBrandIds = []
    state.selectedMaterialTagIds = []
    state.selectedColorTagIds = []
    state.selectedPriceRange = { min: MIN_PRICE, max: MAX_PRICE }
    state.selectedObjectDetectionTypes = null
  }),
}
