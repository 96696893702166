import React, { useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success-bullet-icon.svg'
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error-bullet-icon.svg'
import './Feedback.css'

export enum FeedbackStatus {
  Success = 0,
  Error = 1,
  Idle = 2,
}

export type FeedbackProps = {
  isVisible: boolean
  onComplete: () => void
  duration?: number
  message: string
  status: FeedbackStatus
}

export const Feedback: React.FC<FeedbackProps> = ({
  isVisible,
  onComplete,
  duration = 3000,
  message,
  status,
}) => {
  useEffect(() => {
    if (!isVisible) return

    setTimeout(() => onComplete(), duration)
  }, [isVisible, duration, onComplete])

  return (
    <>
      <CSSTransition in={isVisible} timeout={500} classNames="feedback" unmountOnExit>
        <div className={`feedback-wrapper ${status === FeedbackStatus.Error ? 'error' : ''}`}>
          <div className={'feedback-content'}>
            {status === FeedbackStatus.Success && (
              <div className={'svg-wrapper'}>
                <SuccessIcon />
              </div>
            )}
            {status === FeedbackStatus.Error && (
              <div className={'svg-wrapper'}>
                <ErrorIcon />
              </div>
            )}

            <p>{message}</p>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}
