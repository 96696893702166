import {
  Ordering,
  UserProjectInvitationCreationDTO,
  UserProjectInvitationDeletionDTO,
  UserProjectInvitationDTO,
  UserProjectInvitationFiltersDTO,
  UserProjectMemberDeletionDTO,
  UserProjectMemberDTO,
  UserProjectMemberFiltersDTO,
  UserProjectMemberUpdateDTO,
} from '@fynde/dtos'
import { Action, Thunk, action, thunk, Computed, computed } from 'easy-peasy'
import {
  deleteUserProjectInvitation,
  getUserProjectInvitations,
  postUserProjectInvitation,
} from '../services/user-project-invitation'
import {
  deleteUserProjectMember,
  getUserProjectMember,
  getUserProjectMembers,
  patchUserProjectMember,
} from '../services/user-project-member'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'
import { RequestStatus } from '../utils/reqStatus'
import { StoreModel } from './store'

export interface ProjectMembersModel {
  // store
  members: UserProjectMemberDTO[]
  pendingMembers: UserProjectInvitationDTO[]
  loadingStatus: { projectId: string; status: RequestStatus }[]

  // actions
  _addPendingMember: Action<ProjectMembersModel, UserProjectInvitationDTO>
  _removePendingMember: Action<ProjectMembersModel, { projectId: string; email: string }>
  _addMember: Action<ProjectMembersModel, UserProjectMemberDTO>
  _updateMemberRole: Action<
    ProjectMembersModel,
    { projectId: string; userId: string; role: string }
  >
  _removeMember: Action<ProjectMembersModel, { projectId: string; userId: string }>
  _setProjectLoadingStatus: Action<
    ProjectMembersModel,
    { projectId: string; status: RequestStatus }
  >

  // computed
  projectMember: Computed<ProjectMembersModel, (userId: string) => UserProjectMemberDTO | null>
  projectMembers: Computed<ProjectMembersModel, (projectId: string) => UserProjectMemberDTO[]>
  orderedProjectMembers: Computed<
    ProjectMembersModel,
    (projectId: string, order: Ordering) => UserProjectMemberDTO[]
  >
  projectPendingMembers: Computed<
    ProjectMembersModel,
    (projectId: string) => UserProjectInvitationDTO[]
  >
  projectLoadingStatus: Computed<ProjectMembersModel, (projectId: string) => RequestStatus | null>
  projectMemberFullName: Computed<ProjectMembersModel, (userId: string) => string | null>
  projectMembersCount: Computed<ProjectMembersModel, (projectId: string) => number>

  // thunks
  fetchProjectMember: Thunk<
    ProjectMembersModel,
    { userProjectId: string; userId: string },
    any,
    StoreModel,
    Promise<boolean>
  >
  fetchProjectMembers: Thunk<ProjectMembersModel, string, any, StoreModel, Promise<boolean>>
  inviteMember: Thunk<
    ProjectMembersModel,
    { projectId: string; email: string; role: string },
    any,
    StoreModel,
    Promise<boolean>
  >
  updateMemberRole: Thunk<
    ProjectMembersModel,
    { projectId: string; userId: string; role: string },
    any,
    StoreModel,
    Promise<boolean>
  >
  deleteMember: Thunk<
    ProjectMembersModel,
    { userProjectId: string; userId: string },
    any,
    StoreModel,
    Promise<boolean>
  >
  deletePendingMember: Thunk<
    ProjectMembersModel,
    { userProjectId: string; email: string },
    any,
    StoreModel,
    Promise<boolean>
  >
}

export const projectMembers: ProjectMembersModel = {
  members: [],
  pendingMembers: [],
  loadingStatus: [],

  _addMember: action((state, newMember) => {
    if (
      state.members.filter(
        (member) =>
          member.projectId === newMember.projectId && member.user.email === newMember.user.email
      ).length === 0
    ) {
      newMember.createdAt = new Date(newMember.createdAt)
      state.members = [newMember, ...state.members]
    }
  }),

  _updateMemberRole: action((state, { projectId, userId, role }) => {
    for (let i = 0; i < state.members.length; i++) {
      if (state.members[i].projectId === projectId && state.members[i].user.id === userId) {
        state.members[i].role = role
        return
      }
    }
  }),

  _removeMember: action((state, { projectId, userId }) => {
    state.members = state.members.filter(
      (member) => member.projectId !== projectId || member.user.id !== userId
    )
  }),

  _setProjectLoadingStatus: action((state, { projectId, status }) => {
    // if it exists, update
    for (const loadingStatus of state.loadingStatus) {
      if (loadingStatus.projectId === projectId) {
        loadingStatus.status = status
        return
      }
    }
    // else, create
    state.loadingStatus = [
      ...state.loadingStatus,
      {
        projectId: projectId,
        status: status,
      },
    ]
  }),

  _addPendingMember: action((state, newPendingMember) => {
    if (
      state.pendingMembers.filter(
        (pm) => pm.projectId === newPendingMember.projectId && pm.email === newPendingMember.email
      ).length === 0
    ) {
      state.pendingMembers = [newPendingMember, ...state.pendingMembers]
    }
  }),

  _removePendingMember: action((state, { projectId, email }) => {
    state.pendingMembers = state.pendingMembers.filter(
      (pm) => pm.projectId !== projectId || pm.email !== email
    )
  }),

  projectMember: computed((state) => {
    return (userId) => {
      const results: UserProjectMemberDTO[] = state.members.filter(
        (member) => member.user.id === userId
      )
      return results.length > 0 ? results[0] : null
    }
  }),

  projectMembers: computed((state) => {
    return (projectId) => {
      const results: UserProjectMemberDTO[] = state.members.filter(
        (member) => member.projectId === projectId
      )
      return results
    }
  }),

  orderedProjectMembers: computed((state) => {
    return (projectId, order) => {
      const results = state.projectMembers(projectId)
      switch (order) {
        case Ordering.CreationDateAsc:
          results.sort((first, second) => (first.createdAt < second.createdAt ? -1 : 1))
          break
        case Ordering.CreationDateDesc:
          results.sort((first, second) => (first.createdAt > second.createdAt ? -1 : 1))
          break
        case Ordering.NameAsc:
          results.sort((first, second) => {
            const firstName = `${first.user.firstname} ${first.user.lastname}`
            const secondName = `${second.user.firstname} ${second.user.lastname}`
            return firstName < secondName ? -1 : firstName > secondName ? 1 : 0
          })
          break
        case Ordering.NameDesc:
          results.sort((first, second) => {
            const firstName = `${first.user.firstname} ${first.user.lastname}`
            const secondName = `${second.user.firstname} ${second.user.lastname}`
            return firstName > secondName ? -1 : firstName < secondName ? 1 : 0
          })
          break
      }
      return results
    }
  }),

  projectPendingMembers: computed((state) => {
    return (projectId) => {
      const results: UserProjectInvitationDTO[] = state.pendingMembers.filter(
        (pendingMember) => pendingMember.projectId === projectId
      )
      return results
    }
  }),

  projectLoadingStatus: computed((state) => {
    return (projectId) => {
      for (const loadingStatus of state.loadingStatus) {
        if (loadingStatus.projectId === projectId) return loadingStatus.status
      }
      return null
    }
  }),

  projectMemberFullName: computed((state) => {
    return (userId) => {
      const user = state.projectMember(userId)
      return user ? `${user.user.firstname} ${user.user.lastname}` : null
    }
  }),

  projectMembersCount: computed((state) => {
    return (projectId) => {
      return state.projectMembers(projectId).length + state.projectPendingMembers(projectId).length
    }
  }),

  fetchProjectMember: thunk(async (actions, { userProjectId, userId }, { getStoreState }) => {
    console.debug('[store.project-members]', `fetch a user-project-member`)

    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.project-members] cannot fetch a user-project-member because user's token is missing"
      )
      return false
    }

    const dto: UserProjectMemberFiltersDTO = { userProjectId, userId }
    const member = await getUserProjectMember(storeState.user.token, dto)
    if (member === null) return false
    actions._addMember(member)

    return true
  }),

  fetchProjectMembers: thunk(async (actions, userProjectId, { getState, getStoreState }) => {
    console.debug('[store.project-members]', `fetch members of the project '${userProjectId}'`)

    if (
      [RequestStatus.Completed, RequestStatus.Loading].includes(
        getState().projectLoadingStatus(userProjectId) || RequestStatus.Idle
      )
    ) {
      console.error('[store.project-members] user-project-members were already fetched')
      return false
    }

    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.project-members] cannot fetch user-project-members because user's token is missing"
      )
      return false
    }

    actions._setProjectLoadingStatus({
      projectId: userProjectId,
      status: RequestStatus.Loading,
    })

    // members
    const membersDTO: UserProjectMemberFiltersDTO = { userProjectId }
    const members = await getUserProjectMembers(storeState.user.token, membersDTO)
    if (members === null) {
      actions._setProjectLoadingStatus({
        projectId: userProjectId,
        status: RequestStatus.Failed,
      })
      return false
    }
    for (let member of members) actions._addMember(member)

    // pending members
    const invitationsDTO: UserProjectInvitationFiltersDTO = { userProjectId }
    const pendingMembers = await getUserProjectInvitations(storeState.user.token, invitationsDTO)
    if (pendingMembers === null) {
      actions._setProjectLoadingStatus({
        projectId: userProjectId,
        status: RequestStatus.Failed,
      })
      return false
    }
    for (let pendingMember of pendingMembers) actions._addPendingMember(pendingMember)

    actions._setProjectLoadingStatus({
      projectId: userProjectId,
      status: RequestStatus.Completed,
    })
    return true
  }),

  inviteMember: thunk(async (actions, { projectId, email, role }, { getStoreState }) => {
    console.debug(
      '[store.project-members]',
      `invite '${email}' as '${role}' to project '${projectId}'`
    )

    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.project-members] cannot post a new user-project-invitation because user's token is missing"
      )
      return false
    }

    const dto: UserProjectInvitationCreationDTO = {
      projectId: projectId,
      email: email,
      role: role,
    }
    const newInvitation = await postUserProjectInvitation(storeState.user.token, dto)
    console.debug('[store.project-members] new user-project-invitation:', newInvitation)
    if (newInvitation === null) return false

    if (newInvitation.userId === null) {
      console.debug('[store.project-members] new user-project-invitation must register')
      actions._addPendingMember(newInvitation)
    } else {
      console.debug('[store.project-members] new user-project-invitation is already a user')
      await actions.fetchProjectMember({
        userProjectId: projectId,
        userId: newInvitation.userId,
      })
    }

    Mixpanel.track(MixpanelEvents.ProjectInvitationSend, {
      projectId,
      email,
      role,
      isAlreadyRegistered: newInvitation.userId !== null,
    })

    return true
  }),

  updateMemberRole: thunk(async (actions, { projectId, userId, role }, { getStoreState }) => {
    console.debug(`[store.project-members] update role of a project's member...`)

    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.project-members] cannot patch a user-project-member because user's token is missing"
      )
      return false
    }

    // update online DB
    const dto: UserProjectMemberUpdateDTO = {
      projectId: projectId,
      userId: userId,
      role: role,
    }
    const updatedMember = await patchUserProjectMember(storeState.user.token, dto)
    if (updatedMember === null) return false

    // update offline state
    actions._updateMemberRole({
      projectId: projectId,
      userId: userId,
      role: role,
    })

    console.debug(`[store.project-members] role of project's member updated`)
    return true
  }),

  deleteMember: thunk(async (actions, { userProjectId, userId }, { getStoreState }) => {
    console.debug(
      '[store.project-members]',
      `remove user '${userId}' from project '${userProjectId}'`
    )

    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.project-members] cannot delete a user-project-member because user's token is missing"
      )
      return false
    }

    // online
    const dto: UserProjectMemberDeletionDTO = {
      userProjectId: userProjectId,
      userId: userId,
    }
    const success = await deleteUserProjectMember(storeState.user.token, dto)
    if (!success) return false

    // local
    actions._removeMember({
      projectId: userProjectId,
      userId: userId,
    })

    Mixpanel.track(MixpanelEvents.ProjectMemberDeleted, {
      projectId: userProjectId,
      userId,
    })

    return true
  }),

  deletePendingMember: thunk(async (actions, { userProjectId, email }, { getStoreState }) => {
    console.debug(
      '[store.project-members]',
      `remove invitation of '${email}' from project '${userProjectId}'`
    )

    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.project-members] cannot delete a user-project-invitation because user's token is missing"
      )
      return false
    }

    // online
    const dto: UserProjectInvitationDeletionDTO = {
      projectId: userProjectId,
      email: email,
    }
    const success = await deleteUserProjectInvitation(storeState.user.token, dto)
    if (!success) return false

    // local
    actions._removePendingMember({
      projectId: userProjectId,
      email: email,
    })

    return true
  }),
}
