import React from 'react'
import './TabHeader.css'

export interface TabHeaderProps {
  tabTexts: string[]
  selectedTab: number,
  onChange: (tabIndex: number) => void
}

export const TabHeader: React.FC<TabHeaderProps> = ({ 
  tabTexts,
  selectedTab,
  onChange,
}) => {

  return (
    <div className={'tab-header'}>
      {tabTexts.map((text, index) => 
        <div
          key={text}
          className={`tab${index === selectedTab ? ' selected' : ''}`}
          onClick={() => onChange(index)}
        >
          {text}
        </div>
      )}
    </div>
  )
}
