import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { CardFilter } from '../../molecules/FilterCard/FilterCard'
import { CenteredLoading } from '../../molecules/CenteredLoading/CenteredLoading'
import './MaterialFilterCard.css'

export interface MaterialTagData {
  id: string
  name: string
}

export interface MaterialFilterCardProps {
  materials: MaterialTagData[]
  selectedMaterialIds: string[]
  onClear?: () => void
  onSelectionChange?: (selection: MaterialTagData[]) => void
}

export const MaterialFilterCard: React.FC<MaterialFilterCardProps> = ({
  materials,
  selectedMaterialIds,
  onClear,
  onSelectionChange,
}) => {
  const { t } = useTranslation()

  const [selectedMaterialTags, setSelectedMaterialTags] = useState<MaterialTagData[]>(
    () => materials.filter((mat) => selectedMaterialIds.includes(mat.id))
  )

  const onClearClicked = useCallback(() => {
    setSelectedMaterialTags([])
    if (onClear)
      onClear()
  }, [onClear])

  const onTagClick = (clickedId: string) => {
    const isAlreadySelected = selectedMaterialTags.some(
      (selectedMat) => selectedMat.id === clickedId
    )

    const newSelection:MaterialTagData[] = isAlreadySelected
      ? selectedMaterialTags.filter((selectedMat) => selectedMat.id !== clickedId)
      : [...selectedMaterialTags, materials.filter((mat) => mat.id === clickedId)[0]]

      setSelectedMaterialTags(newSelection)
    
    if (onSelectionChange)
      onSelectionChange(newSelection);
  }

  return (
    <CardFilter
      title={t('filters.material.title')}
      onClearCliked={onClearClicked}
      disabled={selectedMaterialTags.length === 0}
      className={'material-filter-card'}
    >
      {materials.length === 0 ? (
        <CenteredLoading isLight={true}/>
      ) : (
        <div className={'materials'}>
          {materials.map((material) => (
            <button
              key={material.id}
              className={
                'item'
                + (selectedMaterialTags.some((selectedMat) => selectedMat.id === material.id) ? ' selected' : '')
              }
              onClick={() => onTagClick(material.id)}
            >
              {material.name.toLocaleLowerCase()}
            </button>
          ))}
        </div>
      )}
    </CardFilter>
  )
}
