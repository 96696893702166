import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../Modal/Modal'
import { ChatMessageFile, ChatMessageFileData } from '../../atoms/ChatMessageFile/ChatMessageFile'
import './UserMessageFileRenamingModal.css'
import { splitFileExtension } from '../../../utils/tool'
import { TextInput } from '../../atoms/TextInput/TextInput'

export interface MinimalProject {
  id: string
  name: string
  isDefault: boolean
}

export interface UserMessageFileRenamingModalProps {
  isOpen: boolean
  onCancel: () => void
  onSubmit: (userFileId: string, newName: string) => void
  chatMessageFileData: ChatMessageFileData
}

export const UserMessageFileRenamingModal: React.FC<UserMessageFileRenamingModalProps> = ({
  isOpen,
  onCancel,
  onSubmit,
  chatMessageFileData,
}) => {
  const { t } = useTranslation()

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  // name
  const [basename, extension] = splitFileExtension(chatMessageFileData.name)
  const [newName, setNewName] = useState<string>(basename)
  const handleNewNameChange = useCallback((newName: string) => {
    const correctedNewName = newName.replace(/\n/g, '')
    setNewName(correctedNewName)
  }, [])

  // cancel
  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  // submit
  const handleSubmit = useCallback(() => {
    const fullNewName = newName + extension
    onSubmit(chatMessageFileData.id, fullNewName)
    handleCancel()
  }, [chatMessageFileData, newName, extension, onSubmit, handleCancel])

  return (
    <Modal
      isOpen={isOpen}
      header={t('chatMessageFileRenamingModal.header')}
      cancelButton={t('chatMessageFileRenamingModal.cancel')}
      validateButton={t('chatMessageFileRenamingModal.submit')}
      onCancel={handleCancel}
      onValidate={handleSubmit}
      isValidateButtonLocked={newName === basename || newName.search(/\S/) === -1}
      className={'user-message-file-renaming-modal'}
    >
      <>
        <div className={'file'}>
          <ChatMessageFile {...chatMessageFileData} size={'medium'} withHover={false} />
          <TextInput
            ref={textAreaRef}
            type={'textarea'}
            placeholder={t('chatMessageFileRenamingModal.namePlaceholder')}
            size={'medium'}
            value={newName}
            onChange={handleNewNameChange}
          />
        </div>
      </>
    </Modal>
  )
}
