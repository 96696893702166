import React, { useRef } from 'react'
import styled from 'styled-components'
import { IonPage, IonContent } from '@ionic/react'
import { DesktopHeaderWrapper } from '../../molecules/DesktopHeader/DesktopHeaderWrapper'
import { HeaderTabs } from '../../molecules/DesktopHeader/DesktopHeader'
import { ScrollDetail } from '@ionic/core/dist/types/interface'

const PageWrapper = styled.div<{ maxWidth: string }>`
  width: 100%;
  max-width: ${(props) => `calc(${props.maxWidth} + 4rem)`};
  margin: 0rem auto 0 auto;
  padding: 2rem;
`

export interface BaseDesktopLayoutProps {
  selectedHeaderTab?: HeaderTabs | null
  onScroll?: (e: any) => void
  onBottomHit?: () => void
  onBottomHitOffset?: number
  maxWidth?: string
  className?: string
}

export const BaseDesktopLayout: React.FC<BaseDesktopLayoutProps> = ({
  children,
  selectedHeaderTab = null,
  onScroll = undefined,
  onBottomHit = undefined,
  onBottomHitOffset = 0,
  maxWidth = '100vw',
  className,
}) => {
  // header size
  const contentRef = useRef<HTMLIonContentElement>(null)

  // scroll event
  const handleScroll = (e: CustomEvent<ScrollDetail>) => {
    if (onScroll) onScroll(e)

    if (onBottomHit === undefined) return
    if (contentRef.current == null) return
    const el = contentRef.current.shadowRoot?.querySelector('.inner-scroll')
    if (el == null) return

    const offset = 60 + onBottomHitOffset
    const hitBottom = el.scrollTop >= el.scrollHeight - el.clientHeight - offset
    if (hitBottom) {
      console.debug('[BaseDesktopLayout] onIonScroll bottom!')
      onBottomHit()
    }
  }

  return (
    <IonPage>
      <DesktopHeaderWrapper selectedTab={selectedHeaderTab} />

      <IonContent
        ref={contentRef}
        scrollEvents={onScroll || onBottomHit ? true : false}
        onIonScroll={handleScroll}
        forceOverscroll={false}
        style={{ overflow: 'auto' }}
      >
        <PageWrapper className={'page-wrapper ' + (className || '')} maxWidth={maxWidth}>
          {children}
        </PageWrapper>
      </IonContent>
    </IonPage>
  )
}
