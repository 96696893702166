import React, { useRef, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useStoreActions } from '../../../store/hooks'
import { useTranslation } from 'react-i18next'
import { Button } from '../../atoms//Button/Button'
import { ReactComponent as Cross } from '../../../assets/icons/cross-icon.svg'
import { IonSlides, IonSlide } from '@ionic/react'
import { BackButtonEvent } from '@ionic/core';
import { Dots } from '../../atoms/Dots/Dots'

const Container = styled.div`
  width: 100vw;
  height: calc(100vh - constant(safe-area-inset-bottom));
  height: calc(100vh - env(safe-area-inset-bottom));
  position: fixed;
  z-index: 1000;
  text-align: center;
  background-color: ${(props) => props.theme.colors.greyBg};
  color: ${(props) => props.theme.colors.black};
`
const Header = styled.div`
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
  }
`
const CloseButton = styled.a`
  position: absolute;
  right: 0.7rem;
  top: 0.7rem;

  svg {
    width: 1.7rem;
  }
  svg .stroke {
    stroke-width: 1.5;
  }
`
const ionSlideContainerStyle = {
  height: 'calc(100% - 3.5rem)',
}
const ionSlideStyle = {
  height: '100%',
  flexFlow: 'column',
  justifyContent: 'start',
  padding: '0 1rem'
}
const ImageContainer = styled.div`
  width: calc(100vw - 2rem);
  height: calc(100vw - 2rem);
  background-color: ${(props) => props.theme.colors.grey400};
  margin-bottom: 1.4rem;

  @media (max-height: 750px) {
    width: calc(100vw - 4rem);
    height: calc(100vw - 4rem);
  }
  @media (max-height: 700px) {
    width: calc(100vw - 5rem);
    height: calc(100vw - 5rem);
  }
  @media (max-height: 650px) {
    width: calc(100vw - 6rem);
    height: calc(100vw - 6rem);
  }
  @media (max-height: 600px) {
    width: calc(100vw - 8rem);
    height: calc(100vw - 8rem);
    margin-bottom: 1.0rem;
  }
`
const Image = styled.img`
  width: 100% !important;
  height: 100% !important;
`
const Instructions = styled.div`
  width: 100%;
  height: 100px;
  padding: 0;
  margin-bottom: 1rem;
  display: flex;
  flex-flow: column;
  flex: auto;
  align-items: center;
`
const StepTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  margin: 1.0rem 0 0.5em 0;

  @media (max-height: 700px) {
    font-size: 1.15rem;
  }
  @media (max-height: 650px) {
    font-size: 1.0rem;
  }
  @media (max-height: 600px) {
    font-size: 0.95rem;
    margin: 0.8rem 0 0.5em 0;
  }
`
const Description = styled.p`
  font-size: 1rem;
  line-height: 1.6em;
  margin: 0 0 2rem 0;

  @media (max-height: 750px) {
    font-size: 0.95rem;
  }
  @media (max-height: 700px) {
    font-size: 0.9rem;
  }
  @media (max-height: 650px) {
    font-size: 0.88rem;
  }
  @media (max-height: 600px) {
    font-size: 0.85rem;
  }
`
const ButtonContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
`
const PreviousButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.greyBg};
  color: ${(props) => props.theme.colors.black};
  margin-right: 1rem;
  font-size: 0.9em;

  @media (max-height: 700px) {
    font-size: 0.85em;
  }
  @media (max-height: 600px) {
    font-size: 0.8em;
  }
`
const NextButton = styled(Button)`
  font-size: 0.9em;

  @media (max-height: 700px) {
    font-size: 0.85em;
  }
  @media (max-height: 600px) {
    font-size: 0.8em;
  }
`


export type TutorialContainerProps = {
  steps: number[];
  onComplete?: () => void;
}

export const TutorialContainer: React.FC<TutorialContainerProps> = ({steps, onComplete}) => {
  const { t } = useTranslation()

  // close
  const { setTutorialViewState } = useStoreActions((actions) => actions.user)

  const closeTutorial = useCallback(() => {
    setTutorialViewState(true);
    if (onComplete !== undefined) onComplete();
  }, [setTutorialViewState, onComplete])

  // Android back button
  const handleBackButton = useCallback(
    (ev: BackButtonEvent) => {
      ev.preventDefault()
      ev.detail.register(10, closeTutorial);
    }, [closeTutorial]
  )

  useEffect(() => {
    document.addEventListener('ionBackButton', handleBackButton as EventListener);
    return () => {
      document.removeEventListener('ionBackButton', handleBackButton as EventListener);
    }
  }, [handleBackButton])

  // slides
  const ionSlidesRef = useRef<HTMLIonSlidesElement>(null)
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    effect: 'fade',
  }
  const goToSlide = useCallback(
    (slideIndex: number) => {
      ionSlidesRef!.current!.slideTo(slideIndex)
    },
    [ionSlidesRef]
  )

  const slideImages = [
    'assets/tutorial/in-app_tutorial_step1.gif',
    'assets/tutorial/in-app_tutorial_step2.jpg',
    'assets/tutorial/in-app_tutorial_step3.jpg',
    'assets/tutorial/in-app_tutorial_step4.gif',
    'assets/tutorial/in-app_tutorial_step5.jpg',
  ]
  function generateSlide(slideIndex: number) {
    let displayedSlideIndex: number = 0;
    for (let i = 0; i < steps.length; i++) {
      if (steps[i] === slideIndex) {
        displayedSlideIndex = i;
        break;
      }
    }
    const description: string = t(`tutorial.step${slideIndex + 1}_description`);
    
    return (
      <IonSlide key={`step_${slideIndex}`} style={ionSlideStyle}>
        <ImageContainer>
          <Image src={slideImages[slideIndex]} alt={`tutorial step ${slideIndex + 1}`} />
        </ImageContainer>

        <Instructions>
          <Dots number={steps.length} active={displayedSlideIndex} color="black" />
          {t(`tutorial.step${slideIndex + 1}_title`).length > 0 && (
            <StepTitle>{t(`tutorial.step${slideIndex + 1}_title`)}</StepTitle>
          )}
          <Description>
            {description.split('\n').map((x, i) => [i > 0 ? <br key={i}/> : null, x])}
          </Description>
        </Instructions>

        <ButtonContainer>
          {displayedSlideIndex > 0 && (
            <PreviousButton onClick={() => goToSlide(displayedSlideIndex - 1)} filled={false}>
              {t('tutorial.previousPageButton')}
            </PreviousButton>
          )}
          {displayedSlideIndex < steps.length - 1 && (
            <NextButton onClick={() => goToSlide(displayedSlideIndex + 1)}>
              {t('tutorial.nextPageButton')}
            </NextButton>
          )}
          {displayedSlideIndex === steps.length - 1 && (
            <NextButton onClick={closeTutorial}>{t('tutorial.finalButton')}</NextButton>
          )}
        </ButtonContainer>
      </IonSlide>
    )
  }

  return (
    <Container>
        <Header>
          <h1>{t('tutorial.header')}</h1>
          <CloseButton onClick={closeTutorial}>
            <Cross />
          </CloseButton>
        </Header>

        <IonSlides style={ionSlideContainerStyle} pager={false} options={slideOpts} ref={ionSlidesRef}>
          {steps.map((x) => generateSlide(x))}
        </IonSlides>
    </Container>
  )
}
