import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Ordering, UserOpinion, UserProjectProductDTO } from '@fynde/dtos'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { Button } from '../../atoms/Button/Button'
import { Select, SelectOption } from '../../atoms/Select/Select'
import { CenteredLoading } from '../../molecules/CenteredLoading/CenteredLoading'
import { GridCard } from '../GridCard/GridCard'
import {
  ProjectProductVariationCardWrapper,
  ProjectProductVariationCardWrapperProps,
} from '../ProjectProductVariationCard/ProjectProductVariationCardWrapper'
import './ProjectPageProductsTab.css'
import { Socket } from 'socket.io-client'
import { getFormattedPrice } from '../../../utils/tool'
import { RequestStatus } from '../../../utils/reqStatus'
import { UserProjectProductChatModal } from '../UserProjectProductModal/UserProjectProductModal'
import { LoadingProjectProductVariationCard } from '../ProjectProductVariationCard/ProjectProductVariationCard'
import { UserProjectProductOpinionReminder } from '../../atoms/UserProjectProductOpinionReminder/UserProjectProductOpinionReminder'
import { UserProductModal } from '../UserProductModal/UserProductModal'
import { Routes } from '../../../utils/Routes'
import { useHistory } from 'react-router'

export interface ProjectPageProductsTabProps {
  projectId: string
  isDefaultProject: boolean
  show: boolean
  socket: Socket | undefined
}

export const ProjectPageProductsTab: React.FC<ProjectPageProductsTabProps> = ({
  projectId,
  isDefaultProject,
  show,
  socket,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  // ordering
  const orderingOptions: SelectOption[] = [
    { displayedText: t('ordering.creationDateDesc'), value: Ordering.CreationDateDesc },
    { displayedText: t('ordering.creationDateAsc'), value: Ordering.CreationDateAsc },
    { displayedText: t('ordering.quantityDesc'), value: Ordering.QuantityDesc },
    { displayedText: t('ordering.quantityAsc'), value: Ordering.QuantityAsc },
    { displayedText: t('ordering.priceDesc'), value: Ordering.PriceDesc },
    { displayedText: t('ordering.priceAsc'), value: Ordering.PriceAsc },
  ]

  const [suggestedProductsOption, setSuggestedProductsOption] = useState<SelectOption | null>(
    orderingOptions[0]
  )
  const [validatedProductsOption, setValidatedProductsOption] = useState<SelectOption | null>(
    orderingOptions[0]
  )
  const [suggestedProductsOrder, setSuggestedProductsOrder] = useState<Ordering>(
    suggestedProductsOption!.value as number
  )
  const [validatedProductsOrder, setValidatedProductsOrder] = useState<Ordering>(
    validatedProductsOption!.value as number
  )

  const handleOrderingChange = useCallback(
    (target: 'suggested' | 'validated', newOrder: SelectOption) => {
      if (target === 'suggested') {
        setSuggestedProductsOption(newOrder)
        setSuggestedProductsOrder(newOrder.value as number)
      } else {
        setValidatedProductsOption(newOrder)
        setValidatedProductsOrder(newOrder.value as number)
      }
    },
    [
      setSuggestedProductsOption,
      setSuggestedProductsOrder,
      setValidatedProductsOption,
      setValidatedProductsOrder,
    ]
  )

  // new user-product
  const [newUserProductAlertState, setNewUserProductAlertState] = useState<boolean>(false)

  // product chat
  const [productChatProductId, setProductChatProductId] = useState<string | undefined>(undefined)

  // data
  const userId = useStoreState((store) => store.user.userId())
  const project = useStoreState((store) => store.projects.project(projectId))
  const projectBudget = useStoreState((store) => store.projects.projectBudget(projectId))
  const isProjectLoaded = useStoreState((state) => state.projects.isProjectFullyLoaded(projectId))
  const products = useStoreState((store) =>
    store.projectProducts.orderedProjectProducts(projectId, Ordering.CreationDateAsc)
  )
  const orderedProducts = useStoreState((store) => store.projectProducts.orderedProjectProducts)
  const productsLoadingStatus = useStoreState((store) =>
    store.projectProducts.projectProductsLoadingStatus(projectId)
  )
  const fetchProductVariations = useStoreActions(
    (store) => store.projectProducts.fetchProjectProductVariations
  )
  const fetchProductOpinions = useStoreActions(
    (store) => store.userProjectProductOpinions.fetchProjectProductsOpinions
  )

  useEffect(() => {
    if (productsLoadingStatus === RequestStatus.Completed) {
      fetchProductVariations(projectId)
      fetchProductOpinions(projectId)
    }
  }, [projectId, productsLoadingStatus, fetchProductVariations, fetchProductOpinions])

  // products
  const suggestedProducts = useMemo(() => {
    return orderedProducts(projectId, suggestedProductsOrder).filter(
      (product) => product.validated === false
    )
  }, [projectId, orderedProducts, suggestedProductsOrder])

  const validatedProducts = useMemo(() => {
    return orderedProducts(projectId, validatedProductsOrder).filter(
      (product) => product.validated === true
    )
  }, [projectId, orderedProducts, validatedProductsOrder])

  // opinion reminder
  const [isPostingOpinion, setIsPostingOpinion] = useState<boolean>(false)
  const opinionsLoadingStatus = useStoreState((store) =>
    store.userProjectProductOpinions.projectLoadingStatus(projectId)
  )

  const postOpinion = useStoreActions(
    (store) => store.userProjectProductOpinions.postUserProjectProductOpinion
  )
  const noOpinionProducts = useMemo(() => {
    if (userId === null) return []
    if (opinionsLoadingStatus !== RequestStatus.Completed) return []
    return products.filter(
      (product) =>
        product.opinions.length === 0 ||
        !product.opinions.some((opinion) => opinion.userId === userId)
    )
  }, [userId, opinionsLoadingStatus, products])

  const handleReview = useCallback(
    async (userProjectProductId: string, opinion: UserOpinion) => {
      console.log(
        `[ProjectPageProductsTab] set opinion '${opinion}' to project-product '${userProjectProductId}'`
      )
      if (userId === null) {
        console.error(
          `[ProjectPageProductsTab] cannot post a new opinion because userId is missing`
        )
        return
      }
      setIsPostingOpinion(true)
      postOpinion({
        userProjectProductId,
        userId: userId,
        opinion: opinion,
      }).then(() => setIsPostingOpinion(false))
    },
    [userId, postOpinion, setIsPostingOpinion]
  )

  // product card
  const getProjectProductCardProps = (
    product: UserProjectProductDTO
  ): ProjectProductVariationCardWrapperProps | undefined => {
    if (
      !project ||
      (product.productVariationId && !product.productVariation) ||
      (product.userProductId && !product.userProduct)
    ) {
      return undefined
    } else {
      return {
        userProjectProductId: product.id,
        isDefaultProject: project.isDefault,
        productVariationCardProps: {
          productVariationId: product.productVariation?.id,
          userProductId: product.userProduct?.id,
          brandId: product.productVariation?.product.brand?.id,
          productId: product.productVariation?.product.id,
          eshopProductVariationId: product.eshopProductVariation?.id,
          displayName: (product.productVariation?.displayName || product.userProduct?.name)!,
          brandName: product.productVariation?.product.brand?.name || product.userProduct?.brand,
          buyLink: product.eshopProductVariation?.url || product.userProduct?.url,
          price: product.eshopProductVariation?.price || product.userProduct?.price,
          thumbnail:
            product.productVariation?.thumbnail?.media.publicUrl ||
            product.userProduct?.thumbnail?.publicUrl,
          thumbnailBgColor: product.productVariation?.thumbnail?.hexaColor || undefined,
        },
      }
    }
  }

  return (
    <div className={'project-products-wrapper' + (show ? '' : ' hidden')}>
      {productChatProductId && (
        <UserProjectProductChatModal
          isOpen={!!productChatProductId}
          onCancel={() => setProductChatProductId(undefined)}
          userProjectProductId={productChatProductId}
          socket={socket}
        />
      )}

      <UserProductModal
        isOpen={newUserProductAlertState}
        onCancel={() => setNewUserProductAlertState(false)}
        userProjectId={projectId}
      />

      {!project || !isProjectLoaded ? (
        <CenteredLoading />
      ) : (
        <>
          <div className={'main-cta-wrapper'}>
            <Button onClick={() => history.push(Routes.Search)} fontSize={'large'} filled={true}>
              {t('project.newSearchButton')}
            </Button>
            <Button
              onClick={() => setNewUserProductAlertState(true)}
              fontSize={'medium'}
              filled={false}
            >
              {t('project.newUserProductButton')}
            </Button>
          </div>

          {!isDefaultProject && noOpinionProducts.length > 0 && (
            <UserProjectProductOpinionReminder
              userProjectProductId={noOpinionProducts[0].id}
              imgData={
                noOpinionProducts[0].productVariation?.thumbnail?.media.publicUrl ||
                noOpinionProducts[0].userProduct?.thumbnail.publicUrl
              }
              productName={
                noOpinionProducts[0].productVariation?.displayName ||
                noOpinionProducts[0].userProduct?.name ||
                ''
              }
              brandName={
                noOpinionProducts[0].productVariation?.product.brand?.name ||
                noOpinionProducts[0].userProduct?.brand ||
                ''
              }
              price={
                noOpinionProducts[0].productVariation?.eshopProductVariation?.price ||
                noOpinionProducts[0].userProduct?.price ||
                0
              }
              onReview={handleReview}
              isLocked={isPostingOpinion}
            />
          )}

          {/* Suggested products */}
          <div className={'title'}>
            <h2>{t('project.products.suggested.header')}</h2>

            {suggestedProducts.length >= 2 && (
              <div className={'ordering-wrapper'}>
                <Select
                  options={orderingOptions}
                  selectedOption={suggestedProductsOption}
                  onChange={(newSelection) => handleOrderingChange('suggested', newSelection!)}
                  isClearable={false}
                  fontSize={'medium'}
                  choicesAnchor={'right'}
                />
              </div>
            )}
          </div>

          {suggestedProducts.length > 0 ? (
            <GridCard justify={'left'}>
              {suggestedProducts.map((product) => {
                const cardProps = getProjectProductCardProps(product)
                return cardProps === undefined ? (
                  <div key={product.id}>
                    <LoadingProjectProductVariationCard />
                  </div>
                ) : (
                  <ProjectProductVariationCardWrapper
                    key={product.id}
                    {...cardProps}
                    onChatClick={() => setProductChatProductId(product.id)}
                    isDefaultProject={isDefaultProject}
                  />
                )
              })}
            </GridCard>
          ) : (
            <p className={'message'}>{t('project.products.suggested.noProducts')}</p>
          )}

          {/* Validated products */}
          {!isDefaultProject && (
            <>
              <div className={'title'}>
                <h2>{t('project.products.validated.header')}</h2>

                {validatedProducts.length >= 2 && (
                  <div className={'ordering-wrapper'}>
                    <Select
                      options={orderingOptions}
                      selectedOption={validatedProductsOption}
                      onChange={(newSelection) => handleOrderingChange('validated', newSelection!)}
                      isClearable={false}
                      fontSize={'medium'}
                      choicesAnchor={'right'}
                    />
                  </div>
                )}
              </div>

              {validatedProducts.length > 0 ? (
                <>
                  <div className={'budget'}>
                    {projectBudget !== undefined ? (
                      <>
                        <div>
                          <p>{t('project.withoutVAT')}</p>
                          <span className={'price'}>
                            {getFormattedPrice(projectBudget / 1.2, 'EUR')}
                          </span>
                        </div>
                        <div className={'divider'} />
                        <div>
                          <p>{t('project.withVAT')}</p>
                          <span className={'price'}>{getFormattedPrice(projectBudget, 'EUR')}</span>
                        </div>
                      </>
                    ) : (
                      <CenteredLoading isLight={true} />
                    )}
                  </div>

                  <GridCard justify={'left'}>
                    {validatedProducts.map((product) => {
                      const cardProps = getProjectProductCardProps(product)
                      return cardProps === undefined ? (
                        <div key={product.id}>
                          <LoadingProjectProductVariationCard />
                        </div>
                      ) : (
                        <ProjectProductVariationCardWrapper
                          key={product.id}
                          {...cardProps}
                          onChatClick={() => setProductChatProductId(product.id)}
                          isDefaultProject={isDefaultProject}
                        />
                      )
                    })}
                  </GridCard>
                </>
              ) : (
                <p className={'message'}>{t('project.products.validated.noProducts')}</p>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}
