import React, { useCallback } from 'react'
import { useStoreState, useStoreActions } from '../../../store/hooks'
import { PriceFilterCard, PriceRange } from './PriceFilterCard'

const PRICE_VALUES = [0, 100, 200, 300, 500, 750, 1000, 1500, 2000, 2500, 3000, 4000, 5000, 10000]
export const MIN_PRICE = PRICE_VALUES[0]
export const MAX_PRICE = PRICE_VALUES[PRICE_VALUES.length - 1]

export interface PriceFilterCardWrapperProps {
  applyOnChange: boolean
  onChange?: (priceRange: PriceRange) => void
}

export const PriceFilterCardWrapper: React.FC<PriceFilterCardWrapperProps> = ({
  applyOnChange,
  onChange,
}) => {
  const filtersPriceRange = useStoreState((state) => state.filters.selectedPriceRange)
  const updateFilter = useStoreActions((actions) => actions.filters.setNewFilters)

  const applyFilter = useCallback(
    (newRange: PriceRange) => {
      if (filtersPriceRange.min !== newRange.min || filtersPriceRange.max !== newRange.max) {
        console.debug('[PriceFilterCardWrapper] applyFilter:', newRange)
        updateFilter({
          selectedPriceRange: newRange,
        })
      }
    },
    [filtersPriceRange, updateFilter]
  )

  const onRangeChange = useCallback(
    (newRange: PriceRange) => {
      console.debug('[PriceFilterCardWrapper] onRangeChange')
      const newSelection: PriceRange = {
        min: newRange.min,
        max: newRange.max,
      }

      if (applyOnChange === true) {
        applyFilter(newSelection)
      }

      if (
        onChange &&
        (filtersPriceRange.min !== newSelection.min || filtersPriceRange.max !== newSelection.max)
      ) {
        onChange(newSelection)
      }
    },
    [applyOnChange, applyFilter, onChange, filtersPriceRange]
  )

  return (
    <PriceFilterCard
      values={PRICE_VALUES}
      selectedRange={{
        min: filtersPriceRange.min,
        max: filtersPriceRange.max,
      }}
      onRangeChange={(range) => onRangeChange(range)}
    />
  )
}
