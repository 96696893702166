import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { useStoreState } from '../../../store/hooks'
import { ProjectListItem } from './ProjectListItem'

export interface ProjectListItemWrapperProps {
  projectId: string
}

export const ProjectListItemWrapper: React.FC<ProjectListItemWrapperProps> = ({ projectId }) => {
  const history = useHistory()

  // data
  const project = useStoreState((state) => state.projects.project(projectId))
  const projectBudget = useStoreState((state) => state.projects.projectBudget(projectId))
  const membersCount = useStoreState((state) => state.projectMembers.projectMembersCount(projectId))
  const productsCount = useStoreState((state) =>
    state.projectProducts.projectProductsCount(projectId)
  )
  const isProjectLoaded = useStoreState((state) => state.projects.isProjectFullyLoaded(projectId))

  const handleProjectOpening = useCallback(() => {
    history.push(`/projects/${projectId}`)
  }, [history, projectId])

  return (
    <ProjectListItem
      isLoading={!isProjectLoaded}
      name={project?.name}
      budget={projectBudget}
      membersCount={membersCount}
      productsCount={productsCount}
      creationDate={project?.createdAt}
      isDefaultProject={project?.isDefault}
      onOpen={handleProjectOpening}
    />
  )
}
