import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactCrop from 'react-image-crop'
import styled from 'styled-components'
import { Crop, getCenterPosition, pushAwayCrops } from '../../../utils/crop'
import { loadImage } from '../../../utils/tool'
import { Loading } from '../Loading/Loading'
import 'react-image-crop/dist/ReactCrop.css'
import './CVController.css'

const AreaPointsWrapper = styled.div<{ width: number; height: number }>`
  width: ${(props) => props.width + 'px'};
  height: ${(props) => props.height + 'px'};
`

interface CropArea {
  xMin: number
  yMin: number
  xMax: number
  yMax: number
}
const DOT_SIZE = 24 // css must be updated too
const DOT_SAFE_DISTANCE = 8
const DEFAULT_CROP_MARGIN = 10
const MIN_CROP_MARGIN = 10
const MIN_CROP_SIZE = 64

export interface CVControllerProps {
  imageSrc: string
  isLocked: boolean
  onCrop: (crop: Crop, suggestedCropIndex: number | null) => void
  suggestedCrops?: Crop[]
  initialCrop?: Crop
  availableHeight: number
  availableWidth: number
  withEmptySpace: boolean
}

export const CVController: React.FC<CVControllerProps> = ({
  imageSrc,
  isLocked,
  onCrop,
  suggestedCrops = [],
  initialCrop,
  availableHeight,
  availableWidth,
  withEmptySpace,
}) => {
  const ref = useRef<any>()

  const [imageSize, setImageSize] = useState<number[]>([1, 1])
  const [imageScaleCoef, setImageScaleCoef] = useState<number>(1)
  const [imageScaledSize, setImageScaledSize] = useState<number[]>([
    availableWidth,
    availableHeight,
  ])

  const [crop, setCrop] = useState<Crop | undefined>(undefined)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [isTempDisabled, setIsTempDisabled] = useState(false)

  useEffect(() => {
    setIsImageLoaded(false)
  }, [imageSrc])

  const calculateImageScale = useCallback(
    (imageSize: number[]) => {
      const imageRatio = imageSize[1] / imageSize[0]
      let width = availableWidth
      let height = availableHeight
      if (imageRatio <= 1) {
        width = availableWidth
        height = Math.min(availableWidth * imageRatio, availableHeight)
      } else {
        width = Math.min(availableWidth, availableHeight / imageRatio)
        height = availableHeight
      }
      setImageScaledSize([width, height])

      // image scale
      const scaleCoef = width / imageSize[0]
      setImageScaleCoef(scaleCoef)

      return { width, height, scaleCoef }
    },
    [availableWidth, availableHeight]
  )

  useEffect(() => {
    if (!crop) return

    // old crop
    const oldCrop = { ...crop }
    const oldScale = imageScaleCoef

    // image size
    const { scaleCoef } = calculateImageScale(imageSize)

    // croppable area
    setCrop({
      x: (oldCrop.x! / oldScale) * scaleCoef,
      y: (oldCrop.y! / oldScale) * scaleCoef,
      width: (oldCrop.width! / oldScale) * scaleCoef,
      height: (oldCrop.height! / oldScale) * scaleCoef,
    })
  }, [availableWidth, availableHeight])

  // fired once, when the image is not loaded yet
  const handleImageLoaded = useCallback(
    async (image: HTMLImageElement): Promise<boolean> => {
      // disable the Pinterest plugin over the UI
      image.setAttribute('nopin', 'nopin')

      if (!imageSrc) return false

      const img = await loadImage(imageSrc)
      console.debug('[CVController] CV image is loaded')
      setIsImageLoaded(true)

      const imageSize = [image.naturalWidth, image.naturalHeight]
      setImageSize(imageSize)
      // console.debug('[CVController] image size:', imageSize)
      // console.debug('[CVController] available size:', availableWidth, 'x', availableHeight)

      const { width, height, scaleCoef } = calculateImageScale(imageSize)
      // console.debug('[CVController] displayed image size:', [width, height])
      // console.debug('[CVController] scale coef:', scaleCoef)

      // full image region
      const fullImageCrop = {
        x: 0,
        y: 0,
        width: Math.round(width / scaleCoef),
        height: Math.round(height / scaleCoef),
      }
      // console.debug('[CVController] full image area:', fullImageCrop)

      const defaultCrop = initialCrop
        ? {
            x: Math.round(initialCrop.x * scaleCoef),
            y: Math.round(initialCrop.y * scaleCoef),
            width: Math.round(initialCrop.width * scaleCoef),
            height: Math.round(initialCrop.height * scaleCoef),
          }
        : {
            x: DEFAULT_CROP_MARGIN,
            y: DEFAULT_CROP_MARGIN,
            width: width - 2 * DEFAULT_CROP_MARGIN,
            height: height - 2 * DEFAULT_CROP_MARGIN,
          }
      if (initialCrop) console.debug('[CVController] set initial crop:', initialCrop)
      else console.debug('[CVController] set default crop:', defaultCrop)
      setCrop(defaultCrop)
      if (!isLocked) onCrop(fullImageCrop, null)

      img.remove()
      return true
    },
    [initialCrop, imageSrc, isLocked, calculateImageScale, onCrop]
  )

  const scaleCrop = useCallback(
    (c: Crop): Crop => {
      return {
        x: Math.round(c.x! / imageScaleCoef),
        y: Math.round(c.y! / imageScaleCoef),
        width: Math.round(c.width! / imageScaleCoef),
        height: Math.round(c.height! / imageScaleCoef),
      }
    },
    [imageScaleCoef]
  )

  // ObjectDetection's areas
  // Here we scale (down) the provided object detection regions and remove the unusable ones
  const suggestedScaledCrops: Crop[] = useMemo(() => {
    if (suggestedCrops.length === 0 || !ref.current || !isImageLoaded) return []

    const scaledCrops = suggestedCrops
      .map((suggestedCrop) => {
        return {
          x: Math.round(suggestedCrop.x * imageScaleCoef),
          y: Math.round(suggestedCrop.y * imageScaleCoef),
          width: Math.round(suggestedCrop.width * imageScaleCoef),
          height: Math.round(suggestedCrop.height * imageScaleCoef),
          customCenter: suggestedCrop.customCenter
            ? {
                x: Math.round(suggestedCrop.customCenter!.x * imageScaleCoef),
                y: Math.round(suggestedCrop.customCenter!.y * imageScaleCoef),
              }
            : undefined,
        } as Crop
      })
      .filter((c) => {
        // TODO: Find a better solution for this
        // For now we remove all crops that would not fit in the container (too low)
        // That's because the picture would not fit in the container, except in very "high" phones such as the iPhone X (18/9 or 21/9 ratio)
        // We could "fit" the picture, but we would have borders
        const top = c.y + c.height / 2
        return top < availableHeight
      })

    // move away too close points
    for (let i = 0; i < scaledCrops.length; i++) {
      const scaledCrop = scaledCrops[i]

      for (let j = 0; j < scaledCrops.length; j++) {
        if (i === j) continue
        const otherCrop = scaledCrops[j]

        // mutate
        pushAwayCrops(scaledCrop, otherCrop, DOT_SIZE + DOT_SAFE_DISTANCE)
      }
    }
    return scaledCrops
  }, [suggestedCrops, isImageLoaded, imageScaleCoef, availableHeight])

  // refresh the component
  const handleCropChange = (c: ReactCrop.Crop) => {
    if (isTempDisabled || !isImageLoaded) return

    // prevent crop to exceed the authorized area (minimal margins)
    const x = Math.max(c.x || 0, MIN_CROP_MARGIN)
    const y = Math.max(c.y || 0, MIN_CROP_MARGIN)
    const crop = {
      x: x,
      y: y,
      width: Math.min(c.width || 0, imageScaledSize[0] - MIN_CROP_MARGIN - x),
      height: Math.min(c.height || 0, imageScaledSize[1] - MIN_CROP_MARGIN - y),
    }
    setCrop(crop)
  }

  // after dragging the crop area
  const handleCropComplete = useCallback(
    (c: ReactCrop.Crop) => {
      if (!c || !ref.current || isLocked) return
      console.debug('[CVController] handleCropComplete')

      const crop: Crop = {
        x: c.x!,
        y: c.y!,
        width: c.width!,
        height: c.height!,
      }
      if (crop.width === 0 || crop.height === 0) return
      onCrop(scaleCrop(crop), null)
    },
    [isLocked, scaleCrop, onCrop]
  )

  // after clicking on a point
  const handlePointClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, i: number) => {
      e.preventDefault()
      e.stopPropagation()

      const crop = suggestedScaledCrops[i]
      console.debug('[CVController] handle OD point click')

      setIsTempDisabled(true)

      setCrop(crop)
      onCrop(scaleCrop(crop), i)

      window.setTimeout(() => setIsTempDisabled(false), 100)
    },
    [setCrop, setIsTempDisabled, suggestedScaledCrops, scaleCrop, onCrop]
  )

  return (
    <div
      className={`react-crop-wrapper ${withEmptySpace ? 'with-empty-space' : ''} ${
        isLocked ? 'locked' : ''
      }`}
    >
      <div className={'mask'} />
      {!imageSrc ? (
        <div className={'loader-wrapper'}>
          <Loading isLight />
        </div>
      ) : (
        <>
          {crop && (
            <AreaPointsWrapper
              className={'area-points-wrapper'}
              width={imageScaledSize[0]}
              height={imageScaledSize[1]}
            >
              {suggestedScaledCrops.map((suggestedCrop, i) => (
                <div
                  key={i}
                  className="area-point"
                  style={{
                    left:
                      (suggestedCrop.customCenter
                        ? suggestedCrop.customCenter.x
                        : getCenterPosition(suggestedCrop)[0]) + DOT_SAFE_DISTANCE,
                    top:
                      (suggestedCrop.customCenter
                        ? suggestedCrop.customCenter.y
                        : getCenterPosition(suggestedCrop)[1]) + DOT_SAFE_DISTANCE,
                  }}
                  onClick={(e) => handlePointClick(e, i)}
                />
              ))}
            </AreaPointsWrapper>
          )}
          <ReactCrop
            ref={ref}
            src={imageSrc}
            imageStyle={{
              maxWidth: availableWidth,
              maxHeight: availableHeight,
              objectFit: 'cover',
            }}
            crop={crop}
            minWidth={MIN_CROP_SIZE}
            minHeight={MIN_CROP_SIZE}
            disabled={!isImageLoaded || isLocked || isTempDisabled}
            keepSelection={true}
            onImageLoaded={handleImageLoaded}
            onChange={handleCropChange}
            onComplete={handleCropComplete}
          />
        </>
      )}
    </div>
  )
}
