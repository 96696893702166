import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../Modal/Modal'
import config from '../../../config'
import { isAndroid } from '../../../utils/platform'
import { Button } from '../../atoms/Button/Button'

export interface AppVersionModalProps {}

export const AppVersionModal: React.FC<AppVersionModalProps> = () => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={true}
      onCancel={() => null}
      header={t('outdatedAppAlert.header')}
      withButtons={true}
      altValidateButton={
        <a
          href={isAndroid() ? config.appStores.android : config.appStores.ios}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          <Button>
            {t('outdatedAppAlert.button')}
          </Button>
        </a>
      }
    >
      <p>
        {t('outdatedAppAlert.message')}
      </p>
    </Modal>
  )
}
