import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  colors: {
    white: '#ffffff',
    black: '#000000',
    blackBorder: '#747577',
    primary200: '#000000',
    secondary200: '#9FBFBC',
    secondary500: '#A03232',
    grey100: '#EFEFEF',
    grey200: '#E2E2E2',
    grey300: '#BEBEBE',
    grey400: '#D1D1D1',
    grey450: '#ABABAB',
    grey500: '#606060',
    greyBg: '#F2F2F2',
    success: '#5AB0C3',
    warning: '#CEA40D',
    error: '#DE4848',
  },
}
