import { IonPage } from '@ionic/react'
import React, { useState, useEffect, FormEvent } from 'react'
import { useStoreActions } from '../store/hooks'
import styled from 'styled-components'
import { Button } from '../components/atoms/Button/Button'
import { PasswordInput } from '../components/atoms/PasswordInput/PasswordInput'
import { LanguageSelect } from '../components/molecules/LanguageSelect/LanguageSelect'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Routes } from '../utils/Routes'
import { useIonViewDidEnter } from '@ionic/react'
import config from '../config'
import { ErrorsCode } from '@fynde/dtos'
import { TextInput } from '../components/atoms/TextInput/TextInput'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'

const CenteredContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`
const LanguageSelectContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`
const Logo = styled.img`
  width: 230px;
  margin-bottom: 1.5em;
`
const Title = styled.h2`
  font-size: 1.8em;
  font-weight: 700;
  margin-bottom: 0.8em;
  width: 100%;
  text-align: center;
`
const Info = styled.p`
  color: ${(props) => props.theme.colors.black};
  margin: 0 0 2em 0;
  text-align: left;

  @media (min-width: 480px) {
    width: 400px;
    text-align: center;
  }
`
const Form = styled.form`
  margin-bottom: 2.5em;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  @media (min-width: 480px) {
    width: 400px;
  }
`
const Error = styled.p`
  color: ${(props) => props.theme.colors.error};
  margin: 0 0 1.5em 0;
  text-align: center;
`
const SubmmitButton = styled(Button)`
  width: 100%;
  margin-top: 1em;
`
const Line = styled.p`
  margin: 0 0 0.8em 0;
`
const Link = styled.a`
  color: ${(props) => props.theme.colors.black};
  font-weight: 700;
`

const Login: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  // title
  useIonViewDidEnter(() => {
    document.title = config.pageTitlePrefix + t('pageTitle.Login')
  }, [t])

  // form
  const [filledEmail, setEmail] = useState<string | null>(null)
  const [filledPassword, setPassword] = useState<string | null>(null)

  const [logInError, setLogInError] = useState<string | null>(null)
  const logIn = useStoreActions((actions) => actions.user.logIn)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    console.log('[Login] log in')

    if (filledEmail != null && filledPassword != null) {
      const logInError = await logIn({
        email: filledEmail,
        password: filledPassword,
      })
      setLogInError(logInError)

      if (logInError === ErrorsCode.Wrong_Credentials) {
        Mixpanel.track(MixpanelEvents.WrongUserPassword, {
          email: filledEmail,
        })
      }

      if (logInError === null) {
        history.push(Routes.Home)
      }
    }
  }

  // autofilled
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const email = urlParams.get('email')
    if (email != null) {
      setEmail(email)
    }
  }, [])

  return (
    <IonPage>
      <CenteredContainer>
        <Logo src="assets/branding/logo_fynde_noMargin_500px.png" alt="Fynde logo" />
        <Title>{t('logIn.title')}</Title>
        <Info>{t('logIn.info')}</Info>

        <Form onSubmit={handleSubmit}>
          <TextInput
            placeholder={t('logIn.emailPlaceholder')}
            onChange={setEmail}
            autoComplete={'email'}
            value={filledEmail || ''}
            size={'medium'}
          />
          <PasswordInput
            placeholder={t('logIn.passwordPlaceholder')}
            onChange={setPassword}
            withEye={true}
            autoComplete={'current-password'}
          />
          {logInError === ErrorsCode.Not_Validated_Email ? (
            <Error>{t('logIn.errors.notValidatedEmail')}</Error>
          ) : logInError === ErrorsCode.Wrong_Credentials ? (
            <Error>{t('logIn.errors.wrongCredentials')}</Error>
          ) : (
            logInError !== null && <Error>{t('logIn.errors.logInFail')}</Error>
          )}
          <SubmmitButton>{t('logIn.submitButton')}</SubmmitButton>
        </Form>

        <Line>
          {t('link.forgottenPassword.prefix')}&nbsp;
          <Link href={Routes.ResetPassword}>{t('link.forgottenPassword.access')}</Link>
        </Line>
        <Line>
          {t('link.register.prefix')}&nbsp;
          <Link href={Routes.Register}>{t('link.register.access')}</Link>
        </Line>
      </CenteredContainer>

      <LanguageSelectContainer>
        <LanguageSelect />
      </LanguageSelectContainer>
    </IonPage>
  )
}

export default Login
