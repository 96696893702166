import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useStoreState, useStoreActions } from '../../../store/hooks'
import { Select, SelectOption } from '../../atoms/Select/Select'

// TMP: duplicated from DTOs to prevent storybook errors
export enum LanguageCode {
  French = "fr",
  English = "en"
}
export enum LanguageName {
  French = "Fran\u00E7ais",
  English = "English"
}

export interface LanguageSelectProps {
  onChange?: (local: string) => void
  className?: string
  choicesAnchor?: 'left' | 'right'
  withText?: boolean
}

export const LanguageSelect: React.FC<LanguageSelectProps> = ({
  onChange,
  className,
  choicesAnchor = 'left',
  withText = true,
}) => {
  // language
  const userId = useStoreState((state) => state.user.userId())
  const userLanguage = useStoreState((state) => state.user.language)
  const setUserLanguage = useStoreActions((actions) => actions.user.setLanguage)
  const saveUserLanguageInDB = useStoreActions((actions) => actions.user.saveLanguageInDB)

  useEffect(() => {
    if (userLanguage != null) {
      for (let i = 0; i < languageOptions.length; i++) {
        if (languageOptions[i].value === userLanguage)
          setSelectedOptionIndex(i)
      }
      if (onChange != null)
        onChange(userLanguage);
    }
  }, [userLanguage, onChange])

  // UI
  const languageOptions: SelectOption[] = useMemo(() => {
    return [
      {
        value: LanguageCode.English,
        displayedText: withText ? LanguageName.English : undefined,
        picto: <img src="assets/flags/en.png" alt={LanguageCode.English} />
      },
      {
        value: LanguageCode.French,
        displayedText: withText ? LanguageName.French : undefined,
        picto: <img src="assets/flags/fr.png" alt={LanguageCode.French} />
      },
    ]
  }, [withText])

  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0)
  const handleLanguageChange = useCallback((option: SelectOption) => {
    const newLanguage = option.value as string;
    setUserLanguage(newLanguage);
    if (userId)
      saveUserLanguageInDB(newLanguage);
  }, [saveUserLanguageInDB, setUserLanguage, userId])

  return (
    <Select
      className={`language-select${className ? ` ${className}` : ''}`}
      options={languageOptions}
      selectedOption={languageOptions[selectedOptionIndex]}
      onChange={(newSelection) => handleLanguageChange(newSelection!)}
      isClearable={false}
      choicesAnchor={choicesAnchor}
    />
  )
}
