import React from 'react'
import './ProjectPageChatTab.css'
import { Socket } from 'socket.io-client'
import { ChatWrapper } from '../../molecules/Chat/ChatWrapper'

export interface ProjectPageChatTabProps {
  projectId: string
  show: boolean
  socket: Socket | undefined
}

export const ProjectPageChatTab: React.FC<ProjectPageChatTabProps> = ({
  projectId,
  show,
  socket,
}) => {
  return (
    <div className={'project-chat-wrapper' + (show ? '' : ' hidden')}>
      <ChatWrapper
        projectId={projectId}
        userProjectProductId={null}
        socket={socket}
        maxHeight={'auto'}
        scrollOnEditorSizeChange={true}
      />
    </div>
  )
}
