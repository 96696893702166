import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { Globalization } from '@ionic-native/globalization'
import translationEN from '../i18n/en.json'
import translationFR from '../i18n/fr.json'

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})

const changeLanguage = (local: string) => {
  i18n.changeLanguage(local);
}

const setUpDefault = async () => {
  try {
    const defaultLang = await Globalization.getPreferredLanguage();
    changeLanguage(defaultLang.value);
  } catch (err) {
    console.error(err);
  }
}

setUpDefault()

export default i18n
