import React from 'react'
import { Route } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { LastLocationProvider } from 'react-router-last-location'
import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register/Register'
import EmailValidation from './pages/EmailValidation'
import ResetPassword from './pages/ResetPassword'
import NewPassword from './pages/NewPassword'
import Search from './pages/Search'
import Projects from './pages/Projects'
import Project from './pages/Project'
import Searches from './pages/Searches'
import Account from './pages/Account/Account'
// import StoreRedirection from './pages/StoreRedirection'
import ProductVariation from './pages/ProductVariation'
import Filters from './pages/Filters'

import { isApp } from './utils/platform'
import { ProtectedRoute } from './utils/ProtectedRoute'
import { Routes } from './utils/Routes'

const Router: React.FC = () => {
  //   const useMobile = isMobile();
  const useApp = isApp()

  return (
    <IonReactRouter>
      <LastLocationProvider>
        {useApp ? (
          <IonRouterOutlet mode="ios" animated={true}>
            <Route exact path={Routes.Home} component={Home} />
            <Route exact path={Routes.Search} component={Search} />
            <Route exact path={Routes.Filters} component={Filters} />
            <Route path={`${Routes.ProductVariation}/:id`} component={ProductVariation} />
          </IonRouterOutlet>
        ) : (
          <IonRouterOutlet mode="ios" animated={false} className={useApp ? 'app' : 'desktop'}>
            {/* {useMobile ? (
                        <>
                            <Route component={StoreRedirection} />
                            <Route exact path={Routes.EmailValidation} component={EmailValidation} />
                        </>
                    ) : ( */}
            <>
              <ProtectedRoute exact path={Routes.Home} component={Home} />
              <Route exact path={Routes.Login} component={Login} />
              <Route exact path={Routes.Register} component={Register} />
              <Route exact path={Routes.EmailValidation} component={EmailValidation} />
              <Route exact path={Routes.ResetPassword} component={ResetPassword} />
              <Route exact path={Routes.NewPassword} component={NewPassword} />
              <ProtectedRoute exact path={Routes.Search} component={Search} />
              <ProtectedRoute exact path={Routes.Filters} component={Filters} />
              <ProtectedRoute
                path={`${Routes.ProductVariation}/:id`}
                component={ProductVariation}
              />
              <ProtectedRoute exact path={Routes.Projects} component={Projects} />
              <ProtectedRoute path={`${Routes.Projects}/:projectId`} component={Project} />
              {/* <ProtectedRoute exact path={Routes.Searches} component={Searches} /> */}
              <ProtectedRoute exact path={Routes.Account} component={Account} />
            </>
            {/* )} */}
          </IonRouterOutlet>
        )}
      </LastLocationProvider>
    </IonReactRouter>
  )
}

export default Router
