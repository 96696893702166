import React, { useEffect, useState } from 'react'
import LogoImg from '../../../assets/branding/logo_fynde_noMargin_500px.png'
import { ReactComponent as AccountIcon } from '../../../assets/icons/user-icon.svg'
import { useTranslation } from 'react-i18next'
import { LanguageSelect } from '../LanguageSelect/LanguageSelect'
import './DesktopHeader.css'

export enum HeaderTabs {
  Search = 'search',
  Projects = 'likes',
  Searches = 'searches',
  Account = 'account',
}

export interface DesktopHeaderProps {
  selectedTab: HeaderTabs | null
  onHomeClick: () => void
  onSearchClick: () => void
  onProjectsClick: () => void
  onAccountClick: () => void
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  selectedTab,
  onHomeClick,
  onSearchClick,
  onProjectsClick,
  onAccountClick,
}) => {
  const { t } = useTranslation()

  // language
  const [withLanguageName, setWithLanguageName] = useState<boolean>(window.innerWidth >= 768)
  useEffect(() => {
    const newValue = window.innerWidth >= 768
    if (withLanguageName !== newValue) {
      setWithLanguageName(newValue)
    }
  }, [window.innerWidth, withLanguageName])

  return (
    <header className={'desktop-header'}>
      <img className={'logo'} src={LogoImg} alt="fynde-logo" onClick={onHomeClick} />

      <div className={'tabs-container'}>
        <div
          onClick={onSearchClick}
          className={`tab${selectedTab === HeaderTabs.Search ? ' selected' : ''}`}
        >
          <span>{t('desktopNav.searchTab')}</span>
        </div>

        <div
          onClick={onProjectsClick}
          className={`tab${selectedTab === HeaderTabs.Projects ? ' selected' : ''}`}
        >
          <span>{t('desktopNav.projectsTab')}</span>
        </div>
      </div>

      <div className={'grower'} />

      <div className={'right-container'}>
        <LanguageSelect choicesAnchor={'right'} withText={withLanguageName} />
        <div
          className={`account-container tab${
            selectedTab === HeaderTabs.Account ? ' selected' : ''
          }`}
          onClick={onAccountClick}
        >
          <AccountIcon />
        </div>
      </div>
    </header>
  )
}
