import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { getFormattedDate } from '../../../utils/tool'
import { ReactComponent as DefaultProjectIcon } from '../../../assets/icons/star-icon.svg'
import { ReactComponent as UserProjectIcon } from '../../../assets/icons/hashtag-icon.svg'
import './ProjectHeader.css'

export interface ProjectHeaderProps {
  isDefault: boolean
  name: string
  description?: string
  creationDate: Date
}

export const ProjectHeader: React.FC<ProjectHeaderProps> = ({
  isDefault,
  name,
  description,
  creationDate,
}) => {
  const { t } = useTranslation()

  return (
    <div className={'project-page-header'}>
      {!isDefault && <p className={'date light-txt'}>{getFormattedDate(creationDate, 'DMY')}</p>}

      <div className={'name'}>
        {isDefault ? (
          <>
            <DefaultProjectIcon />
            <h1>{t('project.defaultProjectName')}</h1>
          </>
        ) : (
          <>
            <UserProjectIcon />
            <h1>{name}</h1>
          </>
        )}
      </div>

      {description ? (
        <ReactMarkdown className={'description'}>
          {description.replace(/\n/g, '  \n')}
        </ReactMarkdown>
      ) : null}
    </div>
  )
}
