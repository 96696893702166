import { useIonViewDidEnter } from '@ionic/react'
import { AddressDTO, MediaAllowedMimetype } from '@fynde/dtos'
import { encode } from 'blurhash'
import config from '../config'
import Pica from 'pica/dist/pica'
import { useRef, useEffect } from 'react'
import { format } from 'date-fns'

export const loadImage = async (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = (...args) => reject(args)
    img.src = src
  })
}

export const createCanvasAndLoadImage = (image: HTMLImageElement): HTMLCanvasElement => {
  const canvas = document.createElement('canvas')
  canvas.width = image.width
  canvas.height = image.height
  const context = canvas.getContext('2d')
  context!.drawImage(image, 0, 0)
  return canvas
}

export const getImageData = (image: HTMLImageElement): ImageData => {
  const canvas = createCanvasAndLoadImage(image)
  const context = canvas.getContext('2d')
  const imageData = context!.getImageData(0, 0, image.width, image.height)
  canvas.remove()
  return imageData
}

export const readAsDataURL = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

export const dataURLToFile = (val: string, filename: string) => {
  const arr = val.split(',')
  const match = arr[0].match(/:(.*?);/)

  if (!match) return

  const mime = match[1]
  const bstr = atob(arr[1])

  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export const resizeImage = async (urlOrBase64: string, maxSize: number) => {
  const img = await loadImage(urlOrBase64)
  const width = img.width
  const height = img.height
  console.debug('original image size: ', width, height)

  if (width <= maxSize && height <= maxSize) return urlOrBase64

  const ratio = width >= height ? maxSize / width : maxSize / height
  const iwScaled = width * ratio
  const ihScaled = height * ratio

  // Create a canvas to hold the resized picture
  const resizedCanvas = document.createElement('canvas')
  resizedCanvas.width = iwScaled
  resizedCanvas.height = ihScaled

  const pica = new Pica()
  const result = await pica.resize(img, resizedCanvas)
  const blob = await pica.toBlob(result, 'image/jpeg')

  const resizedBase64 = await readAsDataURL(blob)

  resizedCanvas.remove()
  img.remove()

  return resizedBase64
}

export const encodeImageToBlurhash = async (
  imageUrl: string
): Promise<{ hash: string; imageWidth: number; imageHeight: number }> => {
  const resizedImageBase64 = await resizeImage(imageUrl, config.blurredImageMaxSize)
  const image = await loadImage(resizedImageBase64)
  const imageData = getImageData(image)

  // We create a miniature version of our image for blurring
  const componentX = scaleValue(imageData.width, [0, config.blurredImageMaxSize], [1, 4])
  const componentY = scaleValue(imageData.height, [0, config.blurredImageMaxSize], [1, 4])

  const blurhash = encode(imageData.data, imageData.width, imageData.height, componentX, componentY)

  image.remove()
  return { hash: blurhash, imageWidth: imageData.width, imageHeight: imageData.height }
}

export const isTheFileAnImage = (mimetype: string) => {
  return mimetype.split('/')[0] === 'image'
}

export const scaleValue = (value: number, from: number[], to: number[]) => {
  var scale = (to[1] - to[0]) / (from[1] - from[0])
  var capped = Math.min(from[1], Math.max(from[0], value)) - from[0]
  return ~~(capped * scale + to[0])
}

export const usePrevious = <T extends {}>(value: T | null): T | null | undefined => {
  const ref = useRef<T | null>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useIonPrevious = <T extends {}>(value: T | null): T | null | undefined => {
  const ref = useRef<T | null>()
  useIonViewDidEnter(() => {
    ref.current = value
  })
  return ref.current
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const areArraysEqual = <T>(array1: T[], array2: T[], sort: boolean): boolean => {
  if (array1 === array2) return true
  if (array1 == null || array2 == null) return false
  if (array1.length !== array2.length) return false

  const sortedArray1 = [...array1]
  const sortedArray2 = [...array2]
  if (sort === true) {
    sortedArray1.sort()
    sortedArray2.sort()
  }
  for (let i = 0; i < sortedArray1.length; ++i) {
    if (sortedArray1[i] !== sortedArray2[i]) return false
  }
  return true
}

export const getFormattedDate = (date: Date, type: 'DMY HM' | 'DMY' | 'HM'): string => {
  const emptyValue = ''
  let strFormat = 'dd\u202F/\u202FMM\u202F/\u202Fyyyy HH:mm'
  if (type === 'DMY') strFormat = 'dd\u202F/\u202FMM\u202F/\u202Fyyyy'
  else if (type === 'HM') strFormat = 'HH:mm'

  try {
    return date ? format(date, strFormat) : emptyValue
  } catch (err) {
    console.error('cannot get formatted date:', err)
    return emptyValue
  }
}

export const getFormattedAddress = (address: AddressDTO): string[] => {
  const lines: string[] = []
  lines.push(address.line1 || '')
  lines.push(address.line2 || '')
  lines.push((address.zipcode ? address.zipcode + ', ' : '') + (address.city || ''))
  lines.push(address.region || '')
  lines.push(address.country || '')
  return lines
}

export const getFormattedPrice = (price: number, currency: 'EUR' | 'USD'): string => {
  const formatter = new Intl.NumberFormat(currency === 'EUR' ? 'fr-FR' : 'en-US', {
    style: 'currency',
    currency: currency,
  })
  return formatter.format(price).replace(/\s/g, '\u202F')
}

export const getInitials = (name: string, maxLetters: number = 100): string => {
  const result = name
    .split(/[\s-]+/)
    .map((word) => word[0])
    .slice(0, maxLetters)
    .join('')
    .toUpperCase()
  // console.debug(`get initials: '${name}' -> '${result}'`)
  return result
}

export const splitFileExtension = (filename: string): string[] => {
  const split = filename.split('.')
  if (split.length === 1) {
    return [filename, '']
  } else {
    const basename = split.slice(0, split.length - 1).join('.')
    const extension = '.' + split.pop()
    return [basename, extension]
  }
}

export const getExtensionFromMimetype = (mimetype: string) => {
  const values = Object.values(MediaAllowedMimetype)
  const keys = Object.keys(MediaAllowedMimetype)
  for (let i = 0; i < values.length; i++) {
    if (mimetype === values[i]) return keys[i]
  }
  return null
}

export const toReadableFileSize = (filesize: number) => {
  if (filesize < 1000) {
    return `${filesize} o`
  } else if (filesize < 1000000) {
    return `${(filesize / 1000).toFixed(1)} ko`
  } else if (filesize < 1000000000) {
    return `${(filesize / 1000000).toFixed(1)} Mo`
  } else {
    return `${(filesize / 1000000000).toFixed(1)} Go`
  }
}

export const scrollToIonContentBottom = (animate: boolean) => {
  const ionContentTag = document.body.querySelector('ion-content')
  if (!ionContentTag) return
  const scrollableTag = ionContentTag.shadowRoot?.querySelector('.inner-scroll')
  if (!scrollableTag) return
  const top = scrollableTag.scrollHeight - scrollableTag.clientHeight
  if (animate) {
    scrollableTag.scrollTo({ top: top, behavior: 'smooth' })
  } else {
    scrollableTag.scrollTo({ top: top })
  }
}
