import axiosApi from '../utils/axios'
import { BrandDTO, BrandPaginatedDTO } from '@fynde/dtos'

// GET
export const getAllBrands = async (userToken: string): Promise<BrandDTO[] | null> => {
  let expected = 1
  const results: BrandDTO[] = []
  while (results.length < expected) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        params: {
          take: 100,
          skip: results.length,
        },
      }
      const resp = await axiosApi.get<BrandPaginatedDTO>(`/brands/`, config)
      results.push(...resp.data.data)
      expected = resp.data.total
    } catch (err) {
      console.error('An error occured while getting brands:', err)
      return null
    }
    // break
  }

  return results
}
