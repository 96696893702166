import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../Modal/Modal'
import ReactMarkdown from 'react-markdown'
import { useStoreActions } from '../../../store/hooks'

export interface LogOutModalProps {
  isOpen: boolean
  onCancel: () => void
}

export const LogOutModal: React.FC<LogOutModalProps> = ({
  isOpen,
  onCancel,
}) => {
  const { t } = useTranslation()

  const logOut = useStoreActions((actions) => actions.user.logOut)
  const handleValidation = useCallback(() => {
      console.debug(`[LogOutModal] log out`)
      logOut();
  }, [logOut])

  const message = t('lougOutAlert.message')
    .replace(/\n/g, '  \n') // markdown carriage return

  return (
    <Modal
      isOpen={isOpen}
      header={t('lougOutAlert.header')}
      cancelButton={t('lougOutAlert.cancel')}
      validateButton={t('lougOutAlert.confirm')}
      onCancel={onCancel}
      onValidate={handleValidation}
      isValidateButtonLocked={false}
      maxWidth={'600px'}
    >
      <ReactMarkdown>{message}</ReactMarkdown>
    </Modal>
  )
}
