import 'reflect-metadata' // cuz of dtos
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ScreenOrientation } from '@ionic-native/screen-orientation'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { isApp } from './utils/platform'

ReactDOM.render(<App />, document.getElementById('root'))

defineCustomElements(window)

if (isApp()) ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
