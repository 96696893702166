import React, { useEffect, useState } from 'react'
import { TextInput } from '../TextInput/TextInput'
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross-icon.svg'
import './SearchBar.css'

export type SearchBarProps = {
  placeholder: string
  size: 'medium' | 'large'
  onChange: (value: string) => void
}

export const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  size,
  onChange
}) => {
  const [value, setValue] = useState<string>('')

  function handleCrossClick() {
    setValue('')
  }

  useEffect(() => {
    onChange(value)
  }, [onChange, value])

  return (
    <div className={`searchbar ${size || ''}`}>
      <TextInput
        placeholder={placeholder}
        value={value}
        onChange={setValue}
        withRoundCorners={true}
        size={size}
      />
      {value.length > 0 && <CrossIcon onClick={handleCrossClick}/>}
    </div>
  )
}