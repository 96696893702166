import { UserProjectFileDTO } from '@fynde/dtos'
import { Action, Thunk, action, thunk, Computed, computed } from 'easy-peasy'
import { getUserProjectFiles, postUserProjectFile } from '../services/user-project-file'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'
import { StoreModel } from './store'
import { LocalUserFile } from './user-files'

export interface StoredProjectFile {
  userProjectId: string
  userFileId: string
}

export interface ProjectFilesModel {
  files: StoredProjectFile[]

  _addFile: Action<ProjectFilesModel, StoredProjectFile>
  _deleteFile: Action<ProjectFilesModel, StoredProjectFile>

  projectUserFiles: Computed<
    ProjectFilesModel,
    (userProjectId: string) => UserProjectFileDTO[],
    StoreModel
  >
  projectLocalUserFiles: Computed<
    ProjectFilesModel,
    (userProjectId: string) => LocalUserFile[],
    StoreModel
  >

  postProjectFile: Thunk<
    ProjectFilesModel,
    { userProjectId: string; localFile: LocalUserFile },
    any,
    StoreModel,
    Promise<boolean>
  >
  fetchProjectFiles: Thunk<ProjectFilesModel, string, any, StoreModel, Promise<boolean>>
  deleteProjectFile: Thunk<ProjectFilesModel, StoredProjectFile, any, StoreModel, Promise<boolean>>
}

export const projectFiles: ProjectFilesModel = {
  files: [],

  _addFile: action((state, newFile) => {
    if (
      state.files.some(
        (file) =>
          file.userProjectId === newFile.userProjectId && file.userFileId === newFile.userFileId
      )
    )
      return
    state.files = [...state.files, newFile]
  }),

  _deleteFile: action((state, fileToDelete) => {
    state.files = state.files.filter(
      (file) =>
        file.userProjectId !== fileToDelete.userProjectId ||
        file.userFileId !== fileToDelete.userFileId
    )
  }),

  projectUserFiles: computed(
    [(state) => state, (state, storeState) => storeState],
    (state, storeState) => {
      return (userProjectId) => {
        const results = []
        const projectFiles = state.files.filter((file) => file.userProjectId === userProjectId)
        for (const file of projectFiles) {
          const userFile = storeState.userFiles.userFiles([file.userFileId])[0]
          if (userFile === undefined) continue
          results.push({
            userProjectId: file.userProjectId,
            userFile: userFile,
          })
        }
        return results
      }
    }
  ),

  projectLocalUserFiles: computed([(state, storeState) => storeState], (storeState) => {
    return (userProjectId) => {
      return storeState.userFiles.linkedLocalFiles('drive', userProjectId, null)
    }
  }),

  postProjectFile: thunk(
    async (actions, { userProjectId, localFile }, { getStoreState, getStoreActions }) => {
      console.debug('[store.project-files] post a new user-project-file')

      const storeState = getStoreState()
      const storeActions = getStoreActions()

      if (storeState.user.token === null) {
        console.error(
          "[store.project-files] cannot post a new user-project-file because user's token is missing"
        )
        return false
      }

      const userFile = await storeActions.userFiles.postUserFile(localFile)
      if (userFile === null) return false

      const newProjectFile = await postUserProjectFile(storeState.user.token, {
        userProjectId,
        userFileId: userFile.id,
      })
      if (newProjectFile === null) return false

      storeActions.userFiles._addUserFile(userFile)
      storeActions.userFiles._removeLocalUserFile(localFile.id)
      actions._addFile({
        userProjectId: newProjectFile.userProjectId,
        userFileId: newProjectFile.userFile.id,
      })

      Mixpanel.track(MixpanelEvents.ProjectFileCreated, {
        projectId: userProjectId,
        userId: userFile.userId,
        mimetype: localFile.file.type,
        size: localFile.file.size,
        name: localFile.name,
      })

      return true
    }
  ),

  fetchProjectFiles: thunk(async (actions, userProjectId, { getStoreState, getStoreActions }) => {
    const storeState = getStoreState()
    const storeActions = getStoreActions()
    if (storeState.user.token === null) {
      console.error(
        '[store.project-files]',
        `cannot fetch user-project-files because user's token is missing`
      )
      return false
    }

    console.debug('[store.project-files]', `fetch user-project-files of a project:`, userProjectId)
    const userFiles = await getUserProjectFiles(storeState.user.token, userProjectId)

    for (const userFile of userFiles) {
      storeActions.userFiles._addUserFile(userFile)
      actions._addFile({
        userProjectId: userProjectId,
        userFileId: userFile.id,
      })
    }
    return true
  }),

  deleteProjectFile: thunk(async (actions, payload, { getStoreActions }) => {
    console.debug('[store.project-files] delete user-project-file:', payload)
    const success = await getStoreActions().userFiles.deleteUserFile(payload.userFileId)
    if (success) {
      actions._deleteFile(payload)

      Mixpanel.track(MixpanelEvents.ProjectFileDeleted, {
        projectId: payload.userProjectId,
        userFileId: payload.userFileId,
      })
    }
    return true
  }),
}
