import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { CenteredLoading } from '../../molecules/CenteredLoading/CenteredLoading'
import './ProjectPageMembersTab.css'
import { TextInput } from '../../atoms/TextInput/TextInput'
import { Button } from '../../atoms/Button/Button'
import ReactMarkdown from 'react-markdown'
import { RequestStatus } from '../../../utils/reqStatus'
import { useCallback } from 'react'
import { Feedback, FeedbackStatus } from '../../atoms/Feedback/Feedback'
import { validateEmail } from '../../../utils/login'
import { UserProjectMemberCard } from '../../molecules/UserProjectMemberCard/UserProjectMemberCard'
import { SelectWithTextInput } from '../../atoms/SelectWithTextInput/SelectWithTextInput'
import { UserProjectMemberDTO } from '@fynde/dtos/dist'

export interface ProjectPageMembersTabProps {
  projectId: string
}

export const ProjectPageMembersTab: React.FC<ProjectPageMembersTabProps> = ({ projectId }) => {
  const { t } = useTranslation()

  // data
  const project = useStoreState((store) => store.projects.project(projectId))
  const membersLoadingStatus = useStoreState((store) =>
    store.projectMembers.projectLoadingStatus(projectId)
  )
  const members = useStoreState((store) => store.projectMembers.projectMembers(projectId))
  const pendingMembers = useStoreState((store) =>
    store.projectMembers.projectPendingMembers(projectId)
  )

  // my informations
  const userId = useStoreState((store) => store.user.userId())
  const user = useStoreState((store) => store.user.user)
  const isTheProjectOwner = useStoreState((store) => store.projects.isTheProjectOwner)
  const [currentUser, setCurrentUser] = useState<UserProjectMemberDTO | undefined>(undefined)

  const [currentUserRole, setCurrentUserRole] = useState<string>('')
  const isCurrentUserRoleOk = useMemo(() => currentUserRole.search(/\S/) > -1, [currentUserRole])
  const [showCurrentUserError, setShowCurrentUserError] = useState<boolean>(false)
  const updateUserRole = useStoreActions((store) => store.projectMembers.updateMemberRole)

  useEffect(() => {
    if (project === null) return
    const currentUser = members.filter((member) => member.user.email === user.email)[0]
    setCurrentUser(currentUser)
  }, [project])

  useEffect(() => {
    if (currentUser && currentUserRole !== currentUser.role) setCurrentUserRole(currentUser.role)
  }, [currentUser])

  const isCurrentUserTheProjectOwner = useMemo(() => {
    return isTheProjectOwner(projectId, user.email)
  }, [projectId, user, isTheProjectOwner])

  const handleUserDataChange = useCallback(async () => {
    if (!isCurrentUserRoleOk) {
      console.warn("[ProjectPageMembersTab] cannot patch current user's role: role is not correct")
      setShowCurrentUserError(true)
      return
    }
    if (userId === null) {
      console.error("[ProjectPageMembersTab] cannot patch current user's role: userId is missing")
      return
    }
    await updateUserRole({
      projectId: projectId,
      userId: userId,
      role: currentUserRole,
    })
  }, [projectId, userId, currentUserRole, isCurrentUserRoleOk, updateUserRole])

  // invitation
  const [showEmailToInviteError, setShowEmailToInviteError] = useState<boolean>(false)
  const [emailToInvite, setEmailToInvite] = useState<string>('')
  const [isEmailToInviteOk, setIsEmailToInviteOk] = useState<boolean>(false)
  const [invitedRole, setInvitedRole] = useState<string>('')
  const isInvitedRoleOk = useMemo(() => invitedRole.search(/\S/) > -1, [invitedRole])

  useEffect(() => {
    setIsEmailToInviteOk(validateEmail(emailToInvite))
  }, [emailToInvite])

  const inviteMember = useStoreActions((store) => store.projectMembers.inviteMember)
  const handleEmailInvitation = useCallback(() => {
    if (!isEmailToInviteOk || !isInvitedRoleOk) {
      console.warn('[ProjectPageMembersTab] cannot invite new member: email or role is not correct')
      setShowEmailToInviteError(true)
      return
    }
    inviteMember({
      projectId: projectId,
      email: emailToInvite,
      role: invitedRole,
    }).then((success) => {
      if (success) {
        setFeedbackSuccess(FeedbackStatus.Success)
        setFeedbackMessage(t('project.inviteMember.success'))
        setFeedbackVisibility(true)
        setEmailToInvite('')
        setInvitedRole('')
        setShowEmailToInviteError(false)
      } else {
        setFeedbackSuccess(FeedbackStatus.Error)
        setFeedbackMessage(t('project.inviteMember.fail'))
        setFeedbackVisibility(true)
      }
    })
  }, [emailToInvite, isEmailToInviteOk, invitedRole, isInvitedRoleOk, inviteMember, projectId, t])

  // member deletion
  const deleteMember = useStoreActions((store) => store.projectMembers.deleteMember)
  const deletePendingMember = useStoreActions((store) => store.projectMembers.deletePendingMember)

  // roles
  const roles = [
    t('userProjectMemberRole.architect'),
    t('userProjectMemberRole.interiorDesigner'),
    t('userProjectMemberRole.designer'),
    t('userProjectMemberRole.promoter'),
    t('userProjectMemberRole.hotelier'),
    t('userProjectMemberRole.projectManager'),
    t('userProjectMemberRole.client'),
  ]

  // feedback
  const [feedbackVisibility, setFeedbackVisibility] = useState<boolean>(false)
  const [feedbackMessage, setFeedbackMessage] = useState<string>('')
  const [feedbackSuccess, setFeedbackSuccess] = useState<FeedbackStatus>(FeedbackStatus.Success)

  // DOM
  const membersList = useMemo(() => {
    if (!project) return []
    const usersData = []
    for (const pendingMember of pendingMembers) {
      usersData.push({
        firstName: '',
        lastName: '',
        isOwner: false,
        isPending: true,
        email: pendingMember.email,
        phone: undefined,
        role: pendingMember.role || '',
        invitationDate: new Date(pendingMember.createdAt),
        uiFirstColor: undefined,
        uiSecondColor: undefined,
        onEdit: () => console.debug('[ProjectPageMembersTab] onEdit pending-member'),
        onDelete: () =>
          deletePendingMember({ userProjectId: projectId, email: pendingMember.email }),
      })
    }
    for (const member of members) {
      const isOwner = project.ownerEmail === member.user.email
      usersData.push({
        firstName: member.user.firstname || '',
        lastName: member.user.lastname || '',
        isOwner: isOwner,
        isPending: member.user.firstname === null,
        email: member.user.email,
        phone: member.user.professional?.phone || undefined,
        role: member.role || '',
        invitationDate: isOwner ? undefined : new Date(member.createdAt),
        uiFirstColor: member.user.uiFirstColor || undefined,
        uiSecondColor: member.user.uiSecondColor || undefined,
        onEdit: () => console.debug('[ProjectPageMembersTab] onEdit member'),
        onDelete: () => deleteMember({ userProjectId: projectId, userId: member.user.id }),
      })
    }
    return usersData
      .sort((first, second) => {
        const firstName = `${first.firstName} ${first.lastName}`
        const secondName = `${second.firstName} ${second.lastName}`
        return firstName < secondName ? -1 : firstName > secondName ? 1 : 0
      })
      .map((userData) => {
        return (
          <UserProjectMemberCard
            key={userData.email}
            firstName={userData.firstName}
            lastName={userData.lastName}
            isOwner={userData.isOwner}
            isPending={userData.isPending}
            email={userData.email}
            phone={userData.phone}
            role={userData.role}
            invitationDate={userData.invitationDate}
            uiFirstColor={userData.uiFirstColor}
            uiSecondColor={userData.uiSecondColor}
            onEdit={isCurrentUserTheProjectOwner ? userData.onEdit : undefined}
            onDelete={isCurrentUserTheProjectOwner ? userData.onDelete : undefined}
          />
        )
      })
  }, [
    project,
    pendingMembers,
    deleteMember,
    projectId,
    deletePendingMember,
    isCurrentUserTheProjectOwner,
    members,
  ])

  return (
    <div className={'project-members-wrapper'}>
      <Feedback
        isVisible={feedbackVisibility}
        onComplete={() => setFeedbackVisibility(false)}
        message={feedbackMessage}
        status={feedbackSuccess}
      />

      <section>
        <h2>{t('project.myInformations.header')}</h2>
        <div className={'current-user-wrapper'}>
          {currentUser && (
            <>
              <div className={'left-part'}>
                <div className={'members-list'}>
                  {membersList.filter((listItem) => listItem.key === user.email)}
                </div>
              </div>
              <div className={'right-part'}>
                <SelectWithTextInput
                  value={currentUserRole}
                  onChange={setCurrentUserRole}
                  placeholder={t('project.inviteMember.rolePlaceholder')}
                  fontSize={'medium'}
                  options={roles}
                  isWrong={showCurrentUserError && !isCurrentUserRoleOk}
                  optionsPosition={'bottom'}
                />
                {showCurrentUserError && !isCurrentUserRoleOk && (
                  <p className="inline-feedback error">{t('project.inviteMember.roleError')}</p>
                )}
                <Button
                  onClick={handleUserDataChange}
                  isLocked={currentUser.role === currentUserRole}
                  fontSize={'medium'}
                >
                  {t('project.myInformations.button')}
                </Button>
              </div>
            </>
          )}
        </div>
      </section>

      <section>
        <h2>{t('project.otherMembers.header')}</h2>
        <div className={'members-list'}>
          {
            // loading
            membersLoadingStatus !== RequestStatus.Completed ? (
              <CenteredLoading height={'3rem'} />
            ) : // no members
            membersList.length === 1 ? (
              <ReactMarkdown className={'no-members'}>
                {t('project.otherMembers.noMembers').replace(/\n/g, '  \n')}
              </ReactMarkdown>
            ) : (
              // with members
              membersList.filter((listItem) => listItem.key !== user.email)
            )
          }
        </div>
      </section>

      {isCurrentUserTheProjectOwner && (
        <section className={'invitation-section'}>
          <h2>{t('project.inviteMember.header')}</h2>
          <div>
            <TextInput
              type="text"
              size={'medium'}
              placeholder={t('project.inviteMember.emailPlaceholder')}
              value={emailToInvite}
              onChange={setEmailToInvite}
              isWrong={showEmailToInviteError && !isEmailToInviteOk}
            />
            {showEmailToInviteError && !isEmailToInviteOk && (
              <p className="inline-feedback error">{t('register.emailError')}</p>
            )}
          </div>
          <div>
            <SelectWithTextInput
              value={invitedRole}
              onChange={setInvitedRole}
              placeholder={t('project.inviteMember.rolePlaceholder')}
              fontSize={'medium'}
              options={roles}
              isWrong={showEmailToInviteError && !isInvitedRoleOk}
              optionsPosition={'top'}
            />
            {showEmailToInviteError && !isInvitedRoleOk && (
              <p className="inline-feedback error">{t('project.inviteMember.roleError')}</p>
            )}
          </div>
          <Button onClick={handleEmailInvitation} fontSize={'medium'}>
            {t('project.inviteMember.button')}
          </Button>
        </section>
      )}
    </div>
  )
}
