import { Thunk, thunk, action, Action, Computed, computed } from 'easy-peasy'
import { UserFileCreationDTO, UserFileDTO, UserFileUpdateDTO } from '@fynde/dtos'
import { StoreModel } from './store'
import { nanoid } from 'nanoid'
import {
  deleteUserFile,
  downloadUserFile,
  getUserFile,
  patchUserFile,
  postUserFile,
} from '../services/user-file'
import { postMedia } from '../services/media'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'

export interface LocalUserFileCreationDTO {
  file: File
  dataUrl: string | null
  name: string
  linkedTo: 'drive' | 'message' | 'user-product'
  projectId: string
  projectProductId: string | null
  userMessageId: string | null
}
export interface LocalUserFile extends LocalUserFileCreationDTO {
  id: string
  uploadProgress: number
  userFileId: string | null
}

export interface UserFilesModel {
  // store
  items: UserFileDTO[]
  localItems: LocalUserFile[]

  // computed
  userFiles: Computed<UserFilesModel, (userFileIds: string[]) => UserFileDTO[]>
  localFiles: Computed<UserFilesModel, (localFileIds: string[]) => LocalUserFile[]>
  linkedLocalFiles: Computed<
    UserFilesModel,
    (linkedTo: string, projectId: string, projectProductId: string | null) => LocalUserFile[]
  >

  // actions
  _addUserFile: Action<UserFilesModel, UserFileDTO>
  _patchUserFile: Action<UserFilesModel, { userFileId: string; dto: UserFileUpdateDTO }>
  _removeUserFile: Action<UserFilesModel, string>
  _addLocalUserFile: Action<UserFilesModel, LocalUserFile>
  _renameLocalUserFile: Action<UserFilesModel, { fileId: string; newName: string }>
  _updateLocalUserFileUploadProgress: Action<
    UserFilesModel,
    { fileId: string; uploadProgress: number }
  >
  _removeLocalUserFile: Action<UserFilesModel, string>

  // thunks
  addLocalUserFile: Thunk<
    UserFilesModel,
    LocalUserFileCreationDTO,
    any,
    StoreModel,
    Promise<LocalUserFile>
  >
  renameLocalUserFile: Thunk<UserFilesModel, { fileId: string; newName: string }, any, StoreModel>
  removeOneLocalUserFile: Thunk<UserFilesModel, string, any, StoreModel>
  removeManyLocalUserFiles: Thunk<UserFilesModel, string[], any, StoreModel>

  postUserFile: Thunk<UserFilesModel, LocalUserFile, any, StoreModel, Promise<UserFileDTO | null>>
  fetchUserFiles: Thunk<UserFilesModel, string[], any, StoreModel, Promise<boolean>>
  patchUserFile: Thunk<
    UserFilesModel,
    { userFileId: string; dto: UserFileUpdateDTO },
    any,
    StoreModel,
    Promise<UserFileDTO | null>
  >
  deleteUserFile: Thunk<UserFilesModel, string, any, StoreModel, Promise<boolean>>
  downloadUserFile: Thunk<UserFilesModel, string, any, StoreModel>
}

export const userFiles: UserFilesModel = {
  // store
  items: [],
  localItems: [],

  // computed
  userFiles: computed((state) => {
    return (userFileIds) => {
      const results = state.items.filter((userFile) => userFileIds.includes(userFile.id))
      return results
    }
  }),

  localFiles: computed((state) => {
    return (localFileIds) => {
      const results = state.localItems.filter((item) => localFileIds.includes(item.id))
      return results
    }
  }),

  linkedLocalFiles: computed((state) => {
    return (linkedTo, projectId, projectProductId) => {
      const results = state.localItems.filter(
        (userFile) =>
          userFile.linkedTo === linkedTo &&
          userFile.projectId === projectId &&
          userFile.projectProductId === projectProductId
      )
      return results
    }
  }),

  _addUserFile: action((state, newItem) => {
    newItem.createdAt = new Date(newItem.createdAt)
    newItem.media.createdAt = new Date(newItem.media.createdAt)
    if (!state.items.some((item) => item.id === newItem.id)) state.items = [...state.items, newItem]
  }),

  _patchUserFile: action((state, { userFileId, dto }) => {
    for (let userFile of state.items) {
      if (userFile.id === userFileId) {
        userFile.name = dto.name
      }
    }
  }),

  _removeUserFile: action((state, itemId) => {
    state.items = state.items.filter((item) => item.id !== itemId)
  }),

  _addLocalUserFile: action((state, item) => {
    if (!state.localItems.includes(item)) state.localItems = [...state.localItems, item]
  }),

  _renameLocalUserFile: action((state, { fileId, newName }) => {
    state.localItems = state.localItems.map((item) => {
      return item.id !== fileId
        ? item
        : {
            ...item,
            name: newName,
          }
    })
  }),

  _updateLocalUserFileUploadProgress: action((state, { fileId, uploadProgress }) => {
    for (let localFile of state.localItems) {
      if (localFile.id === fileId) {
        localFile.uploadProgress = uploadProgress
      }
    }
  }),

  _removeLocalUserFile: action((state, itemId) => {
    state.localItems = state.localItems.filter((item) => item.id !== itemId)
  }),

  addLocalUserFile: thunk(async (actions, payload, { getState }) => {
    console.debug('[store.user-files] add local file:', payload)
    const localFileId = nanoid(10)
    const localUserFile: LocalUserFile = {
      id: localFileId,
      uploadProgress: 0,
      userFileId: null,
      ...payload,
    }
    actions._addLocalUserFile(localUserFile)
    return getState().localFiles([localFileId])[0]
  }),

  renameLocalUserFile: thunk(async (actions, { fileId, newName }) => {
    console.debug(`[store.user-files] rename local file: '${fileId}' to '${newName}'`)
    actions._renameLocalUserFile({ fileId, newName })

    Mixpanel.track(MixpanelEvents.LocalFileRenamed, {
      userFileId: fileId,
      name: newName,
    })
  }),

  removeOneLocalUserFile: thunk(async (actions, localUserFileId) => {
    console.debug('[store.user-files] remove local file:', localUserFileId)
    actions._removeLocalUserFile(localUserFileId)
  }),

  removeManyLocalUserFiles: thunk(async (actions, localFileIds, { getState }) => {
    console.debug('[store.user-files] remove many local files')
    const state = getState()
    const localUserFiles = []
    for (const localFile of state.localItems) {
      if (localFileIds.includes(localFile.id)) localUserFiles.push(localFile)
    }
    for (const file of localUserFiles) {
      actions._removeLocalUserFile(file.id)
    }
  }),

  // thunks with API calls
  postUserFile: thunk(async (actions, localFile, { getStoreState }) => {
    const storeState = getStoreState()
    const userId = storeState.user.userId()
    if (!userId || !storeState.user.token) {
      console.error(
        "[store.user-files] cannot post user-file because user's id or token is missing"
      )
      return null
    }
    // post media
    const media = await postMedia(
      localFile.file,
      (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100
        actions._updateLocalUserFileUploadProgress({
          fileId: localFile.id,
          uploadProgress: progress,
        })
      },
      storeState.user.token
    )
    if (media === null) return null
    // post user-file
    const userFilePayload: UserFileCreationDTO = {
      userId: userId,
      mediaId: media.id,
      name: localFile.name,
    }
    const userFile = await postUserFile(storeState.user.token, userFilePayload)
    if (userFile === null) return null
    // user-file to store
    actions._addUserFile(userFile)
    return userFile
  }),

  fetchUserFiles: thunk(async (actions, userFilesIds, { getStoreState }) => {
    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error('[store.user-files]', `cannot fetch user-files because user's token is missing`)
      return false
    }

    userFilesIds = Array.from(new Set(userFilesIds))
    console.debug('[store.user-files]', `fetch user-files, ids:`, userFilesIds)
    const userFileDTOs = []
    for (const userFileId of userFilesIds) {
      const userFileDTO = await getUserFile(storeState.user.token, userFileId)
      if (userFileDTO !== null) userFileDTOs.push(userFileDTO)
    }
    console.debug('[store.user-files]', `fetched user-files:`, userFileDTOs)
    for (const userFileDTO of userFileDTOs) {
      actions._addUserFile(userFileDTO)
    }

    return true
  }),

  patchUserFile: thunk(async (actions, { userFileId, dto }, { getStoreState }) => {
    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error("[store.user-files] cannot patch a user-file because user's token is missing")
      return null
    }
    const patchedUserFile = await patchUserFile(storeState.user.token, userFileId, dto)
    if (patchedUserFile !== null) {
      actions._patchUserFile({ userFileId: userFileId, dto: dto })

      Mixpanel.track(MixpanelEvents.UserFileRenamed, {
        userFileId,
        name: dto.name,
      })

      return patchedUserFile
    } else {
      return null
    }
  }),

  deleteUserFile: thunk(async (actions, userFileId, { getStoreState }) => {
    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error("[store.user-files] cannot delete a user-file because user's token is missing")
      return false
    }
    const success = await deleteUserFile(storeState.user.token, userFileId)
    if (success) actions._removeUserFile(userFileId)
    return success
  }),

  downloadUserFile: thunk(async (actions, userFileId, { getStoreState }) => {
    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error(
        "[store.user-files] cannot download a user-file because user's token is missing"
      )
      return false
    }
    downloadUserFile(storeState.user.token, userFileId)
  }),
}
