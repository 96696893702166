import React, { useState } from 'react'
import { useCallback } from 'react'
import { ReactComponent as HelpIcon } from '../../../assets/icons/stroked-help-icon.svg'
import './HelperBullet.css'

export interface HelperBulletProps {
  text: string
}

export const HelperBullet: React.FC<HelperBulletProps> = ({
  text,
}) => {

  const [isHelpVisible, setIsHelpVisible] = useState<boolean>(false)
  const handleMouseEnter = useCallback(() => {
    setIsHelpVisible(true)
  }, [])
  const handleMouseExit = useCallback(() => {
    setIsHelpVisible(false)
  }, [])

  return (
    <div
      className={'help-bullet'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseExit}
    >
      <HelpIcon />
      {isHelpVisible && (
        <>
          <div className={'arrow'} />
          <div className={'popup'}>
            {text}
          </div>
        </>
      )}
    </div>
  )
}
