import config from "../config"

export const cloudImageUrl = (
  url: string,
  width?: number,
  height?: number,
  extension?: 'jpg' | 'png' | 'webp'
) => {
  let baseUrl = `https://${config.cloudImage.token}.cloudimg.io/v7/${url}`

  const parameters = {
    width: width,
    height: height,
    funct: 'crop',
    force_format: extension,
  }

  const params = Object.entries(parameters).map(([key, value]) =>
    value !== undefined ? `${key}=${value}` : undefined
  )

  const paramsString = params.filter((p) => p !== undefined).join('&')

  return `${baseUrl}${paramsString ? `?${paramsString}` : ''}`
}
