import React, { useMemo } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useStoreState } from '../store/hooks'
import LoadingPage from '../pages/LoadingPage'
import { Routes } from '../utils/Routes'
import { RequestStatus } from './reqStatus'

export interface ProtectedRouteProps extends RouteProps {}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { component, ...otherProps } = props;

  const localUserDataStatus = useStoreState((state) => state.user.localDataStatus);
  const userToken = useStoreState((state) => state.user.token);

  const isAuthorized = useMemo(() => {
    let result = undefined;
    if (localUserDataStatus === RequestStatus.Completed)
      result = userToken != null;
    //console.debug('[ProtectedRoute] useMemo', props.path, result);
    return result;
  }, [localUserDataStatus, userToken])

  return (
    <>
      {isAuthorized === undefined ? (
        <>
          <Route {...otherProps}>
            <LoadingPage/>
          </Route>
        </>
      ) : (
        isAuthorized ? (
          <>
            {console.debug("[ProtectedRoute] authorized:", props)}
            <Route {...props}/>
          </>
        ) : (
          <>
            {console.debug("[ProtectedRoute] redirected:", otherProps)}
            <Route {...otherProps}>
              <Redirect push to={Routes.Login} />
            </Route>
          </>
        )
      )}
    </>
  )
}

export default ProtectedRoute;