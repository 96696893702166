import React from 'react'
import styled from 'styled-components'

const Container = styled.div<{ black: boolean }>`
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.colors[props.black ? 'black' : 'primary200']};

  & > * + * {
    margin-left: 4px;
  }

  font-size: 1.2rem;
`

const PriceValue = styled.span<{ bold: boolean }>`
  color: currentColor;
  font-weight: ${(props) => props.bold ? 700 : 400};
`

const PriceSymbol = styled.span<{ bold: boolean }>`
  color: currentColor;
  font-weight: ${(props) => props.bold ? 700 : 400};
`

export interface PriceProps {
  black?: boolean
  bold?: boolean
  value?: number
  className?: string
}

export const Price: React.FC<PriceProps> = ({ black = false, bold = true, value, className }) => {
  return (
    <Container className={className} black={black}>
      <PriceValue bold={bold}>{value!.toFixed(2) || 0}</PriceValue>
      <PriceSymbol bold={bold}>€</PriceSymbol>
    </Container>
  )
}
