import { Thunk, thunk, action, Action, Computed, computed } from 'easy-peasy'
import { Plugins } from '@capacitor/core'
import { RequestStatus } from '../utils/reqStatus'
import { ProductVariationDTO, UserLikeDTO, UserLikeCreationDTO } from '@fynde/dtos'
import axiosApi from '../utils/axios'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'
import { isApp } from '../utils/platform'
import { StoreModel } from './store'
import { Routes } from '../utils/Routes'

const SAVED_STORAGE_KEY = 'saved'

const { Storage } = Plugins

const getStorageValue = async (): Promise<string[]> => {
  const { value } = await Storage.get({ key: SAVED_STORAGE_KEY })
  return (value && JSON.parse(value)) || []
}

export interface SavedModel {
  addItem: Thunk<SavedModel, ProductVariationDTO, any, StoreModel>
  removeItem: Thunk<SavedModel, string, any, StoreModel>

  items: ProductVariationDTO[]
  itemIds: Computed<SavedModel, string[]>

  isLike: Computed<SavedModel, (id: string) => boolean>

  _addItem: Action<SavedModel, ProductVariationDTO>
  _removeItem: Action<SavedModel, string>
  _setItems: Action<SavedModel, ProductVariationDTO[]>

  fetchItems: Thunk<SavedModel, void, any, StoreModel>
  reqStatus: RequestStatus

  setReqStatus: Action<SavedModel, RequestStatus>
}

export const saved: SavedModel = {
  reqStatus: RequestStatus.Idle,
  items: [],

  isLike: computed([(state) => state.items], (items) => (id: string) =>
    items.some((i) => i.id === id)
  ),

  setReqStatus: action((state, reqStatus) => {
    state.reqStatus = reqStatus
  }),

  itemIds: computed([(state) => state.items], (items) => {
    return items.map((i) => i.id)
  }),

  _addItem: action((state, item) => {
    if (!state.items.includes(item)) state.items = [item, ...state.items]
  }),

  _removeItem: action((state, itemId) => {
    state.items = state.items.filter((i) => i.id !== itemId)
  }),

  _setItems: action((state, items) => {
    state.items = items
  }),

  addItem: thunk(async (actions, item, { getState, getStoreState }) => {
    const state = getState();
    if (state.items.includes(item)) return;

    // app
    if (isApp()) {
      await Storage.set({
        key: SAVED_STORAGE_KEY,
        value: JSON.stringify([item.id, ...state.itemIds]),
      })
    }
    // user account
    else {
      try {
        const store = getStoreState();
        const userId = store.user.userId()
        if (!userId || !store.user.token) return;

        const config = {
          headers: {
            Authorization: `Bearer ${store.user.token}`
          }
        }
        const payload:UserLikeCreationDTO = {
          userId: userId,
          productVariationId: item.id
        }
        console.debug("[store.saved] addItem payload:", JSON.stringify(payload));
        await axiosApi.post<UserLikeDTO>(`/user-like`, payload, config);
      } catch (err) {
        console.error("[store.saved] error when posting user-like:", err);
      }
    }

    actions._addItem(item)

    Mixpanel.track(MixpanelEvents.ProductLiked, {
      productVariationId: item.id
    })
  }),

  removeItem: thunk(async (actions, productVariationId, { getState, getStoreState }) => {
    const currentItemIds = getState().itemIds;
    if (!currentItemIds.includes(productVariationId)) return;
    
    // app
    if (isApp()) {
      await Storage.set({
        key: SAVED_STORAGE_KEY,
        value: JSON.stringify(currentItemIds.filter((itemId) => itemId !== productVariationId)),
      })
    }
    // user account
    else {
      try {
        const store = getStoreState();
        const userId = store.user.userId()
        if (!userId || store.user.token === null) return;

        const config = {
          headers: {
            Authorization: `Bearer ${store.user.token}`
          },
          data: {
            userId: userId,
            productVariationId: productVariationId
          }
        }
        console.debug("[store.saved] removeItem payload:", JSON.stringify(config.data));
        await axiosApi.delete<UserLikeDTO>(`/user-like`, config);
      } catch (err) {
        console.error("[store.saved] error when deleting user-like:", err);
      }
    }

    actions._removeItem(productVariationId)

    Mixpanel.track(MixpanelEvents.ProductDisliked, {
      productVariationId: productVariationId
    })
  }),

  fetchItems: thunk(async (actions, payload, { getStoreState }) => {
    if (!isApp())
      return

    let itemIds: string[] = []
    const storeState = getStoreState()

    actions.setReqStatus(RequestStatus.Loading)

    console.debug("[store.saved] fetch items from local storage");
    try {
      itemIds = await getStorageValue()
    } catch (err) {
      console.error(err)
      actions.setReqStatus(RequestStatus.Failed)
      return
    }

    const fetchItem = async (id: string) => {
      try {
        const resp = await axiosApi.get<ProductVariationDTO>(`${Routes.ProductVariation}/${id}`, {
          params: {
            worldArea: storeState.user.worldArea
          }
        })
        return resp.data
      } catch (err) {
        actions.removeItem(id)
        return undefined
      }
    }

    try {
      const items = await Promise.all(itemIds.map((id) => fetchItem(id)))
      const availableItems = items.filter((i) => i !== undefined) as ProductVariationDTO[]
      actions._setItems(availableItems)
    } catch (err) {
      console.error(err)
      actions.setReqStatus(RequestStatus.Failed)
    }

    actions.setReqStatus(RequestStatus.Completed)
  }),
}
