import axiosApi from '../utils/axios'
import { UserFileCreationDTO, UserFileDTO, UserFileUpdateDTO } from '@fynde/dtos'
import { downloadBlob } from '../utils/download'

export const postUserFile = async (
  userToken: string,
  dto: UserFileCreationDTO
): Promise<UserFileDTO | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.post<UserFileDTO>(`/user-file/`, dto, config)
    return resp.data
  } catch (err) {
    console.error('An error occured while posting a new user-file:', err)
  }
  return null
}

export const getUserFile = async (
  userToken: string,
  userFileId: string
): Promise<UserFileDTO | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.get<UserFileDTO>(`/user-file/${userFileId}`, config)
    return resp.data
  } catch (err) {
    console.error('An error occured while getting a new user-file:', err)
  }
  return null
}

export const patchUserFile = async (
  userToken: string,
  userFileId: string,
  dto: UserFileUpdateDTO
): Promise<UserFileDTO | null> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  try {
    const resp = await axiosApi.patch<UserFileDTO>(`/user-file/${userFileId}`, dto, config)
    return resp.data
  } catch (err) {
    console.error('An error occured while patching a user-file:', err)
  }
  return null
}

export const deleteUserFile = async (userToken: string, userFileId: string): Promise<boolean> => {
  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  let success = true
  try {
    await axiosApi.delete<UserFileDTO>(`/user-file/${userFileId}`, config)
  } catch (err) {
    console.error('An error occured while deleting a user-file:', err)
    success = false
  }
  return success
}

export const downloadUserFile = async (userToken: string, userFileId: string) => {
  const userFile = await getUserFile(userToken, userFileId)
  if (!userFile) return

  const config = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    responseType: 'blob' as 'blob',
  }
  const blob: { data: BlobPart } = await axiosApi.get(`/user-file/download/${userFileId}`, config)

  downloadBlob(blob.data, userFile.name)
}
