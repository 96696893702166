import { Thunk, thunk, Action, action, Computed, computed } from 'easy-peasy'
import { ProductVariationPaginatedDTO, ProductVariationFiltersDTO, WorldArea } from '@fynde/dtos'
import { RequestStatus } from '../utils/reqStatus'
import { StoreModel } from './store'
import Mixpanel, { MixpanelEvents } from '../utils/mixpanel'
import { getProductVariations } from '../services/product-variation'
import { MAX_PRICE, MIN_PRICE } from './filters'

const ITEM_PER_PAGE = 20

export interface SearchModel {
  // store
  reqStatus: RequestStatus
  reqStatusMore: RequestStatus
  total: number | null
  results: ProductVariationPaginatedDTO['data'] | null
  wrongResultsCount: number

  // computed
  areAllResultsLoaded: Computed<SearchModel, boolean>
  isFetchingMore: Computed<SearchModel, boolean>
  filters: Computed<SearchModel, ProductVariationFiltersDTO, StoreModel>

  // actions
  setReqStatus: Action<SearchModel, RequestStatus>
  setReqStatusMore: Action<SearchModel, RequestStatus>
  setResults: Action<SearchModel, ProductVariationPaginatedDTO['data'] | null>
  appendResults: Action<SearchModel, ProductVariationPaginatedDTO['data']>
  setTotal: Action<SearchModel, number | null>
  setWrongResultsCount: Action<SearchModel, number>

  // thunks
  fetch: Thunk<SearchModel, void, any, StoreModel>
  fetchMore: Thunk<SearchModel, void | (() => void), any, StoreModel>
  clear: Thunk<SearchModel, void, any, StoreModel>
}

export const search: SearchModel = {
  reqStatus: RequestStatus.Idle,
  setReqStatus: action((state, requestStatus) => {
    state.reqStatus = requestStatus
  }),

  reqStatusMore: RequestStatus.Idle,
  setReqStatusMore: action((state, requestStatus) => {
    state.reqStatusMore = requestStatus
  }),

  results: null,
  wrongResultsCount: 0,
  setResults: action((state, results) => {
    if (results === null) {
      state.results = null
    } else {
      const nonNullResults = results.filter((result) => result !== null)
      state.results = [...nonNullResults]
      state.wrongResultsCount = results.length - nonNullResults.length
    }
    console.debug(
      '[store.search] set results:',
      state.results ? state.results.length : null,
      'wrong:',
      state.wrongResultsCount
    )
  }),
  appendResults: action((state, results) => {
    const nonNullResults = results.filter((result) => result != null)
    if (state.results == null) {
      state.results = [...nonNullResults]
    } else {
      state.results = [...state.results, ...nonNullResults]
    }
    state.wrongResultsCount += results.length - nonNullResults.length
    console.debug(
      '[store.search] append results:',
      state.results ? state.results.length : null,
      'wrong:',
      state.wrongResultsCount
    )
  }),

  total: null,
  setTotal: action((state, total) => {
    state.total = total
  }),

  setWrongResultsCount: action((state, count) => {
    state.wrongResultsCount = count
  }),

  filters: computed([(state, storeState) => storeState], (storeState) => {
    let result: ProductVariationFiltersDTO = {
      search:
        storeState.filters.keywords.search(/\w/) > -1 ? storeState.filters.keywords : undefined,
      brandIds:
        storeState.filters.selectedBrandIds.length > 0
          ? storeState.filters.selectedBrandIds
          : undefined,
      colorTagIds:
        storeState.filters.selectedColorTagIds.length > 0
          ? storeState.filters.selectedColorTagIds
          : undefined,
      materialTagIds:
        storeState.filters.selectedMaterialTagIds.length > 0
          ? storeState.filters.selectedMaterialTagIds
          : undefined,
      priceMin:
        storeState.filters.selectedPriceRange.min > MIN_PRICE
          ? storeState.filters.selectedPriceRange.min
          : undefined,
      priceMax:
        storeState.filters.selectedPriceRange.max < MAX_PRICE
          ? storeState.filters.selectedPriceRange.max
          : undefined,
      objectDetectionTypes: storeState.filters.selectedObjectDetectionTypes
        ? storeState.filters.selectedObjectDetectionTypes
        : undefined,
      visualSearchIds: storeState.computerVision.visualSearch
        ? [storeState.computerVision.visualSearch?.id]
        : undefined,
      firstVariationOnly: true,
    }
    // remove undefined values
    result = JSON.parse(
      JSON.stringify(result, (key, value) => {
        if (value === undefined) return undefined
        return value
      })
    )
    return result
  }),

  areAllResultsLoaded: computed(
    [(state) => state.total, (state) => state.results?.length, (state) => state.wrongResultsCount],
    (total, loaded, wrong) => {
      return !total ? false : total === (loaded || 0) + (wrong || 0)
    }
  ),

  isFetchingMore: computed([(state) => state], (state) => {
    return state.reqStatusMore === RequestStatus.Loading
  }),

  fetch: thunk(async (actions, payload, { getState, getStoreState }) => {
    const state = getState()
    const storeState = getStoreState()
    let filters = state.filters

    console.debug("[store.search] Fetch search's results", filters)
    actions.setReqStatus(RequestStatus.Loading)
    actions.setResults(null)

    const filtersDTO = {
      ...filters,
      worldArea: (storeState.user.worldArea as WorldArea) || undefined,
      useGreatResultsObjectTypes: true,
    }
    const skip = 0
    const take = ITEM_PER_PAGE

    Mixpanel.track(MixpanelEvents.SearchLaunched, {
      filters: filtersDTO,
    })

    let resp = await getProductVariations(filtersDTO, take, skip)
    if (!resp) {
      actions.setReqStatus(RequestStatus.Failed)
      return
    }
    console.debug('[store.search] search results:', resp.data.length, '/', resp.total)

    // If no results, clear the object detection types and try again
    if (
      resp.total === 0 &&
      !!filters.objectDetectionTypes &&
      filters.objectDetectionTypes.length > 0
    ) {
      console.debug('[store.search] no result, refetch without object types...')
      filtersDTO.objectDetectionTypes = undefined
      resp = await getProductVariations(filtersDTO, take, skip)
      if (!resp) {
        actions.setReqStatus(RequestStatus.Failed)
        return
      }
    }

    actions.setResults(resp.data)
    actions.setTotal(resp.total)
    actions.setReqStatus(RequestStatus.Completed)

    Mixpanel.track(MixpanelEvents.SearchResultsReceived, {
      filters: filtersDTO,
      skip,
      take,
    })
  }),

  fetchMore: thunk(async (actions, callback, { getState, getStoreState }) => {
    const state = getState()
    const storeState = getStoreState()

    if (!storeState.search.results) return

    const filters = state.filters
    console.debug("[store.search] Fetch more search's results", filters)

    actions.setReqStatusMore(RequestStatus.Loading)

    const filtersDTO = {
      ...filters,
      worldArea: (storeState.user.worldArea as WorldArea) || undefined,
      useGreatResultsObjectTypes: true,
    }
    const skip = storeState.search.results.length + storeState.search.wrongResultsCount
    const take = ITEM_PER_PAGE
    let resp = await getProductVariations(filtersDTO, take, skip)
    if (!resp) {
      actions.setReqStatusMore(RequestStatus.Failed)
      return
    }

    Mixpanel.track(MixpanelEvents.LoadResultsPagination, { ...filtersDTO, skip, take })

    actions.appendResults(resp.data)
    actions.setReqStatusMore(RequestStatus.Completed)

    Mixpanel.track(MixpanelEvents.SearchResultsReceived, {
      filters: filtersDTO,
      skip,
      take,
    })

    if (callback) callback()
  }),

  clear: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setReqStatus(RequestStatus.Idle)
    actions.setReqStatusMore(RequestStatus.Idle)
    actions.setResults(null)
    actions.setTotal(null)
    actions.setWrongResultsCount(0)
    getStoreActions().filters.clearFiltersWithoutFetch()
  }),
}
