import React, { useState } from 'react'
import { useCallback } from 'react'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down-icon.svg'
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross-icon.svg'
import './Select.css'

export interface SelectOption {
  value: string | number
  displayedText?: string
  picto?: JSX.Element
}

export interface SelectProps {
  options: SelectOption[]
  selectedOption: SelectOption | null
  isClearable?: boolean // if true, selectionOption can be null
  placeholder?: string | null // if selectedOption === null
  isLocked?: boolean
  onChange: (newSelection: SelectOption | null) => void
  isWrong?: boolean // style as feedback
  isGood?: boolean // style as feedback
  className?: string
  fontSize?: 'small' | 'medium'
  choicesAnchor?: 'left' | 'right'
}

export const Select: React.FC<SelectProps> = ({
  options,
  selectedOption,
  isClearable = false,
  placeholder = null,
  isLocked = false,
  onChange,
  isWrong = false,
  isGood = false,
  className,
  fontSize = 'small',
  choicesAnchor = 'left',
}) => {
  const [isOpened, setIsOpened] = useState(false)

  const toggleOptions = useCallback(() => {
    if (isLocked) return
    setIsOpened(!isOpened)
  }, [isLocked, isOpened])

  function handleListClick(option: SelectOption) {
    console.debug("[Select] handleListClick, clicked option's value:", option)
    setIsOpened(false)
    onChange(option)
  }

  function clearSelection() {
    setIsOpened(false)
    onChange(null)
  }

  const handleMouseLeave = useCallback(() => {
    if (isOpened) {
      setIsOpened(false)
    }
  }, [isOpened])

  let wrapperClassNames = ''
  if (isGood) wrapperClassNames += ' good'
  if (isWrong) wrapperClassNames += ' wrong'
  if (selectedOption?.displayedText !== placeholder) wrapperClassNames += ' selected'
  if (isOpened) wrapperClassNames += ' opened'
  if (isLocked) wrapperClassNames += ' disabled'

  return (
    <div
      className={`select ${fontSize} ${className || ''} ${wrapperClassNames}`}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`selection-wrapper`}>
        <div className={'option'} onClick={toggleOptions}>
          {selectedOption ? (
            <>
              {selectedOption.picto}
              {selectedOption.displayedText && <div>{selectedOption.displayedText}</div>}
            </>
          ) : (
            <div className={'placeholder'}>{placeholder}</div>
          )}
          {selectedOption &&
            selectedOption.displayedText !== placeholder &&
            isClearable === true && <CrossIcon className={'cross'} onClick={clearSelection} />}
          <ArrowDownIcon
            className={`arrow ${isOpened ? 'opened' : 'closed'}`}
            onClick={toggleOptions}
          />
        </div>
      </div>
      {isOpened && (
        <div className={`options-wrapper ${choicesAnchor}`}>
          {options.map((option) => (
            <div className={'option'} key={option.value} onClick={(e) => handleListClick(option)}>
              {option.picto}
              {option.displayedText && <span>{option.displayedText}</span>}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
