import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { useParams } from 'react-router-dom'
import { IonPage, IonContent, IonAlert, useIonViewDidEnter } from '@ionic/react'
import { Plugins } from '@capacitor/core'
import styled from 'styled-components'
import { ProductVariationDTO, ProductVariationImageDTO } from '@fynde/dtos'
import { LikeButton } from '../components/organisms/LikeButton/LikeButton'
import { SimilarProducts } from '../components/organisms/SimilarProducts/SimilarProducts'
import { BaseDesktopLayout } from '../components/organisms/BaseDesktopLayout/BaseDesktopLayout'
import { AppCarousel } from '../components/molecules/AppCarousel/AppCarousel'
import {
  DesktopCarousel,
  ProductPictureDTO,
} from '../components/molecules/DesktopCarousel/DesktopCarousel'
import { Price } from '../components/atoms/Price/Price'
import { TextEmphasis } from '../components/molecules/TextEmphasis/TextEmphasis'
import { InformationSection } from '../components/molecules/InformationSection/InformationSection'
import { CenteredLoading } from '../components/molecules/CenteredLoading/CenteredLoading'
import { Button } from '../components/atoms/Button/Button'
import { BackButton } from '../components/atoms/BackButton/BackButton'
import { Loading } from '../components/atoms/Loading/Loading'
import { Img } from '../components/atoms/Img/Img'
import { NoResults } from '../components/atoms/NoResults/NoResults'
import { useStoreState, useStoreActions } from '../store/hooks'
import axiosApi from '../utils/axios'
import Mixpanel, { getCurrentPageName, MixpanelEvents } from '../utils/mixpanel'
import { cloudImageUrl } from '../utils/cloudImage'
import { Routes } from '../utils/Routes'
import config from '../config'
import { isApp } from '../utils/platform'
import { ReactComponent as ProjectIcon } from '../assets/icons/hashtag-icon.svg'
import { AddToProjectModalWrapper } from '../components/organisms/AddToProjectModal/AddToProjectModalWrapper'
import { useWindowSize } from '../utils/useWindowSize'

const { Browser } = Plugins

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
`

const Content = styled.div`
  padding: 1rem;
  font-size: 0.9rem;

  margin-top: 1.8rem;

  & > * + * {
    margin-top: 1rem;
  }
`

const ProductVariationSheet = styled.div`
  //background-color: rgba(0,255,150,0.1);
  display: flex;
  flex-flow: row;
  align-items: start;
  margin-bottom: 5rem;
`
const ProductVariationImages = styled.div`
  flex-flow: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 280px;
  height: auto;
  margin-right: 2rem;

  @media (min-width: 1000px) {
    flex-basis: 410px;
  }
  @media (min-width: 1200px) {
    flex-basis: 500px;
  }
`
const ProductVariationInfos = styled.div`
  //background-color: rgba(0,255,255,0.1);
  flex-grow: 1;

  & > * + * {
    margin-top: 1.5rem;
  }
`

const StyledImg = styled(Img)<{ backgroundColor: string }>`
  display: inline-block;
  object-fit: contain;
  // Ion slide overrides this
  width: 100vw !important;
  background-color: ${(props) => props.backgroundColor};
`

const BuyingRow = styled.div<{ useMobileLayout: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 480px) {
    justify-content: start;

    & > *:not(:first-child) {
      margin-left: ${(props) => (props.useMobileLayout ? '2.5rem' : '1.5rem')};
    }
  }
`

const PriceLabel = styled(Price)`
  font-size: 1.9rem;
  font-weight: 400;
`

const LikeButtonContainer = styled.span`
  display: inline-block;
  vertical-align: middle;
`

const ProductVariationName = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;

  @media (min-width: 480px) {
    font-size: 1.4rem;
  }
`

const Brand = styled.p`
  color: ${(props) => props.theme.colors.black};
  font-size: 1rem;

  @media (min-width: 480px) {
    font-size: 1.2rem;
    margin: 0 0 0.3rem 0;
  }
`

const SeparatorLine = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey300};
  margin: 2.5rem -5rem 0 -5rem;
  padding: 0;
`

const SimilarTitle = styled.h2`
  margin-bottom: 0.5em;

  @media (min-width: 480px) {
    text-align: center;
  }
`

const StyledBackButton = styled(BackButton)`
  position: fixed;
  top: calc(1rem + constant(safe-area-inset-top));
  top: calc(1rem + env(safe-area-inset-top));
  left: 1rem;
  z-index: 5;

  @media (min-width: 480px) {
    & {
      width: 2.7rem;
      height: 2.7rem;
    }
  }

  @media (min-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    margin-right: 1.5rem;
  }
`

const NoResultsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 0.8rem;
  margin-top: 4rem;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  & > * + * {
    margin-top: 0.25rem;
  }
`

const Part = styled.div``

const ProductVariation: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { t, i18n } = useTranslation()

  // title
  useIonViewDidEnter(() => {
    document.title = config.pageTitlePrefix + t('pageTitle.ProductVariation')
  }, [t])

  // get url params
  const { id } = useParams<{ id: string }>()
  if (id === undefined) {
    history.push(Routes.Home)
    throw new Error('Missing "ID" param, you should now be redirected to the home page')
  }

  // get product variation data
  const [productVariation, setProductVariation] = useState<ProductVariationDTO | null>(null)
  const worldArea = useStoreState((store) => store.user.worldArea)
  useEffect(() => {
    setProductVariation(null)
    setPictures(null)

    Promise.all([
      axiosApi.get<ProductVariationDTO>(`${Routes.ProductVariation}/${id}`, {
        params: {
          worldArea: worldArea,
        },
      }),
      axiosApi.get<ProductVariationImageDTO[]>(`${Routes.ProductVariation}/${id}/images`),
    ]).then((results) => {
      setProductVariation(results[0].data)
      setPictures(results[1].data)
      const firstVSId = results[1].data.map((pic) => pic.visualSearchId).filter((vsId) => vsId)[0]
      setSelectedPicVSId(firstVSId)
      window?.scrollTo({
        top: 0,
      })
    })
  }, [id, worldArea])

  // app
  const useAppLayout = isApp()

  // add to projects
  const [addToProjectAlertState, setAddToProjectAlertState] = useState(false)

  useEffect(() => {
    if (productVariation !== null) {
      console.debug('[ProductVariation] trackProductVariationViewed')
      Mixpanel.trackProductVariationViewed({
        brandId: productVariation.product.brand?.id,
        brandName: productVariation.product.brand?.name,
        productId: productVariation.product.id,
        productName: productVariation.displayName,
        productVariationId: productVariation.id,
        productVariationName: productVariation.displayName,
        from: getCurrentPageName(location.pathname),
      })
    }
  }, [productVariation])

  // like feature
  const isLiked = useStoreState((state) => state.saved.isLike(id))
  const { addItem, removeItem } = useStoreActions((actions) => actions.saved)
  const [unlikeAlertState, setUnlikeAlertState] = useState(false)

  const toggleLiking = useCallback(() => {
    if (isLiked) {
      setUnlikeAlertState(true)
    } else if (productVariation) {
      addItem(productVariation)
    } else {
      // Product hasn't been fully loaded yet
      console.warn('Cannot like the product as the DTO was not fully loaded yet.')
    }
  }, [isLiked, productVariation, addItem])

  const onUnlikeConfirmed = useCallback(() => {
    removeItem(id)
  }, [removeItem, id])

  // buy feature
  const buyingUrl = productVariation?.eshopProductVariation?.url
  const price = productVariation?.eshopProductVariation?.price

  const handleBuyButtonClick = useCallback(() => {
    if (buyingUrl) {
      Browser.open({ url: buyingUrl })
    }

    if (productVariation) {
      Mixpanel.track(MixpanelEvents.BuyButtonClicked, {
        brandId: productVariation.product.brand?.id,
        brandName: productVariation.product.brand?.name,
        productId: productVariation.product.id,
        productName: productVariation.displayName,
        productVariationId: productVariation.id,
        productVariationName: productVariation.displayName,
        eshopProductVariationId: productVariation.eshopProductVariation?.id,
        from: getCurrentPageName(location.pathname),
      })
    }
  }, [buyingUrl, productVariation, location.pathname])

  // pictures
  const [pictures, setPictures] = useState<ProductVariationImageDTO[] | null>(null)
  const [selectedPicVSId, setSelectedPicVSId] = useState<string | null>(null)
  const carouselHeight = '100vw'
  const handleCarouselChange = useCallback(
    (index: number) => {
      if (productVariation && pictures != null && pictures[index] != null) {
        Mixpanel.track(MixpanelEvents.ProductVariationImageSlided, {
          product: {
            brandId: productVariation.product.brand?.id,
            brandName: productVariation.product.brand?.name,
            productId: productVariation.product.id,
            productName: productVariation.displayName,
            productVariationId: productVariation.id,
            productVariationName: productVariation.displayName,
          },
          newPictureIndex: index,
          newPictureId: pictures[index].media.id,
          newPictureUrl: pictures[index].media.publicUrl,
          newPictureType: pictures[index].type,
        })
      }
    },
    [pictures, productVariation]
  )

  // media queries
  const windowSize = useWindowSize()
  const useMobileLayout = useMemo(() => {
    return windowSize.width < 768
  }, [windowSize])

  // scroll feature
  const [hitBottom, setHitBottom] = useState<boolean>(false)

  const handleScroll = useCallback<NonNullable<any['onScroll']>>(
    async (e: any) => {
      if (e.currentTarget) {
        const el = e.currentTarget
        const scrollElement = await el.getScrollElement()
        const hitBot = e.detail.scrollTop + el.clientHeight >= scrollElement.scrollHeight
        setHitBottom(hitBot)
      }
    },
    [setHitBottom]
  )

  useEffect(() => {
    if (hitBottom) setHitBottom(false)
  }, [hitBottom, setHitBottom])

  // DOM
  const backButton = (
    <StyledBackButton
      onClick={() => {
        history.goBack()
        Mixpanel.track(MixpanelEvents.ReturnButtonClicked, {
          from: getCurrentPageName(location.pathname),
        })
      }}
    />
  )

  const buyButton = buyingUrl && (
    <Button onClick={handleBuyButtonClick} fontSize={'medium'}>
      {t('productVariation.buy')}
    </Button>
  )

  const ctaButtons = (
    <BuyingRow useMobileLayout={useAppLayout}>
      {price && <PriceLabel value={price} black={true} bold={useMobileLayout} />}
      {useAppLayout ? (
        <>
          <LikeButtonContainer>
            <LikeButton iconSize={'1.4rem'} isLike={isLiked} onClick={() => toggleLiking()} />
          </LikeButtonContainer>
          {buyButton}
        </>
      ) : (
        <>
          {buyButton}
          <LikeButtonContainer>
            <Button
              filled={false}
              Picto={ProjectIcon}
              onClick={() => setAddToProjectAlertState(true)}
              fontSize={'medium'}
            >
              {t('productVariation.addToProject')}
            </Button>
          </LikeButtonContainer>
        </>
      )}
    </BuyingRow>
  )

  const designerSection: JSX.Element = useMemo(() => {
    if (productVariation == null || productVariation.designer == null) return <></>

    return (
      <InformationSection title={t('productVariation.design')}>
        {productVariation.designer}
      </InformationSection>
    )
  }, [productVariation, t])

  const dimensionSection: JSX.Element = useMemo(() => {
    if (productVariation == null) return <></>

    const { height, width, depth, seatHeight, diameter, weight } = productVariation

    if (
      !(
        height != null ||
        width != null ||
        depth != null ||
        weight != null ||
        seatHeight != null ||
        diameter != null
      )
    )
      return <></>

    const firstLine = [
      height ? `${(height / 10).toFixed(2)}${t('productVariation.heightUnit')}` : null,
      width ? `${(width / 10).toFixed(2)}${t('productVariation.widthUnit')}` : null,
      depth ? `${(depth / 10).toFixed(2)}${t('productVariation.depthUnit')}` : null,
    ]
      .filter((_) => _)
      .join(' x ')

    return (
      <InformationSection title={t('productVariation.dimensions')}>
        {!!firstLine && firstLine}
        {!!firstLine && <br></br>}
        {seatHeight &&
          `${t('productVariation.seatHeight')}: 
          ${(seatHeight / 10).toFixed(2)}
          ${t('productVariation.seatHeightUnit')}`}
        {seatHeight && <br></br>}
        {diameter &&
          `${t('productVariation.diameter')}: 
          ${(diameter / 10).toFixed(2)}
          ${t('productVariation.diameterUnit')}`}
        {diameter && <br></br>}
        {weight &&
          `${t('productVariation.weight')}: 
          ${(weight / 1000).toFixed(2)}
          ${t('productVariation.weightUnit')}`}
        {weight && <br></br>}
      </InformationSection>
    )
  }, [productVariation, t])

  const descriptionSection: JSX.Element = useMemo(() => {
    if (productVariation == null || productVariation.description == null) return <></>

    return (
      <InformationSection title={t('productVariation.description')}>
        <TextEmphasis
          onMoreClicked={() => {
            Mixpanel.track(MixpanelEvents.ReadMoreClicked, {
              productVariationId: productVariation.id,
              deviceLanguage: i18n.language,
            })
          }}
          max={150}
        >
          {productVariation.description}
        </TextEmphasis>
      </InformationSection>
    )
  }, [productVariation, t, i18n.language])

  const similarProductSection: JSX.Element = useMemo(() => {
    if (productVariation == null) return <></>

    return (
      <Part>
        <SimilarTitle>{t('productVariation.similarProducts')}</SimilarTitle>
        {!selectedPicVSId ? (
          <NoResultsContainer>
            <NoResults />
          </NoResultsContainer>
        ) : (
          <SimilarProducts
            hitBottom={hitBottom}
            visualSearchId={selectedPicVSId}
            objectTypes={productVariation.product.objectTypeTagIds}
            productVariationId={productVariation.id}
          />
        )}
      </Part>
    )
  }, [selectedPicVSId, hitBottom, productVariation, t])

  return (
    <>
      <IonAlert
        isOpen={!!unlikeAlertState}
        onDidDismiss={() => setUnlikeAlertState(false)}
        header={t('removalAlert.header')}
        message={t('removalAlert.fromSavedProducts')}
        buttons={[
          {
            text: t('removalAlert.cancel'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => setUnlikeAlertState(false),
          },
          {
            text: t('removalAlert.confirm'),
            handler: () =>
              unlikeAlertState
                ? onUnlikeConfirmed()
                : console.error(
                    "Cannot confirm removal from history as removal alert's state is false"
                  ),
          },
        ]}
      />

      {!useAppLayout && productVariation && (
        <AddToProjectModalWrapper
          isOpen={!!addToProjectAlertState}
          onCancel={() => setAddToProjectAlertState(false)}
          productVariationProps={{
            productVariationId: productVariation.id,
            brandId: productVariation.product.brand?.id,
            productId: productVariation.product.id,
            eshopProductVariationId: productVariation.eshopProductVariation?.id,
            displayName: productVariation.displayName,
            brandName: productVariation.product.brand?.name,
            buyLink: productVariation.eshopProductVariation?.url,
            price: productVariation.eshopProductVariation?.price,
            thumbnail: productVariation.thumbnail!.media.publicUrl,
            thumbnailBgColor: productVariation.thumbnail!.hexaColor || undefined,
          }}
        />
      )}

      {useMobileLayout ? (
        <IonPage>
          <IonContent
            id={'productPageContent'}
            scrollEvents={true}
            onIonScroll={handleScroll}
            forceOverscroll={false}
            style={{ overflow: 'auto', position: 'relative' }}
          >
            {backButton}
            {productVariation === null ? (
              <LoaderContainer>
                <Loading />
              </LoaderContainer>
            ) : (
              <>
                <AppCarousel height={carouselHeight} onChange={handleCarouselChange}>
                  {!pictures
                    ? []
                    : pictures.map((pic) => config.cloudImage.use
                      ? <picture key={pic.media.id}>
                          <source
                            srcSet={cloudImageUrl(pic.media.publicUrl, 800, undefined, 'webp')}
                            type="image/webp"
                          />
                          <source
                            srcSet={cloudImageUrl(pic.media.publicUrl, 800, undefined, 'jpg')}
                            type="image/jpeg"
                          />{' '}
                          <StyledImg
                            src={pic.media.publicUrl}
                            backgroundColor={pic.hexaColor || ''}
                          />
                        </picture>
                      : <StyledImg
                          key={pic.media.id}
                          src={pic.media.publicUrl}
                          backgroundColor={pic.hexaColor || ''}
                        />
                      )}
                </AppCarousel>
                <Content>
                  <Part>
                    <Brand>{productVariation.product.brand?.name}</Brand>
                    <ProductVariationName>{productVariation.displayName}</ProductVariationName>
                  </Part>
                  <Part>{ctaButtons}</Part>
                  {designerSection}
                  {dimensionSection}
                  {productVariation.description != null && descriptionSection}
                  <SeparatorLine />
                  {similarProductSection}
                </Content>
              </>
            )}
          </IonContent>
        </IonPage>
      ) : (
        <BaseDesktopLayout onScroll={handleScroll} maxWidth={'1520px'}>
          <ProductVariationSheet>
            {backButton}
            {productVariation == null || pictures == null ? (
              <ProductVariationInfos>
                <CenteredLoading height={'calc(100vh - 4rem - 4rem)'} />
              </ProductVariationInfos>
            ) : (
              <>
                <ProductVariationImages>
                  <DesktopCarousel
                    imagesData={pictures.map<ProductPictureDTO>((picture) => ({
                      mediaId: picture.media.id,
                      publicUrl: picture.media.publicUrl,
                      hexaColor: picture.hexaColor,
                    }))}
                  />
                </ProductVariationImages>

                <ProductVariationInfos>
                  <Part>
                    <Brand>{productVariation.product.brand?.name}</Brand>
                    <ProductVariationName>{productVariation.displayName}</ProductVariationName>
                  </Part>
                  <Part>{ctaButtons}</Part>
                  {designerSection}
                  {dimensionSection}
                  {descriptionSection}
                </ProductVariationInfos>
              </>
            )}
          </ProductVariationSheet>

          {similarProductSection}
        </BaseDesktopLayout>
      )}
    </>
  )
}

export default ProductVariation
