import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormattedDate } from '../../../utils/tool'
import { Card } from '../../atoms/Card/Card'
import { IconButton } from '../../atoms/IconButton/IconButton'
import { UserIcon } from '../../atoms/UserIcon/UserIcon'
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash-icon.svg'

import './UserProjectMemberCard.css'
import { useCallback } from 'react'
import { useEffect } from 'react'

export interface UserProjectMemberCardProps {
  isPending: boolean
  isOwner: boolean
  firstName: string
  lastName: string
  email?: string
  phone?: string
  role: string
  invitationDate?: Date
  uiFirstColor?: string
  uiSecondColor?: string
  onEdit?: (email: string) => void
  onDelete?: (email: string) => void
}

export const UserProjectMemberCard: React.FC<UserProjectMemberCardProps> = ({
  isPending,
  firstName,
  lastName,
  isOwner,
  email,
  phone,
  role,
  invitationDate,
  uiFirstColor,
  uiSecondColor,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation()

  // edit
  const handleEdit = useCallback(() => {
    if (onEdit) onEdit(email || '')
  }, [onEdit, email])

  // delete
  const handleDelete = useCallback(() => {
    if (onDelete) onDelete(email || '')
  }, [onDelete, email])

  // copy email or phone
  const [copiedData, setCopiedData] = useState<string | undefined>(undefined)

  const copyToClipboard = useCallback((dataType: string, text: string) => {
    setCopiedData(dataType)
    navigator.clipboard.writeText(text)
  }, [])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined = undefined
    if (copiedData) {
      timeoutId = setTimeout(() => setCopiedData(undefined), 1000)
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [copiedData])

  return (
    <Card className={'user-project-member-card' + (isPending ? ' pending' : '')}>
      <section>
        {isPending ? (
          <>
            <div className={'pending-label'}>{t('project.pendingMember')}</div>
            <button className={'copy-button'} onClick={() => copyToClipboard('email', email || '')}>
              <p className={'email bold'}>{email}</p>
              <span className={'hover-label'}>
                {copiedData === 'email'
                  ? t('project.member.copiedInfo')
                  : t('project.member.copyInfo')}
              </span>
            </button>
            {role.search(/\S/) > -1 ? (
              <p className={'role condensed-txt'}>{role}</p>
            ) : (
              <p className={'role light-txt'}>{t('project.member.noDefinedRole')}</p>
            )}
          </>
        ) : (
          <>
            <UserIcon
              name={`${firstName} ${lastName}`}
              bgFirstColor={uiFirstColor}
              bgSecondColor={uiSecondColor}
              withOverlay={false}
            />
            <p className={'name condensed-txt bold'}>{`${firstName} ${lastName}`}</p>
            {role.search(/\S/) > -1 ? (
              <p className={'role condensed-txt'}>{role}</p>
            ) : (
              <p className={'role light-txt'}>{t('project.member.noDefinedRole')}</p>
            )}
            {email && (
              <button className={'copy-button'} onClick={() => copyToClipboard('email', email)}>
                <p className={'email'}>{email}</p>
                <span className={'hover-label'}>
                  {copiedData === 'email'
                    ? t('project.member.copiedInfo')
                    : t('project.member.copyInfo')}
                </span>
              </button>
            )}
            {phone && (
              <button className={'copy-button'} onClick={() => copyToClipboard('phone', phone)}>
                <p className={'phone'}>{phone}</p>
                <span className={'hover-label'}>
                  {copiedData === 'phone'
                    ? t('project.member.copiedInfo')
                    : t('project.member.copyInfo')}
                </span>
              </button>
            )}
          </>
        )}
        <div className={'cta-wrapper on-hover'}>
          {onEdit && (
            <IconButton icon={<EditIcon />} onClick={handleEdit} bg={'alpha'} size={'small'} />
          )}
          {onDelete && (
            <IconButton icon={<DeleteIcon />} onClick={handleDelete} bg={'alpha'} size={'small'} />
          )}
        </div>
        <div className={'date on-hover'}>
          <p className={'light-txt'}>
            {isOwner
              ? t('project.member.isOwner')
              : `${t('project.member.invitedOn')} ${getFormattedDate(invitationDate!, 'DMY')}`}
          </p>
        </div>
      </section>
    </Card>
  )
}
