import { BrandDTO } from '@fynde/dtos'
import { Action, Thunk, action, thunk } from 'easy-peasy'
import { RequestStatus } from '../utils/reqStatus'
import { getAllBrands } from '../services/brand'
import { StoreModel } from './store'

export interface BrandsModel {
  // store
  brands: BrandDTO[]
  reqStatus: RequestStatus

  // actions
  setReqStatus: Action<BrandsModel, RequestStatus>
  addBrand: Action<BrandsModel, BrandDTO>

  // thunks
  fetchBrands: Thunk<BrandsModel, void, any, StoreModel>
}

export const brands: BrandsModel = {
  // store
  brands: [],
  reqStatus: RequestStatus.Idle,

  // actions
  setReqStatus: action((state, status) => {
    state.reqStatus = status
  }),

  addBrand: action((state, newBrand) => {
    if (state.brands.some((brand) => brand.id === newBrand.id)) return
    state.brands.push(newBrand)
  }),

  // thunks
  fetchBrands: thunk(async (actions, payload, { getStoreState }) => {
    const storeState = getStoreState()
    if (storeState.user.token === null) {
      console.error("[store.brands] cannot fetch brands because user's token is missing")
      return false
    }

    console.debug('[store.brands] fetch brands')
    actions.setReqStatus(RequestStatus.Loading)
    const brands = await getAllBrands(storeState.user.token)
    if (brands === null) {
      actions.setReqStatus(RequestStatus.Failed)
      return
    }
    console.log('[store.brands] fetched brands:', brands.length)

    for (const brand of brands) actions.addBrand(brand)
    actions.setReqStatus(RequestStatus.Completed)
  }),
}
