import React, { useState } from 'react'
import styled from 'styled-components'
import { IonSlides, IonSlide } from '@ionic/react'
import { Dots } from '../../atoms/Dots/Dots'

const StyledSlidesContainer = styled.div<{ height: string }>`
  height: ${(props) => props.height};
`

const DotsContainer = styled.div`
  padding: 0;
  padding-top: 0.25rem;
  text-align: center;
  margin: 0;
`

const DotsWrapper = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
`

export const slideOpts = {
  initialSlide: 0,
  speed: 400,
  zoom: false,
}

const ProductPictureSlides = styled(IonSlides)<{ height: string }>`
  img {
    height: ${(props) => props.height};
    object-fit: contain;
  }
`

export interface AppCarouselProps {
  height: string
  children: React.ReactNode[]
  onChange?: (slide: number) => void
}

export const AppCarousel: React.FC<AppCarouselProps> = ({ height, children, onChange }) => {
  const [current, setCurrent] = useState(0)

  return (
    <StyledSlidesContainer height={height}>
      <ProductPictureSlides
        height={height}
        options={slideOpts}
        onIonSlideWillChange={(e) => {
          ;(e.target as any).getActiveIndex().then((index: number) => {
            setCurrent(index)
            onChange?.(index)
          })
        }}
      >
        {children.map((slide, i) => (
          <IonSlide key={i}>{slide}</IonSlide>
        ))}
      </ProductPictureSlides>
      <DotsContainer>
        <DotsWrapper>
          <Dots number={children.length} active={current} small={true} squared={true} />
        </DotsWrapper>
      </DotsContainer>
    </StyledSlidesContainer>
  )
}
