import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../Modal/Modal'
import { ChatMessageFile, ChatMessageFileData } from '../../atoms/ChatMessageFile/ChatMessageFile'
import './UserMessageFileDeletionModal.css'
import ReactMarkdown from 'react-markdown'
import { getExtensionFromMimetype, toReadableFileSize } from '../../../utils/tool'

export interface MinimalProject {
  id: string
  name: string
  isDefault: boolean
}

export interface UserMessageFileDeletionModalProps {
  isOpen: boolean
  onCancel: () => void
  onSubmit: (userMessageId: string, userFileId: string) => void
  userMessageId: string
  chatMessageFileData: ChatMessageFileData
}

export const UserMessageFileDeletionModal: React.FC<UserMessageFileDeletionModalProps> = ({
  isOpen,
  onCancel,
  onSubmit,
  userMessageId,
  chatMessageFileData,
}) => {
  const { t } = useTranslation()

  const fileType = useMemo(() => {
    return getExtensionFromMimetype(chatMessageFileData.mimetype)
  }, [chatMessageFileData])

  // cancel
  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  // submit
  const handleSubmit = useCallback(() => {
    onSubmit(userMessageId, chatMessageFileData.id)
    handleCancel()
  }, [userMessageId, chatMessageFileData, onSubmit, handleCancel])

  return (
    <Modal
      isOpen={isOpen}
      header={t('chatMessageFileDeletionModal.header')}
      cancelButton={t('chatMessageFileDeletionModal.cancel')}
      validateButton={t('chatMessageFileDeletionModal.submit')}
      onCancel={handleCancel}
      onValidate={handleSubmit}
      className={'user-message-file-deletion-modal'}
    >
      <>
        <ReactMarkdown className={'message'}>
          {t('chatMessageFileDeletionModal.longMessage').replace(/\n/g, '  \n')}
        </ReactMarkdown>

        <div className={'file'}>
          <ChatMessageFile {...chatMessageFileData} size={'medium'} withHover={false} />
          <div className={'file-desc condensed-txt'}>
            <div className={'file-specs'}>
              <span className={'size'}>{toReadableFileSize(chatMessageFileData.filesize)}</span>
              {fileType && (
                <>
                  <div className={'divider'} />
                  <span className={'format'}>{fileType.toUpperCase()}</span>
                </>
              )}
            </div>
            <p>{chatMessageFileData.name}</p>
          </div>
        </div>
      </>
    </Modal>
  )
}
